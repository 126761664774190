import styled from '@emotion/styled';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import type { TooltipProps } from '@mui/material/Tooltip';
import type { JSX, ReactNode } from 'react';
import StihlIconRefresh from '../../icons/stihl-icon-refresh';
import { stihlColor } from '../../theme/stihl-style-guide';
import StihlTooltip from '../stihl-tooltip/stihl-tooltip';
import StihlButtonPrimaryLoading from './stihl-button-primary-loading';

const StyledLoadingButton = styled(StihlButtonPrimaryLoading)`
  min-inline-size: 40px;
  block-size: 40px;
  padding: 0;
  border-radius: 50%;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &:hover {
    rect {
      fill: ${stihlColor.stihlOrangeDark};
    }
  }
`;

const StihlButtonRefresh = ({
  // naming convention from @mui/lab/LoadingButton
  // eslint-disable-next-line @typescript-eslint/naming-convention
  loading,
  title: tooltipTitle,
  placement: tooltipPlacement,
  onClick,
  ...rest
}: LoadingButtonProps &
  Omit<TooltipProps, 'children' | 'title'> & {
    title: ReactNode & (string | JSX.Element);
  }): JSX.Element => {
  return loading ? (
    <StyledLoadingButton loading {...rest} />
  ) : (
    <StihlTooltip title={tooltipTitle} placement={tooltipPlacement} {...rest}>
      <StyledIconButton onClick={onClick}>
        <StihlIconRefresh />
      </StyledIconButton>
    </StihlTooltip>
  );
};

export default StihlButtonRefresh;
