/* eslint-disable @typescript-eslint/naming-convention */
export type Connection = {
  sequenceNumber: number;
  sequenceRowNumber: number;
  lastUpdatedTimestamp: string;
  lastUpdatedSubtopic: string;
  lastUpdatedTopic: string;
  cellularIsConnected: boolean | null;
  cellularNetworkInformation_providerId: string | null;
  cellularNetworkInformation_connectionType: string | null;
  cellularNetworkInformation_currentSignalStrength: number | null;
  deviceIsCurrentlyConnected: boolean | null;
  wifiIsConnected: boolean;
  serialNumber: string | null;
  validUntil: string | null;
  deviceId: string;
};

export enum ConnectionDataType {
  WifiConnected = 'wifiConnected',
  DeviceConnected = 'deviceConnected',
  CellularConnected = 'cellularConnected',
}

export type ConnectionGraphData = {
  [ConnectionDataType.CellularConnected]: number;
  [ConnectionDataType.DeviceConnected]: number;
  [ConnectionDataType.WifiConnected]: number;
  timestamp: number;
  providerId: string;
};
/* eslint-enable @typescript-eslint/naming-convention */
