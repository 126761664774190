import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconBin from '../../icons/stihl-icon-bin';
import { stihlColor } from '../../theme/stihl-style-guide';
import { StihlHelperText } from '../stihl-helper-text/stihl-helper-text';

export enum FilterParameterValue {
  SerialNumber = 'serialNumber',
  RangeOfSerialnumbers = 'rangeOfSerialNumbers',
  RangeOfProductionDates = 'rangeOfProductionDates',
  SoftwareVersion = 'softwareVersion',
  ConnectionState = 'connectionState',
  DeviceType = 'deviceType',
  Tag = 'tag',
  Imei = 'imei',
  Imsi = 'imsi',
  DeviceId = 'deviceId',
  DeviceModel = 'deviceModel',
  ProcessingChain = 'processingChain',
  Email = 'email',
  Name = 'name',
  TimeSpan = 'timeSpan',
}

export type RangeObject = {
  identifier: FilterParameterValue;
  start: string;
  end: string;
  range: (Date | null)[];
};

export type ChipType = {
  filter: FilterParameterValue;
  searchTerm: string;
  value?: RangeObject | number;
  chipRenderText?: string;
};

const StyledChipContainer = styled(Container)`
  @media (min-width: 600px) {
    padding-inline: 0;
  }

  @media (min-width: 1200px) {
    max-inline-size: none;
    padding-inline: 0;
  }
`;

const StyledClearChip = styled(Chip)`
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.33rem;
  float: inline-end;
  background-color: ${stihlColor.greyLight};

  > .MuiChip-label {
    line-height: normal;
  }
`;

const StyledChip = styled(Chip)`
  margin-inline-end: 0.5rem;
  margin-block-end: 0.5rem;
  border: 1px solid black;

  > .MuiChip-deleteIcon {
    color: ${stihlColor.black};
  }

  ,
  > .MuiChip-label {
    padding-block-start: 0.25rem;
  }
`;

const StyledClearFiltersGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  block-size: 65px;
  margin-block-start: 1rem;
`;

type StihlChipContainerProps = {
  chips: ChipType[];
  setChips: Dispatch<SetStateAction<ChipType[]>>;
  filterCallback: (filterTerms: ChipType[]) => void;
  isInvalidInput: boolean;
  setInvalidInput: Dispatch<SetStateAction<boolean>>;
  isClearAllFiltersButtonShown?: boolean;
};

export const StihlChipContainer = ({
  chips,
  setChips,
  filterCallback,
  isInvalidInput,
  setInvalidInput,
  isClearAllFiltersButtonShown = true,
}: StihlChipContainerProps): JSX.Element => {
  const { t } = useTranslation();

  function handleDelete(searchedTerm: string): void {
    const remainingChips = chips.filter(
      (chip) => chip.searchTerm !== searchedTerm,
    );
    setChips(remainingChips);
    filterCallback(remainingChips);
  }

  function clearFilters(): void {
    setChips([]);
    setInvalidInput(false);
    filterCallback([]);
  }

  return (
    <Grid container data-testid="chipContainerComponent">
      <Grid item xs={12} style={{ minHeight: '28px' }}>
        {isInvalidInput && (
          <StihlHelperText
            text="filterLabel.duplicateError"
            data-testid="errorText"
          />
        )}
      </Grid>

      <Grid item xs={9} style={{ minHeight: '60px', marginTop: '1rem' }}>
        <StyledChipContainer>
          {chips.map((chip) => {
            return (
              <StyledChip
                key={chip.searchTerm}
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                label={`${t(`filterLabel.${chip.filter}`)}: ${
                  chip.chipRenderText ?? chip.searchTerm
                }`}
                variant="outlined"
                onDelete={() => handleDelete(chip.searchTerm)}
                data-testid="chip"
              />
            );
          })}
        </StyledChipContainer>
      </Grid>
      <StyledClearFiltersGrid item xs={3}>
        {isClearAllFiltersButtonShown && chips.length > 0 && (
          <StyledClearChip
            label={t('filterLabel.clearAllFilters')}
            icon={<StihlIconBin />}
            onClick={clearFilters}
            data-testid="clearFilterChip"
          />
        )}
      </StyledClearFiltersGrid>
    </Grid>
  );
};
