import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconValidFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import CardContentItem from './card-content-item';

export const IconTextWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ValidityText = ({
  name,
  value,
  icon,
}: {
  name: string;
  value: boolean;
  icon?: JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name} icon={icon}>
      {value ? (
        <IconTextWrapper>
          <StihlIconValidFilled color="success" data-testid="validIcon" />
          <Typography color="text.success">
            {t('deviceDetails.isValid')}
          </Typography>
        </IconTextWrapper>
      ) : (
        <IconTextWrapper>
          <StihlIconXFilled color="error" data-testid="inValidIcon" />
          <Typography color="error">{t('deviceDetails.isInvalid')}</Typography>
        </IconTextWrapper>
      )}
    </CardContentItem>
  );
};

export default ValidityText;
