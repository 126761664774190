export type CustomerProfileUid = {
  uid: string;
};

export enum ConnectionErrorReason {
  NoDevice = 'NO_DEVICE',
  AlreadyOwned = 'ALREADY_OWNED',
  Error = 'ERROR',
}

export type CustomerCreationResponse = {
  isPreferredDealerSet: boolean | undefined;
  isCustomerConnectedToEquipment: boolean | undefined;
  connectionErrorReason?: ConnectionErrorReason;
};
export type CustomerDeviceData = {
  customerId: string;
  serialNumber: string | undefined;
};

export type DisconnectCustomerData = {
  serialNumber: string;
  deviceModel: string;
  /* eslint-disable @typescript-eslint/naming-convention */
  scrapDevice: boolean;
  resetDevice: boolean;
  /* eslint-enable @typescript-eslint/naming-convention */
};
