import styled from '@emotion/styled';
import Slider from '@mui/material/Slider';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { RangeFieldValue } from '../../../../model';
import CardContentItem from './card-content-item';

const SliderWrapper = styled.div`
  display: flex;
  gap: 1rem;
  max-inline-size: 90%;
  margin: 0 auto;

  svg {
    margin-block-start: 1.7rem;
  }
`;

const StyledSlider = styled(Slider)`
  &.MuiSlider-root {
    margin-block-start: 20px;
  }

  &.Mui-disabled {
    color: ${stihlColor.greyDark};
  }

  .MuiSlider-rail {
    block-size: 0.5rem;
    background-color: ${stihlColor.greyBase};
  }

  .MuiSlider-thumb {
    inline-size: 0.5rem;
    block-size: 0.5rem;
    background-color: ${stihlColor.greyDark};
    border: none;

    ::before {
      display: none;
    }
  }

  .MuiSlider-markLabel[data-index='1'],
  .MuiSlider-markLabel[data-index='3'],
  .MuiSlider-markLabel[data-index='5'] {
    inset-block-start: -20px;
  }

  .MuiSlider-markLabelActive {
    font-weight: 700;
  }
`;

const RangeText = ({
  name,
  value,
}: {
  name: string;
  value: RangeFieldValue;
  iconMin?: JSX.Element;
  iconMax?: JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name}>
      <SliderWrapper>
        {value.minIcon}
        <StyledSlider
          value={value.currentValue}
          marks={value.rangeOptions.map((option) => {
            option.label = t(option.label);
            return option;
          })}
          min={value.rangeOptions[0].value}
          max={[...value.rangeOptions].reverse()[0].value}
          color="primary"
          track={false}
          disabled
        />
        {value.maxIcon}
      </SliderWrapper>
    </CardContentItem>
  );
};

export default RangeText;
