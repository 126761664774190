import styled from '@emotion/styled';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsType } from '../../app-shell/model/app.models';
import QueryComponent from '../../app-shell/ui/query-component';
import TabsBase, { TabPanel } from '../../app-shell/ui/tabs-base';
import { useHelpTabs } from '../service/help-service/help-service';
import { HelpContact } from './help-contact';
import HelpDtcCodes from './help-dtc-codes';
import { HelpFaq } from './help-faq';

const StyledHelpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  block-size: 100%;
`;

export const HelpTabsComponent = ({
  dealerLocale,
}: {
  dealerLocale: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const helpResult = useHelpTabs();
  const { data: tabs } = helpResult;

  const tabLabels =
    tabs?.map((tab) => ({ label: t(tab.title), hasBadge: false })) ?? [];

  function handleTabsChange(
    _: React.ChangeEvent<unknown>,
    newValue: number,
  ): void {
    setActiveTab(newValue);
  }

  function renderTabContent(tabType: TabsType): JSX.Element | null {
    switch (tabType) {
      case TabsType.Faq: {
        return <HelpFaq dealerLocale={dealerLocale} />;
      }
      case TabsType.Contact: {
        return <HelpContact dealerLocale={dealerLocale} />;
      }
      case TabsType.DtcCodes: {
        return <HelpDtcCodes />;
      }
      default: {
        return null;
      }
    }
  }

  return (
    <StyledHelpWrapper>
      <QueryComponent data={helpResult}>
        {tabs && (
          <>
            <TabsBase
              value={activeTab}
              handleChange={handleTabsChange}
              tabs={tabLabels}
              data-testid="helpTabsComponent"
            />
            {tabs.map((tab, index) => (
              <TabPanel key={tab.title} index={index} value={activeTab}>
                {renderTabContent(tab.type)}
              </TabPanel>
            ))}
          </>
        )}
      </QueryComponent>
    </StyledHelpWrapper>
  );
};
