import type { AlertColor } from '@mui/material/Alert';

export type AlertStore = {
  isOpen: boolean;
  message: string;
  severity: AlertColor;
};

const createAlertStore = (): AlertStore => {
  const store: AlertStore = {
    isOpen: false,
    message: '',
    severity: 'success',
  };

  return store;
};

export default createAlertStore;
