import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useCallback, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { removeCustomer } from '../../service/customer-api-client/customer-api-client';

export const RemoveCustomerModal = ({
  customersCounter,
  customersToBeRemoved,
  openModal,
  setOpenModal,
}: {
  customersCounter: number;
  customersToBeRemoved: string[];
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  // set isMounted to false when we unmount the component
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function handleClose(): void {
    setOpenModal(false);
  }

  const handleRemove = useCallback(() => {
    setIsLoading(true);
    Promise.all(
      customersToBeRemoved.map(async (selectedId) =>
        removeCustomer(selectedId),
      ),
    )
      .then(() => {
        if (!isMounted.current) {
          return;
        }
        setIsLoading(false);
        setOpenModal(false);
        return setAlert({
          isOpen: true,
          severity: 'success',
          message: t('customerManagement.successfulRemoveCustomer'),
        });
      })
      .catch((error: AxiosError) => {
        console.warn(error);
        setIsLoading(false);
        setOpenModal(false);
        setAlert({
          isOpen: true,
          severity: 'error',
          message: t('customerManagement.failedRemoveCustomer'),
        });
      });
  }, [customersToBeRemoved, setAlert, setOpenModal, t]);

  return (
    <StihlModal
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      data-testid="removeCustomerModal"
      title={
        customersCounter > 1
          ? t('customerManagement.removeMultipleCustomers')
          : t('customerManagement.removeSingleCustomer')
      }
      actionButtonText={t('remove')}
      handleActionClick={handleRemove}
    >
      {customersCounter > 1 ? (
        <Typography>
          {t('customerManagement.removeMultipleCustomersHint')}
        </Typography>
      ) : (
        <Typography>
          {t('customerManagement.removeSingleCustomerHint')}
        </Typography>
      )}
    </StihlModal>
  );
};
