import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterParameterValue } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type SearchCritriaFilterProps = {
  searchCriteriaCallback: (filterValue: FilterParameterValue) => void;
  selectedFilters: FilterParameterValue[];
  setInvalidChipInput: (value: boolean) => void;
};

// eslint-disable-next-line max-lines-per-function
const SearchCriteriaFilter = ({
  searchCriteriaCallback,
  selectedFilters,
  setInvalidChipInput,
}: SearchCritriaFilterProps): JSX.Element => {
  const [filter, setFilter] = useState<string>(
    FilterParameterValue.SerialNumber,
  );
  const { t } = useTranslation();

  const isCombinableFilterSelected = Boolean(
    selectedFilters.some(
      (selectedFilter) =>
        selectedFilter === FilterParameterValue.RangeOfSerialnumbers ||
        selectedFilter === FilterParameterValue.ConnectionState ||
        selectedFilter === FilterParameterValue.SoftwareVersion ||
        selectedFilter === FilterParameterValue.RangeOfProductionDates ||
        selectedFilter === FilterParameterValue.DeviceType ||
        selectedFilter === FilterParameterValue.Tag ||
        selectedFilter === FilterParameterValue.DeviceId,
    ),
  );

  const isUniqueFilterSelected = Boolean(
    selectedFilters.some(
      (selectedFilter) =>
        selectedFilter === FilterParameterValue.Imsi ||
        selectedFilter === FilterParameterValue.Imei ||
        selectedFilter === FilterParameterValue.SerialNumber,
    ),
  );

  const FilterParameterValues = [
    {
      value: FilterParameterValue.SerialNumber,
      label: t('filterLabel.serialNumber'),
      disabled:
        Boolean(
          selectedFilters.some(
            (selectedFilter) =>
              selectedFilter === FilterParameterValue.Imsi ||
              selectedFilter === FilterParameterValue.Imei,
          ),
        ) || Boolean(isCombinableFilterSelected),
    },
    {
      value: FilterParameterValue.RangeOfSerialnumbers,
      label: t('filterLabel.rangeOfSerialNumbers'),
      disabled: isUniqueFilterSelected,
    },
    {
      value: FilterParameterValue.ConnectionState,
      label: t('filterLabel.connectionState'),
      disabled: isUniqueFilterSelected,
    },

    {
      value: FilterParameterValue.SoftwareVersion,
      label: t('filterLabel.softwareVersion'),
      disabled: isUniqueFilterSelected,
    },
    {
      value: FilterParameterValue.RangeOfProductionDates,
      label: t('filterLabel.rangeOfProductionDates'),
      disabled: isUniqueFilterSelected,
    },
    {
      value: FilterParameterValue.DeviceType,
      label: t('filterLabel.deviceType'),
      disabled: isUniqueFilterSelected,
    },
    {
      value: FilterParameterValue.Tag,
      label: t('filterLabel.tag'),
      disabled: isUniqueFilterSelected,
    },
    {
      value: FilterParameterValue.Imei,
      label: t('filterLabel.imei'),
      disabled:
        Boolean(
          selectedFilters.some(
            (selectedFilter) =>
              selectedFilter === FilterParameterValue.Imsi ||
              selectedFilter === FilterParameterValue.SerialNumber,
          ),
        ) || Boolean(isCombinableFilterSelected),
    },
    {
      value: FilterParameterValue.Imsi,
      label: t('filterLabel.imsi'),
      disabled:
        Boolean(
          selectedFilters.some(
            (selectedFilter) =>
              selectedFilter === FilterParameterValue.Imei ||
              selectedFilter === FilterParameterValue.SerialNumber,
          ),
        ) || Boolean(isCombinableFilterSelected),
    },
    {
      value: FilterParameterValue.DeviceId,
      label: t('filterLabel.deviceId'),
      disabled: isUniqueFilterSelected,
    },
  ];

  function handleFilterCriteriaChange(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setFilter(event.target.value);
    searchCriteriaCallback(event.target.value as FilterParameterValue);
    setInvalidChipInput(false);
  }

  return (
    <StyledWrapper>
      <InputLabel
        variant="standard"
        htmlFor="deviceType"
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('filterLabel.filterCriteria')}
      </InputLabel>
      <StihlSelect
        id="deviceType"
        value={filter}
        onChange={handleFilterCriteriaChange}
        fullWidth
        data-testid="searchCriteriaFilter"
      >
        {FilterParameterValues.map((filterCriteria) => (
          <MenuItem
            key={filterCriteria.value}
            value={filterCriteria.value}
            disabled={filterCriteria.disabled}
          >
            {filterCriteria.label}
          </MenuItem>
        ))}
      </StihlSelect>
    </StyledWrapper>
  );
};

export default SearchCriteriaFilter;
