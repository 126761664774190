import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import type { FC, ReactNode } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';
import StihlCheckbox from '../stihl-checkbox/stihl-checkbox';

type StihlTableRowProps = {
  id: string;
  isItemSelected?: boolean;
  selected?: string[];
  setSelected?: (selectedDeviceIds: string[]) => void;
  isHoveredAction?: boolean;
  children: ReactNode;
  shouldHaveCheckbox?: boolean;
};
export const StihlTableRow: FC<StihlTableRowProps> = ({
  id,
  isItemSelected,
  selected = [],
  setSelected = () => null,
  isHoveredAction = false,
  children,
  shouldHaveCheckbox = true,
}) => {
  function handleClick(): void {
    if (!shouldHaveCheckbox) {
      return;
    }

    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  }

  return (
    <TableRow
      hover={!isHoveredAction}
      onClick={handleClick}
      role="checkbox"
      tabIndex={-1}
      key={id}
      aria-checked={isItemSelected}
      selected={isItemSelected}
      data-testid="stihlTableRow"
      sx={{
        '&.Mui-selected': {
          backgroundColor: `${stihlColor.greyLight}`,
        },
        '&.MuiTableRow-root.Mui-selected:hover': {
          backgroundColor: `${stihlColor.greyMid}`,
        },
      }}
    >
      {shouldHaveCheckbox && (
        <TableCell padding="checkbox" sx={{ paddingLeft: '1.75rem' }}>
          <StihlCheckbox data-testid="checkbox" checked={isItemSelected} />
        </TableCell>
      )}
      {children}
    </TableRow>
  );
};
