import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconDotsConnected from '../../../../../../base/stihl-material-ui/icons/stihl-icon-dots-connected';
import CardContentItem from './card-content-item';

type TeamMowerProps = {
  title: string;
  value?: boolean;
};
const TeamMowerText = ({ value, title }: TeamMowerProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={t(title)}>
      <StihlIconTextFlexbox>
        {value ? (
          <StihlIconDotsConnected color="primary" data-testid="iconOrange" />
        ) : (
          <StihlIconDotsConnected color="grey" data-testid="iconGrey" />
        )}

        <Typography
          sx={{
            marginLeft: '0.5rem',
          }}
        >
          {value
            ? t('deviceDetails.settings.isTeamMower')
            : t('deviceDetails.settings.isNoTeamMower')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardContentItem>
  );
};

export default TeamMowerText;
