import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlThemeColor } from '../../../../base/stihl-material-ui/theme/stihl-theme-colors';
import type { SwVersions } from '../../model/statistics.model';
import StatisticsPieChart from './statistics-piechart';

export type StatisticsSwProps = {
  softwareVersions: SwVersions[];
};

const StatisticsSoftwareVersions = ({
  softwareVersions,
}: StatisticsSwProps): JSX.Element => {
  const { t } = useTranslation();
  const data = softwareVersions.map((swVersion) => ({
    name: swVersion.targetSwVersion,
    value: swVersion.numInstalled,
  }));

  /**
   * one color array is needed for the icon colors (Mui themed component,
   * and one for the recharts component as this one doesn't accept primary as a color)
   */
  const iconColors: StihlThemeColor[] = [
    'stihlOrange',
    'stihlOrangeLight',
    'stihlOrangeMid',
    'stihlOrangeDark',
    'stihlOrangeDeep',
    'yellow',
    'yellowLight',
    'yellowMid',
    'yellowDark',
    'yellowDeep',
  ];
  const colors = [
    `${stihlColor.stihlOrange}`,
    `${stihlColor.stihlOrangeLight}`,
    `${stihlColor.stihlOrangeMid}`,
    `${stihlColor.stihlOrangeDark}`,
    `${stihlColor.stihlOrangeDeep}`,
    `${stihlColor.yellow}`,
    `${stihlColor.yellowLight}`,
    `${stihlColor.yellowMid}`,
    `${stihlColor.yellowDark}`,
    `${stihlColor.yellowDeep}`,
  ];
  return (
    <StatisticsPieChart
      title={t('deviceStatistics.swVersions.installedSwVersions')}
      chartData={data}
      colors={colors}
      iconColors={iconColors}
    />
  );
};
export default StatisticsSoftwareVersions;
