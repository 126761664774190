import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QueryComponent from '../../../app-shell/ui/query-component';
import TabsBase, { TabPanel } from '../../../app-shell/ui/tabs-base';
import EmailSearchResultList from '../../../email-search/ui/email-search-results-list';
import OrganizationSearchResultList from '../../../organization-search/ui/organization-search-results-list';
import DeviceSearchCard from '../../../search/ui/search-device-card';
import type { SearchResult } from '../../service/device-service/device-search-service';
import { Container, ContainerContent } from './tabs-shared';

type DeviceTabsSearchProps = {
  deviceData: SearchResult;
};

const DeviceTabsSearch: FC<DeviceTabsSearchProps> = ({ deviceData }) => {
  const { t } = useTranslation();

  return (
    <>
      <TabsBase
        value={0}
        tabs={[{ label: t('tabs.search'), hasBadge: false }]}
      />

      <TabPanel key="initialTab" value={0} index={0}>
        <Container isFullWidth>
          <ContainerContent>
            <DeviceSearchCard isLoading={deviceData.isLoading} />

            <QueryComponent data={deviceData}>
              {deviceData.type === 'email' && deviceData.data != null && (
                <EmailSearchResultList searchResult={deviceData.data} />
              )}
              {deviceData.type === 'organization' &&
                deviceData.data != null && (
                  <OrganizationSearchResultList deviceIds={deviceData.data} />
                )}
            </QueryComponent>
          </ContainerContent>
        </Container>
      </TabPanel>
    </>
  );
};

export default DeviceTabsSearch;
