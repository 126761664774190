import type { AlertColor } from '@mui/material/Alert';
import type { JSX } from 'react';
import { StihlHelperText } from '../../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import type { CustomerCreationResponse } from '../../../model/customer-profile.model';
import { ConnectionErrorReason } from '../../../model/customer-profile.model';

export const getAlertMessage = (
  customerCreationResponse: CustomerCreationResponse,
): { message: string; severity: AlertColor; reason?: string } => {
  if (customerCreationResponse.isCustomerConnectedToEquipment === false) {
    let reason = '';
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (customerCreationResponse.connectionErrorReason) {
      switch (customerCreationResponse.connectionErrorReason) {
        case ConnectionErrorReason.AlreadyOwned: {
          reason = 'customerRegistration.errorReasonOwnerExists';
          break;
        }
        case ConnectionErrorReason.NoDevice: {
          reason = 'customerRegistration.errorReasonDevice';
          break;
        }
        default: {
          break;
        }
      }
    }

    if (customerCreationResponse.isPreferredDealerSet === false) {
      return {
        message: 'customerRegistration.confirmationWithErrors',
        reason,
        severity: 'warning',
      };
    }

    return {
      message: 'customerRegistration.errorMessageCustomerNotConnected',
      reason,
      severity: 'warning',
    };
  }

  if (customerCreationResponse.isPreferredDealerSet === false) {
    if (customerCreationResponse.isCustomerConnectedToEquipment) {
      return {
        message: 'customerRegistration.errorMessageDealerNotSet',
        severity: 'warning',
      };
    }

    return {
      message: 'customerRegistration.errorMessageDealerNotSetNoMower',
      severity: 'warning',
    };
  }

  return {
    message: 'customerRegistration.confirmation',
    severity: 'success',
  };
};

export const getHelperText = (
  isTouched: boolean,
  error?: string,
): JSX.Element | null => {
  return isTouched && error ? <StihlHelperText text={error} /> : null;
};
