import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import type { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlTableHeader from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import { StihlTableRow } from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-row';
import { getComparator } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInvalid from '../../../../base/stihl-material-ui/icons/stihl-icon-invalid';
import StihlIconPen from '../../../../base/stihl-material-ui/icons/stihl-icon-pen';
import StihlIconValidFilled from '../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { DeviceModel } from '../../../device/model';
import type {
  SoftwareRelease,
  ReleaseManagementColumns,
  ReleaseManagementHeadCell,
} from '../../model/release-management/release-management.model';
import { invalidateReleaseMetadata } from '../../service/device-management-api-client/device-management-api-client';
// eslint-disable-next-line import/max-dependencies
import { useSoftwareVersionsMetadata } from '../../service/device-management-service/device-management-service';

const StyledTableCell = styled(TableCell)<
  TableCellProps & { isHidden: boolean; isInvalid: boolean }
>`
  display: ${(props) => (props.isHidden ? 'none' : 'table-cell')};
  max-inline-size: 170px;
  color: ${(props) => (props.isInvalid ? stihlColor.greyBase : '')};
  overflow-wrap: break-word;
`;

const InvalidIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 32px;
  block-size: 33px;
`;

// eslint-disable-next-line max-lines-per-function
export const ReleaseManagementTable = ({
  softwareReleases,
  handleAddMetadata,
  deviceModel,
}: {
  softwareReleases: SoftwareRelease[];
  handleAddMetadata: (
    deviceModel: DeviceModel,
    version: string,
    fileName: string,
  ) => void;
  deviceModel: DeviceModel;
}): JSX.Element => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof ReleaseManagementColumns>('version');
  const [isHoveredAction, setIsHoveredAction] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [versionInModal, setVersionInModal] = useState('');
  const [_, setAlert] = useAlertStore();

  const softwareVersionsMetadata = useSoftwareVersionsMetadata(
    deviceModel,
    false,
  );

  const headCells: ReleaseManagementHeadCell[] = [
    { id: 'version', label: t('releaseManagement.version'), isSortable: true },
    {
      id: 'fileName',
      label: t('releaseManagement.fileName'),
      isSortable: true,
    },
    {
      id: 'deviceModel',
      label: t('releaseManagement.deviceModel'),
      isSortable: true,
    },
    {
      id: 'isMetadataAvailable',
      label: t('releaseManagement.isMetadataAvailable'),
      isSortable: true,
    },
  ];

  function handleAction(): void {
    const packageId = softwareVersionsMetadata.data?.find(
      (metadata) => metadata.swVersion === versionInModal,
    )?.packageId;

    if (!packageId) {
      setAlert({
        isOpen: true,
        message: 'releaseManagement.invalidateMetadataError',
        severity: 'error',
      });
      return;
    }

    setIsLoading(true);
    void invalidateReleaseMetadata(packageId)
      .then(() =>
        setAlert({
          isOpen: true,
          message: 'releaseManagement.invalidateMetadataSuccess',
          severity: 'success',
        }),
      )
      .catch((_error: Error) =>
        setAlert({
          isOpen: true,
          message: 'releaseManagement.invalidateMetadataError',
          severity: 'error',
        }),
      )
      .finally(() => {
        handleClose();
        setIsLoading(false);
      });
  }

  function onHoverAction(): void {
    setIsHoveredAction(true);
  }
  function onStopHoverAction(): void {
    setIsHoveredAction(false);
  }

  function handleClose(): void {
    setOpenModal(false);
  }

  function handleModalOpen(version: string): void {
    setOpenModal(true);
    setVersionInModal(version);
  }

  function isInvalid(softwareRelease: SoftwareRelease): boolean {
    return softwareRelease.isMetadataAvailable && !softwareRelease.isValid;
  }

  const actions = useCallback(
    (row: SoftwareRelease): JSX.Element => {
      if (isInvalid(row)) {
        return (
          <InvalidIconContainer>
            <StihlTooltip
              title={t('releaseManagement.invalid')}
              placement="left"
            >
              <StihlIconInvalid color="error" />
            </StihlTooltip>
          </InvalidIconContainer>
        );
      }

      if (row.isMetadataAvailable) {
        return (
          <IconButton
            onMouseOver={onHoverAction}
            onMouseOut={onStopHoverAction}
            onClick={() => handleModalOpen(row.version)}
            data-testid="markInvalid"
          >
            <StihlTooltip
              title={t('releaseManagement.markInvalid')}
              placement="left"
            >
              <StihlIconInvalid color="black" />
            </StihlTooltip>
          </IconButton>
        );
      }

      return (
        <IconButton
          onMouseOver={onHoverAction}
          onMouseOut={onStopHoverAction}
          onClick={() =>
            handleAddMetadata(row.deviceModel, row.version, row.fileName)
          }
          data-testid="addMetadata"
        >
          <StihlTooltip
            title={t('releaseManagement.addMetaData')}
            placement="left"
          >
            <StihlIconPen />
          </StihlTooltip>
        </IconButton>
      );
    },
    [handleAddMetadata, t],
  );

  return (
    <>
      <TableContainer>
        <Table data-testid="releaseManagamentTable">
          <StihlTableHeader<ReleaseManagementColumns>
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            headCells={headCells}
            shouldHaveCheckbox={false}
            shouldHaveLastColumnEmpty
          />
          <TableBody>
            {softwareReleases.sort(getComparator(order, orderBy)).map((row) => {
              return (
                <StihlTableRow
                  key={`${row.version}-${row.fileName}`}
                  id={row.version}
                  shouldHaveCheckbox={false}
                  isHoveredAction={isHoveredAction}
                >
                  <StyledTableCell
                    isHidden={false}
                    isInvalid={isInvalid(row)}
                    component="th"
                    scope="row"
                    align="left"
                  >
                    {row.version}
                  </StyledTableCell>
                  <StyledTableCell
                    isHidden={false}
                    isInvalid={isInvalid(row)}
                    align="left"
                  >
                    {row.fileName}
                  </StyledTableCell>
                  <StyledTableCell
                    isHidden={false}
                    isInvalid={isInvalid(row)}
                    align="left"
                  >
                    {row.deviceModel}
                  </StyledTableCell>
                  <StyledTableCell
                    isHidden={false}
                    isInvalid={isInvalid(row)}
                    align="left"
                  >
                    {row.isMetadataAvailable ? (
                      <StihlIconValidFilled
                        // eslint-disable-next-line @getify/proper-ternary/nested
                        color={row.isValid ? 'success' : stihlColor.greyBase}
                      />
                    ) : (
                      <StihlIconXFilled color="error" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell isHidden={false} isInvalid={isInvalid(row)}>
                    {actions(row)}
                  </StyledTableCell>
                </StihlTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StihlModal
        open={openModal}
        onClose={handleClose}
        title={t('releaseManagement.invalidateModalTitle', {
          version: versionInModal,
        })}
        actionButtonText={t('releaseManagement.markInvalid')}
        handleActionClick={handleAction}
        data-testid="invalidateModal"
        isLoading={isLoading || softwareVersionsMetadata.isFetching}
      >
        <Typography>{t('releaseManagement.markInvalidHint')}</Typography>
      </StihlModal>
    </>
  );
};
