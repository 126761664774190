import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const LEFT_COLUMN_SIZE = 4;
export const RIGHT_COLUMN_SIZE = 8;

const StyledGrid = styled(Grid)`
  max-inline-size: 850px;
  margin: 0.5rem 0;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  > svg {
    inline-size: 1rem;
  }
`;

const CardContentItem = ({
  name,
  icon,
  children,
}: {
  name: string;
  icon?: JSX.Element;
  children: ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledGrid container>
      <Grid item xs={LEFT_COLUMN_SIZE} pr=".25rem">
        <FlexGrid>
          {icon}
          <Typography>
            <b>{t(name)}</b>
          </Typography>
        </FlexGrid>
      </Grid>
      <Grid item xs={RIGHT_COLUMN_SIZE} pl=".25rem" data-testid={name}>
        {children}
      </Grid>
    </StyledGrid>
  );
};

export default CardContentItem;
