/* eslint-disable no-secrets/no-secrets */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { UseQueryResult } from '@tanstack/react-query';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormattingParamsDefault } from '../../../base/date-formatting/date-formatting';
import StihlButtonPrimaryLoading from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import StihlTooltip from '../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconCdh from '../../../base/stihl-material-ui/icons/stihl-icon-cdh';
import StihlIconIotPlattform from '../../../base/stihl-material-ui/icons/stihl-icon-iot-plattform';
import StihlIconMobile from '../../../base/stihl-material-ui/icons/stihl-icon-mobile';
import StihlIconPause from '../../../base/stihl-material-ui/icons/stihl-icon-pause';
import StihlIconRefresh from '../../../base/stihl-material-ui/icons/stihl-icon-refresh';
import StihlIconUser from '../../../base/stihl-material-ui/icons/stihl-icon-user';
import StihlIconValidFilled from '../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import ServicePortalHealthGear from '../../../base/stihl-material-ui/images/stihl-image-service-portal-health-gear.svg';
import StandardHealthGear from '../../../base/stihl-material-ui/images/stihl-image-standard-health-gear.svg';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import QueryComponent from '../../app-shell/ui/query-component';
import type { HealthStatus } from '../model/health-status.model';
// eslint-disable-next-line import/max-dependencies
import { useHealthStatus } from '../service/health-service/health-service';

const GEAR_SIZE_IN_PX = 300;

type HealthSystem = {
  name: string;
  hasStatus: string;
};

enum DependentSystems {
  Cdh = 'CDH',
  IotPlatform = 'IoT Platform',
  ImowApp = 'iMowApp',
  ServicePortal = 'Service Portal',
  StihlId = 'STIHL ID',
}

const CardHeader = styled.div`
  position: relative;
  padding: 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

const StyledFlexbox = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HealthSystemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-self: center;
  justify-content: center;
  margin-block-end: 5rem;
  inline-size: 80%;

  @media (max-width: 1024px) {
    inline-size: 600px;
  }
`;

const IconPositionerRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

const HealthGearComponent = styled.div<{
  healthSystem: HealthSystem;
}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  inline-size: ${GEAR_SIZE_IN_PX}px;
  block-size: ${GEAR_SIZE_IN_PX}px;
  color: ${stihlColor.white};
  text-align: center;
  background-image: url(${(props) =>
    props.healthSystem.name === 'Service Portal'
      ? `${ServicePortalHealthGear}`
      : `${StandardHealthGear}`});
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1024px) {
    inline-size: ${GEAR_SIZE_IN_PX * 0.75}px;
    block-size: ${GEAR_SIZE_IN_PX * 0.75}px;
  }
`;

const HealthComponentContainer = styled.div`
  inline-size: ${GEAR_SIZE_IN_PX}px;
  block-size: ${GEAR_SIZE_IN_PX}px;
  margin-block-end: 1rem;
  text-align: center;

  @media (max-width: 1024px) {
    inline-size: ${GEAR_SIZE_IN_PX * 0.75}px;
    block-size: ${GEAR_SIZE_IN_PX * 0.75}px;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &:hover {
    rect {
      fill: ${stihlColor.stihlOrangeDark};
    }
  }
`;

const StyledLoadingButton = styled(StihlButtonPrimaryLoading)`
  min-inline-size: 40px;
  block-size: 40px;
  padding: 0;
  border-radius: 50%;
`;

const StyledSystemHealthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  block-size: 100%;
  padding: 1rem;

  @media screen and (min-width: 2000px) {
    padding: 2rem;
  }
`;

export const ErrorMessages = ({
  headline,
  implications,
}: {
  headline: string;
  implications: string[];
}): JSX.Element => {
  return (
    <>
      <Typography>{headline}</Typography>
      <ul>
        {implications.map((implication) => (
          <li key={implication}>
            <Typography>{implication}</Typography>
          </li>
        ))}
      </ul>
    </>
  );
};

export const HealthDetails = (): JSX.Element => {
  const healthStatusQueryResult = useHealthStatus();

  return (
    <StyledSystemHealthWrapper>
      <QueryComponent data={healthStatusQueryResult}>
        {healthStatusQueryResult.data && (
          <SystemHealthOverview
            healthStatusQueryResult={healthStatusQueryResult}
          />
        )}
      </QueryComponent>
    </StyledSystemHealthWrapper>
  );
};

const HealthComponent = ({ system }: { system: HealthSystem }): JSX.Element => {
  const { t } = useTranslation();

  const getTooltip = (name: string): JSX.Element => {
    switch (name) {
      case DependentSystems.Cdh: {
        return (
          <>
            {t('systemHealthStatus.cdhFunctions1')}
            <ul>
              <li> {t('systemHealthStatus.cdhFunctions2')}</li>
              <li> {t('systemHealthStatus.cdhFunctions3')}</li>
            </ul>
          </>
        );
      }
      case DependentSystems.IotPlatform: {
        return (
          <>
            {t('systemHealthStatus.iotFunctions1')}
            <ul>
              <li> {t('systemHealthStatus.iotFunctions2')}</li>
              <li> {t('systemHealthStatus.iotFunctions3')}</li>
              <li> {t('systemHealthStatus.iotFunctions4')}</li>
            </ul>
          </>
        );
      }
      case DependentSystems.ImowApp: {
        return (
          <>
            {t('systemHealthStatus.appFunctions1')}
            <ul>
              <li> {t('systemHealthStatus.appFunctions2')}</li>
              <li> {t('systemHealthStatus.appFunctions3')}</li>
            </ul>
          </>
        );
      }
      case DependentSystems.StihlId: {
        return (
          <>
            {t('systemHealthStatus.stihlIdFunctions1')}
            <ul>
              <li> {t('systemHealthStatus.stihlIdFunctions2')}</li>
              <li> {t('systemHealthStatus.stihlIdFunctions3')}</li>
              <li> {t('systemHealthStatus.stihlIdFunctions4')}</li>
            </ul>
          </>
        );
      }
      default: {
        return <>{t('systemHealthStatus.ispFunction')}</>;
      }
    }
  };

  const getStatus = (hasStatus: string): JSX.Element => {
    switch (hasStatus) {
      case 'ok': {
        return (
          <>
            <StihlIconValidFilled color="success" data-testid="systemWorking" />
            {t('systemHealthStatus.isWorking')}
          </>
        );
      }
      case 'error': {
        return (
          <>
            <StihlIconXFilled color="error" data-testid="systemNotWorking" />
            {t('systemHealthStatus.isNotWorking')}
          </>
        );
      }
      default: {
        return (
          <>
            <StihlIconPause color="yellow" data-testid="systemTimeOut" />
            {t('systemHealthStatus.isTimeout')}
          </>
        );
      }
    }
  };

  return (
    <HealthComponentContainer>
      <StihlTooltip title={getTooltip(system.name)}>
        <HealthGearComponent healthSystem={system}>
          {system.name === DependentSystems.IotPlatform.toString() && (
            <StihlIconIotPlattform />
          )}
          {system.name === DependentSystems.Cdh.toString() && <StihlIconCdh />}
          {system.name === DependentSystems.ImowApp.toString() && (
            <StihlIconMobile />
          )}
          {system.name === DependentSystems.StihlId.toString() && (
            <StihlIconUser size={32} />
          )}
          {system.name}
        </HealthGearComponent>
      </StihlTooltip>
      <IconPositionerRow>{getStatus(system.hasStatus)}</IconPositionerRow>
    </HealthComponentContainer>
  );
};

export type SystemHealthProps = {
  healthStatusQueryResult: UseQueryResult<HealthStatus, Error>;
};

// eslint-disable-next-line max-lines-per-function
export const SystemHealthOverview = ({
  healthStatusQueryResult,
}: SystemHealthProps): JSX.Element => {
  const { t } = useTranslation();

  const healthStatusData = healthStatusQueryResult.data;

  const displayData: HealthSystem[] = healthStatusData
    ? Object.keys(healthStatusData).map((system) => {
        switch (system) {
          case 'iotPlatform': {
            return {
              name: DependentSystems.IotPlatform,
              hasStatus: healthStatusData.iotPlatform,
            };
          }
          case 'stihlId': {
            return {
              name: DependentSystems.StihlId,
              hasStatus: healthStatusData.stihlId,
            };
          }
          case 'cdh': {
            return {
              name: DependentSystems.Cdh,
              hasStatus: healthStatusData.cdh ?? '',
            };
          }
          case 'customerApp': {
            return {
              name: DependentSystems.ImowApp,
              hasStatus: healthStatusData.customerApp ?? '',
            };
          }
          default: {
            return {
              name: DependentSystems.ServicePortal,
              hasStatus: healthStatusData.servicePlatform,
            };
          }
        }
      })
    : [];

  function handleRefetch(): void {
    void healthStatusQueryResult.refetch();
  }

  return (
    <Card>
      <CardHeader data-testid="SystemHealthStatus">
        <HeaderContainer>
          <Typography variant="h3">{t('systemHealthStatus.title')}</Typography>
          {healthStatusQueryResult.isRefetching ? (
            <StyledLoadingButton loading />
          ) : (
            <StihlTooltip
              title={t('systemHealthStatus.updateTooltip')}
              placement="left"
            >
              <StyledIconButton onClick={handleRefetch}>
                <StihlIconRefresh />
              </StyledIconButton>
            </StihlTooltip>
          )}
        </HeaderContainer>
      </CardHeader>
      {healthStatusData && (
        <CardContent style={{ padding: '1rem' }}>
          <StyledFlexbox>
            <HealthSystemsWrapper>
              {displayData.map((system) => (
                <HealthComponent system={system} key={system.name} />
              ))}
            </HealthSystemsWrapper>

            {healthStatusData.iotPlatform === 'error' && (
              <ErrorMessages
                headline={t('systemHealthStatus.IotPlatformErrorMessage1')}
                implications={[
                  t('systemHealthStatus.IotPlatformErrorMessage2'),
                  t('systemHealthStatus.IotPlatformErrorMessage3'),
                  t('systemHealthStatus.IotPlatformErrorMessage4'),
                ]}
              />
            )}
            {healthStatusData.stihlId === 'error' && (
              <ErrorMessages
                headline={t('systemHealthStatus.stihlIdErrorMessage1')}
                implications={[
                  t('systemHealthStatus.stihlIdErrorMessage2'),
                  t('systemHealthStatus.stihlIdErrorMessage3'),
                ]}
              />
            )}
            {healthStatusData.cdh === 'error' && (
              <ErrorMessages
                headline={t('systemHealthStatus.CdhErrorMessage1')}
                implications={[
                  t('systemHealthStatus.CdhErrorMessage2'),
                  t('systemHealthStatus.CdhErrorMessage3'),
                ]}
              />
            )}
            {healthStatusData.customerApp === 'error' && (
              <ErrorMessages
                headline={t('systemHealthStatus.iMowAppErrorMessage1')}
                implications={[
                  t('systemHealthStatus.iMowAppErrorMessage2'),
                  t('systemHealthStatus.iMowAppErrorMessage3'),
                  t('systemHealthStatus.iMowAppErrorMessage4'),
                ]}
              />
            )}
            <Typography variant="caption" data-testid="systemHealthLastUpdated">
              {t('systemHealthStatus.lastUpdated', {
                lastUpdated: healthStatusQueryResult.dataUpdatedAt,
                formatParams: {
                  lastUpdated: dateTimeFormattingParamsDefault,
                },
              })}
            </Typography>
          </StyledFlexbox>
        </CardContent>
      )}
    </Card>
  );
};

export default HealthDetails;

/* eslint-enable no-secrets/no-secrets */
