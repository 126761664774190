import type { ReactNode, JSX } from 'react';
import type { DealerDetails } from '../modules/app-shell/model/app.models';
import { AppNavigationFrame } from '../modules/app-shell/ui/app-navigation-frame';

const AppMain = ({
  children,

  dealerDetails,
}: {
  children: ReactNode;
  dealerDetails: DealerDetails;
}): JSX.Element => {
  return (
    <AppNavigationFrame dealerDetails={dealerDetails}>
      {children}
    </AppNavigationFrame>
  );
};

export default AppMain;
