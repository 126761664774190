import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlThemeColor } from '../../../../base/stihl-material-ui/theme/stihl-theme-colors';
import StatisticsPieChart from './statistics-piechart';

export type StatisticsConnectionsProps = {
  totalDevicesOffline: number;
  totalDevicesConnectedViaWifi: number;
  totalDevicesConnectedViaCellular: number;
  totalDevicesConnectedViaEthernet: number;
  isConnectedBox: boolean;
};

const StatisticsConnections = ({
  totalDevicesOffline,
  totalDevicesConnectedViaWifi,
  totalDevicesConnectedViaCellular,
  totalDevicesConnectedViaEthernet,
  isConnectedBox,
}: StatisticsConnectionsProps): JSX.Element => {
  const { t } = useTranslation();
  const data = [
    {
      name: t('deviceStatistics.connections.offline'),
      value: totalDevicesOffline,
    },
    {
      name: t('deviceStatistics.connections.wifi'),
      value: totalDevicesConnectedViaWifi,
    },
    {
      name: t('deviceStatistics.connections.cellular'),
      value: totalDevicesConnectedViaCellular,
    },
  ];

  if (isConnectedBox) {
    data.push({
      name: t('deviceStatistics.connections.ethernet'),
      value: totalDevicesConnectedViaEthernet,
    });
  }
  /**
   * one color array is needed for the icon colors (Mui themed component,
   * and one for the recharts component as this one doesn't accept primary as a color)
   */
  const colors = [
    `${stihlColor.blue}`,
    `${stihlColor.black}`,
    `${stihlColor.greyBase}`,
    `${stihlColor.greyLight}`,
  ];
  const iconColors: StihlThemeColor[] = [
    'info',
    'black',
    'grey.500',
    'grey.200',
  ];
  return (
    <StatisticsPieChart
      title={t('deviceStatistics.connections.connectionType')}
      chartData={data}
      colors={colors}
      iconColors={iconColors}
    />
  );
};
export default StatisticsConnections;
