import styled from '@emotion/styled';
import type { JSX } from 'react';

const ContentWrapper = styled.div`
  h2 {
    font-size: 1em;
  }
`;

const ReleaseNotesHeading = styled.div`
  h2 {
    margin: 0;
    font-size: 1em;
  }

  p {
    margin: 0;
  }
`;

const ReleaseNotesContent = (): JSX.Element => {
  return (
    <ContentWrapper>
      <ReleaseNotesHeading>
        <h2>IoT Service Portal 1.11.0</h2>
        <p>2024-07-29</p>
      </ReleaseNotesHeading>
      <h2>New Features</h2>
      <ul>
        <li>Improve mobile connection signal strength reading</li>
        <li>Enable subsidiary role to jump to MYiMOW app</li>
      </ul>
      <h2>General Maintenance</h2>
      <ul>
        <li>Clarification of snackbar notification descriptions</li>
        <li>New GPS position handling for IA02</li>
        <li>Enable for IA02 to disconnect device from customer</li>
        <li>Unify format of date and timestamps</li>
      </ul>
      <h2>Bug Fixes</h2>
      <ul>
        <li>Registration of a customer which already has a STIHL ID</li>
        <li>Fix DTC description text formats</li>
        <li>Prevent Google Translate crashing the iSP web app</li>
      </ul>
    </ContentWrapper>
  );
};

export default ReleaseNotesContent;
