import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { JSX, ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimary from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import StihlSelect from '../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlIconInfo from '../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconTakeOff from '../../../base/stihl-material-ui/icons/stihl-icon-takeoff';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import { AccessLicenses } from '../../device/model';
import { CardBase } from '../../device/ui/card/ui/card-component';
import { SupportFrame } from './support-utils';

function handleClick(): void {
  window.open(
    'https://stihl.service-now.com/sp?id=sc_cat_item&sys_id=40cf53ce1ba36510054adceacd4bcb14&sysparm_category=af11ad711b6a90d4054adceacd4bcb26',
    '_blank',
    'noopener',
  );
}

const HelpContactForm = ({ locale }: { locale: string }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Typography data-testid="languageHint" sx={{ marginBottom: '1rem' }}>
        <StihlIconInfo />
        <Typography variant="h5" component="span" sx={{ marginLeft: '0.5rem' }}>
          {t('help.languageHint')}
        </Typography>
      </Typography>
      <Typography>{t('help.contactInformationForm')}</Typography>
      <Typography>{t('help.answerMessage')}</Typography> <br />
      <SupportFrame
        dealerLocale={locale}
        title="contactForm"
        testId="contactForm"
        urlParameter="imowsp-contact"
      />
    </>
  );
};

const ServiceNowLink = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StihlButtonPrimary data-testid="serviceNowButton" onClick={handleClick}>
      <StihlIconTakeOff />
      {t('help.jumpToServiceNow')}
    </StihlButtonPrimary>
  );
};

export const HelpContact = ({
  dealerLocale,
}: {
  dealerLocale: string;
}): JSX.Element => {
  const [store] = useStore();
  const { t } = useTranslation();
  const [topic, setTopic] = useState('');

  function changeTopic(event: ChangeEvent<HTMLInputElement>): void {
    setTopic(event.target.value);
  }

  if (
    store.license === AccessLicenses.DealerLicense ||
    store.license === AccessLicenses.DealerUsLicense
  ) {
    return (
      <CardBase
        title={t('tabs.contact')}
        isFullHeight
        data-testid="helpContactDealer"
        sx={{
          minHeight: '900px',
        }}
      >
        <HelpContactForm locale={dealerLocale} />
      </CardBase>
    );
  }

  if (
    store.license === AccessLicenses.VuLicense ||
    store.license === AccessLicenses.VuUsLicense
  ) {
    enum ContactReason {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      iMow = 'iMow',
      IotServicePortal = 'IoT Service Portal',
    }

    return (
      <CardBase
        title={t('tabs.contact')}
        isFullHeight={topic === (ContactReason.iMow as string)}
        data-testid="helpContactVu"
        sx={{
          minHeight:
            topic === (ContactReason.iMow as string) ? '1000px' : 'unset',
        }}
      >
        <Typography sx={{ paddingBlockEnd: '1rem' }}>
          {t('help.topicForContact')}
        </Typography>

        <StihlSelect
          value={topic}
          onChange={changeTopic}
          sx={{
            inlineSize: '200px',
            marginBlockEnd: '1rem',
          }}
          data-testid="helpContactSelect"
        >
          <MenuItem value="">{t('help.selectTopic')}</MenuItem>
          <MenuItem value={ContactReason.iMow}>{ContactReason.iMow}</MenuItem>
          <MenuItem value={ContactReason.IotServicePortal}>
            {ContactReason.IotServicePortal}
          </MenuItem>
        </StihlSelect>
        {topic === ContactReason.IotServicePortal.toString() && (
          <>
            <Typography>{t('help.contactServiceNow')}</Typography>
            <br />
            <ServiceNowLink />
          </>
        )}
        {topic === ContactReason.iMow.toString() && (
          <HelpContactForm locale={dealerLocale} />
        )}
      </CardBase>
    );
  }

  return (
    <CardBase
      title={t('tabs.contact')}
      headerElement={<ServiceNowLink />}
      data-testid="helpContactOther"
    >
      <Typography data-testid="helpContact">
        {t('help.contactServiceNow')}
      </Typography>
    </CardBase>
  );
};
