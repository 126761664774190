import styled from '@emotion/styled';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { DeviceModel } from '../../../device/model';
import { CardHeader } from '../../../device/ui/card/ui/card-component';
import type { ReleaseManagementMetaData } from '../../model/release-management/release-management.model';
import { addReleaseMetadata } from '../../service/device-management-api-client/device-management-api-client';
import CheckMetadataOverview from './check-metadata-overview';
import EditMetaDataForm from './edit-meta-data-form';
import { ReleaseManagementOverview } from './release-management-overview';

const BreadCrumbButton = styled(Button)`
  padding: 0;
  color: inherit;
  font-weight: normal;
  font-size: 1rem;
  background: transparent;
  border: none;

  :hover {
    background: transparent;
  }

  :disabled {
    color: inherit;
  }
`;
const ReleaseManagement = (): JSX.Element => {
  const { t } = useTranslation();
  const [breadCrumbStep, setBreadCrumbStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMetadata, setCurrentMetadata] =
    useState<ReleaseManagementMetaData | null>(null);
  const [_, setAlert] = useAlertStore();

  function handleSubmitEditMetadata(metadata: ReleaseManagementMetaData): void {
    setCurrentMetadata(metadata);
    setBreadCrumbStep(2);
  }

  function handleCancelEditMetadata(): void {
    setBreadCrumbStep(0);
    setCurrentMetadata(null);
  }

  function handleAddMetadataSubmit(): void {
    if (currentMetadata) {
      setIsLoading(true);
      void addReleaseMetadata(currentMetadata)
        .then(() =>
          setAlert({ isOpen: true, message: '', severity: 'success' }),
        )
        .catch((_error: Error) =>
          setAlert({
            isOpen: true,
            message: 'releaseManagement.addMetadataError',
            severity: 'error',
          }),
        )
        .finally(() => {
          setBreadCrumbStep(0);
          setIsLoading(false);
        });
    }
  }

  function handleAddMetadata(
    deviceModel: DeviceModel,
    version: string,
    fileName: string,
  ): void {
    setCurrentMetadata({
      deviceModel,
      version,
      fileName,
      isIncremental: true,
    });
    setBreadCrumbStep(1);
  }

  return (
    <Card>
      {breadCrumbStep === 0 && (
        <CardHeader>
          <Typography variant="h3">
            {t('releaseManagement.softwareReleases')}
          </Typography>
        </CardHeader>
      )}
      <CardContent>
        <Breadcrumbs>
          {breadCrumbStep >= 1 && (
            <BreadCrumbButton
              disableRipple
              disabled={breadCrumbStep === 0}
              onClick={() => setBreadCrumbStep(0)}
              data-testid="realeaseManagementBreadcrumb"
            >
              {t('releaseManagement.breadCrumbs.releaseManagement')}
            </BreadCrumbButton>
          )}
          {breadCrumbStep >= 1 && (
            <BreadCrumbButton
              disableRipple
              disabled={breadCrumbStep === 1}
              onClick={() => setBreadCrumbStep(1)}
              data-testid="addMetadataBreadcrumb"
            >
              {t('releaseManagement.breadCrumbs.addMetadata')}
            </BreadCrumbButton>
          )}
          {breadCrumbStep >= 2 && (
            <BreadCrumbButton
              disableRipple
              disabled={breadCrumbStep === 2}
              onClick={() => setBreadCrumbStep(2)}
              data-testid="checkMetadataBreadcrumb"
            >
              {t('releaseManagement.breadCrumbs.checkMetadata')}
            </BreadCrumbButton>
          )}
        </Breadcrumbs>

        {breadCrumbStep === 0 && (
          <ReleaseManagementOverview handleAddMetadata={handleAddMetadata} />
        )}
        <br />
        {currentMetadata && breadCrumbStep === 1 && (
          <EditMetaDataForm
            handleSubmitForm={handleSubmitEditMetadata}
            handleCancel={handleCancelEditMetadata}
            metadata={currentMetadata}
          />
        )}
        {currentMetadata && breadCrumbStep === 2 && (
          <CheckMetadataOverview
            metadata={currentMetadata}
            handleBackToEdit={() => setBreadCrumbStep(1)}
            handleSubmitMetadata={handleAddMetadataSubmit}
            isLoading={isLoading}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ReleaseManagement;
