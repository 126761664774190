import { createContext, useContext, useMemo, useState } from 'react';
import type { ReactNode, SetStateAction, Dispatch, JSX } from 'react';
import type { SessionStore } from './authentication-session-store';
import createSessionStore from './authentication-session-store';

export type StoreContextValue = [
  SessionStore,
  Dispatch<SetStateAction<SessionStore>>,
];

export const StoreContext = createContext<StoreContextValue | null>(null);

type Props = {
  children: ReactNode;
};

export const StoreProvider = ({ children }: Props): JSX.Element => {
  const [store, setStore] = useState<SessionStore>(createSessionStore());
  const value: StoreContextValue = useMemo(() => [store, setStore], [store]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useStore = (): StoreContextValue => {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store;
};
