import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const dateFormattingParamsDefault: Intl.DateTimeFormatOptions = {
  dateStyle: 'long',
};

export const dateTimeFormattingParamsDefault: Intl.DateTimeFormatOptions = {
  ...dateFormattingParamsDefault,
  timeStyle: 'medium',
};

export function useDateFormatting(): {
  toLocaleDateString: (date: Date | number) => string;
  toLocaleDateTimeString: (date: Date | number) => string;
} {
  const { t } = useTranslation();

  const toLocaleDateString = useCallback(
    (date: Date | number): string => {
      return t('toLocaleDateString', {
        value: date,
        formatParams: {
          value: dateFormattingParamsDefault,
        },
      });
    },
    [t],
  );

  const toLocaleDateTimeString = useCallback(
    (date: Date | number): string => {
      return t('toLocaleDateString', {
        value: date,
        formatParams: {
          value: dateTimeFormattingParamsDefault,
        },
      });
    },
    [t],
  );

  return { toLocaleDateString, toLocaleDateTimeString };
}
