import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

export function useLocalStorage<T>(
  storageKey: string,
  fallbackState: T,
): [T, Dispatch<SetStateAction<T>>] {
  const storedValue = localStorage.getItem(storageKey);
  const initialValue = storedValue
    ? (JSON.parse(storedValue) as T)
    : fallbackState;
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
}
