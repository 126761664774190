import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconLink from '../../../../base/stihl-material-ui/icons/stihl-icon-link';
import StihlIconMedal from '../../../../base/stihl-material-ui/icons/stihl-icon-medal';
import type { Customer } from '../../../device/model';
import CustomerDetailsActionsMenu from './customer-details-actions-menu';
import CustomerDetailsRow from './customer-details-row';

const ContentWrapper = styled.div`
  margin-block-end: 1rem;
`;

const StyledCustomerRole = styled(Typography)`
  margin: 1rem 0 0.5rem;
  text-transform: uppercase;
`;

function handlePasswordReset(email: string): void {
  const encodedEmail = btoa(email);
  window.open(
    `${process.env.REACT_APP_STIHL_CONNECTED_BASE_URL}/account/forgot-password?email=${encodedEmail}`,
  );
}

const CustomerDetailsCardContent = ({
  customer,
  isUserDealer,
  hasCustomerAccess,
}: {
  customer: Customer;
  isUserDealer: boolean;
  hasCustomerAccess: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <StyledCustomerRole data-testid="owner">
        {t('customerDetails.owner')}
      </StyledCustomerRole>

      <ContentWrapper data-testid="customer-details">
        <CustomerDetailsRow
          name={t('customerDetails.name')}
          hasMatchingPreferredDealer={customer.hasMatchingPreferredDealer}
          leftIcon={<StihlIconMedal color="greyBase" />}
          value={`${customer.ownerFirstName} ${customer.ownerLastName}`}
          isVerified={customer.isAccountValid}
          hasCustomerAccess={hasCustomerAccess}
          rightIcon={
            <CustomerDetailsActionsMenu
              handlePasswordReset={() =>
                handlePasswordReset(customer.ownerEmail)
              }
              email={customer.ownerEmail}
              isDeputy={false}
              customerId={customer.customerId}
              subscriptionId=""
              name={`${customer.ownerFirstName} ${customer.ownerLastName}`}
              isVerified={customer.isAccountValid}
              isUserDealer={isUserDealer}
            />
          }
          shouldDisplayVerified
        />
        <CustomerDetailsRow
          name={t('customerDetails.email')}
          value={customer.ownerEmail}
          shouldDisplayVerified={false}
        />
        <CustomerDetailsRow
          name={t('customerDetails.salutation')}
          value={customer.ownerSalutation}
          shouldDisplayVerified={false}
        />
      </ContentWrapper>

      {customer.deputies.length > 0 && (
        <StyledCustomerRole>{t('customerDetails.deputies')}</StyledCustomerRole>
      )}
      {customer.deputies.map((data) => (
        <ContentWrapper key={data.deputyEmail}>
          <CustomerDetailsRow
            name={t('customerDetails.name')}
            leftIcon={<StihlIconLink color="greyBase" />}
            hasMatchingPreferredDealer={data.hasDeputyMatchingPreferredDealer}
            value={`${data.deputyFirstName} ${data.deputyLastName}`}
            isVerified={data.isDeputyAccountValid}
            hasCustomerAccess={hasCustomerAccess}
            rightIcon={
              <CustomerDetailsActionsMenu
                handlePasswordReset={() =>
                  handlePasswordReset(data.deputyEmail)
                }
                email={data.deputyEmail}
                isDeputy
                customerId={data.deputyId}
                subscriptionId={data.subscriptionId}
                name={`${data.deputyFirstName} ${data.deputyLastName}`}
                isVerified={data.isDeputyAccountValid}
                isUserDealer={isUserDealer}
              />
            }
            shouldDisplayVerified
          />
          <CustomerDetailsRow
            name={t('customerDetails.email')}
            value={data.deputyEmail}
            shouldDisplayVerified={false}
          />
          <CustomerDetailsRow
            name={t('customerDetails.salutation')}
            value={data.deputySalutation}
            shouldDisplayVerified={false}
          />
        </ContentWrapper>
      ))}
    </>
  );
};

export default CustomerDetailsCardContent;
