import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import type { JSX, ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { CardTab, Meta } from '../../../../model';
import renderSwitch from '../card-utils';

const StyledTabsBar = styled(Tabs)`
  margin: 1rem 0 0 3rem;
`;

const StyledTab = styled(Tab)`
  color: ${stihlColor.black};
  background-color: ${stihlColor.greyMid};
  border: 1px solid ${stihlColor.greyMid};
`;

export const StyledAlert = styled(Alert)`
  margin: 0 0 1rem;
`;

type TabHeadingsProps = {
  value: number;
  handleTabsChange: (_: ChangeEvent<unknown>, newValue: number) => void;
  cardTabs: CardTab[];
  children?: ReactNode;
};

const StyledTabPanelBody = styled.div`
  block-size: 100%;
`;

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <StyledTabPanelBody>{children}</StyledTabPanelBody>}
    </div>
  );
};

export const DeviceStatusTabTitle = ({
  value,
  handleTabsChange,
  cardTabs,
}: TabHeadingsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledTabsBar
      value={value}
      onChange={handleTabsChange}
      data-testid="tabTitleBar"
      TabIndicatorProps={{
        style: {
          blockSize: '0px',
        },
      }}
    >
      {cardTabs.map((tab) => (
        <StyledTab
          key={tab.title}
          label={t(tab.title)}
          disabled={tab.cardFields.length === 0}
          sx={{
            '&.Mui-selected': {
              backgroundColor: `${stihlColor.white}`,
              color: `${stihlColor.black}`,
            },
            '&.MuiTableRow-root.Mui-selected:hover': {
              backgroundColor: `${stihlColor.greyMid}`,
              color: `${stihlColor.black}`,
            },
          }}
        />
      ))}
    </StyledTabsBar>
  );
};

const DeviceStatusTabContent = ({
  cardTabs,
  activeTab,
  meta,
}: {
  cardTabs: CardTab[];
  activeTab: number;
  meta: Meta;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div data-testid="tabContent">
      {cardTabs.map((tab, index) => (
        <TabPanel key={tab.title} value={activeTab} index={index}>
          {tab.title === 'cardTabs.liveStatus' &&
            (meta.isOffline === true || meta.isOffline === undefined) && (
              <StyledAlert
                severity="error"
                data-testid={
                  meta.isOffline ? 'notConnected' : 'connectionUnknown'
                }
              >
                {meta.isOffline
                  ? t('deviceDetails.notConnected')
                  : t('deviceDetails.connectionUnknown')}
              </StyledAlert>
            )}
          {tab.cardFields.map((data) => (
            <div key={data.title}>{renderSwitch(data)}</div>
          ))}
        </TabPanel>
      ))}
    </div>
  );
};

export default DeviceStatusTabContent;
