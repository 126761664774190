import styled from '@emotion/styled';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconValidFilled from '../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledVerificationChip = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  block-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  color: ${(props: VerificationChipProps) =>
    props.isVerified
      ? stihlColor.green
      : unverifiedOrUnknownColor(props.isVerified)};
`;

function unverifiedOrUnknownColor(isVerified: undefined | boolean): string {
  return isVerified === undefined ? stihlColor.greyDark : stihlColor.red;
}

type VerificationChipProps = {
  isVerified: boolean | undefined;
};
const VerificationChip = ({
  isVerified,
}: VerificationChipProps): JSX.Element => {
  const { t } = useTranslation();
  function unverifiedOrUnknown(): JSX.Element {
    return isVerified === undefined ? (
      <>
        <StihlIconXFilled /> {t('customerDetails.unknown')}
      </>
    ) : (
      <>
        <StihlIconXFilled /> {t('customerDetails.unverified')}
      </>
    );
  }

  return (
    <StyledVerificationChip isVerified={isVerified}>
      {isVerified ? (
        <>
          <StihlIconValidFilled /> {t('customerDetails.verified')}
        </>
      ) : (
        unverifiedOrUnknown()
      )}
    </StyledVerificationChip>
  );
};

export default VerificationChip;
