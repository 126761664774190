import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { SearchResult } from '../../service/device-service/device-search-service';

// eslint-disable-next-line max-lines-per-function
export function useDeviceDetailsAlert(deviceData: SearchResult): void {
  const [_alertStore, setAlert] = useAlertStore();
  const { t } = useTranslation();

  // eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
  useEffect(() => {
    if (deviceData.type === 'organization') {
      if (deviceData.data?.length === 0) {
        setAlert({
          isOpen: true,
          message: 'searchErrors.noDevicesInFleet',
          severity: 'warning',
        });
      }

      if (isAxiosError(deviceData.error)) {
        if (
          deviceData.error.response?.status === 400 ||
          deviceData.error.response?.status === 404
        ) {
          setAlert({
            isOpen: true,
            message: 'searchErrors.noFleetOrgFound',
            severity: 'warning',
          });
        } else if (deviceData.error.response?.status === 500) {
          setAlert({
            isOpen: true,
            message: 'searchErrors.fleetSearchError',
            severity: 'warning',
          });
        } else if (deviceData.error.message) {
          setAlert({
            isOpen: true,
            message: deviceData.error.message,
            severity: 'error',
          });
        }
      }
    } else if (deviceData.type === 'device' && isAxiosError(deviceData.error)) {
      if (deviceData.data != null) {
        setAlert({
          isOpen: true,
          message: 'deviceDetails.deviceStatusUpdateError',
          severity: 'error',
        });
      } else if (
        deviceData.error.response?.status === 403 &&
        (
          deviceData.error.response.data as { message?: string }
        ).message?.includes('non US mower')
      ) {
        setAlert({
          isOpen: true,
          message: 'searchErrors.noNonUsPermissions',
          severity: 'error',
        });
      } else if (deviceData.error.response?.status === 500) {
        setAlert({
          isOpen: true,
          message: 'searchErrors.deviceSearchError',
          severity: 'error',
        });
      } else if (deviceData.error.message) {
        setAlert({
          isOpen: true,
          message: deviceData.error.message,
          severity: 'error',
        });
      }
    } else if (deviceData.type === 'email' && isAxiosError(deviceData.error)) {
      if (deviceData.error.response?.status === 500) {
        setAlert({
          isOpen: true,
          message: 'searchErrors.emailSearchError',
          severity: 'error',
        });
      } else if (deviceData.error.response?.status === 404) {
        setAlert({
          isOpen: true,
          message: t('searchErrors.noDevicesFound'),
          severity: 'error',
        });
      } else if (deviceData.error.message) {
        setAlert({
          isOpen: true,
          message: deviceData.error.message,
          severity: 'error',
        });
      }
    }
  }, [deviceData.type, deviceData.data, deviceData.error, setAlert, t]);
}
