import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlIconFilter from '../../../../base/stihl-material-ui/icons/stihl-icon-filter';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type {
  ItemType,
  StihlDeviceTag,
  DeviceConnectionState,
  DeviceType,
} from '../../../device/model';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledSelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type SelectFilterProps = {
  filterLabel: string;
  name: string;
  items: ItemType<DeviceConnectionState | StihlDeviceTag | DeviceType>[];
  handleCallback: (
    name: string,
    value: DeviceConnectionState | StihlDeviceTag | DeviceType,
  ) => void;
};

const SelectFilter = ({
  filterLabel,
  name,
  items,
  handleCallback,
}: SelectFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const [filterInput, setFilterInput] = useState<
    StihlDeviceTag | DeviceConnectionState | DeviceType | ''
  >('');

  useEffect(() => {
    setFilterInput('');
  }, [items, setFilterInput]);

  function handleSelectSubmitCallback(): void {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (filterInput) {
      handleCallback(name, filterInput);
    }
  }

  return (
    <StyledWrapper>
      <StyledSelectGroup>
        <InputLabel
          variant="standard"
          htmlFor="selectItem"
          sx={{ color: `${stihlColor.black}` }}
        >
          {t(`${filterLabel}`)}
        </InputLabel>
        <StihlSelect
          id="selectItem"
          fullWidth
          value={filterInput}
          onChange={(event) =>
            setFilterInput(
              event.target.value as StihlDeviceTag | DeviceConnectionState,
            )
          }
          data-testid="selectFilter"
        >
          <MenuItem value="" disabled>
            {t('select')}
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </StihlSelect>
      </StyledSelectGroup>
      <IconButton
        onClick={handleSelectSubmitCallback}
        data-testid="deviceManagementFilterButton"
        sx={{ marginTop: '1.5rem', marginLeft: '0.25rem' }}
      >
        <StihlIconFilter color="text.primary" />
      </IconButton>
    </StyledWrapper>
  );
};

export default SelectFilter;
