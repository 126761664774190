import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import type { TableCellProps } from '@mui/material/TableCell';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { StihlTableRow } from '../../../../../base/stihl-material-ui/components/stihl-table/stihl-table-row';
import StihlTooltipSmall from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-small';
import StihlIconArrowDown from '../../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconBoxLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-box-link';
import StihlIconMowerLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import type { ErrorValue } from '../../../../device/model';
import { DeviceModel } from '../../../../device/model';
import { SearchParameterValues } from '../../../../device/model/search.model';
import { renderPriority } from '../../../../device/ui/card/ui/card-texts/error-text';
import GreyText from '../../../../device/ui/card/ui/card-texts/grey-text';
import type {
  DeviceManagementColumns,
  ManagedDevice,
} from '../../../model/overview.models';
import type {
  DeviceStatusColumns,
  DeviceStatus,
} from '../../../model/statistics.model';
import { DeviceStatusDisplayType } from '../../../model/statistics.model';
import ConnectionState from '../../common/connection-state';
import {
  DeviceManagementTableContext,
  DeviceStateActionType,
  // eslint-disable-next-line import/max-dependencies
} from './device-management-table.state';

const StyledArrowIconRight = styled(StihlIconArrowDown)`
  transform: rotate(270deg);
`;

const StyledArrowIconLeft = styled(StihlIconArrowDown)`
  transform: rotate(90deg);
`;

const StyledTableCell = styled(TableCell)<
  TableCellProps & { isHidden: boolean }
>`
  display: ${(props) => (props.isHidden ? 'none' : 'table-cell')};
  max-inline-size: 170px;
  font-weight: 400;
  overflow-wrap: break-word;
`;

export type DeviceManagementTableProps = {
  devicesForPage: ManagedDevice[] | DeviceStatus[];
  displayColumns:
    | (keyof DeviceManagementColumns)[]
    | (keyof DeviceStatusColumns)[];
  isAllowedForUpdateManagement: boolean;
  deviceStatusDisplayType?: DeviceStatusDisplayType;
};

const ErrorTextCell = ({ errors }: { errors: ErrorValue[] }): JSX.Element => {
  return (
    <>
      {errors.map((error) => (
        <Grid
          key={error.code}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
          spacing={1}
        >
          <Grid item>{renderPriority(error.priority)}</Grid>
          <Grid item>
            <Typography>
              {error.code} - {error.requiredAction}
              <GreyText> {error.activeSince}</GreyText>
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

// eslint-disable-next-line max-lines-per-function
const DeviceManagementTableBody = ({
  devicesForPage,
  displayColumns,
  isAllowedForUpdateManagement,
  deviceStatusDisplayType,
}: DeviceManagementTableProps): JSX.Element => {
  const { t } = useTranslation();
  const [isHoveredAction, setIsHoveredAction] = useState(false);
  const deviceManagementTableContext = useContext(DeviceManagementTableContext);
  const { state, dispatch } = deviceManagementTableContext;
  const isSelected = (deviceId: string): boolean =>
    state.uniqueSelectedDeviceIds.has(deviceId);
  const history = useHistory();

  function onDetailsClick(
    event: React.ChangeEvent<unknown>,
    deviceId: string,
  ): void {
    event.stopPropagation();
    event.preventDefault();
    if (state.currentOpenDetailsId === deviceId) {
      dispatch({
        type: DeviceStateActionType.DetailsOpen,
        value: false,
      });
      dispatch({
        type: DeviceStateActionType.CurrentOpenDetailsId,
        value: '',
      });
    } else {
      dispatch({
        type: DeviceStateActionType.CurrentOpenDetailsId,
        value: deviceId,
      });
      dispatch({
        type: DeviceStateActionType.DetailsOpen,
        value: true,
      });
    }
  }

  function onHoverAction(): void {
    setIsHoveredAction(true);
  }
  function onStopHoverAction(): void {
    setIsHoveredAction(false);
  }

  function setSelected(selectedDevicesIds: string[]): void {
    dispatch({
      type: DeviceStateActionType.SetUniqueSelectedDeviceIds,
      value: selectedDevicesIds,
    });
  }

  function returnBoxOrImowLogo(deviceModel: DeviceModel): JSX.Element {
    return deviceModel === DeviceModel.connectedBox ? (
      <StihlIconBoxLink color="black" />
    ) : (
      <StihlIconMowerLink color="black" />
    );
  }

  function handleOpenDevice(deviceId: string, deviceModel: DeviceModel): void {
    history.push({
      pathname: '/device',
      state: {
        search: deviceId,
        selector: SearchParameterValues.DeviceId,
        deviceModel,
      },
    });
  }

  return (
    <TableBody>
      {[...devicesForPage].map((row) => {
        const isItemSelected = isSelected(row.deviceId);
        const isErrorDeviceStatus =
          'errors' in row &&
          deviceStatusDisplayType === DeviceStatusDisplayType.ErrorDevices;
        const isSwVersionDeviceStatus =
          'softwareVersion' in row &&
          deviceStatusDisplayType === DeviceStatusDisplayType.SoftwareVersion;
        return (
          <StihlTableRow
            key={row.deviceId}
            id={row.deviceId}
            setSelected={setSelected}
            selected={[...state.uniqueSelectedDeviceIds]}
            isItemSelected={isItemSelected}
            isHoveredAction={isHoveredAction}
            shouldHaveCheckbox={isAllowedForUpdateManagement}
          >
            <StyledTableCell
              isHidden={false}
              component="th"
              scope="row"
              align="left"
            >
              {row.serialNumber ?? t('unknown')}
            </StyledTableCell>
            <StyledTableCell isHidden={false} align="left">
              {row.deviceId}
            </StyledTableCell>

            <StyledTableCell
              isHidden={!displayColumns.includes('deviceModel')}
              align="left"
            >
              {row.deviceModel}
            </StyledTableCell>
            <StyledTableCell
              isHidden={!displayColumns.includes('deviceType')}
              align="left"
            >
              {row.deviceType ?? t('unknown')}
            </StyledTableCell>

            {!isErrorDeviceStatus && !isSwVersionDeviceStatus && (
              <>
                <StyledTableCell
                  isHidden={!displayColumns.includes('softwareVersion')}
                  align="left"
                >
                  {row.softwareVersion ?? t('unknown')}
                </StyledTableCell>
                <StyledTableCell
                  isHidden={!displayColumns.includes('isConnected')}
                  align="left"
                >
                  <ConnectionState isConnected={row.isConnected ?? null} />
                </StyledTableCell>
                <StyledTableCell
                  isHidden={!displayColumns.includes('processingChain')}
                  align="left"
                >
                  {row.processingChain ?? t('unknown')}
                </StyledTableCell>
              </>
            )}

            {isErrorDeviceStatus && (
              <TableCell align="left">
                <ErrorTextCell errors={row.errors} />
              </TableCell>
            )}

            {isSwVersionDeviceStatus && (
              <StyledTableCell
                isHidden={!displayColumns.includes('softwareVersion')}
                align="left"
              >
                {(row as DeviceStatus).softwareVersion}
              </StyledTableCell>
            )}

            {isErrorDeviceStatus || isSwVersionDeviceStatus ? (
              <TableCell align="center">
                <StihlTooltipSmall title={t('deviceStatusTable.goToDevice')}>
                  <IconButton
                    onClick={() =>
                      handleOpenDevice(row.deviceId, row.deviceModel)
                    }
                    size="large"
                    onMouseOver={onHoverAction}
                    onMouseOut={onStopHoverAction}
                    data-testid="jumpToSearchIcon"
                  >
                    {returnBoxOrImowLogo(row.deviceModel)}
                  </IconButton>
                </StihlTooltipSmall>
              </TableCell>
            ) : (
              <StyledTableCell isHidden={false}>
                <IconButton
                  size="small"
                  data-testid="clickDetailsCardButton"
                  onClick={(event) => onDetailsClick(event, row.deviceId)}
                  onMouseOver={onHoverAction}
                  onMouseOut={onStopHoverAction}
                >
                  {state.isDetailsOpen &&
                  state.currentOpenDetailsId === row.deviceId ? (
                    <StyledArrowIconLeft />
                  ) : (
                    <StihlTooltipSmall
                      title={t('deviceManagementTable.showDetails')}
                    >
                      <StyledArrowIconRight />
                    </StihlTooltipSmall>
                  )}
                </IconButton>
              </StyledTableCell>
            )}
          </StihlTableRow>
        );
      })}
    </TableBody>
  );
};

export default DeviceManagementTableBody;
