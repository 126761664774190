import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconArrowDown from '../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

type ErrorCounterProps = {
  hasErrors: boolean;
  isOpenTable: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 13rem;
  margin: 0;
  padding: 2rem 0.5rem 1rem;
  text-align: center;
  background-color: ${(props: ErrorCounterProps) =>
    props.isOpenTable ? stihlColor.greyMid : 'none'};

  &:hover {
    background-color: ${(props: ErrorCounterProps) =>
      props.hasErrors ? stihlColor.greyMid : stihlColor.white};
    cursor: ${(props: ErrorCounterProps) =>
      props.hasErrors ? 'pointer' : 'auto'};
  }
`;

const StyledArrowIcon = styled(StihlIconArrowDown)`
  margin-inline-start: 0.5rem;
  transform: rotate(270deg);
`;

const StyledArrowIconDown = styled(StihlIconArrowDown)`
  margin-inline-start: 0.5rem;
`;

export type ErrorDevicesProps = {
  totalDevicesWithActiveErrors: number;
  handleOpenErrorTable: () => void;
  isOpenErrorTable: boolean;
};

const ErrorDevicesCounter = ({
  totalDevicesWithActiveErrors,
  handleOpenErrorTable,
  isOpenErrorTable,
}: ErrorDevicesProps): JSX.Element => {
  const { t } = useTranslation();
  const hasErrors = totalDevicesWithActiveErrors > 0;

  const returnArrowRightOrDown = (): JSX.Element => {
    return isOpenErrorTable ? <StyledArrowIconDown /> : <StyledArrowIcon />;
  };

  return (
    <StyledContainer
      role="button"
      onClick={hasErrors ? handleOpenErrorTable : undefined}
      onKeyDown={hasErrors ? handleOpenErrorTable : undefined}
      hasErrors={hasErrors}
      tabIndex={0}
      isOpenTable={isOpenErrorTable}
      data-testid="errorDevicesButton"
    >
      <Typography variant="h4">{totalDevicesWithActiveErrors}</Typography>
      <Typography variant="h5">
        {t('deviceStatistics.devicesWithErrors')}
        {hasErrors && returnArrowRightOrDown()}
      </Typography>
    </StyledContainer>
  );
};
export default ErrorDevicesCounter;
