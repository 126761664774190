import type {
  AccessLicenses,
  DeviceModel,
  StihlDeviceTag,
} from '../../device/model';

export const NavigationItem = {
  Device: 'Device',
  DeviceManagement: 'DeviceManagement',
  CustomerManagment: 'CustomerManagement',
  SystemHealthStatus: 'SystemHealthStatus',
  Help: 'Help',
};

export type NavigationItem =
  (typeof NavigationItem)[keyof typeof NavigationItem];

export type AppBaseResponse = {
  navigationItems: NavigationItem[];
  deviceModels: DeviceModel[];
  deviceFlags: StihlDeviceTag[];
  license: AccessLicenses;
};

export const TabsType = {
  Statistics: 'Statistics',
  Overview: 'Overview',
  Updatemanagement: 'Updatemanagement',
  AvailableUpdates: 'Available Updates',
  RegisterCustomer: 'RegisterCustomer',
  OverviewCustomer: 'OverviewCustomer',
  SoftwareVersions: 'Software Versions',
  Faq: 'FAQ',
  Contact: 'Contact',
  DtcCodes: 'DtcCodes',
  ReleaseManagement: 'ReleaseManagement',
  MassAssignments: 'MassAssignments',
  SupportFunctions: 'SupportFunctions',
  DiagnosisData: 'DiagnosisData',
};

export type DealerDetails = {
  postalCode?: string;
  city?: string;
  houseNo?: string;
  street?: string;
  dealerCompanyName?: string;
  locale: string;
};

export type TabsType = (typeof TabsType)[keyof typeof TabsType];

export type TabsResponse = {
  title: string;
  type: TabsType;
};
