import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconDemo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-demo';
import CardContentItem from './card-content-item';

const FlexGrid = styled(Grid)`
  display: flex;
`;

const CenteredStihlIconDemo = styled(StihlIconDemo)`
  align-self: center;
`;

type DemoModeProps = {
  name: string;
};
const DemoModeText = ({ name }: DemoModeProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name}>
      <Grid container alignItems="center" data-testid="demoMode">
        <FlexGrid item xs={1}>
          <CenteredStihlIconDemo />
        </FlexGrid>

        <Grid item xs>
          <Typography>{t('supportFunctions.active')}</Typography>
        </Grid>
      </Grid>
    </CardContentItem>
  );
};

export default DemoModeText;
