import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconPause from '../../../../../../base/stihl-material-ui/icons/stihl-icon-pause';
import StihlIconPlay from '../../../../../../base/stihl-material-ui/icons/stihl-icon-play';
import CardContentItem from './card-content-item';

const CenteredStihlIconPlay = styled(StihlIconPlay)`
  align-self: center;
`;

const CenteredStihlIconPause = styled(StihlIconPause)`
  align-self: center;
`;

type AccessPinCodeProps = {
  title: string;
  value: boolean;
  icon?: JSX.Element;
};

const AccessPinCodeText = ({
  value,
  title,
  icon,
}: AccessPinCodeProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={t(title)} icon={icon}>
      <StihlIconTextFlexbox>
        {value ? (
          <CenteredStihlIconPlay data-testid="playIcon" />
        ) : (
          <CenteredStihlIconPause color="grey" data-testid="pauseIcon" />
        )}

        <Typography
          sx={{
            marginLeft: '0.5rem',
          }}
        >
          {value
            ? t('supportFunctions.active')
            : t('supportFunctions.inactive')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardContentItem>
  );
};

export default AccessPinCodeText;
