import styled from '@emotion/styled';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlCardMedia from '../../../../base/stihl-material-ui/components/stihl-card-media/stihl-card-media';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconWarning from '../../../../base/stihl-material-ui/icons/stihl-icon-warning';
import StihlBleConnectionSetup from '../../../../base/stihl-material-ui/images/stihl-ble-connection-setup.png';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

type ConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
};

const ImageContainer = styled.div`
  margin-block: 1rem;
`;

const WarningContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-block-start: 1rem;
`;

export const CustomerAccountRegistrationConfirmationModal = ({
  open,
  onClose,
  handleSubmit,
  isLoading,
}: ConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StihlModal
      open={open}
      onClose={onClose}
      title={t('customerRegistration.confirmationModal.title')}
      actionButtonText={t(
        'customerRegistration.confirmationModal.finishRegistration',
      )}
      isLoading={isLoading}
      cancelButtonText={t(
        'customerRegistration.confirmationModal.cancelRegistration',
      )}
      handleActionClick={handleSubmit}
      data-testid="confirmationModal"
    >
      <>
        <Typography>
          {t('customerRegistration.confirmationModal.instructions')}
        </Typography>

        <ImageContainer>
          <StihlCardMedia
            image={StihlBleConnectionSetup}
            title={t('customerRegistration.confirmationModal.imageTitle')}
            height="281px"
            width="160px"
          />
        </ImageContainer>

        <Link
          href="./pdfs/stihl-set-BLE-password-instructions.pdf"
          target="_blank"
          style={{
            color: stihlColor.blue,
            // stihl blue with transparency
            textDecorationColor: 'rgba(36, 154, 190, 0.4)',
          }}
        >
          {t('customerRegistration.confirmationModal.pdfLink')}
        </Link>

        <WarningContainer>
          <StihlIconWarning />
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
            {t('customerRegistration.confirmationModal.warning')}
          </Typography>
        </WarningContainer>
      </>
    </StihlModal>
  );
};
