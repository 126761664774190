import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsType } from '../../../app-shell/model/app.models';
import QueryComponent from '../../../app-shell/ui/query-component';
import type { TabData } from '../../../app-shell/ui/tabs-base';
import TabsBase, { TabPanel } from '../../../app-shell/ui/tabs-base';
import { useDeviceManagementTabs } from '../../service/device-management-service/device-management-service';
import DeviceManagementOverview from '../device-management-overview';
import DeviceManagementStatistics from '../device-management-statistics';
import { MassAssignments } from '../mass-assignments/mass-assignments';
import ReleaseManagement from '../release-management/release-management';
import { DeviceManagementSoftwareVersionsCard } from '../software-versions-tab/device-management-software-versions-card';
import UpdateManagement from '../update-management/update-management';

const DeviceManagementTabsComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const deviceManagementResult = useDeviceManagementTabs();
  const { data: tabs } = deviceManagementResult;

  function handleChange(_: React.ChangeEvent<unknown>, newValue: number): void {
    setActiveTab(newValue);
  }

  const isAllowedForUpdateManagement = Boolean(
    tabs?.find((tab) => tab.type === TabsType.Updatemanagement),
  );

  const tabsArray: TabData[] =
    tabs?.map((tab) => ({
      label: t(tab.title),
      hasBadge: tab.type === TabsType.Updatemanagement,
    })) ?? [];

  function renderTabPanel(type: TabsType): JSX.Element {
    switch (type) {
      case TabsType.Overview: {
        return (
          <DeviceManagementOverview
            isAllowedForUpdateManagement={isAllowedForUpdateManagement}
          />
        );
      }
      case TabsType.Updatemanagement: {
        return <UpdateManagement />;
      }
      case TabsType.AvailableUpdates: {
        return <div>Available Updates</div>;
      }
      case TabsType.SoftwareVersions: {
        return <DeviceManagementSoftwareVersionsCard />;
      }
      case TabsType.ReleaseManagement: {
        return <ReleaseManagement />;
      }
      case TabsType.MassAssignments: {
        return <MassAssignments />;
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case TabsType.Statistics:
      default: {
        return (
          <DeviceManagementStatistics
            isAllowedForUpdateManagement={isAllowedForUpdateManagement}
          />
        );
      }
    }
  }

  return (
    <QueryComponent data={deviceManagementResult}>
      {tabs && (
        <>
          <TabsBase
            value={activeTab}
            handleChange={handleChange}
            tabs={tabsArray}
          />
          {tabs.map((tab, index) => (
            <TabPanel key={tab.title} index={index} value={activeTab}>
              {renderTabPanel(tab.type)}
            </TabPanel>
          ))}
        </>
      )}
    </QueryComponent>
  );
};

export default DeviceManagementTabsComponent;
