import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconCellularConnected from '../../../../../../base/stihl-material-ui/icons/stihl-icon-cellular-connected';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import CardContentItem from './card-content-item';

type CellularConnectionProps = {
  value: boolean;
  name: string;
};
const CellularConnectionText = ({
  value,
  name,
}: CellularConnectionProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name}>
      <StihlIconTextFlexbox>
        <StihlIconCellularConnected
          color={value ? stihlColor.green : stihlColor.red}
        />
        <Typography
          sx={{
            marginLeft: '0.5rem',
          }}
        >
          {value
            ? t('deviceDetails.isConnected')
            : t('deviceDetails.isDisconnected')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardContentItem>
  );
};

export default CellularConnectionText;
