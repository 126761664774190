import type { TooltipProps } from '@mui/material/Tooltip';
import type { FC, ReactNode } from 'react';
import StihlTooltip from './stihl-tooltip';

type StihlTooltipSmallProps = TooltipProps & {
  children: ReactNode;
  title: string;
};

export const StihlTooltipSmall: FC<StihlTooltipSmallProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <StihlTooltip
      title={title}
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            '&.MuiTooltip-tooltip': {
              padding: '0.2rem 0.4rem',
              fontSize: '0.75rem',
              boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
              filter: 'drop-shadow(0px 1px 3px rgb(0, 0, 0, 0.3))',
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </StihlTooltip>
  );
};

export default StihlTooltipSmall;
