import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconArrowDown from '../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconBusiness from '../../../../base/stihl-material-ui/icons/stihl-icon-business';
import StihlIconInfo from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconKey from '../../../../base/stihl-material-ui/icons/stihl-icon-key';
import StihlIconUser from '../../../../base/stihl-material-ui/icons/stihl-icon-user';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { CustomerSearchObject } from '../../model/customer-account.model';
import { connectCustomerToDevice } from '../../service/customer-api-client/customer-api-client';
import SearchCustomerBar from '../search/search-customer-bar';
import { CustomerAccountRegistrationConfirmationModal } from './customer-account-registration-confirmation-modal';

const StyledTypography = styled(Typography)`
  padding-block-start: 1rem;
  font-size: 1rem;
`;

const FlexPositionerBaseline = styled.div`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`;

const IconLinkPositioner = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const StyledNavLink = styled(NavLink)`
  color: ${stihlColor.black};
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
`;

const StyledArrowIcon = styled(StihlIconArrowDown)`
  transform: rotate(270deg);

  path {
    fill: ${stihlColor.black};
  }
`;

const StyledHelperText = styled(Typography)`
  margin-block-end: 1rem;
`;

const CustomerTypeFlexBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`;

const StyledFlexBox = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export type CustomerRegistrationModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  serialNumber: string | undefined;
};

// eslint-disable-next-line max-lines-per-function
const CustomerRegistrationModal = ({
  open,
  setOpen,
  serialNumber,
}: CustomerRegistrationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [customerSearchObject, setCustomerSearchObject] =
    useState<CustomerSearchObject>({
      searchTerm: '',
      isAborted: false,
    });

  function handleClose(): void {
    setOpen(false);
    setSelectedCustomerId('');
  }

  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  function handleCustomerConnect(): void {
    setIsLoading(true);
    if (selectedCustomerId && serialNumber) {
      connectCustomerToDevice({
        customerId: selectedCustomerId,
        serialNumber,
      })
        .then(() => {
          setSelectedCustomerId('');
          setConfirmationModalOpen(false);
          setOpen(false);
          setAlert({
            isOpen: true,
            message: t('customerRegistrationModal.confirmation'),
            severity: 'success',
          });
          setIsLoading(false);
          return true;
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            message: t('customerRegistrationModal.errorMessage'),
            severity: 'error',
          });
        });
    }
  }

  function handleConfirmationModalOpen(): void {
    setConfirmationModalOpen(true);
  }

  function handleConfirmationModalClose(): void {
    setConfirmationModalOpen(false);
  }

  const CustomerTypesToolTip: JSX.Element = (
    <div>
      <b>{t('customerRegistrationModal.customerTypes')}</b>
      <CustomerTypeFlexBox>
        <StihlIconKey color="greyBase" />
        {t('customerRegistrationModal.private')}
      </CustomerTypeFlexBox>
      <CustomerTypeFlexBox>
        <StihlIconBusiness />
        {t('customerRegistrationModal.business')}
      </CustomerTypeFlexBox>
      <CustomerTypeFlexBox>
        <StihlIconUser color={stihlColor.greyBase} />
        {t('customerRegistrationModal.other')}
      </CustomerTypeFlexBox>
    </div>
  );

  return (
    <StihlModal
      title={t('customerRegistrationModal.connectTitle')}
      open={open}
      onClose={handleClose}
      actionButtonText={t('customerRegistrationModal.connect')}
      handleActionClick={handleConfirmationModalOpen}
      data-testid="connectCustomerModal"
      isDisabled={
        !(selectedCustomerId && serialNumber) ||
        customerSearchObject.searchTerm === ''
      }
      isLoading={isLoading}
    >
      <>
        <StyledFlexBox>
          <StyledTypography>
            {t('customerRegistrationModal.search')}
          </StyledTypography>

          <StihlTooltip title={CustomerTypesToolTip} placement="left">
            <StihlIconInfo />
          </StihlTooltip>
        </StyledFlexBox>

        <SearchCustomerBar
          setSelectedCustomerId={setSelectedCustomerId}
          customerSearchObject={customerSearchObject}
          setCustomerSearchObject={setCustomerSearchObject}
        />

        <FlexPositionerBaseline>
          <StyledTypography>
            {t('customerRegistrationModal.noAccount')}
          </StyledTypography>
          <IconLinkPositioner>
            <StyledNavLink
              to={`/customer-management/register?serialNumber=${serialNumber}`}
            >
              {t('customerRegistrationModal.register')}
            </StyledNavLink>
            <IconButton size="large">
              {/* eslint-disable-next-line react/jsx-max-depth */}
              <StyledArrowIcon />
            </IconButton>
          </IconLinkPositioner>
        </FlexPositionerBaseline>

        <StyledHelperText>
          {t('customerRegistrationModal.helperText')}
        </StyledHelperText>

        <CustomerAccountRegistrationConfirmationModal
          open={isConfirmationModalOpen}
          onClose={handleConfirmationModalClose}
          handleSubmit={handleCustomerConnect}
          isLoading={isLoading}
        />
      </>
    </StihlModal>
  );
};

export default CustomerRegistrationModal;
