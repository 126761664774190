import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const LEFT_COLUMN_SIZE = 4;
export const RIGHT_COLUMN_SIZE = 8;

const StyledGrid = styled(Grid)`
  margin: 0.5rem 0;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const OperationsDataText = ({
  name,
  icon,
  value,
}: {
  name: string;
  icon?: JSX.Element;
  value: number;
}): JSX.Element => {
  const { t, i18n } = useTranslation();

  const hourFormat = useMemo(
    () => new Intl.NumberFormat(i18n.language, { style: 'unit', unit: 'hour' }),
    [i18n.language],
  );

  const minuteFormat = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, { style: 'unit', unit: 'minute' }),
    [i18n.language],
  );

  const meterFormat = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, { style: 'unit', unit: 'meter' }),
    [i18n.language],
  );

  function secondsToHours(seconds: number): string {
    const hours = seconds / 3600;
    const fullHours = Math.floor(hours);
    const minutes = Math.round((seconds - fullHours * 3600) / 60);

    return `${hourFormat.format(fullHours)} ${minuteFormat.format(minutes)}`;
  }

  const getValue = (field: string): string | number => {
    switch (field) {
      case 'operationData.totalWorkingSeconds': {
        return secondsToHours(value);
      }
      case 'operationData.totalWorkingTimeOfCurrentCuttingKnifes': {
        return secondsToHours(value);
      }
      case 'operationData.totalStartedMowingJobs': {
        return `${value}`;
      }
      case 'operationData.totalDrivenDistance': {
        return meterFormat.format(value);
      }
      case 'operationData.totalWorkingTimeOfCuttingMotor': {
        return secondsToHours(value);
      }
      default: {
        return '';
      }
    }
  };

  return (
    <StyledGrid container>
      <FlexGrid item xs={LEFT_COLUMN_SIZE} pr=".25rem">
        {icon}
        <Typography data-testid="operationsDataText">
          <b>{t(name)}</b>
        </Typography>
      </FlexGrid>
      <Grid
        item
        xs={RIGHT_COLUMN_SIZE}
        pl=".25rem"
        data-testid="operationsDataValue"
      >
        {getValue(name)}
      </Grid>
    </StyledGrid>
  );
};

export default OperationsDataText;
