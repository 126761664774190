import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import StihlIconTextFlexbox from '../../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlTooltip from '../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { ServiceValue } from '../../../../model';
import { DeviceTabsContext } from '../../../tabs/device-tabs.context';
import CardContentItem from './card-content-item';
import GreyText from './grey-text';

const StyledLink = styled.button`
  color: ${stihlColor.blueDark};
  text-decoration: underline;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

type ServiceTextProps = {
  value: ServiceValue[];
  name: string;
};

const TooltipIcon = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StihlTooltip
      title={t('deviceDetails.errorsAndServicesTooltip')}
      placement="bottom"
      data-testid="requiredServicesTooltip"
    >
      <StihlIconInfo />
    </StihlTooltip>
  );
};

const ServiceText = ({ value, name }: ServiceTextProps): JSX.Element => {
  const { openTab } = useContext(DeviceTabsContext);
  const { toLocaleDateTimeString } = useDateFormatting();

  function handleClick(): void {
    openTab('tabs.dtcCodes');
  }

  return (
    <CardContentItem name={name} icon={<TooltipIcon />}>
      {value.map((data: ServiceValue) => (
        <StihlIconTextFlexbox key={data.code}>
          <div style={{ marginLeft: '0.5rem' }}>
            <Typography>
              <StyledLink
                onClick={handleClick}
                data-testid="requiredServiceLink"
              >
                {data.code}
              </StyledLink>{' '}
              -{' '}
              <GreyText>
                {toLocaleDateTimeString(new Date(data.activeSince))}
              </GreyText>
            </Typography>
          </div>
        </StihlIconTextFlexbox>
      ))}
    </CardContentItem>
  );
};

export default ServiceText;
