import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

export type DashboardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleGoToDashboard: () => void;
  setHasAcceptedDashboardModal: Dispatch<SetStateAction<boolean>>;
};

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${stihlColor.stihlOrange};
  }
`;

const DashboardModal = ({
  isOpen,
  onClose,
  handleGoToDashboard,
  setHasAcceptedDashboardModal,
}: DashboardModalProps): JSX.Element => {
  const { t } = useTranslation();

  function handleToggleAcceptDashboard(): void {
    setHasAcceptedDashboardModal((hasAccepted) => !hasAccepted);
  }

  return (
    <StihlModal
      open={isOpen}
      onClose={onClose}
      title={t('deviceStatistics.dashboardModalTitle')}
      actionButtonText={t('accept')}
      handleActionClick={handleGoToDashboard}
      data-testid="dashboardModal"
    >
      <>
        <Typography>{t('deviceStatistics.dashboardHint')}</Typography>
        <InputLabel htmlFor="doNotShowAgain">
          <StyledCheckbox
            id="doNotShowAgain"
            onChange={handleToggleAcceptDashboard}
          />
          {t('doNotShowAgain')}
        </InputLabel>
      </>
    </StihlModal>
  );
};

export default DashboardModal;
