import styled from '@emotion/styled';
import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconXFilled from '../../icons/stihl-icon-x-filled';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledHelperTextContainer = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledHelperText = styled.span`
  margin-block-start: 0.25rem;
  color: ${stihlColor.black};
`;

type SpanProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export const StihlHelperText: FC<SpanProps & { text: string }> = ({
  text,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <StyledHelperTextContainer {...props}>
      <StihlIconXFilled color="error" />
      <StyledHelperText>{t(text)}</StyledHelperText>
    </StyledHelperTextContainer>
  );
};
