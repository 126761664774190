import styled from '@emotion/styled';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../theme/stihl-style-guide';
import StihlCheckbox from '../stihl-checkbox/stihl-checkbox';
import type { Order } from './table-utils';

const StyledHeaderTableCell = styled(TableCell)`
  color: ${stihlColor.fontGrey};
`;

export type HeadCell<TColumn> = {
  id: keyof TColumn;
  label: string;
  isSortable: boolean;
};

function onRequestSort<T>(
  property: T,
  order: Order,
  setOrder: Dispatch<SetStateAction<Order>>,
  orderBy: T,
  setOrderBy: Dispatch<SetStateAction<T>>,
): void {
  const isAsc = orderBy === property && order === 'asc';
  // eslint-disable-next-line @getify/proper-ternary/where
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
}

type TableHeaderProps<
  TColumn,
  TColumnOrderable extends keyof TColumn = keyof TColumn,
> = {
  order: 'asc' | 'desc';
  orderBy: TColumnOrderable;
  setOrder: Dispatch<SetStateAction<Order>>;
  setOrderBy: Dispatch<SetStateAction<TColumnOrderable>>;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numberSelected?: number;
  rowCount?: number;
  headCells: HeadCell<TColumn>[];
  isDetailsOpen?: boolean;
  displayColumns?: (keyof TColumn)[];
  shouldHaveCheckbox?: boolean;
  shouldHaveLastColumnEmpty?: boolean;
};

const StihlTableHeader = <
  TColumn,
  TColumnOrderable extends keyof TColumn = keyof TColumn,
>({
  order,
  orderBy,
  setOrder,
  setOrderBy,
  onSelectAllClick,
  numberSelected,
  rowCount,
  headCells,
  shouldHaveCheckbox = true,
  shouldHaveLastColumnEmpty = false,
}: TableHeaderProps<TColumn, TColumnOrderable>): ReactNode => {
  const { t } = useTranslation();

  const createSortHandler = (property: TColumnOrderable) => () => {
    onRequestSort<TColumnOrderable>(
      property,
      order,
      setOrder,
      orderBy,
      setOrderBy,
    );
  };

  function returnCheckBoxAllOrEmpty(): ReactNode {
    if (
      shouldHaveCheckbox &&
      numberSelected !== undefined &&
      rowCount !== undefined
    ) {
      return (
        <StyledHeaderTableCell
          padding="checkbox"
          sx={{ paddingLeft: '1.75rem' }}
        >
          <StihlCheckbox
            indeterminate={numberSelected > 0 && numberSelected < rowCount}
            checked={rowCount > 0 && numberSelected === rowCount}
            onChange={onSelectAllClick}
            data-testid="checkboxAll"
          />
        </StyledHeaderTableCell>
      );
    }
  }

  return (
    <TableHead>
      <TableRow>
        {returnCheckBoxAllOrEmpty()}
        {headCells.map((headCell) => {
          if (headCell.isSortable) {
            return (
              <StyledHeaderTableCell
                key={headCell.id.toString()}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id as TColumnOrderable)}
                  data-testid="sortingButton"
                >
                  <b>{t(headCell.label)}</b>
                </TableSortLabel>
              </StyledHeaderTableCell>
            );
          }
          return (
            <StyledHeaderTableCell key={headCell.id.toString()} align="left">
              <b>{headCell.label}</b>
            </StyledHeaderTableCell>
          );
        })}
        {shouldHaveLastColumnEmpty && <StyledHeaderTableCell />}
      </TableRow>
    </TableHead>
  );
};

export default StihlTableHeader;
