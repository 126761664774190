import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, FC } from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTextField from '../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconArrowDown from '../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import { CardBase } from '../../device/ui/card/ui/card-component';
import GreyText from '../../device/ui/card/ui/card-texts/grey-text';
import type { Dtc } from '../model/dtc.model';
import { dtcMapper } from '../service/dtc-mapper/dtc-mapper';

const StyledContent = styled.div`
  --size-dtc-per-column: 4;

  display: grid;
  grid-template-columns: repeat(var(--size-dtc-per-column, 4), 1fr);
  gap: 1rem;
`;

const NothingFoundTypography = styled(Typography)`
  grid-column: span var(--size-dtc-per-column, 4);
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledExpandable = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isOpen',
})<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : '')};
  }
`;

const HelpDtcCodeCard: FC<{ item: Dtc }> = ({ item }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card key={item.code} id={item.code}>
      <CardHeader
        title={
          <Typography variant="h3">
            {Number.parseInt(item.code, 16)} <GreyText>({item.code})</GreyText>
          </Typography>
        }
      />
      <StyledCardContent>
        <Typography>{item.headline}</Typography>
        <StyledExpandable isOpen={isOpen}>
          <Typography variant="subtitle2">{t('help.instructions')}</Typography>
          <IconButton
            onClick={() => setIsOpen((isOpenCurrent) => !isOpenCurrent)}
          >
            <StihlIconArrowDown />
          </IconButton>
        </StyledExpandable>
        <Collapse in={isOpen}>
          {item.description && (
            <Typography
              // it's ok to dangerously set inner HTML here because the description can only come
              // from the translation file (which is as safe as our code)
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
          {item.component ? (
            <>
              <br />
              <Typography>{`${t('help.affectedComponents')}: ${
                item.component
              }`}</Typography>
            </>
          ) : null}
        </Collapse>
      </StyledCardContent>
    </Card>
  );
};

function filterDtcCodes(search: string, dtcCodesAll: Dtc[]): Dtc[] {
  const filterValues = search.split(',').map((value) => value.toLowerCase());

  return filterValues.length > 0
    ? dtcCodesAll.filter((item) => {
        const codeHex = item.code.toLowerCase();
        const codeDecimal = Number.parseInt(item.code, 16)
          .toString()
          .toLowerCase();
        const headline = item.headline.toLocaleLowerCase();

        return filterValues.some((value) => {
          return (
            codeHex.includes(value.toLowerCase()) ||
            codeDecimal.includes(value) ||
            headline.includes(value)
          );
        });
      })
    : dtcCodesAll;
}

type HelpDtcCodesProps = {
  codes?: string[];
  isSearchBarHidden?: boolean;
};

const HelpDtcCodes: FC<HelpDtcCodesProps> = ({
  codes,
  isSearchBarHidden = false,
}) => {
  const { t, i18n } = useTranslation();
  const dtcCodesAll = useMemo(dtcMapper, [i18n.language]);
  const [search, setSearch] = useState(() => codes?.join(',') ?? '');

  const dtcCodes = useMemo(() => {
    return filterDtcCodes(search, dtcCodesAll);
  }, [dtcCodesAll, search]);

  useEffect(() => {
    if (codes) {
      setSearch(codes.join(','));
    }
  }, [codes]);

  function handleSearch(event: ChangeEvent<HTMLInputElement>): void {
    setSearch(event.target.value);
  }

  return (
    <CardBase title={t('tabs.dtcCodes')}>
      <StihlTextField
        sx={{
          marginBlockEnd: '2rem',
          '&.MuiTextField-root.MuiFormControl-marginDense': {
            inlineSize: '30%',
            minWidth: '380px',
          },
        }}
        value={search}
        placeholder={t('help.searchForDtc')}
        inputProps={{
          'aria-label': t('help.searchForDtc'),
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton style={{ padding: '6px' }} type="submit" size="large">
                <SearchIcon color="secondary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
        hidden={isSearchBarHidden}
      />
      <StyledContent>
        {dtcCodes.length === 0 ? (
          <NothingFoundTypography>
            {t('help.noDtcFound')}
          </NothingFoundTypography>
        ) : (
          dtcCodes.map((item) => (
            <HelpDtcCodeCard key={item.code} item={item} />
          ))
        )}
      </StyledContent>
    </CardBase>
  );
};

export default HelpDtcCodes;
