import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlThemeColor } from '../../../../base/stihl-material-ui/theme/stihl-theme-colors';
import StatisticsPieChart from './statistics-piechart';

export type StatisticsErrorsProps = {
  totalDevicesWithActiveErrors: number;
  totalDevicesWithoutActiveErrors: number;
};

const StatisticsErrors = ({
  totalDevicesWithActiveErrors,
  totalDevicesWithoutActiveErrors,
}: StatisticsErrorsProps): JSX.Element => {
  const { t } = useTranslation();
  const data = [
    {
      name: t('deviceStatistics.errors.withErrors'),
      value: totalDevicesWithActiveErrors,
    },
    {
      name: t('deviceStatistics.errors.withoutErrors'),
      value: totalDevicesWithoutActiveErrors,
    },
  ];
  /**
   * one color array is needed for the icon colors (Mui themed component,
   * and one for the recharts component as this one doesn't accept primary as a color)
   */
  const colors = [`${stihlColor.red}`, `${stihlColor.green}`];
  const iconColors: StihlThemeColor[] = ['error', 'success'];
  return (
    <StatisticsPieChart
      title={t('deviceStatistics.errors.errors')}
      chartData={data}
      colors={colors}
      iconColors={iconColors}
    />
  );
};
export default StatisticsErrors;
