import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconPause from '../../../../../../base/stihl-material-ui/icons/stihl-icon-pause';
import StihlIconPlay from '../../../../../../base/stihl-material-ui/icons/stihl-icon-play';
import StihlIconXFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import CardContentItem from './card-content-item';

const CenteredStihlIconPlay = styled(StihlIconPlay)`
  align-self: center;
`;

const CenteredStihlIconPause = styled(StihlIconPause)`
  align-self: center;
`;

const StyledTypography = styled(Typography)`
  margin-left: 0.5rem;
`;

type PartOfOrganizationProps = {
  title: string;
  value: boolean | null;
  icon?: JSX.Element;
};

const PartOfOrganizationText = ({
  value,
  title,
  icon,
}: PartOfOrganizationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={t(title)} icon={icon}>
      <StihlIconTextFlexbox>
        {value === true && (
          <>
            <CenteredStihlIconPlay data-testid="playIcon" />
            <StyledTypography>
              {t('deviceDetails.fleetManaged')}
            </StyledTypography>
          </>
        )}
        {value === false && (
          <>
            <CenteredStihlIconPause color="yellow" data-testid="pauseIcon" />
            <StyledTypography>
              {t('deviceDetails.notFleetManaged')}
            </StyledTypography>
          </>
        )}
        {value !== true && value !== false && (
          <>
            <StihlIconXFilled color={stihlColor.red} data-testid="xIcon" />
            <StyledTypography>
              {t('deviceDetails.noDataAvailable')}
            </StyledTypography>
          </>
        )}
      </StihlIconTextFlexbox>
    </CardContentItem>
  );
};

export default PartOfOrganizationText;
