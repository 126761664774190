import type { JSX } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import { useAlertStore } from '../../../../../app-alert/service/alert-provider';
import type { Card, DeviceModel } from '../../../../model';
import { useCertificateStatus } from '../../../../service/device-service/device-service';
import { CardBase } from '../card-component';
import {
  CommunicationCertificateStatusSection,
  DiagnosisCertificateStatusSection,
} from './certificate-status-section';

export const CertificateStatusCard = ({
  deviceId,
  deviceModel,
  cardData,
}: {
  deviceId: string;
  deviceModel: DeviceModel;
  cardData: Card;
}): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const certificateStatus = useCertificateStatus(deviceId, deviceModel);

  useEffect(() => {
    if (certificateStatus.isError) {
      setAlert({
        isOpen: true,
        message: t('certificateStatus.alertMessage'),
        severity: 'error',
      });
    }
  }, [certificateStatus.isError, setAlert, t]);

  function onButtonClick(): void {
    void certificateStatus.refetch();
  }

  return (
    <CardBase title={cardData.title}>
      {!!certificateStatus.data && (
        <>
          <CommunicationCertificateStatusSection
            certificateStatus={certificateStatus.data}
          />
          <DiagnosisCertificateStatusSection
            certificateStatus={certificateStatus.data}
          />
        </>
      )}

      <StihlButtonSecondaryLoading
        loading={certificateStatus.isFetching}
        onClick={onButtonClick}
      >
        {t('certificateStatus.button')}
      </StihlButtonSecondaryLoading>
    </CardBase>
  );
};

export default CertificateStatusCard;
