import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsBg from './locales/bg-BG/translations.json';
import translationsCs from './locales/cs-CZ/translations.json';
import translationsDa from './locales/da-DK/translations.json';
import translationsDe from './locales/de/translations.json';
// eslint-disable-next-line unicorn/prevent-abbreviations
import translationsEl from './locales/el-GR/translations.json';
import translationsEn from './locales/en/translations.json';
import translationsEs from './locales/es-ES/translations.json';
import translationsEt from './locales/et-EE/translations.json';
import translationsFi from './locales/fi-FI/translations.json';
import translationsFr from './locales/fr-FR/translations.json';
import translationsHr from './locales/hr-HR/translations.json';
import translationsHu from './locales/hu-HU/translations.json';
import translationsIt from './locales/it-IT/translations.json';
import translationsLt from './locales/lt-LT/translations.json';
import translationsLv from './locales/lv-LV/translations.json';
import translationsNb from './locales/nb-NO/translations.json';
import translationsNl from './locales/nl-NL/translations.json';
import translationsPl from './locales/pl-PL/translations.json';
import translationsPt from './locales/pt-PT/translations.json';
import translationsRo from './locales/ro-RO/translations.json';
import translationsSk from './locales/sk-SK/translations.json';
import translationsSl from './locales/sl-SI/translations.json';
import translationsSr from './locales/sr-RS/translations.json';
import translationsSv from './locales/sv-SE/translations.json';
import cs from './modules/help/service/dtc-mapper/constants/dtc-codes/cs.json';
import da from './modules/help/service/dtc-mapper/constants/dtc-codes/da.json';
import de from './modules/help/service/dtc-mapper/constants/dtc-codes/de.json';
import el from './modules/help/service/dtc-mapper/constants/dtc-codes/el.json';
import en from './modules/help/service/dtc-mapper/constants/dtc-codes/en.json';
import es from './modules/help/service/dtc-mapper/constants/dtc-codes/es.json';
import fi from './modules/help/service/dtc-mapper/constants/dtc-codes/fi.json';
import fr from './modules/help/service/dtc-mapper/constants/dtc-codes/fr.json';
import hu from './modules/help/service/dtc-mapper/constants/dtc-codes/hu.json';
import it from './modules/help/service/dtc-mapper/constants/dtc-codes/it.json';
import nl from './modules/help/service/dtc-mapper/constants/dtc-codes/nl.json';
import no from './modules/help/service/dtc-mapper/constants/dtc-codes/no.json';
import pl from './modules/help/service/dtc-mapper/constants/dtc-codes/pl.json';
import pt from './modules/help/service/dtc-mapper/constants/dtc-codes/pt.json';
import ro from './modules/help/service/dtc-mapper/constants/dtc-codes/ro.json';
import sk from './modules/help/service/dtc-mapper/constants/dtc-codes/sk.json';
import sv from './modules/help/service/dtc-mapper/constants/dtc-codes/sv.json';

void use(initReactI18next).init({
  resources: {
    'en-US': { translation: translationsEn, dtc: en },
    'de-DE': { translation: translationsDe, dtc: de },
    'fr-FR': { translation: translationsFr, dtc: fr },
    'it-IT': { translation: translationsIt, dtc: it },
    'cs-CZ': { translation: translationsCs, dtc: cs },
    'bg-BG': { translation: translationsBg, dtc: en },
    'da-DK': { translation: translationsDa, dtc: da },
    'el-GR': { translation: translationsEl, dtc: el },
    'fi-FI': { translation: translationsFi, dtc: fi },
    'es-ES': { translation: translationsEs, dtc: es },
    'et-EE': { translation: translationsEt, dtc: en },
    'hr-HR': { translation: translationsHr, dtc: en },
    'hu-HU': { translation: translationsHu, dtc: hu },
    'lt-LT': { translation: translationsLt, dtc: en },
    'lv-LV': { translation: translationsLv, dtc: en },
    'nb-NO': { translation: translationsNb, dtc: no },
    'nl-NL': { translation: translationsNl, dtc: nl },
    'pl-PL': { translation: translationsPl, dtc: pl },
    'pt-PT': { translation: translationsPt, dtc: pt },
    'ro-RO': { translation: translationsRo, dtc: ro },
    'sk-SK': { translation: translationsSk, dtc: sk },
    'sl-SI': { translation: translationsSl, dtc: en },
    'sr-RS': { translation: translationsSr, dtc: en },
    'sv-SE': { translation: translationsSv, dtc: sv },
  },
  lng: window.localStorage.getItem('chosenLanguage') ?? 'en-US',
  fallbackLng: 'en-US',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interpolation: { escapeValue: false },
});

// eslint-disable-next-line jest/require-hook
i18n.on('languageChanged', (language) => {
  window.localStorage.setItem('chosenLanguage', language);
});

// eslint-disable-next-line import/max-dependencies
export { default } from 'i18next';
