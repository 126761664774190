import type { AxiosError } from 'axios';
import instance from '../../modules/app-authentication/service/authentication-interceptor';
import type {
  AppBaseResponse,
  DealerDetails,
} from '../../modules/app-shell/model/app.models';

export async function getAppBase(): Promise<AppBaseResponse> {
  return instance
    .get<AppBaseResponse>(
      `${process.env.REACT_APP_ISP_API_MAIN_BASE_URL ?? ''}/main/app-base`,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getDealerAccountDetails(
  dealerId: string,
): Promise<DealerDetails> {
  return instance
    .get<DealerDetails>(
      `${
        process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL as string
      }/dealer/account/details/${dealerId}`,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}
