import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlIconXFilled from '../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import CustomerRegistrationModal from '../customer-registration/customer-registration-modal';

const IconAligner = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const CustomerDetailsEmptyCardContent = ({
  deviceSerialNumber,
}: {
  deviceSerialNumber: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  function handleModalOpen(): void {
    setModalOpen(true);
  }
  return (
    <>
      <Typography data-testid="noCustomer">
        {t('customerDetails.noCustomer')}
      </Typography>
      <br />
      <StihlButtonSecondary
        onClick={handleModalOpen}
        data-testid="connectCustomerButton"
      >
        {t('customerDetails.connect')}
      </StihlButtonSecondary>

      <CustomerRegistrationModal
        open={isModalOpen}
        setOpen={setModalOpen}
        serialNumber={deviceSerialNumber}
      />
    </>
  );
};

export const CustomerDetailsErrorCardContent = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <IconAligner>
      <StihlIconXFilled color="error" />
      <Typography data-testid="errorCustomer">
        {t('customerDetails.noCustomerDataFound')}
      </Typography>
    </IconAligner>
  );
};
