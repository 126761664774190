import type { Dispatch, ReactNode, SetStateAction, JSX } from 'react';
import { useContext, useMemo, useState, createContext } from 'react';
import type { AlertStore } from './alert-store';
import createAlertStore from './alert-store';

export type AlertContextValue = [
  AlertStore,
  Dispatch<SetStateAction<AlertStore>>,
];

export const AlertContext = createContext<AlertContextValue | null>(null);

type Props = {
  children: ReactNode;
};

export const AlertProvider = ({ children }: Props): JSX.Element => {
  const [store, setStore] = useState<AlertStore>(createAlertStore());
  const value: AlertContextValue = useMemo(() => [store, setStore], [store]);

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export const useAlertStore = (): AlertContextValue => {
  const store = useContext(AlertContext);

  if (!store) {
    throw new Error('useAlertStore must be used within a AlertProvider.');
  }

  return store;
};
