import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import CardContentItem from './card-content-item';

const CardText = ({
  name,
  value,
  icon,
}: {
  name: string;
  value: string | number | boolean;
  icon?: JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name} icon={icon}>
      <Typography>{t(value.toString())}</Typography>
    </CardContentItem>
  );
};

export default CardText;
