import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTableHeader from '../../../../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import type {
  EventLogColumns,
  EventLogHeadCells,
} from '../../../../../model/event-log.model';

type EventLogTableHeaderProps = {
  rowCount: number;
};

export const EventLogTableHeader = ({
  rowCount,
}: EventLogTableHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof EventLogColumns>('timestamp');

  const headCells: EventLogHeadCells[] = [
    {
      id: 'timestamp',
      label: t('eventLog.timestamp'),
      isSortable: false,
    },
    {
      id: 'type',
      label: t('eventLog.type'),
      isSortable: false,
    },
    {
      id: 'value',
      label: t('eventLog.value'),
      isSortable: false,
    },
  ];

  return (
    <StihlTableHeader<EventLogColumns>
      rowCount={rowCount}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
    />
  );
};
