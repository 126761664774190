/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlModal from '../../../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconWarning from '../../../../../../base/stihl-material-ui/icons/stihl-icon-warning';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../../app-alert/service/alert-provider';
import type { Card, Meta } from '../../../../model';
import { executeCommand } from '../../../../service/device-api-client/device-api-client';
import { CardBase } from '../card-component';

const StyledTypography = styled(Typography)`
  padding-block-start: 0.1rem;
`;

const WarningContent = styled.div`
  display: flex;
  margin: 1rem 0 1.5rem;
  padding: 1.5rem;
  background-color: ${stihlColor.yellowLight};
`;

const StyledWarningTypography = styled(Typography)`
  margin-block-end: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
`;

const StyledWarningTextTypography = styled(Typography)`
  font-weight: bold;
  font-size: 1rem;
`;

const TextPositioner = styled.div`
  margin-inline-start: 1rem;
  font-size: 1rem;
`;

enum ExecutionStatus {
  Executed = 'executed',
  Failed = 'failed',
}

enum ModalAction {
  Reboot = 'forceReboot',
  Reset = 'forceFactoryReset',
  ResetConfiguration = 'resetConfigurationData',
}

// eslint-disable-next-line max-lines-per-function
export const CommandsCard = ({
  meta,
  cardData,
}: {
  meta: Meta;
  cardData: Card;
}): JSX.Element => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();

  function handleClose(): void {
    setOpenModal(false);
  }

  function handleClick(action: ModalAction): void {
    setModalAction(action);
    setOpenModal(true);
  }

  function handleAction(): void {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (modalAction) {
      setIsLoading(true);
      void executeCommand(meta.deviceId, meta.deviceModel, modalAction)
        .then((response) => {
          setIsLoading(false);
          setOpenModal(false);
          if (response === (ExecutionStatus.Executed as string)) {
            const successAlertMessage = getTexts(modalAction).success;
            return setAlert({
              isOpen: true,
              message: successAlertMessage,
              severity: 'success',
            });
          }
          const alertMessage = getTexts(modalAction).failure;
          return setAlert({
            isOpen: true,
            message: alertMessage,
            severity: 'error',
          });
        })
        .catch(() => {
          setIsLoading(false);
          setOpenModal(false);
          const alertMessage = getTexts(modalAction).failure;
          setAlert({ isOpen: true, message: alertMessage, severity: 'error' });
        });
    }
  }

  function getTexts(command: ModalAction | null): {
    button: string;
    success: string;
    failure: string;
    warning: string;
    confirmation: string;
  } {
    if (command === null) {
      return {
        button: '',
        success: '',
        failure: '',
        warning: '',
        confirmation: '',
      };
    }
    switch (command) {
      case ModalAction.Reboot: {
        return {
          button: t('commands.rebootButton'),
          success: t('commands.rebootSuccess'),
          failure: t('commands.rebootFailed'),
          warning: t('commands.rebootWarning'),
          confirmation: t('commands.confirmReboot'),
        };
      }
      case ModalAction.Reset: {
        return {
          button: t('commands.resetButton'),
          success: t('commands.resetSuccess'),
          failure: t('commands.resetFailed'),
          warning: t('commands.resetWarning'),
          confirmation: t('commands.confirmReset'),
        };
      }
      case ModalAction.ResetConfiguration: {
        return {
          button: t('commands.resetConfigurationButton'),
          success: t('commands.resetConfigurationSuccess'),
          failure: t('commands.resetConfigurationFailed'),
          warning: t('commands.resetConfigurationWarning'),
          confirmation: t('commands.confirmResetConfiguration'),
        };
      }
    }
  }

  return (
    <CardBase title={cardData.title}>
      <Grid container spacing={2} data-testid="commands">
        {cardData.cardFields.map((cardField) => (
          <Fragment key={cardField.title}>
            <Grid item xs={12}>
              <StyledTypography>{t(cardField.title)} </StyledTypography>
            </Grid>
            <Grid item xs={5}>
              <StihlButtonSecondary
                sx={{
                  inlineSize: `${
                    cardData.cardFields.length > 1 ? '100%' : 'inherit'
                  }`,
                }}
                onClick={() => handleClick(cardField.value as ModalAction)}
              >
                {getTexts(cardField.value as ModalAction).button}
              </StihlButtonSecondary>
            </Grid>
          </Fragment>
        ))}

        <StihlModal
          open={openModal}
          onClose={handleClose}
          title={getTexts(modalAction).confirmation}
          actionButtonText={getTexts(modalAction).button}
          handleActionClick={handleAction}
          isLoading={isLoading}
          data-testid="commandsModal"
        >
          <WarningContent>
            <StihlIconWarning />
            <TextPositioner>
              <StyledWarningTypography>
                {t('commands.warning')}
              </StyledWarningTypography>
              <StyledWarningTextTypography>
                {getTexts(modalAction).warning}
              </StyledWarningTextTypography>
            </TextPositioner>
          </WarningContent>
        </StihlModal>
      </Grid>
    </CardBase>
  );
};

export default CommandsCard;

/* eslint-enable react/jsx-max-depth */
