import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, Dispatch, SetStateAction, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlIconArrowDown from '../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconFastForward from '../../../../base/stihl-material-ui/icons/stihl-icon-fast-forward';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledButton = styled(StihlButtonSecondary)`
  inline-size: 32px;
  min-inline-size: 32px;
  block-size: 32px;
  padding: 0;
`;

const ArrowRight = styled(StihlIconArrowDown)`
  transform: rotate(270deg);

  path {
    fill: ${stihlColor.white};
  }
`;

const ArrowLeft = styled(StihlIconArrowDown)`
  transform: rotate(90deg);

  path {
    fill: ${stihlColor.white};
  }
`;

const StyledIconFastForward = styled(StihlIconFastForward)`
  transform: rotate(180deg);
`;

const PaginationBar = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
`;

export type PaginationProps = {
  numberOfResults: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  itemToDisplay: string;
  resultsPerPage: number;
  setResultsPerPage: Dispatch<SetStateAction<number>>;
};

const Pagination = ({
  numberOfResults,
  page,
  setPage,
  itemToDisplay,
  resultsPerPage,
  setResultsPerPage,
}: PaginationProps): JSX.Element => {
  const { t } = useTranslation();

  const resultsPerPageOptions = [10, 20, 50];

  const numberOfPages = Math.ceil(numberOfResults / resultsPerPage);

  let itemStartNumber =
    page === 1
      ? page * resultsPerPage - (resultsPerPage - 1)
      : page * resultsPerPage - resultsPerPage + 1;

  let itemEndNumber =
    page === numberOfPages ? numberOfResults : page * resultsPerPage;

  if (numberOfPages === 0) {
    itemStartNumber = 0;
    itemEndNumber = 0;
  }

  function goToPage(pageClick: string): void {
    switch (pageClick) {
      case 'first': {
        return setPage(1);
      }
      case 'last': {
        return setPage(Math.ceil(numberOfResults / resultsPerPage));
      }
      case 'previous': {
        return setPage(page - 1);
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case 'next':
      default: {
        return setPage(page + 1);
      }
    }
  }

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setResultsPerPage(Number(event.target.value));
  }

  return (
    <PaginationBar data-testid="pagination">
      <Typography>{t('pagination.rowsPerPage')}</Typography>
      <StihlSelect
        id="rowsPerPage"
        value={resultsPerPage}
        data-testid="rowsPerPageFilter"
        onChange={handleChange}
        size="small"
      >
        {resultsPerPageOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </StihlSelect>
      {itemStartNumber} - {itemEndNumber}
      <Typography>
        {numberOfPages > 1
          ? ` ${itemToDisplay} ${t('pagination.of')} ${numberOfResults}`
          : ` ${itemToDisplay}`}
      </Typography>
      <div style={{ display: 'flex', gap: '0.2rem' }}>
        <StyledButton
          onClick={() => goToPage('first')}
          disabled={page === 1 || numberOfPages === 0}
          data-testid="firstPageButton"
        >
          <StihlIconFastForward />
        </StyledButton>
        <StyledButton
          onClick={() => goToPage('previous')}
          disabled={page === 1 || numberOfPages === 0}
          data-testid="previousButton"
        >
          <ArrowLeft />
        </StyledButton>
        <StyledButton
          onClick={() => goToPage('next')}
          disabled={page === numberOfPages || numberOfPages === 0}
          data-testid="nextButton"
        >
          <ArrowRight />
        </StyledButton>
        <StyledButton
          onClick={() => goToPage('last')}
          disabled={page === numberOfPages || numberOfPages === 0}
          data-testid="lastPageButton"
        >
          <StyledIconFastForward />
        </StyledButton>
      </div>
    </PaginationBar>
  );
};

export default Pagination;
