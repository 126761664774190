import type { Theme, Palette, PaletteColor } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type { SpacingArgument } from '@mui/system/createTheme/createSpacing';
import {
  stihlColor,
  htmlFontSize,
  stihlSpacingPxUnit,
  stihlSpacingRemAsEmUnit,
  stihlSpacingRemUnit,
  stihlColorTransparent,
  stihlTextStyles,
} from './stihl-style-guide';

const typography = {
  fontFamily: stihlTextStyles.copyMedium.fontFamily,

  htmlFontSize,

  h1: stihlTextStyles.h1,
  h2: stihlTextStyles.h2,
  h3: stihlTextStyles.h3,
  h4: stihlTextStyles.h4,
  h5: stihlTextStyles.h5,
  h6: stihlTextStyles.h6,
  subtitle1: stihlTextStyles.h3,
  subtitle2: stihlTextStyles.subtitle,
  body1: stihlTextStyles.copyMedium,
  body2: stihlTextStyles.copyBody2,
  button: stihlTextStyles.copyButton,
  caption: stihlTextStyles.copyCaption,
  overline: stihlTextStyles.copySmall,
};

export const stihlMaterialUiThemeOptions = {
  typography,
  palette: {
    common: {
      black: stihlColor.black,
      white: stihlColor.white,
    },
    primary: {
      main: stihlColor.stihlOrange,
      light: stihlColor.stihlOrangeLight,
      dark: stihlColor.stihlOrangeDark,
      contrastText: stihlColor.white,
      transparent: stihlColorTransparent.stihlOrange(0.5),
    },
    secondary: {
      main: stihlColor.black,
      light: stihlColor.black,
      dark: stihlColor.black,
      contrastText: stihlColor.white,
      transparent: stihlColorTransparent.black(0.5),
    },
    error: {
      main: stihlColor.red,
      light: stihlColor.red, // Needed as MUI uses a .6 opacity on the light color
      dark: stihlColor.redDark,
      contrastText: stihlColor.white,
    },
    warning: {
      main: stihlColor.yellow,
      light: stihlColor.yellowDark, // Needed as MUI uses a .6 opacity on the light color
      dark: stihlColor.yellowDark,
      contrastText: stihlColor.black,
    },
    info: {
      main: stihlColor.blue,
      light: stihlColor.blueLight,
      dark: stihlColor.blueDark,
      contrastText: stihlColor.white,
    },
    success: {
      main: stihlColor.green,
      light: stihlColor.green, // Needed as MUI uses a .6 opacity on the light color
      dark: stihlColor.greenDark,
      contrastText: stihlColor.white,
    },
    grey: {
      // mui naming conventions
      /* eslint-disable @typescript-eslint/naming-convention */
      '100': stihlColor.background,
      '200': stihlColor.greyLight,
      '300': stihlColor.greyMid,
      '400': stihlColor.greyDark,
      '500': stihlColor.greyBase,
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    text: {
      primary: stihlColor.black,
      secondary: stihlColor.fontGrey,
      disabled: stihlColor.greyBase,
      hint: stihlColor.fontGrey,
      error: stihlColor.red,
      success: stihlColor.green,
    },
    priority: {
      priority1: stihlColor.priority1,
      priority2: stihlColor.priority2,
      priority3: stihlColor.priority3,
    },
    stihlColors: {
      stihlOrange: stihlColor.stihlOrange,
      stihlOrangeLight: stihlColor.stihlOrangeLight,
      stihlOrangeMid: stihlColor.stihlOrangeMid,
      stihlOrangeDark: stihlColor.stihlOrangeDark,
      stihlOrangeDeep: stihlColor.stihlOrangeDeep,
      greyBase: stihlColor.greyBase,
      yellow: stihlColor.yellow,
      yellowLight: stihlColor.yellowLight,
      yellowMid: stihlColor.yellowMid,
      yellowDark: stihlColor.yellowDark,
      yellowDeep: stihlColor.yellowDeep,
    },
    divider: 'rgba(0,0,0,0.3)',
    background: {
      default: stihlColor.white,
      paper: stihlColor.white,
    },
    action: {
      disabled: stihlColor.greyBase,
    },
  },
  // use `rem` as standard for responsive, accessible UI that scales with the user's browser `html` root font size
  spacing: stihlSpacingRemUnit,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      // mui naming conventions
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@global': {
        body: {
          // https://mui.com/api/typography/
          fontSize: typography.body1.fontSize,
        },
      },
    },
  },
  // default props for every material ui component
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
  components: {
    // override Mui button and alert default styles to fit Stihl design guidelines
    MuiButton: {
      styleOverrides: {
        root: {
          blockSize: '3rem',
          padding: '0.375rem 1.5rem',
          fontSize: '14px',
          // mui naming conventions
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' as ButtonSize },
          style: {
            blockSize: '1.5rem',
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          margin: '5.75rem 0.5rem',
          padding: '0.675rem 1.5rem',
          color: stihlColor.black,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1rem',
          color: `${stihlColor.black}`,
          marginTop: '0.5rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: `${stihlColor.black}`,
          fontWeight: 600,
          paddingTop: '0.1rem',
        },
      },
    },
  },
};

export type ButtonSize = 'small' | 'medium' | 'large' | undefined;

const _stihlMaterialUiTheme = createTheme(stihlMaterialUiThemeOptions);

export type StihlSpacing = {
  (value?: number): string;
  (topBottom: SpacingArgument, rightLeft: SpacingArgument): string;
  /* eslint-disable @typescript-eslint/unified-signatures */
  (
    top: SpacingArgument,
    rightLeft: SpacingArgument,
    bottom: SpacingArgument,
  ): string;
  (
    top: SpacingArgument,
    right: SpacingArgument,
    bottom: SpacingArgument,

    left: SpacingArgument,
  ): string;
  /* eslint-enable @typescript-eslint/unified-signatures */
};

export type StihlPalette = Palette & {
  primary: PaletteColor & {
    transparent: string;
  };
  secondary: PaletteColor & {
    transparent: string;
  };
  priority: PaletteColor & {
    priority1: string;
    priority2: string;
    priority3: string;
  };
  stihlColors: PaletteColor & {
    stihlOrange: string;
    stihlOrangeLight: string;
    stihlOrangeMid: string;
    stihlOrangeDark: string;
    stihlOrangeDeep: string;
    greyBase: string;
    yellow: string;
    yellowLight: string;
    yellowMid: string;
    yellowDark: string;
    yellowDeep: string;
  };
};

export type StihlTheme = Omit<Theme, 'spacing' | 'palette'> & {
  palette: StihlPalette;
  spacing: StihlSpacing;
  /**
   * Spacing in pixel instead of default `rem`.
   *
   * Prefer `rem` to enable scaling the UI with set user configured
   * `<html>` root font-size.
   * Only use pixel for non scalable values, that are only relative
   * to the device resolution density.
   *
   * @param factor
   */
  spacingPx: (factor: number) => string;
  /**
   * @see stihlSpacingRemAsEmUnit
   */
  spacingRemAsEm: (factor: number) => string;
};

// use `object` to enable `&` intersection

export type StihlThemeProps<TOwnProps extends object = object> = {
  theme: StihlTheme;
} & (TOwnProps extends Record<string, unknown> ? TOwnProps : object);

function extendMaterialUiTheme(theme: Theme): StihlTheme {
  const extendedTheme = theme as unknown as StihlTheme;

  extendedTheme.spacingPx = stihlSpacingPxUnit;
  extendedTheme.spacingRemAsEm = stihlSpacingRemAsEmUnit;

  return extendedTheme;
}

export const stihlMaterialUiTheme = extendMaterialUiTheme(
  _stihlMaterialUiTheme,
);

export function isStihlMaterialUiTheme(value: unknown): value is StihlTheme {
  return (
    typeof value === 'object' &&
    value != null &&
    'spacingRemAsEm' in value &&
    typeof (value as { spacingRemAsEm: unknown }).spacingRemAsEm === 'function'
  );
}
