import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonOutlined from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlButtonSecondaryLoading from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconWarning from '../../../../base/stihl-material-ui/icons/stihl-icon-warning';
import type { ReleaseManagementMetaData } from '../../model/release-management/release-management.model';
import MetadataPdfExport from './metadata-pdf-export';
import { combineUpgradeSourceVersions } from './metadata-utils';

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OverviewEntryItem = ({
  name,
  value,
}: {
  name: string;
  value?: string | boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const mappedValue = (): string | undefined => {
    if (typeof value === 'boolean') {
      return value
        ? t('releaseManagement.editMetadataForm.yes')
        : t('releaseManagement.editMetadataForm.no');
    }
    return value;
  };
  return (
    <>
      <Grid item xs={3}>
        <Typography variant="body2" fontWeight="600" marginRight="0.5rem">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{mappedValue()}</Typography>
      </Grid>
    </>
  );
};

const MetadataOverviewItems = ({
  metadata,
}: {
  metadata: ReleaseManagementMetaData;
}): JSX.Element => {
  const { t } = useTranslation();

  const combinedUpgradeSourceVersions: string[] | undefined =
    combineUpgradeSourceVersions(
      metadata.supportedUpgradeSourceVersions ?? [],
      metadata.supportedUpgradeSourceVersionsUserInput ?? [],
    );

  return (
    <Grid container rowSpacing={2}>
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.buildNumber')}
        value={metadata.buildNumber}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.isIncremental')}
        value={metadata.isIncremental}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.isBeta')}
        value={metadata.isBetaVersion}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.isSecurityRelevant')}
        value={metadata.isSecurityRelevant}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.isSecurityCritical')}
        value={metadata.isSecurityCritical}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.requiresReboot')}
        value={metadata.requiresReboot}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.supportedDeviceModels')}
        value={metadata.deviceModel}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.supportedDeviceTypes')}
        value={metadata.supportedDeviceTypes?.join(', ')}
      />
      <OverviewEntryItem
        name={t(
          'releaseManagement.editMetadataForm.supportedUpgradeSourceVersions',
        )}
        value={combinedUpgradeSourceVersions?.join(', ')}
      />
      <OverviewEntryItem
        name={t(
          'releaseManagement.editMetadataForm.supportedDeviceMaterialNumbers',
        )}
        value={metadata.supportedDeviceMaterialNumbers?.join(', ')}
      />
      <OverviewEntryItem
        name={t(
          'releaseManagement.editMetadataForm.supportedUpgradeSourceVersions',
        )}
        value={metadata.supportedUpgradeSourceVersions?.join(', ')}
      />
      <OverviewEntryItem
        name={t('releaseManagement.editMetadataForm.fileName')}
        value={metadata.fileName}
      />

      <Grid item xs={12}>
        <Typography variant="body2" fontWeight="600">
          {t('releaseManagement.editMetadataForm.fileHash')}
        </Typography>
      </Grid>

      <Grid item xs={3} sx={{ paddingLeft: '1rem' }}>
        <Typography variant="body2" fontWeight="600">
          {t('releaseManagement.editMetadataForm.fileHashValue')}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {metadata.fileHash?.value}
      </Grid>

      <Grid item xs={3} sx={{ paddingLeft: '1rem' }}>
        <Typography variant="body2" fontWeight="600">
          {t('releaseManagement.editMetadataForm.fileHashType')}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {metadata.fileHash?.type}
      </Grid>
    </Grid>
  );
};

export type CheckMetadataOverviewProps = {
  metadata: ReleaseManagementMetaData;
  handleBackToEdit: () => void;
  handleSubmitMetadata: () => void;
  isLoading?: boolean;
};

const CheckMetadataOverview = ({
  metadata,
  handleBackToEdit,
  handleSubmitMetadata,
  isLoading,
}: CheckMetadataOverviewProps): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClose(): void {
    setIsModalOpen(false);
  }

  const handleSubmit = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return (
    <>
      <HeadlineWrapper>
        <Typography variant="h3" data-testid="metadataCheckHeadline">
          {t('releaseManagement.metadataOverview.checkMetadataForRelease', {
            version: metadata.version,
          })}
        </Typography>
        <MetadataPdfExport metadata={metadata} />
      </HeadlineWrapper>
      <br />
      <Typography>
        {t('releaseManagement.metadataOverview.correctMetadata')}
      </Typography>
      <br />
      <MetadataOverviewItems metadata={metadata} />
      <br />
      <Typography variant="h5">
        <StihlIconWarning />{' '}
        {t('releaseManagement.editMetadataForm.submitWarning')}
      </Typography>
      <br />
      <StihlButtonOutlined
        style={{
          marginRight: '1rem',
        }}
        onClick={handleBackToEdit}
        data-testid="backToEditButton"
      >
        {t('releaseManagement.metadataOverview.buttonBackToEditing')}
      </StihlButtonOutlined>
      <StihlButtonSecondaryLoading
        type="submit"
        onClick={handleSubmit}
        data-testid="submitButton"
      >
        {t('releaseManagement.metadataOverview.buttonAddMetadata')}
      </StihlButtonSecondaryLoading>
      <StihlModal
        title={t('releaseManagement.metadataOverview.addingMetadataModalTitle')}
        open={isModalOpen}
        onClose={handleClose}
        actionButtonText={t(
          'releaseManagement.metadataOverview.buttonConfirmAdd',
        )}
        handleOnClose={handleBackToEdit}
        handleActionClick={handleSubmitMetadata}
        data-testid="submitModal"
        isLoading={isLoading}
      >
        <>
          <Typography>
            {t('releaseManagement.metadataOverview.modalConfirmText')}
          </Typography>
          <br />
          <Typography variant="h5">
            <StihlIconWarning />{' '}
            {t('releaseManagement.editMetadataForm.submitWarning')}
          </Typography>
          <br />
        </>
      </StihlModal>
    </>
  );
};

export default CheckMetadataOverview;
