import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import StihlIconArrowDown from '../../icons/stihl-icon-arrow-down';
import { stihlColor } from '../../theme/stihl-style-guide';
import type { StihlTextFieldProps } from '../stihl-text-field/stihl-text-field';
import StihlTextField from '../stihl-text-field/stihl-text-field';

export const StyledSelectIcon = styled(StihlIconArrowDown)`
  position: absolute;
  /* stylelint-disable declaration-no-important */
  inset-block-start: calc(50% - 0.25rem) !important;
  inset-inline-end: 1rem !important;
  /* stylelint-enable declaration-no-important */
  pointer-events: none;
`;

const StihlSelect: FC<StihlTextFieldProps & { children: ReactNode }> = ({
  children,
  type: _type,
  margin: _margin,
  variant: _variant,
  ...textFieldProps
}) => {
  return (
    <StihlTextField
      {...textFieldProps}
      select
      SelectProps={{
        // 3rd party naming conventions
        // eslint-disable-next-line @typescript-eslint/naming-convention
        displayEmpty: true,
        IconComponent: StyledSelectIcon,
        MenuProps: {
          sx: {
            '&& .Mui-selected': {
              backgroundColor: `${stihlColor.greyMid}`,
            },
          },
        },
        ...textFieldProps.SelectProps,
      }}
    >
      {children}
    </StihlTextField>
  );
};

export default StihlSelect;
