/* eslint-disable import/max-dependencies */
/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState, createRef, useEffect, useMemo } from 'react';
import type { JSX, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import semver from 'semver';
import { dateTimeFormattingParamsDefault } from '../../../base/date-formatting/date-formatting';
import { useLocalStorage } from '../../../base/local-storage-hook/local-storage-hook';
import StihlButtonPrimary from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import { StihlIconTakeOff } from '../../../base/stihl-material-ui/icons/stihl-icon-takeoff';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../app-alert/service/alert-provider';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import QueryComponent from '../../app-shell/ui/query-component';
import { AccessLicenses, DeviceModel } from '../../device/model';
import type {
  DeviceCountries,
  DeviceStatistics,
  DeviceStatus,
  SwVersions,
} from '../model/statistics.model';
import { DeviceStatusDisplayType } from '../model/statistics.model';
import { getErrorStatistics } from '../service/device-management-api-client/device-management-api-client';
import { useDeviceStatistics } from '../service/device-management-service/device-management-service';
import { getDefaultProcessingChain } from '../service/processing-chain';
import ErrorDevicesCounter from './counters/error-devices-counter';
import RegisteredDevicesCounter from './counters/registered-devices-counter';
import CountriesFilter from './filters/countries-filter';
import DeviceModelFilter from './filters/device-model-filter';
import ProcessingChainFilter from './filters/processing-chain-filter';
import DeviceStatusTable from './overview-items/device-management-device-status-table';
import SwVersionSearch from './search/sw-version-search';
import StatisticsConnections from './statistic-items/statistics-connections';
import DashboardModal from './statistic-items/statistics-dashboard-modal';
import StatisticsErrors from './statistic-items/statistics-errors';
import StatisticsSoftwareVersions from './statistic-items/statistics-software-versions';
import StatisticsVersionRollout from './statistic-items/statistics-version-rollout';

const CardHeader = styled.div`
  position: relative;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

const StyledStatisticsGrid = styled(Grid)`
  margin-block-start: 2rem;
`;

const StyledStatisticsGridItem = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & GridProps): JSX.Element => {
  return (
    <Grid
      item
      md={7}
      textAlign="center"
      sx={{ marginBottom: '2rem', display: 'flex' }}
      {...rest}
    >
      {children}
    </Grid>
  );
};

const StyledHeaderSectionWrapper = styled.div`
  display: flex;
`;

const StyledDeviceModelFilterWrapper = styled.div`
  align-self: center;
  margin-inline-start: 1rem;
`;

const StyledFilterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 1rem;
`;

const StyledCountryFilterWrapper = styled.div`
  margin: 0 0.5rem;
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1380px) {
    flex-direction: column;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: auto 4rem;
`;

const StyledStihlButtonPrimary = styled(StihlButtonPrimary)`
  min-inline-size: 185px;
  margin: 0.25rem 0 0.25rem 2rem;
`;

const getStatistics = (
  statisticsData: DeviceStatistics | undefined,
  countryFilter: string,
): {
  totalRegisteredDevices: number;
  totalDevicesWithActiveErrors: number;
  totalDevicesConnectedViaWifi: number;
  totalDevicesConnectedViaCellular: number;
  totalDevicesConnectedViaEthernet: number;
  totalDevicesOffline: number;
  totalDevicesWithoutActiveErrors: number;
  availableCountries: DeviceCountries[];
  softwareVersions: SwVersions[] | null | undefined;
} => {
  const filteredCountryStatistics = statisticsData
    ? statisticsData.countryStatistics.filter(
        (country) =>
          country.countryCode === countryFilter || countryFilter === '',
      )
    : [];

  let totalRegisteredDevices = 0;
  let totalDevicesWithActiveErrors = 0;
  let totalDevicesConnectedViaWifi = 0;
  let totalDevicesConnectedViaCellular = 0;
  let totalDevicesConnectedViaEthernet = 0;
  for (const country of filteredCountryStatistics) {
    totalRegisteredDevices += country.numRegistered;
    totalDevicesWithActiveErrors += country.numWithActiveErrors;
    totalDevicesConnectedViaWifi += country.numCurrentlyConnectedViaWifi;
    totalDevicesConnectedViaCellular +=
      country.numCurrentlyConnectedViaCellular;
    totalDevicesConnectedViaEthernet += country.numConnectedViaEthernet;
  }

  const totalDevicesOffline =
    totalRegisteredDevices -
    (totalDevicesConnectedViaCellular + totalDevicesConnectedViaWifi);

  const totalDevicesWithoutActiveErrors =
    totalRegisteredDevices - totalDevicesWithActiveErrors;

  const availableCountries = statisticsData
    ? statisticsData.countryStatistics
    : [];

  const softwareVersions =
    countryFilter === '' && statisticsData
      ? statisticsData.softwareUpdateStatistics?.swVersions.sort((v1, v2) => {
          try {
            return -semver.compare(String(v1), String(v2));
          } catch {
            return -String(v1).localeCompare(String(v2), undefined, {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              numeric: true,
            });
          }
        })
      : null;

  return {
    totalRegisteredDevices,
    totalDevicesWithActiveErrors,
    totalDevicesConnectedViaWifi,
    totalDevicesConnectedViaCellular,
    totalDevicesConnectedViaEthernet,
    totalDevicesOffline,
    totalDevicesWithoutActiveErrors,
    availableCountries,
    softwareVersions,
  };
};

// eslint-disable-next-line max-lines-per-function
const DeviceManagementStatistics = ({
  isAllowedForUpdateManagement,
}: {
  isAllowedForUpdateManagement: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [processingChain, setProcessingChain] = useState(
    getDefaultProcessingChain(),
  );
  const [countryFilter, setCountryFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenErrorTable, setIsOpenErrorTable] = useState(false);
  const [isOpenSwVersionTable, setIsOpenSwVersionTable] = useState(false);

  const [deviceStatuses, setDeviceStatuses] = useState<DeviceStatus[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [_, setAlert] = useAlertStore();
  const [store] = useStore();
  const [deviceModel, setDeviceModel] = useState<DeviceModel>(
    store.deviceModels[0],
  );

  const [hasAcceptedDashboardModal, setHasAcceptedDashboardModal] =
    useLocalStorage<boolean>('hasAcceptedDashboardModal', false);

  function handleGoToDashboard(): void {
    window.open(`${process.env.REACT_APP_POWER_BI_BASE_URL}`);
    setModalOpen(false);
  }

  const tableRef: RefObject<HTMLDivElement> = createRef();

  function processingChainCallback(filterValue: string): void {
    setProcessingChain(filterValue);
  }

  useEffect(() => {
    const executeScroll = (): void => {
      tableRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    if (isOpenErrorTable || isOpenSwVersionTable) {
      executeScroll();
    }
  }, [isOpenErrorTable, isOpenSwVersionTable, tableRef]);

  const deviceStatistics = useDeviceStatistics(processingChain, deviceModel);
  const statisticsData = deviceStatistics.data;

  const {
    totalRegisteredDevices,
    totalDevicesWithActiveErrors,
    totalDevicesConnectedViaWifi,
    totalDevicesConnectedViaCellular,
    totalDevicesConnectedViaEthernet,
    totalDevicesOffline,
    totalDevicesWithoutActiveErrors,
    availableCountries,
    softwareVersions,
  } = useMemo(
    () => getStatistics(statisticsData, countryFilter),
    [countryFilter, statisticsData],
  );

  const hasImowDashboardAccess =
    store.license === AccessLicenses.SecondLevelSupportLicense ||
    store.license === AccessLicenses.ReleaseManagerLicense ||
    store.license === AccessLicenses.DeveloperLicense;

  const isConnectedBox = store.deviceModels.includes(DeviceModel.connectedBox);

  function handleModalOpen(): void {
    setModalOpen(true);
  }

  function handleClose(): void {
    setModalOpen(false);
  }

  function handleCountryFilter(filterValue: string): void {
    setCountryFilter(filterValue);
  }

  function handleOpenErrorTable(): void {
    if (isOpenErrorTable) {
      return setIsOpenErrorTable(false);
    }
    setIsLoading(true);
    setIsOpenSwVersionTable(false);
    getErrorStatistics(deviceModel)
      .then((response) => {
        setIsLoading(false);
        setDeviceStatuses(response);
        return setIsOpenErrorTable(true);
      })
      .catch(() => {
        setIsLoading(false);
        return setAlert({
          isOpen: true,
          message: t('deviceStatusTable.errorRequestFailed'),
          severity: 'error',
        });
      });
  }

  function returnLoadingOrErrorDevices(): JSX.Element {
    return isLoading ? (
      <StyledCircularProgress color="primary" size={70} />
    ) : (
      <ErrorDevicesCounter
        totalDevicesWithActiveErrors={totalDevicesWithActiveErrors}
        handleOpenErrorTable={handleOpenErrorTable}
        isOpenErrorTable={isOpenErrorTable}
      />
    );
  }

  return (
    <QueryComponent data={deviceStatistics}>
      {deviceStatistics.data && (
        <>
          <Card>
            <CardHeader>
              <StyledHeaderWrapper>
                <StyledHeaderSectionWrapper>
                  <StyledDeviceModelFilterWrapper>
                    <DeviceModelFilter
                      deviceModel={deviceModel}
                      setDeviceModel={setDeviceModel}
                    />
                  </StyledDeviceModelFilterWrapper>
                  <RegisteredDevicesCounter
                    totalRegisteredDevices={totalRegisteredDevices}
                  />
                  {!isConnectedBox && returnLoadingOrErrorDevices()}
                  <SwVersionSearch
                    deviceModel={deviceModel}
                    beforeFetch={() => setIsOpenErrorTable(false)}
                    onSuccess={(response: DeviceStatus[]) => {
                      setDeviceStatuses(response);
                      setIsOpenSwVersionTable(true);
                    }}
                  />
                </StyledHeaderSectionWrapper>
                <StyledFilterWrapper>
                  <ProcessingChainFilter
                    processingChain={processingChain}
                    setProcessingChain={processingChainCallback}
                  />
                  {(store.deviceModels.includes(DeviceModel.iMowIA01) ||
                    store.deviceModels.includes(DeviceModel.iMowIA02)) && (
                    <StyledCountryFilterWrapper data-testid="countryFilterWrapper">
                      <CountriesFilter
                        availableCountries={availableCountries}
                        countryFilterCallback={handleCountryFilter}
                        shouldReset={false}
                      />
                    </StyledCountryFilterWrapper>
                  )}
                  {hasImowDashboardAccess && (
                    <StyledStihlButtonPrimary
                      data-testid="openDashboardOrModalButton"
                      onClick={
                        hasAcceptedDashboardModal
                          ? handleGoToDashboard
                          : handleModalOpen
                      }
                    >
                      <StihlIconTakeOff />
                      {t('deviceStatistics.bigDataDashboard')}
                    </StyledStihlButtonPrimary>
                  )}
                  <DashboardModal
                    isOpen={isModalOpen}
                    onClose={handleClose}
                    handleGoToDashboard={handleGoToDashboard}
                    setHasAcceptedDashboardModal={setHasAcceptedDashboardModal}
                  />
                </StyledFilterWrapper>
              </StyledHeaderWrapper>
            </CardHeader>
            <CardContent>
              <StyledStatisticsGrid container>
                {!store.deviceModels.includes(DeviceModel.connectedBox) && (
                  <StyledStatisticsGridItem data-testid="devicesWithErrorsStatistics">
                    <StatisticsErrors
                      totalDevicesWithActiveErrors={
                        totalDevicesWithActiveErrors
                      }
                      totalDevicesWithoutActiveErrors={
                        totalDevicesWithoutActiveErrors
                      }
                    />
                  </StyledStatisticsGridItem>
                )}
                <StyledStatisticsGridItem data-testid="connectionStatistics">
                  <StatisticsConnections
                    totalDevicesConnectedViaCellular={
                      totalDevicesConnectedViaCellular
                    }
                    totalDevicesConnectedViaWifi={totalDevicesConnectedViaWifi}
                    totalDevicesOffline={totalDevicesOffline}
                    totalDevicesConnectedViaEthernet={
                      totalDevicesConnectedViaEthernet
                    }
                    isConnectedBox={isConnectedBox}
                  />
                </StyledStatisticsGridItem>
                {softwareVersions && (
                  <StyledStatisticsGridItem data-testid="swVersionsStatistics">
                    <StatisticsSoftwareVersions
                      softwareVersions={softwareVersions}
                    />
                  </StyledStatisticsGridItem>
                )}
              </StyledStatisticsGrid>
              {softwareVersions && (
                <StyledStatisticsGrid
                  container
                  data-testid="swVersionRolloutStatistics"
                >
                  <StatisticsVersionRollout
                    softwareVersions={softwareVersions}
                  />
                </StyledStatisticsGrid>
              )}
              <Typography variant="caption" data-testid="statisticsLastUpdated">
                {t('deviceStatistics.statisticsLastUpdated', {
                  lastUpdated: deviceStatistics.dataUpdatedAt,
                  formatParams: {
                    lastUpdated: dateTimeFormattingParamsDefault,
                  },
                })}
              </Typography>
            </CardContent>
          </Card>
          {!isConnectedBox && (isOpenErrorTable || isOpenSwVersionTable) && (
            <DeviceStatusTable
              tableRef={tableRef}
              deviceStatuses={deviceStatuses}
              isAllowedForUpdateManagement={isAllowedForUpdateManagement}
              displayType={
                isOpenErrorTable
                  ? DeviceStatusDisplayType.ErrorDevices
                  : DeviceStatusDisplayType.SoftwareVersion
              }
            />
          )}
        </>
      )}
    </QueryComponent>
  );
};

export default DeviceManagementStatistics;
/* eslint-enable react/jsx-max-depth */
/* eslint-enable import/max-dependencies */
