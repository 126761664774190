import type { Dispatch } from 'react';
import { createContext } from 'react';

export type DeviceManagementState = {
  numberOfSelectedDevices: number;
  isDetailsOpen: boolean;
  currentOpenDetailsId: string;
  uniqueSelectedDeviceIds: Set<string>;
};

export const initialValues: DeviceManagementState = {
  numberOfSelectedDevices: 0,
  isDetailsOpen: false,
  currentOpenDetailsId: '',
  uniqueSelectedDeviceIds: new Set<string>(),
};

export enum DeviceStateActionType {
  SetUniqueSelectedDeviceIds = 'SET_UNIQUE_SELECTED_DEVICE_IDS',
  DetailsOpen = 'DETAILS_OPEN',
  CurrentOpenDetailsId = 'CURRENT_OPEN_DETAILS_ID',
  AddToUniqueSelectedDeviceIds = 'ADD_TO_UNIQUE_SELECTED_DEVICE_IDS',
  DeleteFromUniqueSelectedDeviceIds = 'DELETE_FROM_UNIQUE_SELECTED_DEVICE_IDS',
  ResetUniqueSelectedDeviceIds = 'RESET_UNIQUE_SELECTED_DEVICE_IDS',
}

export type DeviceStateAction =
  | { type: DeviceStateActionType.SetUniqueSelectedDeviceIds; value: string[] }
  | { type: DeviceStateActionType.DetailsOpen; value: boolean }
  | { type: DeviceStateActionType.CurrentOpenDetailsId; value: string }
  | { type: DeviceStateActionType.AddToUniqueSelectedDeviceIds; value: string }
  | {
      type: DeviceStateActionType.DeleteFromUniqueSelectedDeviceIds;
      value: string;
    }
  | {
      type: DeviceStateActionType.ResetUniqueSelectedDeviceIds;
    };

// eslint-disable-next-line max-lines-per-function
export const reducer = (
  state: DeviceManagementState,
  action: DeviceStateAction,
): DeviceManagementState => {
  switch (action.type) {
    case DeviceStateActionType.SetUniqueSelectedDeviceIds: {
      const setFromValue = new Set(action.value);
      return {
        ...state,
        uniqueSelectedDeviceIds: setFromValue,
        numberOfSelectedDevices: setFromValue.size,
      };
    }
    case DeviceStateActionType.DetailsOpen: {
      return { ...state, isDetailsOpen: action.value };
    }
    case DeviceStateActionType.CurrentOpenDetailsId: {
      return { ...state, currentOpenDetailsId: action.value };
    }
    case DeviceStateActionType.AddToUniqueSelectedDeviceIds: {
      const updateSet = new Set(state.uniqueSelectedDeviceIds);
      updateSet.add(action.value);
      return {
        ...state,
        uniqueSelectedDeviceIds: updateSet,
        numberOfSelectedDevices: updateSet.size,
      };
    }
    case DeviceStateActionType.DeleteFromUniqueSelectedDeviceIds: {
      const updateSet = new Set(state.uniqueSelectedDeviceIds);
      updateSet.delete(action.value);
      return {
        ...state,
        uniqueSelectedDeviceIds: updateSet,
        numberOfSelectedDevices: updateSet.size,
      };
    }
    case DeviceStateActionType.ResetUniqueSelectedDeviceIds: {
      return {
        ...state,
        uniqueSelectedDeviceIds: initialValues.uniqueSelectedDeviceIds,
        numberOfSelectedDevices: initialValues.numberOfSelectedDevices,
      };
    }
    default: {
      return state;
    }
  }
};

export const DeviceManagementTableContext = createContext<{
  state: DeviceManagementState;
  dispatch: Dispatch<DeviceStateAction>;
}>({
  state: initialValues,
  dispatch: (state) => {
    return state;
  },
});
