import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { TabsResponse } from '../../../app-shell/model/app.models';
import { getHelpTabs } from '../help-api-client/help-api-client';

export function useHelpTabs(): UseQueryResult<TabsResponse[], Error> {
  return useQuery<TabsResponse[], Error>({
    queryKey: ['helpTabs'],
    queryFn: async ({ signal }) => getHelpTabs(signal),
    refetchOnWindowFocus: false,
  });
}
