import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import { StihlTableRow } from '../../../../../base/stihl-material-ui/components/stihl-table/stihl-table-row';
import { getComparator } from '../../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconBin from '../../../../../base/stihl-material-ui/icons/stihl-icon-bin';
import Pagination from '../../../../app-shell/ui/pagination/pagination';
import type { DeviceManagementColumns } from '../../../model/overview.models';
import { useDeviceManagementStore } from '../../../service/device-management-store/device-management-store-provider';
import ConnectionState from '../../common/connection-state';
import {
  EnhancedTableToolbar,
  StyledTableContainer,
} from '../../overview-items/device-management-table/device-management-table';
import DeviceManagementTableHeader from '../../overview-items/device-management-table/device-management-table-header';

export type MultiAssignmentDeviceTableProps = {
  handleStepChange: (panel: string) => void;
};

// eslint-disable-next-line max-lines-per-function
const MultiAssignmentDeviceTable = ({
  handleStepChange,
}: MultiAssignmentDeviceTableProps): JSX.Element => {
  const { t } = useTranslation();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof DeviceManagementColumns>('serialNumber');
  const [selected, setSelected] = useState<string[]>([]);

  const [page, setPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const [store, setStore] = useDeviceManagementStore();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function getDevicesFromStore() {
    return [...store.multiAssignmentDevices];
  }

  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  const numberOfResults = getDevicesFromStore().length;
  const displayDevices = getDevicesFromStore().slice(
    firstToDisplay,
    lastToDisplay,
  );

  function handleSelectAllClick(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const newSelectedDevices = getDevicesFromStore().map((n) => n.deviceId);
    if (event.target.checked) {
      setSelected(newSelectedDevices);
      return;
    }
    setSelected([]);
  }

  const isSelected = (deviceId: string): boolean => selected.includes(deviceId);

  function removeSelectedDevicesFromStore(): void {
    const selectedRows = getDevicesFromStore().filter((row) =>
      selected.includes(row.deviceId),
    );

    store.removeDevices(selectedRows);
    const updatedStore = { ...store };
    setStore(updatedStore);
    setSelected([]);
  }

  return (
    <>
      <EnhancedTableToolbar
        data-testid="multiAssignmentToolbar"
        selectedDevicesNumber={selected.length}
        title={t('updateManagement.preselectedDevices')}
      >
        <StihlTooltip
          title={t('updateManagement.deleteTooltip')}
          placement="left"
        >
          <IconButton
            onClick={removeSelectedDevicesFromStore}
            data-testid="deleteButton"
          >
            <StihlIconBin />
          </IconButton>
        </StihlTooltip>
      </EnhancedTableToolbar>
      <StyledTableContainer>
        <Table data-testid="multiAssignmentTable">
          <DeviceManagementTableHeader
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            onSelectAllClick={handleSelectAllClick}
            rowCount={getDevicesFromStore().length}
            numberSelected={selected.length}
            displayColumns={[
              'serialNumber',
              'deviceId',
              'deviceModel',
              'deviceType',
              'softwareVersion',
              'isConnected',
              'processingChain',
            ]}
          />
          <TableBody>
            {[...displayDevices]
              .sort(getComparator(order, orderBy))
              .map((row) => {
                const isItemSelected = isSelected(row.deviceId);
                return (
                  <StihlTableRow
                    key={row.deviceId}
                    id={row.deviceId}
                    setSelected={setSelected}
                    selected={selected}
                    isItemSelected={isItemSelected}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {row.serialNumber}
                    </TableCell>
                    <TableCell align="left">{row.deviceId}</TableCell>
                    <TableCell align="left">{row.deviceModel}</TableCell>
                    <TableCell align="left">
                      {t(row.deviceType ?? 'unknown')}
                    </TableCell>
                    <TableCell align="left">{row.softwareVersion}</TableCell>
                    <TableCell align="left">
                      <ConnectionState isConnected={row.isConnected ?? false} />
                    </TableCell>
                    <TableCell align="left">{row.processingChain}</TableCell>
                    <TableCell />
                  </StihlTableRow>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {displayDevices.length === 0 ? (
        <>
          <br />
          <Typography>{t('updateManagement.addDevicesForUpdate')}</Typography>
        </>
      ) : (
        <Pagination
          numberOfResults={numberOfResults}
          page={page}
          setPage={setPage}
          itemToDisplay={t('updateManagement.devices')}
          resultsPerPage={resultsPerPage}
          data-testid="pagination"
          setResultsPerPage={setResultsPerPage}
        />
      )}

      <StihlButtonSecondary
        style={{ marginTop: '1rem' }}
        disabled={getDevicesFromStore().length === 0}
        onClick={() => handleStepChange('step2')}
        data-testid="continueButton"
      >
        {t('updateManagement.continue')}
      </StihlButtonSecondary>
    </>
  );
};

export default MultiAssignmentDeviceTable;
