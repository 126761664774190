export function combineUpgradeSourceVersions(
  a: string[],
  b: string[],
): string[] | undefined {
  if (a.length > 0 && b.length > 0) {
    return [...a, ...b];
  }
  if (a.length > 0) {
    return a;
  }
  return b;
}
