import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { FormikErrors, FormikTouched } from 'formik';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import type { Country } from '../../../app-shell/service/countries';
import { countriesArray as countries } from '../../../app-shell/service/countries';
import type { Language } from '../../../app-shell/service/languages';
import { languagesArray as languages } from '../../../app-shell/service/languages';

const StyledSelectLabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  inline-size: 100%;
`;
const StyledCountryLanguageGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export type CustomerCountryLanguageSelectProps = {
  languageValue: string;
  countryValue: string;
  onChange: (field: string, value?: string) => void;
  errors?: FormikErrors<{ language: string; country: string }>;
  touched?: FormikTouched<{ language: string; country: string }>;
};

const CustomerCountryLanguageSelect = ({
  countryValue,
  languageValue,
  onChange,
}: CustomerCountryLanguageSelectProps): JSX.Element => {
  const { t } = useTranslation();

  function handleCountryChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    onChange('language', '');
    onChange('country', event.target.value);
    onChange(
      'language',
      languages.find((language: Language) =>
        language.isAllowed.includes(event.target.value),
      )?.languageCode,
    );
  }

  return (
    <StyledCountryLanguageGroup>
      <StyledSelectLabelGroup>
        <InputLabel htmlFor="country" variant="standard">
          {t('customerRegistration.country')}
        </InputLabel>
        <StihlSelect
          id="country"
          name="country"
          fullWidth
          value={countryValue}
          onChange={handleCountryChange}
          data-testid="countrySelector"
          inputProps={{ 'data-testid': 'country' }}
        >
          {countries
            .filter((country: Country) => country.isAllowed)
            .map((country: Country) => (
              <MenuItem key={country.countryCode} value={country.countryCode}>
                {country.countryName}
              </MenuItem>
            ))}
        </StihlSelect>
      </StyledSelectLabelGroup>
      <StyledSelectLabelGroup>
        <InputLabel htmlFor="language" variant="standard">
          {t('customerRegistration.language')}
        </InputLabel>
        <StihlSelect
          id="language"
          name="language"
          fullWidth
          value={languageValue}
          onChange={(event) => onChange('language', event.target.value)}
          data-testid="languageSelector"
          inputProps={{ 'data-testid': 'language' }}
        >
          {languages
            .filter((language: Language) =>
              language.isAllowed.includes(countryValue),
            )
            .map((availableLanguage: Language) => (
              <MenuItem
                key={availableLanguage.languageCode}
                value={availableLanguage.languageCode}
              >
                {availableLanguage.languageName}
              </MenuItem>
            ))}
        </StihlSelect>
      </StyledSelectLabelGroup>
    </StyledCountryLanguageGroup>
  );
};

export default CustomerCountryLanguageSelect;
