import styled from '@emotion/styled';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import LoadingButton from '@mui/lab/LoadingButton';
import type { JSX } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledLoadingButton = styled(LoadingButton)`
  background-color: ${stihlColor.white};
  border-color: ${stihlColor.black};

  &:hover {
    background-color: ${stihlColor.greyLight};
  }

  &:disabled {
    color: ${stihlColor.greyBase};
    background-color: ${stihlColor.white};
  }
`;

const StihlButtonOutlinedLoading = (props: LoadingButtonProps): JSX.Element => {
  return (
    <StyledLoadingButton variant="outlined" color="secondary" {...props} />
  );
};

export default StihlButtonOutlinedLoading;
