import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import type { WarrantyValue } from '../../../../model';
import CardContentItem from './card-content-item';

type WarrantyDataTextProps = {
  name: string;
  value: WarrantyValue;
};
const WarrantyDataText = ({
  name,
  value,
}: WarrantyDataTextProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <CardContentItem name={name}>
      <Typography data-testid="warrantyData">
        {new Date(value.warrantyStart).toLocaleDateString()} -{' '}
        {new Date(value.warrantyEnd).toLocaleDateString()} (
        {t('productionAndIdentificationData.extension', {
          count: value.warrantyExtensionNumber,
        })}
        )
      </Typography>
    </CardContentItem>
  );
};

export default WarrantyDataText;
