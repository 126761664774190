import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { JSX, PropsWithoutRef, RefAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { StihlIconSvgrProps } from '../../../base/stihl-material-ui/components/stihl-icon-svg/stihl-icon-svgr';
import StihlIconArrow from '../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import DataProtectionModal from './footer-modals/data-protection-modal';
import LegalNoticeModal from './footer-modals/legal-notice-modal';
import ReleaseNotesModal from './footer-modals/release-notes-modal';
import ThirdPartyLicenseModal from './footer-modals/third-party-license-modal';
import TermsFooterModal from './footer-modals/tsandcs-footer-modal';

export const FOOTER_HEIGHT_IN_REM = 3;

const StyledFooter = styled.div`
  position: absolute;
  inset-block: auto;
  inset-block-end: 0.4rem;
  inset-inline: 1rem;
  min-inline-size: 650px;
  block-size: ${FOOTER_HEIGHT_IN_REM}rem;
`;

const FooterLine = styled.hr`
  inline-size: 100%;
  block-size: 0;
  margin-block-start: 3px;
  border: 0 solid transparent;
  border-block-start: 1px solid ${stihlColor.greyBase};
  transform: translateY(1px);
`;

const StyledFooterButton = styled.button`
  padding: 0 1rem;
  color: ${stihlColor.black};
  font-size: 16px;
  font-family: 'Stihl Contraface Text', sans-serif;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    color: ${stihlColor.blue};
    text-decoration: underline;
  }
`;

const StyledCompanyInformation = styled.div`
  padding: 0 1rem;
`;

const FlexAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
`;

const StyledStihlIconArrowUp = styled(
  ({
    isHovered: _,
    ...rest
  }: PropsWithoutRef<StihlIconSvgrProps> &
    RefAttributes<SVGSVGElement> & { isHovered: boolean }) => (
    <StihlIconArrow {...rest} />
  ),
)`
  transform: rotate(180deg);

  path {
    fill: ${(props: MoreButtonProps) =>
      props.isHovered ? stihlColor.blue : stihlColor.black};
  }
`;

const StyledMenu = styled(Menu)`
  position: absolute;
  inset-block-start: -1.5rem;
`;

type MoreButtonProps = {
  isHovered: boolean;
};

enum ModalName {
  None = 'None',
  DataProtection = 'DataProtection',
  LegalNotice = 'LegalNotice',
  TermsAndConditions = 'TermsAndConditions',
  ThirdPartyLicense = 'ThirdPartyLicense',
  ReleaseNotes = 'ReleaseNotes',
}

const MoreDropDown = ({
  setOpenModal,
}: {
  setOpenModal: (modalName: ModalName) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isHoveredAction, setIsHoveredAction] = useState(false);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    return setAnchorElement(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorElement(null);
  }

  function onHoverMoreButton(): void {
    setIsHoveredAction(true);
  }
  function onStopHoverMoreButton(): void {
    setIsHoveredAction(false);
  }

  return (
    <>
      <Button
        data-testid="moreButton"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={onHoverMoreButton}
        onMouseOut={onStopHoverMoreButton}
        sx={{
          color: `${stihlColor.black}`,
          blockSize: '1.5rem',
          textTransform: 'none',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            color: `${stihlColor.blue}`,
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
      >
        <FlexAligner>
          <Typography>More</Typography>
          {anchorElement ? (
            <StihlIconArrow color={stihlColor.blue} />
          ) : (
            <StyledStihlIconArrowUp isHovered={isHoveredAction} />
          )}
        </FlexAligner>
      </Button>
      <StyledMenu
        variant="selectedMenu"
        disableAutoFocusItem
        elevation={0}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            border: `1px solid ${stihlColor.greyBase}`,
            paddingTop: '0',
            paddingBottom: '0',
          },
          '& .MuiMenuItem-root': {
            backgroundColor: `${stihlColor.background}`,
            color: `${stihlColor.black}`,
            padding: '1rem',
            '&:hover, &:focus': {
              backgroundColor: `${stihlColor.greyLight}`,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => setOpenModal(ModalName.ThirdPartyLicense)}
          data-testid="thirdPartyLicenseButton"
        >
          {t('footer.thirdPartyLicense')}
        </MenuItem>
        <MenuItem
          onClick={() => setOpenModal(ModalName.TermsAndConditions)}
          data-testid="tsAndCsLink"
        >
          {t('footer.termsAndConditions')}
        </MenuItem>

        <MenuItem
          onClick={() => setOpenModal(ModalName.ReleaseNotes)}
          data-testid="releaseNotesButton"
        >
          {t('footer.releaseNotes')}
        </MenuItem>
      </StyledMenu>
    </>
  );
};

const Footer = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(ModalName.None);
  const [footerWidth, setFooterWidth] = useState<number | undefined>(0);
  const { t } = useTranslation();
  const footerRef = useRef<HTMLDivElement>(null);

  const getFooterWidth = (): void => {
    const newWidth = footerRef.current?.clientWidth;
    setFooterWidth(newWidth);
  };

  useEffect(() => {
    getFooterWidth();
    window.addEventListener('resize', getFooterWidth);
  }, []);

  return (
    <StyledFooter ref={footerRef} data-testid="footer">
      <FooterLine />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <List>
            <StyledFooterButton
              data-testid="dataProtectionFooterButton"
              onClick={() => setOpenModal(ModalName.DataProtection)}
            >
              {t('footer.dataProtection')}
            </StyledFooterButton>
            <StyledFooterButton
              data-testid="legalNoticenFooterButton"
              onClick={() => setOpenModal(ModalName.LegalNotice)}
            >
              {t('footer.legalNotice.title')}
            </StyledFooterButton>
            {footerWidth && footerWidth > 1024 ? (
              <>
                <StyledFooterButton
                  data-testid="termsFooterButton"
                  onClick={() => setOpenModal(ModalName.TermsAndConditions)}
                >
                  {t('footer.termsAndConditions')}
                </StyledFooterButton>
                <StyledFooterButton
                  data-testid="thirdPartyFooterButton"
                  onClick={() => setOpenModal(ModalName.ThirdPartyLicense)}
                >
                  {t('footer.thirdPartyLicense')}
                </StyledFooterButton>
                <StyledFooterButton
                  data-testid="releaseNotesFooterButton"
                  onClick={() => setOpenModal(ModalName.ReleaseNotes)}
                >
                  {t('footer.releaseNotes')}
                </StyledFooterButton>
              </>
            ) : (
              <MoreDropDown setOpenModal={setOpenModal} />
            )}
          </List>
        </Grid>

        <Grid item>
          <StyledCompanyInformation>
            <Typography variant="overline">
              {t('footer.companyInformation')}
            </Typography>
          </StyledCompanyInformation>
        </Grid>
      </Grid>

      <DataProtectionModal
        open={openModal === ModalName.DataProtection}
        setOpen={() => setOpenModal(ModalName.None)}
      />
      <LegalNoticeModal
        open={openModal === ModalName.LegalNotice}
        setOpen={() => setOpenModal(ModalName.None)}
      />
      <TermsFooterModal
        open={openModal === ModalName.TermsAndConditions}
        setOpen={() => setOpenModal(ModalName.None)}
      />
      <ThirdPartyLicenseModal
        open={openModal === ModalName.ThirdPartyLicense}
        setOpen={() => setOpenModal(ModalName.None)}
      />
      <ReleaseNotesModal
        open={openModal === ModalName.ReleaseNotes}
        setOpen={() => setOpenModal(ModalName.None)}
      />
    </StyledFooter>
  );
};

export default Footer;
