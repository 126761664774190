import type { AxiosError } from 'axios';
import instance from '../../../app-authentication/service/authentication-interceptor';
import type { SessionStore } from '../../../app-authentication/service/authentication-session-store';
import type { TermsAndConditionsBody } from '../../model/terms-and-conditions.model';

export async function getTermsAndConditionsAcceptance(
  session: SessionStore,
): Promise<TermsAndConditionsBody> {
  return (
    instance
      //headers need to be set here as addTokensInterceptor() is not done yet
      .get<TermsAndConditionsBody>(
        `${process.env.REACT_APP_ISP_API_MAIN_BASE_URL}/main/get-terms/${session.userId}`,
        {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env
              .REACT_APP_IMOW_API_SUBSCRIPTION_KEY as string,
            Authorization: `Bearer ${session.accessToken}`,
            IdToken: `Bearer ${session.token}`,
          },
        },
      )
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw new Error(error.message);
      })
  );
}

export async function postTermsAndConditionsAcceptance(
  stihlId: string,
  agreementVersion: string,
): Promise<string> {
  const date = new Date();
  const body: TermsAndConditionsBody = {
    stihlId,
    agreementVersion,
    date,
  };
  return instance
    .post<string>(
      `${process.env.REACT_APP_ISP_API_MAIN_BASE_URL}/main/post-terms`,
      body,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}
