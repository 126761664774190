import styled from '@emotion/styled';
import type { ReactNode, JSX } from 'react';

const IconFlexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StihlIconTextFlexBox = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return <IconFlexbox>{children}</IconFlexbox>;
};

export default StihlIconTextFlexBox;
