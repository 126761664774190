import styled from '@emotion/styled';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import LoadingButton from '@mui/lab/LoadingButton';
import type { JSX } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledLoadingButton = styled(LoadingButton)`
  &:hover {
    color: ${stihlColor.white};
    background-color: ${stihlColor.greyDark};
  }

  &:disabled {
    color: ${stihlColor.white};
    background-color: ${stihlColor.greyMid};
  }
`;

const StihlButtonSecondaryLoading = (
  props: LoadingButtonProps,
): JSX.Element => {
  return (
    <StyledLoadingButton variant="contained" color="secondary" {...props} />
  );
};

export default StihlButtonSecondaryLoading;
