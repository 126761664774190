import { InteractionStatus } from '@azure/msal-browser';
import type { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { User } from 'oidc-client';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StihlBackground } from '../../../base/stihl-material-ui/components/stihl-background/stihl-background';
import StihlButtonPrimary from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import {
  login,
  loginRedirect,
} from '../../app-authentication/service/authentication-session-service';
import {
  LoginProvider,
  sessionUserFromLogin,
} from '../../app-authentication/service/authentication-session-store';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import { loginRequest } from '../../app-authentication/service/azure/authentication-config';

const LoginOptionsContainer = styled.div`
  inline-size: 50%;
  max-inline-size: 700px;
  block-size: 50%;
  max-block-size: 320px;
  margin: auto;
  padding: 60px;
  background-color: ${stihlColor.white};
`;

const StyledRuler = styled.hr`
  position: absolute;
  inline-size: 26px;
  block-size: 6px;
  background-color: ${stihlColor.black};
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding-block-start: 60px;

  > button {
    inline-size: 100%;
  }
`;

const LoginPage = ({
  setIsLoading,
}: {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [loginRequested, setLoginRequested] = useState<string | undefined>();
  const { instance, accounts, inProgress } = useMsal();
  const [_session, setSession] = useStore();

  useEffect(() => {
    if (loginRequested === LoginProvider.DealerWebSso) {
      login().catch((error) => console.error(error));
    }
    if (loginRequested === LoginProvider.AzureAd) {
      instance.loginRedirect(loginRequest).catch((error) => {
        console.error(error);
      });
    }
  }, [loginRequested, instance]);

  useEffect(() => {
    function retrieveToken(
      tokenCallback: ({
        authMethod,
      }: {
        tokenResponse: User | AuthenticationResult;
        authMethod: LoginProvider;
      }) => void,
    ): void {
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: loginRequest.scopes,
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            return tokenCallback({
              tokenResponse: accessTokenResponse,
              authMethod: LoginProvider.AzureAd,
            });
          })

          .catch(() => {
            console.log('Authentication failed. Please try again later.');
          });
      } else {
        loginRedirect()
          .then((user) => {
            return tokenCallback({
              tokenResponse: user,
              authMethod: LoginProvider.DealerWebSso,
            });
          })
          .catch(() => {
            console.log('Authentication failed. Try again later.');
          });
      }
    }

    if (inProgress === InteractionStatus.None || accounts.length === 0) {
      retrieveToken((response) => {
        setSession((currentSession) => ({
          ...currentSession,
          ...sessionUserFromLogin(response.tokenResponse),
          authType: response.authMethod,
        }));
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, inProgress]);

  function requestDealerLogin(): void {
    setLoginRequested(LoginProvider.DealerWebSso);
  }

  function requestAzureAdLogin(): void {
    setLoginRequested(LoginProvider.AzureAd);
  }

  return (
    <StihlBackground>
      <LoginOptionsContainer>
        <Typography variant="h1">
          {t('loginPage.welcomeToServicePortal')}
        </Typography>
        <StyledRuler />
        <ButtonContainer>
          <StihlButtonPrimary
            data-testid="azureAdLoginButton"
            onClick={requestAzureAdLogin}
          >
            {t('loginPage.loginAzure')}
          </StihlButtonPrimary>
          <StihlButtonPrimary
            onClick={requestDealerLogin}
            data-testid="dealerLoginButton"
          >
            {t('loginPage.loginDealer')}
          </StihlButtonPrimary>
        </ButtonContainer>
      </LoginOptionsContainer>
    </StihlBackground>
  );
};

export default LoginPage;
