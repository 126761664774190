import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonOutlined from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlModal from '../../../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconInfo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconPause from '../../../../../../base/stihl-material-ui/icons/stihl-icon-pause';
import StihlIconPlay from '../../../../../../base/stihl-material-ui/icons/stihl-icon-play';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../../app-alert/service/alert-provider';
import type { Card, Meta } from '../../../../model';
import { changeSetting } from '../../../../service/device-api-client/device-api-client';

export const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

export const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

export const FlexAligner = styled.div`
  display: flex;
  align-items: center;
`;

export type DirectHomeDriveModalProps = {
  meta: Meta;
  isActiveDirectHomeDrive: boolean;
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  actionMessage: string;
};

export const DirectHomeDriveModal = ({
  meta,
  isActiveDirectHomeDrive,
  isModalOpen,
  setModalOpen,
  actionMessage,
}: DirectHomeDriveModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();
  const isSettingValue = !isActiveDirectHomeDrive;
  // eslint-disable-next-line no-secrets/no-secrets
  const isSettingName = 'settingIsDrivingHomeDirectlyEnabled';

  function handleClose(): void {
    setModalOpen(false);
  }

  function handleChangeDirectHomeDrive(): void {
    setIsLoading(true);
    void changeSetting(
      meta.deviceId,
      meta.deviceModel,
      isSettingName,
      isSettingValue,
    )
      .then(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          severity: 'success',
          // eslint-disable-next-line no-secrets/no-secrets
          message: t('supportFunctions.directHomeDriveChangeSuccess'),
        });
        return setModalOpen(false);
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          severity: 'error',
          // eslint-disable-next-line no-secrets/no-secrets
          message: t('supportFunctions.directHomeDriveChangeError'),
        });
        setIsLoading(false);
      });
  }

  const modalTitle = isActiveDirectHomeDrive
    ? t('supportFunctions.deactivateHeading', {
        setting: t('supportFunctions.directHomeDrive'),
      })
    : t('supportFunctions.activateHeading', {
        setting: t('supportFunctions.directHomeDrive'),
      });

  return (
    <StihlModal
      open={isModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      data-testid="directHomeDriveModal"
      title={modalTitle}
      actionButtonText={actionMessage}
      handleActionClick={handleChangeDirectHomeDrive}
    >
      <FlexAligner>
        <StihlIconInfo />
        <Typography
          component="span"
          variant="h5"
          sx={{ marginLeft: '0.5rem' }}
          data-testid="directHomeDriveActivationInfo"
        >
          {isActiveDirectHomeDrive
            ? // eslint-disable-next-line no-secrets/no-secrets
              t('supportFunctions.disableDirectHomeDriveInfo')
            : // eslint-disable-next-line no-secrets/no-secrets
              t('supportFunctions.enableDirectHomeDriveInfo')}
        </Typography>
      </FlexAligner>
    </StihlModal>
  );
};

export type DirectHomeDriveCardProps = {
  meta: Meta;
  cardData: Card;
};

export const DirectHomeDriveCard = ({
  meta,
  cardData,
}: DirectHomeDriveCardProps): JSX.Element => {
  const { t } = useTranslation();
  const isActiveDirectHomeDrive = cardData.cardFields[0].value as boolean;
  const [isModalOpen, setModalOpen] = useState(false);

  const actionMessage = isActiveDirectHomeDrive
    ? t('supportFunctions.deactivate')
    : t('supportFunctions.activate');

  function handleModalOpen(): void {
    setModalOpen(true);
  }

  return (
    <MuiCard data-testid="directHomeDriveCard">
      <CardHeader>
        <Typography variant="h3">
          {t('supportFunctions.directHomeDrive')}{' '}
          {t('supportFunctions.notImplemented')}
        </Typography>
        <StihlButtonOutlined
          onClick={handleModalOpen}
          data-testid="openDirectHomeDriveModal"
        >
          {actionMessage}
        </StihlButtonOutlined>
      </CardHeader>
      <StyledCardContent>
        <IconAligner>
          {isActiveDirectHomeDrive ? (
            <StihlIconPlay />
          ) : (
            <StihlIconPause color="yellow" />
          )}
          <Typography>
            {`${t('supportFunctions.drivingHomeIs')} `}

            {isActiveDirectHomeDrive
              ? t('supportFunctions.enabledWithDot')
              : t('supportFunctions.disabledWithDot')}
          </Typography>
        </IconAligner>
        <Typography>{t('supportFunctions.directHomeDriveHint')}</Typography>
        <br />
        <FlexAligner>
          <StihlIconInfo />
          <Typography
            component="span"
            variant="h5"
            sx={{ marginLeft: '0.5rem' }}
            data-testid="directHomeDriveActivationInfo"
          >
            {t('supportFunctions.directHomeDriveActivationTime')}
          </Typography>
        </FlexAligner>
      </StyledCardContent>
      <DirectHomeDriveModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        meta={meta}
        isActiveDirectHomeDrive={isActiveDirectHomeDrive}
        actionMessage={actionMessage}
      />
    </MuiCard>
  );
};

export default DirectHomeDriveCard;
