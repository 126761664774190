import type { JSX, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import ReleaseNotesContent from './release-notes-content';

export type FooterModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ReleaseNotesModal = ({
  open,
  setOpen,
}: FooterModalProps): JSX.Element => {
  const { t } = useTranslation();

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <StihlModal
      open={open}
      onClose={handleClose}
      title={t('footer.releaseNotes')}
      data-testid="releaseNotesModal"
      noButtons
    >
      <ReleaseNotesContent />
    </StihlModal>
  );
};

export default ReleaseNotesModal;
