import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useCallback, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import { StihlIconInfo } from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { removeDeputy } from '../../service/customer-api-client/customer-api-client';

export type RemoveDeputyModalProps = {
  name: string;
  id: string;
  subscriptionId: string;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

export const RemoveDeputyModal = ({
  name,
  id: deputyId,
  subscriptionId,
  openModal,
  setOpenModal,
}: RemoveDeputyModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  // set isMounted to false when we unmount the component
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function handleClose(): void {
    setOpenModal(false);
  }

  const handleRemoveDeputy = useCallback(() => {
    setIsLoading(true);
    removeDeputy(deputyId, subscriptionId)
      .then(() => {
        if (!isMounted.current) {
          return;
        }
        setIsLoading(false);
        setOpenModal(false);
        return setAlert({
          isOpen: true,
          severity: 'success',
          message: t('customerDetails.successfulRemoveDeputy'),
        });
      })
      .catch(() => {
        setIsLoading(false);
        setOpenModal(false);
        setAlert({
          isOpen: true,
          severity: 'error',
          message: t('customerDetails.failedRemoveDeputy'),
        });
      });
  }, [deputyId, subscriptionId, setAlert, setOpenModal, t]);

  return (
    <StihlModal
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      data-testid="removeDeputyModal"
      title={`${t('customerDetails.removeDeputy')} ${name}?`}
      actionButtonText={t('remove')}
      handleActionClick={handleRemoveDeputy}
    >
      <>
        <Typography>
          <StihlIconInfo />
          <Typography
            variant="h5"
            component="span"
            sx={{ marginLeft: '0.5rem' }}
          >
            {t('customerDetails.removeDeputyAlert')}
          </Typography>
        </Typography>
        <br />
        <Typography>{t('customerDetails.removeDeputyHint')}</Typography>
      </>
    </StihlModal>
  );
};
