import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { Dispatch, SetStateAction, JSX } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonOutlined from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlButtonOutlinedLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined-loading';
import StihlButtonSecondary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlTextField from '../../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import CustomerCountryLanguageSelect from '../../customer-country-language-select/customer-country-language-select';
import { getHelperText } from '../utils/form-helper.utils';

export type CreateCustomerValues = {
  lastName: string;
  firstName: string;
  email: string;
  language: string;
  country: string;
};

// eslint-disable-next-line max-lines-per-function
const CustomerAccountRegistrationStepOne = ({
  isStepOneValidated,
  setIsStepOneValidated,
  handleStepChange,
  isLoading,
}: {
  isStepOneValidated: boolean;
  setIsStepOneValidated: Dispatch<SetStateAction<boolean>>;
  handleStepChange: (step: 'step' | 'step2') => void;
  isLoading: boolean;
}): JSX.Element => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<CreateCustomerValues>();
  const { t } = useTranslation();

  const isValidStepOne = !(
    errors.lastName ??
    errors.firstName ??
    errors.email ??
    errors.country ??
    errors.language
  );

  function validateFormStepOne(): void {
    if (isValidStepOne) {
      setIsStepOneValidated(true);
      void setFieldValue('isVerificationCodeEnabled', true);
      handleStepChange('step2');
    }
  }

  useEffect(() => {
    void setFieldValue('isVerificationCodeEnabled', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InputLabel htmlFor="lastName" variant="standard">
        {t('customerRegistration.lastName')}
      </InputLabel>
      <StihlTextField
        inputProps={{ 'data-testid': 'lastName' }}
        id="lastName"
        fullWidth
        error={
          Boolean(errors.lastName) && (touched.lastName ?? isStepOneValidated)
        }
        helperText={getHelperText(
          touched.lastName ?? isStepOneValidated,
          errors.lastName,
        )}
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <InputLabel htmlFor="firstName" variant="standard">
        {t('customerRegistration.firstName')}
      </InputLabel>
      <StihlTextField
        inputProps={{ 'data-testid': 'firstName' }}
        id="firstName"
        fullWidth
        error={
          Boolean(errors.firstName) && (touched.firstName ?? isStepOneValidated)
        }
        helperText={getHelperText(
          touched.firstName ?? isStepOneValidated,
          errors.firstName,
        )}
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <InputLabel htmlFor="email" variant="standard">
        {t('customerRegistration.email')}
      </InputLabel>
      <StihlTextField
        inputProps={{ 'data-testid': 'email' }}
        id="email"
        fullWidth
        error={Boolean(errors.email) && (touched.email ?? isStepOneValidated)}
        helperText={getHelperText(
          touched.email ?? isStepOneValidated,
          errors.email,
        )}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <CustomerCountryLanguageSelect
        countryValue={values.country}
        languageValue={values.language}
        onChange={(field, value) => void setFieldValue(field, value)}
      />

      <Typography paragraph style={{ marginTop: '1.5rem', maxWidth: '700px' }}>
        {t('customerRegistration.reminderCreateWithout')}
      </Typography>
      {isLoading ? (
        <StihlButtonOutlinedLoading
          type="submit"
          style={{
            marginRight: '1rem',
          }}
          loading
          data-testid="submitButtonWithoutDeviceLoading"
        >
          {t('customerRegistration.createWithoutDevice')}
        </StihlButtonOutlinedLoading>
      ) : (
        <StihlButtonOutlined
          type="submit"
          style={{
            marginRight: '1rem',
          }}
          data-testid="submitButtonWithoutDevice"
        >
          {t('customerRegistration.createWithoutDevice')}
        </StihlButtonOutlined>
      )}
      <StihlButtonSecondary
        type="button"
        onClick={validateFormStepOne}
        data-testid="nextStepButton"
      >
        {t('customerRegistration.connectDevice')}
      </StihlButtonSecondary>
    </>
  );
};

export default CustomerAccountRegistrationStepOne;
