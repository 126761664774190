import type {
  AccountInfo,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import type {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import { useEffect } from 'react';
import { getUser, logout, renewToken } from './authentication-session-service';
import type { SessionStore } from './authentication-session-store';
import { LoginProvider } from './authentication-session-store';
import { useStore } from './authentication-store-provider';
import { loginRequest } from './azure/authentication-config';

export function addTokensInterceptor(
  session: SessionStore,
): (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig {
  return (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers['Ocp-Apim-Subscription-Key'] = process.env
      .REACT_APP_IMOW_API_SUBSCRIPTION_KEY as string;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (session.accessToken && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${session.accessToken}`;
      config.headers.authmethod = session.authType;
    }
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (session.token && !config.headers.IdToken) {
      config.headers.IdToken = `Bearer ${session.token}`;
    }
    return config;
  };
}

const instance: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

async function renewWebSsoToken(): Promise<{
  accessToken: string | undefined;
  idToken: string | undefined;
}> {
  // refreshes the exisiting token
  await renewToken();

  const user = await getUser();
  return { accessToken: user?.access_token, idToken: user?.id_token };
}

async function renewAzureToken(
  adInstance?: IPublicClientApplication,
  account?: AccountInfo,
): Promise<{ accessToken: string | undefined; idToken: string | undefined }> {
  const accessTokenRequest = {
    scopes: loginRequest.scopes,
    account,
  };
  const result = await adInstance?.acquireTokenSilent(accessTokenRequest);

  return { accessToken: result?.accessToken, idToken: result?.idToken };
}

async function logoutUser(
  session: SessionStore,
  adInstance?: IPublicClientApplication,
): Promise<void> {
  console.warn('No refresh possible, logging user out');
  await (session.authType === LoginProvider.AzureAd
    ? adInstance?.logout()
    : logout());
}

// eslint-disable-next-line max-lines-per-function
export function handleAuthError(
  session: SessionStore,
  adInstance?: IPublicClientApplication,
  account?: AccountInfo,
): (error: AxiosError) => Promise<AxiosResponse> {
  return async (error: AxiosError) => {
    const originalConfig =
      error.config as InternalAxiosRequestConfig<unknown> & {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _retry?: boolean;
      };

    if (
      Boolean(error.response) && // Access Token was expired
      error.response?.status === 401 &&
      // eslint-disable-next-line no-extra-boolean-cast
      !Boolean(originalConfig._retry)
    ) {
      console.warn('Access token expired requesting new token');
      originalConfig._retry = true;

      try {
        const { accessToken, idToken } = await (session.authType ===
        LoginProvider.AzureAd
          ? renewAzureToken(adInstance, account)
          : renewWebSsoToken());

        if (accessToken) {
          originalConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (idToken) {
          originalConfig.headers.IdToken = `Bearer ${idToken}`;
        }
        return await instance(originalConfig);
      } catch (refreshError) {
        await logoutUser(session, adInstance);
        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.reject(refreshError);
      }
    } else if (
      Boolean(error.response) && // Access Token was expired
      error.response?.status === 401
    ) {
      await logoutUser(session, adInstance);
    }
    // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
    return Promise.reject(error);
  };
}

export const useAxiosInterceptors = (): void => {
  const [session] = useStore();
  const { instance: adInstance, accounts } = useMsal();
  useEffect(() => {
    const requestInterceptor = instance.interceptors.request.use(
      addTokensInterceptor(session),
      (error) => {
        throw error;
      },
    );
    const responseInteceptor = instance.interceptors.response.use(
      (response) => {
        return response;
      },
      handleAuthError(session, adInstance, accounts[0]),
    );
    return () => {
      instance.interceptors.request.eject(requestInterceptor);
      instance.interceptors.response.eject(responseInteceptor);
    };
  }, [session, adInstance, accounts]);
};

export default instance;
