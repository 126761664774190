import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useStore } from '../../../app-authentication/service/authentication-store-provider';
import type { DeviceModel } from '../../../device/model';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type DeviceModelFilterProps = {
  deviceModel: DeviceModel | undefined;
  setDeviceModel:
    | ((deviceModel: DeviceModel) => void)
    | ((deviceModel: DeviceModel | undefined) => void);
  disabled?: boolean;
  hasEmptyOption?: boolean;
};

export const DeviceModelFilter = ({
  deviceModel,
  setDeviceModel,
  disabled = false,
  hasEmptyOption = false,
}: DeviceModelFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const [store] = useStore();

  const deviceModels = store.deviceModels.map((model) => {
    return {
      value: model,
      label: t(`searchDeviceModel.${model}`),
    };
  });

  function handleDeviceModelChange(event: ChangeEvent<HTMLInputElement>): void {
    setDeviceModel(event.target.value as DeviceModel);
  }

  return (
    <StyledWrapper>
      <InputLabel
        variant="standard"
        htmlFor="deviceType"
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('filterLabel.deviceModel')}
      </InputLabel>
      <StihlSelect
        id="deviceModel"
        value={deviceModel ?? ''}
        onChange={handleDeviceModelChange}
        fullWidth
        disabled={disabled}
        data-testid="deviceModelFilter"
      >
        {hasEmptyOption && (
          <MenuItem value="" disabled>
            {t('deviceModelFilter.emptyPlaceholder')}
          </MenuItem>
        )}
        {deviceModels.map((stihlDeviceModel) => (
          <MenuItem key={stihlDeviceModel.value} value={stihlDeviceModel.value}>
            {stihlDeviceModel.label}
          </MenuItem>
        ))}
      </StihlSelect>
    </StyledWrapper>
  );
};

export default DeviceModelFilter;
