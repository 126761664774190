import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import StihlTooltip from '../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type {
  Card,
  CellularNetworkCard,
  CellularNetworkValue,
} from '../../../../model/device.model';
import { CardBase } from '../card-component';
import CardContentItem from '../card-texts/card-content-item';

const SignalStrengthContained = styled.span`
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span<{
  color: string;
}>`
  color: ${(props) => props.color};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export type MobileConnectionQualityCardProps = {
  cardData: Card;
};

export const MobileConnectionQualityCard = ({
  cardData,
}: MobileConnectionQualityCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateTimeString } = useDateFormatting();

  const cellularNetworkCardInformation = cardData.cardFields[0]
    .value as CellularNetworkCard;

  const cellularNetworkInformation = (
    cardData.cardFields[0].value as CellularNetworkCard
  ).value as CellularNetworkValue;

  let currentSignalStrengthQuality = '';
  let currentSignalStrengthQualityColor = 'black';

  const isValidProcentage =
    cellularNetworkInformation.currentSignalStrength <= 100 &&
    cellularNetworkInformation.currentSignalStrength >= 0;
  if (isValidProcentage) {
    if (cellularNetworkInformation.currentSignalStrength >= 70) {
      currentSignalStrengthQuality = t('connections.excellent');
      currentSignalStrengthQualityColor = stihlColor.greenDark;
    } else if (cellularNetworkInformation.currentSignalStrength >= 45) {
      currentSignalStrengthQuality = t('connections.good');
      currentSignalStrengthQualityColor = stihlColor.greenDark;
    } else if (cellularNetworkInformation.currentSignalStrength >= 22) {
      currentSignalStrengthQuality = t('connections.okay');
      currentSignalStrengthQualityColor = stihlColor.yellowDark;
    } else if (cellularNetworkInformation.currentSignalStrength >= 5) {
      currentSignalStrengthQuality = t('connections.poor');
      currentSignalStrengthQualityColor = stihlColor.redDark;
    } else {
      currentSignalStrengthQuality = t('connections.noSignal');
      currentSignalStrengthQualityColor = stihlColor.redDark;
    }
  }

  return (
    <CardBase title={cardData.title} data-testid="mobileConnectionCard">
      <CardContentItem name={t('connections.lastCellularType')}>
        {cellularNetworkInformation.connectionType}
      </CardContentItem>
      <CardContentItem name={t('connections.lastProvider')}>
        {cellularNetworkInformation.providerId}
      </CardContentItem>
      <CardContentItem name={t('connections.currentSignalStrength')}>
        <SignalStrengthContained>
          {`${cellularNetworkInformation.currentSignalStrength}${
            isValidProcentage ? '%' : ''
          }`}

          {isValidProcentage && (
            <>
              <StyledSpan color={currentSignalStrengthQualityColor}>
                {currentSignalStrengthQuality}
              </StyledSpan>
              <StihlTooltip
                title={
                  <>
                    <Typography>
                      {t('connections.signalStrengthTooltipExcellent')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipGood')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipOkay')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipPoor')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipNoSignal')}
                    </Typography>
                  </>
                }
                placement="bottom"
              >
                <StihlIconInfo data-testid="connectionQualityInfoIcon" />
              </StihlTooltip>
            </>
          )}
        </SignalStrengthContained>
      </CardContentItem>
      <CardContentItem name={t('connections.lastUpdated')}>
        {toLocaleDateTimeString(
          cellularNetworkCardInformation.lastUpdated * 1000,
        )}
      </CardContentItem>
    </CardBase>
  );
};
