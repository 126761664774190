import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StihlBackground } from '../../../base/stihl-material-ui/components/stihl-background/stihl-background';
import StihlButtonOutlined from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlButtonSecondaryLoading from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlCheckbox from '../../../base/stihl-material-ui/components/stihl-checkbox/stihl-checkbox';
import StihlIconGlobe from '../../../base/stihl-material-ui/icons/stihl-icon-globe';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { logout } from '../../app-authentication/service/authentication-session-service';
import { LoginProvider } from '../../app-authentication/service/authentication-session-store';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import {
  currentTermsVersion,
  generateFileName,
} from '../../legal-documents/service/documents-utils';
import { DocumentCountryFilter } from '../../legal-documents/ui/document-country-filter';
import { postTermsAndConditionsAcceptance } from '../service/terms-and-conditions-api-client/terms-and-conditions-api-client';
import { useTermsAndConditions } from '../service/terms-and-conditions-service/terms-and-conditions-service';

const TermsAndConditionsContainer = styled.div`
  max-inline-size: 700px;
  margin: auto;
  padding: 60px;
  background-color: ${stihlColor.white};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block-start: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line max-lines-per-function
const TermsAndConditionsModal = (): JSX.Element => {
  const { t } = useTranslation();

  const [store, setStore] = useStore();
  const [isChecked, setIsChecked] = useState(false);
  const [termsCountry, setTermsCountry] = useState<string>('Germany (DE)');
  const [isLoading, setIsLoading] = useState(false);

  const { instance } = useMsal();

  const hasAcceptedTermsAndConditions = useTermsAndConditions(store);

  useEffect(() => {
    if (
      hasAcceptedTermsAndConditions.data?.agreementVersion.includes(
        currentTermsVersion,
      )
    ) {
      setStore((current) => ({
        ...current,
        hasAcceptedTermsAndConditions: true,
      }));
    }
  }, [hasAcceptedTermsAndConditions, setStore]);

  const fileName = `termsofuse${currentTermsVersion}${termsCountry
    .split(' (')[1]
    .slice(0, 2)
    .toLocaleLowerCase()}${termsCountry.split(' (')[0].replace(' ', '')}`;

  const handleAcceptChange = useCallback(() => {
    setIsChecked((value) => !value);
  }, [setIsChecked]);

  const handleLogout = useCallback(() => {
    if (store.authType === LoginProvider.DealerWebSso) {
      void logout();
      return;
    }
    void instance.logout();
  }, [instance, store.authType]);

  function handleContinue(): void {
    try {
      setIsLoading(true);
      window.open(
        `./terms/${generateFileName('termsofuse', termsCountry)}.pdf`,
      );
      void postTermsAndConditionsAcceptance(
        store.userId,
        generateFileName('termsofuse', termsCountry),
      ).then(() =>
        setStore((current) => ({
          ...current,
          hasAcceptedTermsAndConditions: true,
        })),
      );
      setIsLoading(false);
    } catch {
      console.log('Could not post term acceptance');
      setIsLoading(false);
    }
  }

  return (
    <StihlBackground>
      <TermsAndConditionsContainer data-testid="termsAndConditions">
        {!hasAcceptedTermsAndConditions.isFetching && (
          <Typography variant="h3">{t('termsAndConditions.title')}</Typography>
        )}
        <br />
        {hasAcceptedTermsAndConditions.isFetching ? (
          <>
            <Typography variant="h3">
              {t('termsAndConditions.loadingTitle')}
            </Typography>
            <br />
            <LoadingWrapper>
              <CircularProgress />{' '}
            </LoadingWrapper>
          </>
        ) : (
          <>
            <Typography>{t('termsAndConditions.introText')}</Typography>
            <br />
            <FlexContainer>
              <StihlIconGlobe color="black" />
              <DocumentCountryFilter
                documentCountry={termsCountry}
                setDocumentCountry={setTermsCountry}
              />
            </FlexContainer>
            <Typography sx={{ margin: '1rem 0' }}>
              <StihlCheckbox
                onClick={handleAcceptChange}
                data-testid="acceptCheckBox"
              />

              {/* for interpolation with links the trans component is needed see https://react.i18next.com/latest/trans-component#alternative-usage-components-array */}
              <Trans
                i18nKey="termsAndConditions.acceptText"
                values={{ fileName }}
                components={[
                  <Link
                    key="0"
                    href={`./terms/${fileName}.pdf`}
                    target="_blank"
                    style={{
                      color: stihlColor.blue,
                      // stihl blue with transparency
                      textDecorationColor: 'rgba(36, 154, 190, 0.4)',
                    }}
                  />,
                ]}
              />
            </Typography>
            <Typography variant="overline">
              {t('termsAndConditions.notice')}
            </Typography>
            <ButtonContainer>
              <StihlButtonOutlined
                onClick={handleLogout}
                data-testid="logoutButton"
              >
                {t('termsAndConditions.logoutButton')}
              </StihlButtonOutlined>

              <StihlButtonSecondaryLoading
                loading={isLoading}
                disabled={!isChecked}
                onClick={handleContinue}
                data-testid="continueButton"
              >
                {t('termsAndConditions.continueButton')}
              </StihlButtonSecondaryLoading>
            </ButtonContainer>
          </>
        )}
      </TermsAndConditionsContainer>
    </StihlBackground>
  );
};

export default TermsAndConditionsModal;
