import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import type { JSX, ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormattingParamsDefault } from '../../../../../../base/date-formatting/date-formatting';
import { useLocalStorage } from '../../../../../../base/local-storage-hook/local-storage-hook';
import StihlButtonOutlinedLoading from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined-loading';
import StihlButtonRefresh from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-refresh';
import StihlButtonSecondary from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlModal from '../../../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlTextField from '../../../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlTooltip from '../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconDownload from '../../../../../../base/stihl-material-ui/icons/stihl-icon-download';
import StihlIconXFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../../app-alert/service/alert-provider';
import { DeviceModel } from '../../../../model';
import type { Card, LogResponse, Meta } from '../../../../model';
import {
  getDeviceLog,
  requestLogUpload,
} from '../../../../service/device-api-client/device-api-client';
// eslint-disable-next-line import/max-dependencies
import { CardHeader, StyledCardContent } from '../card-component';

const StyledTypographyIntro = styled(Typography)`
  margin-block-end: 1rem;
`;

const StyledTypographyHeading = styled(Typography)`
  margin: 2.5rem 0 1.5rem;
  font-weight: 700;
`;

const StyledTypographyLogs = styled(Typography)`
  display: inline-block;
  inline-size: 40%;
  block-size: 100%;
  overflow: hidden;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-inline-end: 1rem;
  vertical-align: middle;
`;

const StyledLi = styled.li`
  margin-block-end: 1rem;
`;

const StyledCheckbox = styled(Checkbox)`
  /* stylelint-disable */
  padding: 0.5rem 0.5rem 0.5rem 0;
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${stihlColor.stihlOrange};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  padding-bottom: 0.5rem;
`;

const StyledTypographySmall = styled(Typography)`
  font-size: 0.875rem;
`;

const InputWrapper = styled.div`
  padding: 0 0 1rem 2rem;
`;

const TimeStampTypography = styled(Typography)`
  display: block;
  margin-top: 1rem;
`;

const NoLogsPlaceHolder = ({
  isLoading,
}: {
  isLoading: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <Typography color={stihlColor.fontGrey} data-testid="loadingLogs">
        {t('deviceLog.loadingLogs')}
      </Typography>
    );
  }
  return (
    <Typography variant="h5" data-testid="noLogsFound">
      <StihlIconXFilled color="error" /> {t('deviceLog.noStoredLogsMessage')}
    </Typography>
  );
};

function handleDownload(downloadUrl: string): void {
  const linkElement = document.createElement('a');
  linkElement.href = downloadUrl;
  linkElement.target = '_blank';
  document.body.append(linkElement);
  linkElement.click();
  linkElement.remove();
}

function getFileTitle(fileUrl: string): string {
  return fileUrl.slice(
    Math.max(0, fileUrl.lastIndexOf('/') + 1),
    fileUrl.indexOf('?'),
  );
}

export type LogCardProps = {
  meta: Meta;
  cardData: Card;
};

enum DataRequest {
  Download = 'DOWNLOAD',
  Upload = 'UPLOAD',
}

// eslint-disable-next-line max-lines-per-function
export const LogsCard = ({ meta, cardData }: LogCardProps): JSX.Element => {
  const { t } = useTranslation();

  const [logs, setLogs] = useState<LogResponse[]>([]);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isPathEnabled, setIsPathEnabled] = useState(false);
  const [dataRequest, setDataRequest] = useState<{
    type: DataRequest;
    requestTimes: number;
  }>({ type: DataRequest.Download, requestTimes: 0 });
  const [_, setAlert] = useAlertStore();

  const defaultPath =
    meta.deviceModel === DeviceModel.connectedBox ? 'journalctl' : '/data/log/';
  const [path, setPath] = useState<string>(defaultPath);

  const [lastUpdated, setLastUpdated] = useState<number | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowLogsModal, setShowLogsModal] = useLocalStorage<boolean>(
    'showLogsModal',
    true,
  );

  const requestLogUploadPath = isPathEnabled ? path : undefined;
  useEffect(() => {
    let isMounted = true;
    function downloadLogs(): void {
      setIsDownloadLoading(true);
      getDeviceLog(meta.deviceId, meta.deviceModel)
        .then((response) => {
          if (isMounted) {
            setLastUpdated(Date.now());
            setIsDownloadLoading(false);

            return setLogs(response);
          }
          return true;
        })

        .catch(() => {
          if (isMounted) {
            setIsDownloadLoading(false);
            setAlert({
              isOpen: true,
              message: t('deviceLog.logsFailed'),
              severity: 'error',
            });
          }
        });
    }

    function requestUpload(): void {
      setIsUploadLoading(true);
      requestLogUpload(meta.deviceId, meta.deviceModel, requestLogUploadPath)
        .then(() => {
          if (isMounted) {
            setIsUploadLoading(false);
            setIsModalOpen(false);
            setAlert({
              isOpen: true,
              message: t('deviceLog.logUploadSuccess'),
              severity: 'success',
            });
          }
          return true;
        })

        .catch(() => {
          if (isMounted) {
            setIsUploadLoading(false);
            setAlert({
              isOpen: true,
              message: t('deviceLog.logUploadFailed'),
              severity: 'error',
            });
          }
        });
    }

    if (
      dataRequest.type === DataRequest.Download &&
      dataRequest.requestTimes > 0
    ) {
      downloadLogs();
    } else if (dataRequest.type === DataRequest.Upload) {
      requestUpload();
    }
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRequest]);

  function handleClose(): void {
    setIsModalOpen(false);
  }

  function handleStoredLogsClick(): void {
    setDataRequest((oldState) => ({
      type: DataRequest.Download,
      requestTimes: oldState.requestTimes + 1,
    }));
  }

  function handleDeviceLogsClick(): void {
    setDataRequest((oldState) => ({
      type: DataRequest.Upload,
      requestTimes: oldState.requestTimes + 1,
    }));
  }

  function handleTogglePathEnabled(): void {
    setIsPathEnabled((isEnabled) => !isEnabled);
  }

  function handlePathInput(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setPath(event.target.value);
  }

  function handleToggleShowModal(): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    setShowLogsModal((currentValue) => !currentValue);
  }

  return (
    <MuiCard>
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
        <StihlButtonRefresh
          title={t('deviceLog.updateLogs')}
          placement="left"
          onClick={handleStoredLogsClick}
          loading={isDownloadLoading}
          data-testid="fileDownloadButton"
        />
      </CardHeader>
      <StyledCardContent>
        <StyledTypographyIntro data-testid="fileDownload">
          {t('deviceLog.request')}
        </StyledTypographyIntro>

        <StyledTypographyHeading>
          {t('deviceLog.storedLogs')}
        </StyledTypographyHeading>
        {logs.length > 0 ? (
          <ul data-testid="logList">
            {logs.map((log): JSX.Element => {
              return (
                <StyledLi key={log.title}>
                  <StihlTooltip
                    title={getFileTitle(log.downloadUrl)}
                    placement="top-start"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: 'none',
                        },
                      },
                    }}
                  >
                    <StyledTypographyLogs>{t(log.title)}</StyledTypographyLogs>
                  </StihlTooltip>
                  <StihlButtonSecondary
                    onClick={() => handleDownload(log.downloadUrl)}
                    startIcon={
                      <StihlIconDownload color="primary.contrastText" />
                    }
                  >
                    {log.title === 'deviceLog.logFile'
                      ? t('deviceLog.downloadGeneral')
                      : t('deviceLog.downloadError')}
                  </StihlButtonSecondary>
                </StyledLi>
              );
            })}
          </ul>
        ) : (
          <NoLogsPlaceHolder isLoading={isDownloadLoading} />
        )}
        <br />

        <StyledTypographyIntro>{t('deviceLog.pathHint')}</StyledTypographyIntro>

        <StyledInputLabel htmlFor="isLogPathEnabled">
          <StyledCheckbox
            id="isLogPathEnabled"
            data-testid="isLogPathEnabled"
            onChange={handleTogglePathEnabled}
            checked={isPathEnabled}
          />
          {t('deviceLog.enterPath')}
        </StyledInputLabel>

        {isPathEnabled && (
          <InputWrapper>
            <StyledTypographySmall>
              {t('deviceLog.pathToLogs')}
            </StyledTypographySmall>
            <StihlTextField
              inputProps={{ 'data-testid': 'enterPathField' }}
              fullWidth
              value={path}
              onChange={handlePathInput}
            />
          </InputWrapper>
        )}

        <StihlButtonOutlinedLoading
          loading={isUploadLoading}
          onClick={() =>
            shouldShowLogsModal ? setIsModalOpen(true) : handleDeviceLogsClick()
          }
          data-testid="requestLogUploadButton"
        >
          {t('deviceLog.requestLogsFromDevice')}
        </StihlButtonOutlinedLoading>
        <TimeStampTypography variant="caption">
          {lastUpdated != null &&
            t('deviceLog.lastUpdated', {
              lastUpdated,
              formatParams: {
                lastUpdated: dateTimeFormattingParamsDefault,
              },
            })}
        </TimeStampTypography>
      </StyledCardContent>
      <StihlModal
        open={isModalOpen}
        title=""
        actionButtonText={t('accept')}
        onClose={handleClose}
        handleActionClick={handleDeviceLogsClick}
        isLoading={isUploadLoading}
      >
        <>
          <Typography>{t('deviceLog.waitForUpload')}</Typography>
          <InputLabel htmlFor="doNotShowAgain">
            <StyledCheckbox
              id="doNotShowAgain"
              onChange={handleToggleShowModal}
              data-testid="displayModalCheckbox"
            />
            {t('doNotShowAgain')}
          </InputLabel>
        </>
      </StihlModal>
    </MuiCard>
  );
};

export default LogsCard;
