import styled from '@emotion/styled';
import type { JSX } from 'react';
import CardContentItem from './card-content-item';

const StyledList = styled.ul`
  margin: 0;
`;
const ListText = ({
  name,
  value,
  icon,
}: {
  name: string;
  value: string[];
  icon?: JSX.Element;
}): JSX.Element => {
  return (
    <CardContentItem name={name} icon={icon}>
      <StyledList>
        {value.map((item) => {
          return <li key={item}>{item}</li>;
        })}
      </StyledList>
    </CardContentItem>
  );
};

export default ListText;
