import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { DefinedUseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAlert } from '../../../../base/stihl-material-ui/components/stihl-snackbar-alert/stihl-snackbar-alert';
import StihlIconInfo from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { TabsType } from '../../../app-shell/model/app.models';
import TabsBase, { TabPanel } from '../../../app-shell/ui/tabs-base';
import CustomerDetailsCard from '../../../customer-management/ui/customer-details-card/customer-details-card';
import HelpDtcCodes from '../../../help/ui/help-dtc-codes';
import DeviceSearchCard from '../../../search/ui/search-device-card';
import { CardType } from '../../model';
import type {
  Card,
  DiagnosisSessionData,
  Meta,
} from '../../model/device.model';
import type { DeviceSearchResult } from '../../model/search.model';
import CardComponent from '../card/ui/card-component';
import CertificateStatusCard from '../card/ui/certificate-status-card/certificate-status-card';
import ChangeEnvironmentCard from '../card/ui/change-environment-card/change-environment-card';
import CommandsCard from '../card/ui/commands-card/commands-card';
import { ConnectionHistoryCard } from '../card/ui/connection-history-card/connection-history-card';
import DemoModeCard from '../card/ui/demo-mode-card/demo-mode-card';
import DeviceStatusCard from '../card/ui/device-status-card/device-status-card';
import DiagnosisCard from '../card/ui/diagnosis-card/diagnosis-card';
import DiagnosisDetailsCard from '../card/ui/diagnosis-card/diagnosis-details/diagnosis-details-card';
import DirectHomeDriveCard from '../card/ui/direct-home-drive-card/direct-home-drive-card';
import { EventLogCard } from '../card/ui/event-log-card/event-log-card';
import FlagsCard from '../card/ui/flags-card/flags-card';
import FleetmanagementCard from '../card/ui/fleetmanagement-card/fleetmanagement-card';
import LogsCard from '../card/ui/logs-card/logs-card';
import { MobileConnectionQualityCard } from '../card/ui/mobile-connection-quality-card/mobile-connection-quality-card';
import { OperationDataCard } from '../card/ui/operation-data-card/operation-data-card';
import ProductionAndIdentificationDataCard from '../card/ui/production-data-card/production-data-card';
import SettingsCard from '../card/ui/settings-card/settings-card';
import { DeviceTabsContext } from './device-tabs.context';
// eslint-disable-next-line import/max-dependencies
import { Container, ContainerContent } from './tabs-shared';

const NamePositioner = styled.div`
  position: absolute;
  font-size: 1.25rem;
  background-color: ${stihlColor.greyMid};
  inset-inline-end: 1rem;
`;

const NameStyler = styled.span`
  color: ${stihlColor.fontGrey};
  font-weight: bold;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  inline-size: 100%;
  padding-block-start: 5%;
`;

export type TabsComponentProps = {
  deviceData: DefinedUseQueryResult<DeviceSearchResult>;
  diagnosisDataArray: DiagnosisSessionData[] | undefined;
  setDiagnosisDataArray: Dispatch<
    SetStateAction<DiagnosisSessionData[] | undefined>
  >;
};

export const AdaptedStyledAlert = styled(StyledAlert)`
  margin: 0 0 1rem;
`;

// eslint-disable-next-line max-lines-per-function
const DeviceTabsDeviceDetails = ({
  deviceData,
  diagnosisDataArray,
  setDiagnosisDataArray,
}: TabsComponentProps): ReactNode => {
  const [visibleTab, setVisibleTab] = useState<TabsType>(
    TabsType.SupportFunctions,
  );

  const { t } = useTranslation();

  const tabs = useMemo(
    () =>
      deviceData.data.tabs.filter(
        (tab) => tab.type === undefined || tab.type === visibleTab,
      ),
    [deviceData.data.tabs, visibleTab],
  );

  const activeTabInitial = tabs.length > 1 ? 1 : 0;

  const [activeTab, setActiveTab] = useState(activeTabInitial);

  const openTab = useCallback(
    (tabName: string) => {
      const tabIndex = tabs.findIndex((tab) => tab.title === tabName);

      if (tabIndex >= 0) {
        setActiveTab(tabIndex);
      }
    },
    [tabs],
  );

  const setVisibleTabAndOpenIt = useCallback(
    (tabType: TabsType) => {
      setVisibleTab(tabType);

      const newActiveTab = deviceData.data.tabs
        .filter((tab) => tab.type === undefined || tab.type === tabType)
        .findIndex((tab) => tab.type === tabType);

      if (newActiveTab >= 0) {
        setActiveTab(newActiveTab);
      }
    },
    [deviceData.data.tabs],
  );

  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void {
    setActiveTab(newValue);
  }

  const tabTitles = tabs.map((tab) => ({
    label: t(tab.title),
    hasBadge: false,
  }));

  const metadata = deviceData.data.meta;

  const isProduction = process.env.REACT_APP_ENV === 'prd';

  // eslint-disable-next-line complexity, max-lines-per-function
  function cardSwitch(card: Card, meta: Meta): ReactNode | null {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (card.type) {
      case CardType.Search: {
        return (
          <DeviceSearchCard
            key="device-search"
            isLoading={deviceData.isLoading}
          />
        );
      }
      case CardType.DeviceStatus: {
        return (
          <DeviceStatusCard
            key="device-status"
            meta={meta}
            cardData={card}
            lastUpdated={deviceData.dataUpdatedAt}
            refreshData={() => void deviceData.refetch()}
            isLoading={deviceData.isLoading}
          />
        );
      }
      case CardType.CustomerDetails: {
        return (
          <CustomerDetailsCard
            meta={meta}
            key="customer-details"
            cardData={card}
          />
        );
      }
      case CardType.Fleetmanagement: {
        return (
          <FleetmanagementCard
            meta={meta}
            key="fleetmanagement"
            cardData={card}
          />
        );
      }
      case CardType.Settings: {
        return <SettingsCard meta={meta} cardData={card} key={card.title} />;
      }
      case CardType.Logs: {
        return <LogsCard meta={meta} cardData={card} key={card.title} />;
      }
      case CardType.ChangeEnvironment: {
        return (
          <ChangeEnvironmentCard meta={meta} cardData={card} key={card.title} />
        );
      }
      case CardType.Flags: {
        return <FlagsCard meta={meta} cardData={card} key={card.title} />;
      }
      case CardType.Commands: {
        return <CommandsCard meta={meta} cardData={card} key={card.title} />;
      }
      case CardType.DemoMode: {
        return <DemoModeCard meta={meta} cardData={card} key={card.title} />;
      }
      case CardType.OperationData: {
        return <OperationDataCard cardData={card} key={card.title} />;
      }
      case CardType.DirectHomeDrive: {
        return isProduction ? (
          <div />
        ) : (
          <DirectHomeDriveCard meta={meta} cardData={card} key={card.title} />
        );
      }
      case CardType.ProductionAndIdentificationData: {
        return (
          <ProductionAndIdentificationDataCard
            meta={meta}
            cardData={card}
            key={card.title}
          />
        );
      }
      case CardType.DiagnosisData: {
        return (
          <DiagnosisCard
            meta={meta}
            cardData={card}
            key={card.title}
            setVisibleTab={setVisibleTabAndOpenIt}
            setDiagnosisData={setDiagnosisDataArray}
          />
        );
      }
      case CardType.DiagnosisDetailsData: {
        return (
          <DiagnosisDetailsCard
            meta={meta}
            setVisibleTab={setVisibleTabAndOpenIt}
            diagnosisDataArray={diagnosisDataArray}
            setDiagnosisData={setDiagnosisDataArray}
            key={card.title}
          />
        );
      }
      case CardType.CertificateStatus: {
        return (
          <CertificateStatusCard
            deviceId={meta.deviceId}
            deviceModel={meta.deviceModel}
            cardData={card}
            key={card.title}
          />
        );
      }
      case CardType.EventLog: {
        return (
          <EventLogCard
            deviceId={deviceData.data.meta.deviceId}
            deviceModel={deviceData.data.meta.deviceModel}
            cardData={card}
            key={card.title}
          />
        );
      }
      case CardType.DtcCodes: {
        return (
          <HelpDtcCodes
            codes={card.cardFields[0].value as string[]}
            isSearchBarHidden
            key={card.title}
          />
        );
      }
      case CardType.QualityOfMobileConnection: {
        return <MobileConnectionQualityCard cardData={card} key={card.title} />;
      }
      case CardType.ConnectionHistory: {
        return (
          <ConnectionHistoryCard
            deviceId={meta.deviceId}
            deviceModel={meta.deviceModel}
            cardData={card}
            key={card.title}
          />
        );
      }
      default: {
        return <CardComponent meta={meta} cardData={card} key={card.title} />;
      }
    }
  }
  function renderInColumns(cards: Card[], meta: Meta): ReactNode {
    const left = cards.slice(0, Math.round(cards.length / 2));
    const right = cards.slice(Math.round(cards.length / 2));
    return (
      <Container
        key={left[0].title}
        isFullWidth={
          cards.length <= 1 ||
          cards[0].type === CardType.Search ||
          cards[0].type === CardType.QualityOfMobileConnection ||
          cards[0].type === CardType.ConnectionHistory
        }
      >
        <ContainerContent>
          {left.map((group) => cardSwitch(group, meta))}
        </ContainerContent>
        <ContainerContent>
          {right.map((group) => cardSwitch(group, meta))}
        </ContainerContent>
      </Container>
    );
  }

  return (
    <DeviceTabsContext.Provider value={{ openTab }}>
      <TabsBase value={activeTab} handleChange={handleChange} tabs={tabTitles}>
        <NamePositioner>
          {metadata.customerName && (
            <NameStyler>{metadata.customerName}&lsquo;s</NameStyler>
          )}
          <b> {metadata.deviceModel}</b>
        </NamePositioner>
      </TabsBase>

      {tabs.map((tab, index) => (
        <TabPanel key={tab.title} value={activeTab} index={index}>
          {(tab.title === 'tabs.logs' ||
            tab.title === 'tabs.supportFunctions') && (
            <AdaptedStyledAlert
              severity="info"
              icon={<StihlIconInfo />}
              data-testid="offlineDeviceHint"
            >
              {t('tabs.offlineDeviceHint')}
            </AdaptedStyledAlert>
          )}
          {tab.cards.length > 0 ? (
            renderInColumns(tab.cards, metadata)
          ) : (
            <MessageWrapper data-testid="noResultMessage">
              <Typography>{t('searchErrors.noResult')}</Typography>
            </MessageWrapper>
          )}
        </TabPanel>
      ))}
    </DeviceTabsContext.Provider>
  );
};

export default DeviceTabsDeviceDetails;
