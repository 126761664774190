export type CustomerAccount = {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  language?: string;
  serialNumber?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  newsletter?: boolean;
  callbackUri?: string;
  clientId?: string;
  verificationCode?: string;
};

export type CustomerManagementCustomer = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  contactBusinessTypeCategory: string;
  type: string;
  isAccountValid: boolean | undefined;
  equipments: CustomerManagementEquipment[];
};

export type CustomerManagementCustomerDetails = {
  id: string;
  firstName: string;
  lastName: string;
  salutation: string;
  language: string;
  country: string;
};

export type CustomerManagementEquipment = {
  id: string;
  subscriptionId?: string;
  serialNumber: string;
  modelName: string;
  deviceModel: string;
  role: EquipmentRole;
};

export const EquipmentRole = {
  Deputy: 'Deputy',
  Owner: 'Owner',
};
export type EquipmentRole = (typeof EquipmentRole)[keyof typeof EquipmentRole];

export type CustomerManagementColumns = {
  firstName: string;
  lastName: string;
  email: string;
  contactBusinessTypeCategory: string;
  isAccountValid: boolean | undefined;
};

export type CustomerDeviceColumns = {
  role: string;
  serialNumber: string;
  modelName: string;
  deviceModel: string;
};

export type CustomerManagementHeadCell = {
  id: keyof CustomerManagementColumns;
  label: string;
  isSortable: boolean;
};

export type CustomerDeviceHeadCell = {
  id: keyof CustomerDeviceColumns;
  label: string;
  isSortable: boolean;
};

export type CustomerListEntry = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  contactBusinessTypeCategory: string;
};

export type CustomerSearchObject = {
  searchTerm: string;
  isAborted?: boolean;
};
