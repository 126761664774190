import type { User, UserManagerSettings } from 'oidc-client';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { authenticationClientConfig } from './authentication-config';

let userManager: UserManager;

export const initAuthManager: (
  settings: UserManagerSettings,
  mockUserManager?: UserManager,
) => UserManager = (
  settings: UserManagerSettings,
  mockUserManager?: UserManager,
) => {
  // for testing only
  if (mockUserManager) {
    userManager = mockUserManager;
    return userManager;
  }
  userManager = new UserManager({
    ...settings,
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  });
  return userManager;
};

export const login = async (): Promise<void> => userManager.signinRedirect();

export const renewToken = async (): Promise<User> => userManager.signinSilent();

export const loginRedirect = async (): Promise<User> =>
  userManager.signinRedirectCallback();

export const silentLogin = async (): Promise<void> => {
  void (await userManager.signinSilentCallback());
};
export const getUser = async (): Promise<User | null> => userManager.getUser();

export const logout = async (): Promise<void> => {
  await userManager.removeUser();
  const signoutArgs = {
    extraQueryParams: {
      goto: authenticationClientConfig.post_logout_redirect_uri,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    useReplaceToNavigate: true,
  };
  await userManager.signoutRedirect(signoutArgs);
};

export default initAuthManager;
