import type { ItemType } from '../../../../device/model';
import {
  DeviceModel,
  DeviceConnectionState,
  StihlDeviceTag,
  DeviceType,
} from '../../../../device/model';

export const deviceTags: ItemType<StihlDeviceTag>[] = [
  {
    value: StihlDeviceTag.SimulatedDevice,
    label: 'simulatedDevice',
  },
  {
    value: StihlDeviceTag.StihlInternalTestDevice,
    label: 'stihlInternalTestDevice',
  },
  {
    value: StihlDeviceTag.StihlDealerTestDevice,
    label: 'stihlDealerTestDevice',
  },
  {
    value: StihlDeviceTag.BetaCustomerDevice,
    label: 'betaCustomerDevice',
  },
  {
    value: StihlDeviceTag.FriendlyCustomerDevice,
    label: 'friendlyCustomerDevice',
  },
  {
    value: StihlDeviceTag.LeasingCustomerDevice,
    label: 'leasingCustomerDevice',
  },
  {
    value: StihlDeviceTag.VipCustomerDevice,
    label: 'vipCustomerDevice',
  },
  {
    value: StihlDeviceTag.StihlKpDevice,
    label: 'stihlKPDevice',
  },
  {
    value: StihlDeviceTag.StihlVpt1Device,
    label: 'stihlVPT1Device',
  },
  {
    value: StihlDeviceTag.StihlVpt2Device,
    label: 'stihlVPT2Device',
  },
  {
    value: StihlDeviceTag.StihlVpt3Device,
    label: 'stihlVPT3Device',
  },
  {
    value: StihlDeviceTag.StihlV1SeriesDevice,
    label: 'stihlV1SeriesDevice',
  },
  {
    value: StihlDeviceTag.StihlV2SeriesDevice,
    label: 'stihlV2SeriesDevice',
  },
  {
    value: StihlDeviceTag.StihlV3SeriesDevice,
    label: 'stihlV3SeriesDevice',
  },
  {
    value: StihlDeviceTag.StihlEseriesDevice,
    label: 'stihlESeriesDevice',
  },
  {
    value: StihlDeviceTag.StihlMarketingDevice,
    label: 'stihlMarketingDevice',
  },
  {
    value: StihlDeviceTag.StihlFieldTestDevice,
    label: 'stihlFieldTestDevice',
  },
];

export const deviceConnectionStates: ItemType<DeviceConnectionState>[] = [
  {
    value: DeviceConnectionState.Connected,
    label: 'deviceConnectionState.connected',
  },
  {
    value: DeviceConnectionState.Disconnected,
    label: 'deviceConnectionState.disconnected',
  },
  {
    value: DeviceConnectionState.NeverOnlineBefore,
    label: 'deviceConnectionState.neverOnlineBefore',
  },
];

const ia01DeviceTypes: ItemType<DeviceType>[] = [
  {
    value: DeviceType.Imow5,
    label: 'searchDeviceType.imow5',
  },
  {
    value: DeviceType.Imow6,
    label: 'searchDeviceType.imow6',
  },
  {
    value: DeviceType.Imow7,
    label: 'searchDeviceType.imow7',
  },
  {
    value: DeviceType.Imow5Evo,
    label: 'searchDeviceType.imow5Evo',
  },
  {
    value: DeviceType.Imow5Evo4Inch,
    label: 'searchDeviceType.imow5Evo4',
  },
  {
    value: DeviceType.Imow6Evo,
    label: 'searchDeviceType.imow6Evo',
  },
  {
    value: DeviceType.Imow6Evo4Inch,
    label: 'searchDeviceType.imow6Evo4',
  },
  {
    value: DeviceType.Imow7Evo,
    label: 'searchDeviceType.imow7Evo',
  },
  {
    value: DeviceType.Imow7Evo4Inch,
    label: 'searchDeviceType.imow7Evo4',
  },
  {
    value: DeviceType.Imow7Pro,
    label: 'searchDeviceType.imow7Pro',
  },
  {
    value: DeviceType.Imow7Pro4Inch,
    label: 'searchDeviceType.imow7Pro4',
  },
];

const ia02DeviceTypes: ItemType<DeviceType>[] = [
  {
    value: DeviceType.Imow3,
    label: 'searchDeviceType.imow3',
  },
  {
    value: DeviceType.Imow4,
    label: 'searchDeviceType.imow4',
  },
  {
    value: DeviceType.Imow3Evo,
    label: 'searchDeviceType.imow3Evo',
  },
  {
    value: DeviceType.Imow4Evo,
    label: 'searchDeviceType.imow4Evo',
  },
];

const ce02DeviceTypes: ItemType<DeviceType>[] = [
  {
    value: DeviceType.ConnectedBoxEuBasic,
    label: 'connected-Box-EU-basic',
  },
  {
    value: DeviceType.ConnectedBoxEuMobile,
    label: 'connected-Box-EU-mobile',
  },
  {
    value: DeviceType.ConnectedBoxUsBasic,
    label: 'connected-Box-US-basic',
  },
  {
    value: DeviceType.ConnectedBoxUsMobile,
    label: 'connected-Box-US-mobile',
  },
];

const legacy6301DeviceTypes: ItemType<DeviceType>[] = [
  {
    value: DeviceType.Rmi422,
    label: 'searchDeviceType.rmi422',
  },
  {
    value: DeviceType.Rmi422P,
    label: 'searchDeviceType.rmi422P',
  },
  {
    value: DeviceType.Rmi422Pc,
    label: 'searchDeviceType.rmi433Pc',
  },
];

const legacy6303DeviceTypes: ItemType<DeviceType>[] = [
  {
    value: DeviceType.Rmi522C,
    label: 'searchDeviceType.rmi522C',
  },
];

const legacy6309DeviceTypes: ItemType<DeviceType>[] = [
  {
    value: DeviceType.Rmi632,
    label: 'searchDeviceType.rmi632',
  },
  {
    value: DeviceType.Rmi632Pc,
    label: 'searchDeviceType.rmi632Pc',
  },
];

export const typeModelMatcher: Record<DeviceModel, ItemType<DeviceType>[]> = {
  [DeviceModel.iMowIA01]: ia01DeviceTypes,
  [DeviceModel.iMowIA02]: ia02DeviceTypes,
  [DeviceModel.connectedBox]: ce02DeviceTypes,
  [DeviceModel.Legacy6301]: legacy6301DeviceTypes,
  [DeviceModel.Legacy6303]: legacy6303DeviceTypes,
  [DeviceModel.Legacy6309]: legacy6309DeviceTypes,
};

export const deviceTypes: ItemType<DeviceType>[] = [
  ...ia01DeviceTypes,
  ...ia02DeviceTypes,
  ...ce02DeviceTypes,
  ...legacy6301DeviceTypes,
  ...legacy6303DeviceTypes,
  ...legacy6309DeviceTypes,
];
