import type { AuthenticationResult as AzureAdUser } from '@azure/msal-browser';
import { User as WebSssoUser } from 'oidc-client';
import type { NavigationItem } from '../../app-shell/model/app.models';
import type {
  DeviceModel,
  StihlDeviceTag,
  AccessLicenses,
} from '../../device/model';

export enum LoginProvider {
  DealerWebSso = 'DEALER_WEBSSO',
  AzureAd = 'AZURE_AD',
}

export type SessionStore = {
  authType: '' | LoginProvider;
  token: string;
  tokenType: string;
  accessToken: string;
  username: string;
  dealerId: string | undefined;
  navigationItems: NavigationItem[];
  deviceModels: DeviceModel[];
  deviceFlags: StihlDeviceTag[];
  license: AccessLicenses | undefined;
  userId: string;
  hasAcceptedTermsAndConditions: boolean;
};

const createSessionStore = (): SessionStore => {
  return {
    authType: '',
    token: '',
    accessToken: '',
    tokenType: '',
    username: '',
    userId: '',
    dealerId: undefined,
    navigationItems: [],
    deviceModels: [],
    deviceFlags: [],
    license: undefined,
    hasAcceptedTermsAndConditions: false,
  };
};

export const sessionUserFromLogin = (
  user: WebSssoUser | AzureAdUser,
): Partial<SessionStore> => {
  if (user instanceof WebSssoUser) {
    return {
      token: user.id_token,
      accessToken: user.access_token,
      tokenType: user.token_type,
      username:
        user.profile.given_name && user.profile.family_name
          ? `${user.profile.given_name} ${user.profile.family_name}`
          : '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dealerId: user.profile.dealer_org_id ?? undefined,
      userId: user.profile.sub,
    };
  }
  return {
    token: user.idToken,
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    username: user.account?.name ?? '',
    userId: user.uniqueId,
  };
};

export default createSessionStore;
