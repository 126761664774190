import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { dateTimeFormattingParamsDefault } from '../../../../../../../base/date-formatting/date-formatting';
import StihlButtonPrimaryLoading from '../../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import StihlTooltip from '../../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconArrowDown from '../../../../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconRefresh from '../../../../../../../base/stihl-material-ui/icons/stihl-icon-refresh';
import { stihlColor } from '../../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../../../app-alert/service/alert-provider';
import { TabsType } from '../../../../../../app-shell/model/app.models';
import type { DiagnosisSessionData, Meta } from '../../../../../model';
import { DiagnosisTable } from './diagnosis-details-table';
// eslint-disable-next-line import/max-dependencies
import { getDiagnosisDetails } from './service/diagnosis-details-api-client';

const stihlAccordionStyles = {
  '&.MuiPaper-root.MuiAccordion-root': {
    boxShadow: 0,
    borderBottom: `1px solid ${stihlColor.greyMid}`,
  },
  '&.MuiPaper-root.MuiAccordion-root:hover': {
    boxShadow: 0,
    borderBottom: `1px solid ${stihlColor.black}`,
  },
  '&.MuiAccordion-root:before': {
    backgroundColor: 'transparent',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    borderBottom: `1px solid ${stihlColor.greyMid}`,
  },
};

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

export const UnderlineOnHoverTypography = styled(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &:hover {
    rect {
      fill: ${stihlColor.stihlOrangeDark};
    }
  }
`;

const StyledLoadingButton = styled(StihlButtonPrimaryLoading)`
  min-inline-size: 40px;
  block-size: 40px;
  padding: 0;
  border-radius: 50%;
`;

// eslint-disable-next-line max-lines-per-function
export const DiagnosisDetailsCard = ({
  meta,
  setVisibleTab,
  diagnosisDataArray,
  setDiagnosisData,
}: {
  meta: Meta;
  setVisibleTab: (tabType: TabsType) => void;
  diagnosisDataArray: DiagnosisSessionData[] | undefined;
  setDiagnosisData: Dispatch<
    SetStateAction<DiagnosisSessionData[] | undefined>
  >;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();

  function onBreadcrumbClick(): void {
    setVisibleTab(TabsType.SupportFunctions);
  }

  function onReloadButtonClick(): void {
    setIsLoading(true);
    getDiagnosisDetails(meta.deviceSerialNumber)
      .then((response) => {
        setDiagnosisData(response);
        return setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          message: t('diagnosisData.alertMessage'),
          severity: 'error',
        });
      });
  }

  function returnLoadingOrRefetchButton(): JSX.Element {
    return isLoading ? (
      <StyledLoadingButton loading data-testid="reloadDiagnosisDataLoading" />
    ) : (
      <StihlTooltip title={t('diagnosisData.refreshTooltip')} placement="left">
        <StyledIconButton
          data-testid="reloadDiagnosisData"
          onClick={onReloadButtonClick}
        >
          <StihlIconRefresh />
        </StyledIconButton>
      </StihlTooltip>
    );
  }

  return (
    <Card data-testid="diagnosisDetailsCard">
      <CardHeader>
        <Breadcrumbs>
          <UnderlineOnHoverTypography
            role="link"
            tabIndex={0}
            onClick={onBreadcrumbClick}
            onKeyUp={(event) => {
              // since this is not a real link (<a> tag),
              // we need to implement following the link on Enter
              // press ourselves
              if (event.key === 'Enter') {
                onBreadcrumbClick();
              }
            }}
          >
            {t('tabs.supportFunctions')}
          </UnderlineOnHoverTypography>
          <Typography color="text.primary">
            {t('supportFunctions.diagnosisData')}
          </Typography>
        </Breadcrumbs>
        {returnLoadingOrRefetchButton()}
      </CardHeader>
      <StyledCardContent>
        <Typography variant="h3">
          {t('supportFunctions.diagnosisData')}
        </Typography>
        <br />
        {diagnosisDataArray && diagnosisDataArray.length > 0 ? (
          diagnosisDataArray.map((diagnosisData) => {
            return (
              <MuiAccordion
                key={diagnosisData.id}
                data-testid="diagnosisDetailsAccordion"
                disableGutters
                sx={stihlAccordionStyles}
              >
                <MuiAccordionSummary
                  data-testid="accordionSummary"
                  expandIcon={<StihlIconArrowDown color="black" />}
                >
                  <Typography>
                    <Trans
                      i18nKey="diagnosisData.diagnosisDetails"
                      values={{
                        timestamp: new Date(diagnosisData.timestamp),
                        symptoms:
                          diagnosisData.symptoms || t('diagnosisData.none'),
                      }}
                      components={[<b key="timestamp" />, <b key="symptoms" />]}
                      tOptions={{
                        formatParams: {
                          timestamp: dateTimeFormattingParamsDefault,
                        },
                      }}
                    />
                  </Typography>
                </MuiAccordionSummary>
                {diagnosisData.ecus && (
                  <MuiAccordionDetails data-testid="accordionDetails">
                    <DiagnosisTable
                      key={diagnosisData.id}
                      ecuDataArray={diagnosisData.ecus}
                    />
                  </MuiAccordionDetails>
                )}
              </MuiAccordion>
            );
          })
        ) : (
          <Typography>{t('diagnosisData.noData')}</Typography>
        )}
      </StyledCardContent>
    </Card>
  );
};

export default DiagnosisDetailsCard;
