import styled from '@emotion/styled';

export const Container = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isFullWidth ? 'column' : 'row')};
  gap: 1rem;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
export const ContainerContent = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  gap: 1rem;
  margin-block-end: 1rem;
`;
