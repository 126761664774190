import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import type { JSX, HTMLAttributes } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../theme/stihl-style-guide';
import { StyledSelectIcon } from '../stihl-select/stihl-select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      inlineSize: 200,
      backgroundColor: `${stihlColor.white}`,
      '& li.Mui-selected': {
        backgroundColor: `${stihlColor.greyMid}`,
      },
      '& li.Mui-selected:hover': {
        backgroundColor: `${stihlColor.greyLight}`,
      },
    },
  },
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

const getRenderValue = (selected: string[]): string => selected.join(', ');

// has to be function definition due to generic nature
// eslint-disable-next-line react/function-component-definition
function StihlMultiSelect<TValue extends string>({
  label,
  items,
  selectedCallback,
  preSelected = [],
  ...htmlProps
}: {
  label?: string;
  items: { label: string; value: TValue }[];
  selectedCallback: (selected: TValue[]) => void;
  preSelected?: TValue[];
} & HTMLAttributes<HTMLDivElement>): JSX.Element {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<TValue[]>(preSelected);

  const handleChange = (event: SelectChangeEvent<TValue[]>): void => {
    setSelectedItems(event.target.value as TValue[]);
    selectedCallback(event.target.value as TValue[]);
  };

  return (
    <StyledWrapper {...htmlProps}>
      {label && (
        <InputLabel
          variant="standard"
          htmlFor="deviceType"
          sx={{ color: stihlColor.black }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id="stihlMultiSelect"
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput sx={{ color: 'black' }} />}
        renderValue={getRenderValue}
        MenuProps={MenuProps}
        IconComponent={StyledSelectIcon}
        sx={{
          blockSize: '3rem',
          marginTop: '0.25rem',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${stihlColor.black}`,
          },
        }}
        data-testid="StihlMultiSelect"
      >
        {items.map((type) => (
          <MenuItem key={type.label} value={type.value}>
            <Checkbox checked={selectedItems.includes(type.value)} />
            <ListItemText primary={t(type.label)} />
          </MenuItem>
        ))}
      </Select>
    </StyledWrapper>
  );
}

export default StihlMultiSelect;
