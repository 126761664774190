import styled from '@emotion/styled';

export const StihlBackground = styled.div`
  position: absolute;
  z-index: 99;
  display: flex;
  /* stylelint-disable property-no-unknown */
  inset-start: 0;
  inset-inline-start: 0;
  inline-size: 100vw;
  block-size: 100vh;
  background-image: url('welcome-page-background.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
