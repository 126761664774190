import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { MouseEventHandler, JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlIconArrowDown from '../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconSettings from '../../../../base/stihl-material-ui/icons/stihl-icon-settings';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { resendVerificationEmail } from '../../service/customer-api-client/customer-api-client';
import { RemoveDeputyModal } from './remove-deputy-modal';

const StyledStihlIconArrow = styled(StihlIconArrowDown)`
  block-size: 12px;
  padding: 0 0 4px 2px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const CustomerDetailsActionsMenu = ({
  handlePasswordReset,
  email,
  isDeputy,
  customerId,
  subscriptionId,
  name,
  isVerified,
  isUserDealer,
}: {
  handlePasswordReset: () => void;
  email: string;
  isDeputy: boolean;
  customerId: string;
  subscriptionId: string;
  name: string;
  isVerified: boolean;
  isUserDealer: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const history = useHistory();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (
    event,
  ): void => {
    setAnchorElement(event.currentTarget);
  };

  function handleSelectMenu(): void {
    setAnchorElement(null);
  }

  function handlePasswortResetClick(): void {
    handlePasswordReset();
    setAnchorElement(null);
  }

  function handleManageCustomerClick(): void {
    history.push({
      pathname: '/customer-management',
      state: { filterEmail: email },
    });
    setAnchorElement(null);
  }

  function handleResendVerificationEmailClick(): void {
    resendVerificationEmail(customerId)
      .then(() => {
        return setAlert({
          isOpen: true,
          severity: 'success',
          message: t('customerDetails.successfulResendVerificationMail'),
        });
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          severity: 'error',
          message: t('customerDetails.unsuccessfulResendVerificationMail'),
        });
      });
  }

  function handleModalOpen(): void {
    setOpenModal(true);
  }

  return (
    <div>
      <StyledIconButton data-testid="settingsButton" onClick={handleOpenMenu}>
        <StihlIconSettings color="black" /> <StyledStihlIconArrow />
      </StyledIconButton>
      <Menu anchorEl={anchorElement} open={open} onClick={handleSelectMenu}>
        {isVerified ? (
          <MenuItem
            data-testid="resetPassword"
            onClick={handlePasswortResetClick}
          >
            {t('passwordReset')}
          </MenuItem>
        ) : (
          <MenuItem
            data-testid="resendVerificationMailButton"
            onClick={handleResendVerificationEmailClick}
          >
            {t('customerDetails.resendEmail')}
          </MenuItem>
        )}
        {isUserDealer && (
          <MenuItem
            data-testid="manageCustomer"
            onClick={handleManageCustomerClick}
          >
            {t('manageCustomer')}
          </MenuItem>
        )}
        {isDeputy && (
          <MenuItem data-testid="removeDeputyButton" onClick={handleModalOpen}>
            {t('customerDetails.removeDeputy')}
          </MenuItem>
        )}
      </Menu>
      <RemoveDeputyModal
        name={name}
        id={customerId}
        subscriptionId={subscriptionId}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default CustomerDetailsActionsMenu;
