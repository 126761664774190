import styled from '@emotion/styled';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import type {
  JSX,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTextField from '../../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconArrowDown from '../../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledStihlIconArrowUp = styled(StihlIconArrowDown)`
  transform: rotate(180deg);
`;

const StyledHelperTextContainer = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledHelperText = styled.span`
  margin-block-start: 0.25rem;
  color: ${stihlColor.black};
`;

const SoftwareVersionAutoCompleteInput = ({
  parameters,
  isOpenAutocomplete,
  setOpenAutocomplete,
  setSelectedSwVersion,
}: {
  parameters: AutocompleteRenderInputParams;
  isOpenAutocomplete: boolean;
  setOpenAutocomplete: Dispatch<SetStateAction<boolean>>;
  setSelectedSwVersion: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
  const { t } = useTranslation();

  function handleToggleOpen(): void {
    setOpenAutocomplete((isOpen) => !isOpen);
  }

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSelectedSwVersion(event.target.value),
    [setSelectedSwVersion],
  );

  return (
    <StihlTextField
      data-testid="swVersionsTextField"
      sx={{
        inlineSize: '75%',
        '.MuiOutlinedInput-input.MuiInputBase-input': {
          boxSizing: 'border-box',
        },
        button: {
          order: 3, // order 3 means the search icon will appear after the clear icon which has an order of 2
        },
        // Clear icon
        'div.MuiAutocomplete-endAdornment': {
          position: 'relative', // default was absolute. we make it relative so that it is now within the flow of the other two elements
          paddingTop: '28px',
          order: 2,
        },
        '.MuiOutlinedInput-root.MuiInputBase-root': {
          padding: '0 0.5rem',
        },
      }}
      placeholder={t('updateManagement.enterSoftwareVersion')}
      onChange={handleChange}
      {...parameters}
      InputProps={{
        ...parameters.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              style={{ padding: '6px' }}
              type="submit"
              size="large"
              data-testid="openOptionsButton"
              onClick={handleToggleOpen}
            >
              {isOpenAutocomplete ? (
                <StyledStihlIconArrowUp />
              ) : (
                <StihlIconArrowDown />
              )}
            </IconButton>
            {parameters.InputProps.endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
};

const SoftwareVersionAutocomplete = ({
  availableSwVersions,
  setSelectedSwVersion,
}: {
  availableSwVersions: string[];
  setSelectedSwVersion: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isOpenAutocomplete, setOpenAutocomplete] = useState(false);

  const customNoOptionsNode = (
    <StyledHelperTextContainer data-testid="noSoftwareVersionsHint">
      <StihlIconXFilled color="error" />
      <StyledHelperText>
        {t('updateManagement.noSoftwareVersionsOptions')}
      </StyledHelperText>
    </StyledHelperTextContainer>
  );

  const handleSelectionChange = useCallback(
    (_: SyntheticEvent<Element, Event>, value?: string | null): void => {
      setSelectedSwVersion(value ?? '');
    },
    [setSelectedSwVersion],
  );

  const checkOptionValueEquality = useCallback(
    (option: string, value: string) => option === value,
    [],
  );

  function setRenderInput(
    parameters: AutocompleteRenderInputParams,
  ): JSX.Element {
    return (
      <SoftwareVersionAutoCompleteInput
        parameters={parameters}
        isOpenAutocomplete={isOpenAutocomplete}
        setOpenAutocomplete={setOpenAutocomplete}
        setSelectedSwVersion={setSelectedSwVersion}
      />
    );
  }

  return (
    <Autocomplete
      data-testid="swVersionsAutocomplete"
      open={isOpenAutocomplete}
      ListboxProps={{ style: { maxHeight: '16rem' } }}
      forcePopupIcon={false}
      onOpen={() => setOpenAutocomplete(true)}
      onClose={() => setOpenAutocomplete(false)}
      onChange={handleSelectionChange}
      isOptionEqualToValue={checkOptionValueEquality}
      noOptionsText={customNoOptionsNode}
      options={availableSwVersions}
      renderInput={setRenderInput}
    />
  );
};

export default SoftwareVersionAutocomplete;
