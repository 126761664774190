import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconGlobe from '../../../../base/stihl-material-ui/icons/stihl-icon-globe';
import { generateFileName } from '../../../legal-documents/service/documents-utils';
import { DocumentCountryFilter } from '../../../legal-documents/ui/document-country-filter';

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export type FooterModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const TermsFooterModal = ({ open, setOpen }: FooterModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [termsCountry, setTermsCountry] = useState<string>('Germany (DE)');

  function handleClose(): void {
    setOpen(false);
  }

  function handleOpenDocument(): void {
    window.open(`./terms/${generateFileName('termsofuse', termsCountry)}.pdf`);
    setOpen(false);
  }

  return (
    <StihlModal
      open={open}
      onClose={handleClose}
      title={t('footer.generalTerms')}
      data-testid="generalTermsModal"
      actionButtonText={t('openDocument')}
      handleActionClick={handleOpenDocument}
    >
      <div>
        <Typography>{t('footer.selectLanguage')}</Typography>
        <br />
        <FlexContainer>
          <StihlIconGlobe color="black" />
          <DocumentCountryFilter
            documentCountry={termsCountry}
            setDocumentCountry={setTermsCountry}
          />
        </FlexContainer>
        <br />
      </div>
    </StihlModal>
  );
};

export default TermsFooterModal;
