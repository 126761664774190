import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTooltipCentered from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-centered';

const StyledRegisteredDevicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 13rem;
  padding: 2rem 0 1rem;
  text-align: center;
`;

export type RegisteredDevicesProps = {
  totalRegisteredDevices: number;
};

const RegisteredDevicesCounter = ({
  totalRegisteredDevices,
}: RegisteredDevicesProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StihlTooltipCentered
      title={t('deviceStatistics.registeredDevicesTooltip')}
    >
      <StyledRegisteredDevicesWrapper>
        <Typography
          variant="h4"
          data-testid="registeredDevices"
          whiteSpace="nowrap"
        >
          {totalRegisteredDevices}
        </Typography>
        <Typography fontWeight="700">
          {t('deviceStatistics.registeredDevices')}
        </Typography>
      </StyledRegisteredDevicesWrapper>
    </StihlTooltipCentered>
  );
};
export default RegisteredDevicesCounter;
