import type { JSX, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTableHeader from '../../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import type { Order } from '../../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import type {
  DeviceManagementColumns,
  DeviceManagementHeadCell,
} from '../../../model/overview.models';

type DeviceManagementTableHeaderProps = {
  order: 'asc' | 'desc';
  orderBy: keyof DeviceManagementColumns;
  setOrder: Dispatch<SetStateAction<Order>>;
  setOrderBy: Dispatch<SetStateAction<keyof DeviceManagementColumns>>;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numberSelected: number;
  rowCount: number;
  isDetailsOpen?: boolean;
  displayColumns: (keyof DeviceManagementColumns)[];
  shouldHaveCheckbox?: boolean;
};

const DeviceManagementTableHeader = ({
  order,
  orderBy,
  setOrder,
  setOrderBy,
  onSelectAllClick,
  numberSelected,
  rowCount,
  displayColumns,
  shouldHaveCheckbox = true,
}: DeviceManagementTableHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  function getHeadCells(): DeviceManagementHeadCell[] {
    return displayColumns.map((column) => ({
      id: column,
      label: t(`deviceManagementTable.${column}`),
      isSortable: true,
    }));
  }

  return (
    <StihlTableHeader<DeviceManagementColumns>
      order={order}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
      onSelectAllClick={onSelectAllClick}
      numberSelected={numberSelected}
      rowCount={rowCount}
      headCells={getHeadCells()}
      shouldHaveCheckbox={shouldHaveCheckbox}
      shouldHaveLastColumnEmpty
    />
  );
};
export default DeviceManagementTableHeader;
