import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import type { StatusValue } from '../../../../model';
import CardContentItem from './card-content-item';
import GreyText from './grey-text';

type StatusTextProps = {
  name: string;
  value: StatusValue;
};
const StatusText = ({ name, value }: StatusTextProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name}>
      <Typography>{t(value.state)}</Typography>
      <Typography>
        <GreyText>{t('triggeredBy')}</GreyText> {t(value.trigger)}
      </Typography>
    </CardContentItem>
  );
};

export default StatusText;
