import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { countriesArray } from '../../../app-shell/service/countries';
import type { DeviceCountries } from '../../model/statistics.model';

const StyledCountryFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type CountriesFilterProps = {
  availableCountries: DeviceCountries[] | undefined;
  countryFilterCallback: (filterValue: string) => void;
  shouldReset: boolean;
};
const CountriesFilter = ({
  availableCountries,
  countryFilterCallback,
  shouldReset,
}: CountriesFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (shouldReset) {
      setFilter('');
    }
  }, [shouldReset]);

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setFilter(event.target.value);
    countryFilterCallback(event.target.value);
  }

  return (
    <StyledCountryFilterWrapper>
      <InputLabel
        variant="standard"
        htmlFor="country"
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('deviceStatistics.country.country')}
      </InputLabel>
      <StihlSelect
        id="country"
        name="country"
        value={filter}
        onChange={handleChange}
        fullWidth
        inputProps={{ 'data-testid': 'countryFilter' }}
      >
        <MenuItem value="">{t('deviceStatistics.country.all')}</MenuItem>
        {availableCountries?.map((country) => (
          <MenuItem key={country.countryCode} value={country.countryCode}>
            {countriesArray.find(
              (listCountry) => listCountry.countryCode === country.countryCode,
            )?.countryName ?? t('deviceStatistics.country.unknownCountry')}
          </MenuItem>
        ))}
      </StihlSelect>
    </StyledCountryFilterWrapper>
  );
};

export default CountriesFilter;
