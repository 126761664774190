import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SearchObject } from '../../device/model/search.model';
import { SearchParameterValues } from '../../device/model/search.model';
import type { EmailSearchResult } from '../model';
import getEmailSearchResult from './email-search-api-client';

export function useEmailSearch(
  search: SearchObject | undefined,
): UseQueryResult<EmailSearchResult, Error> {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['emailSearch', search?.search],
    queryFn: async ({ signal }) => getEmailSearchResult(search, signal),
    enabled: search?.selector === SearchParameterValues.Email,
    refetchOnWindowFocus: false,
  });
}
