import instance from '../../app-authentication/service/authentication-interceptor';

export async function getDeviceIdsFromOrganization(
  organizationId: string | undefined,
  organizationEmail: string | undefined,
  signal: AbortSignal | undefined,
): Promise<string[]> {
  const queryParameter = organizationId
    ? `organizationId=${organizationId}`
    : `organizationEmail=${organizationEmail}`;

  return instance
    .get<string[]>(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/fleet/device-ids?${queryParameter}`,
      {
        signal,
      },
    )
    .then((response) => {
      return response.data;
    });
}
