import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconInfo from '../../../base/stihl-material-ui/icons/stihl-icon-info';
import { CardBase } from '../../device/ui/card/ui/card-component';
import { SupportFrame } from './support-utils';

export const HelpFaq = ({
  dealerLocale,
}: {
  dealerLocale: string;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <CardBase title={t('tabs.faq')} isFullHeight>
      <Typography data-testid="languageHint">
        <StihlIconInfo />
        <Typography variant="h5" component="span" sx={{ marginLeft: '0.5rem' }}>
          {t('help.languageHint')}
        </Typography>
      </Typography>
      <SupportFrame
        dealerLocale={dealerLocale}
        title="helpFaq"
        testId="helpFaq"
        urlParameter="externalkbsearchimowsp"
      />
    </CardBase>
  );
};
