import type { Dispatch, SetStateAction } from 'react';
import type { AlertStore } from '../../modules/app-alert/service/alert-store';

export const handleQueryError = (
  error: unknown,
  setAlert: Dispatch<SetStateAction<AlertStore>>,
): void => {
  if (
    error instanceof Error &&
    (error.message.includes('404') ||
      error.message.includes('500') ||
      error.message.includes('400'))
  ) {
    setAlert({
      isOpen: true,
      message: error.message,
      severity: 'error',
    });
  } else if (!(error instanceof Error && error.message.includes('413'))) {
    setAlert({ isOpen: true, message: 'anyError', severity: 'error' });
  }
};
