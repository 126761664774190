import instance from '../../app-authentication/service/authentication-interceptor';
import type { SearchObject } from '../../device/model/search.model';
import type { EmailSearchResult } from '../model';

export default async function getEmailSearchResult(
  body: SearchObject | undefined,
  signal: AbortSignal | undefined,
): Promise<EmailSearchResult> {
  return instance
    .post<EmailSearchResult>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL as string}/device/email`,
      body,
      { signal },
    )
    .then((response) => {
      return response.data;
    });
}
