import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChipType } from '../../../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import {
  FilterParameterValue,
  StihlChipContainer,
} from '../../../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import StihlSelect from '../../../../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../../../../base/stihl-material-ui/theme/stihl-style-guide';

export type TimeSpanFilterProps = {
  hourTimeSpan: number | undefined;
  setHourTimeSpan: (timeSpan: number | undefined) => void;
};

export const TimeSpanFilter = ({
  hourTimeSpan,
  setHourTimeSpan,
}: TimeSpanFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const timeSpans = [
    {
      value: 1,
      label: t('eventLog.filter.lastHour'),
    },
    {
      value: 3,
      label: t('eventLog.filter.lastThreeHours'),
    },
    {
      value: 24,
      label: t('eventLog.filter.last24Hours'),
    },
    {
      value: 7 * 24,
      label: t('eventLog.filter.last7Days'),
    },
    {
      value: 15 * 24,
      label: t('eventLog.filter.last15Days'),
    },
  ];

  const [chips, setChips] = useState<ChipType[]>([]);
  const [isInvalidChipInput, setInvalidChipInput] = useState(false);

  function handleTimeSpanChange(event: ChangeEvent<HTMLInputElement>): void {
    const selectedTimeSpan = Number(event.target.value);
    setHourTimeSpan(selectedTimeSpan);

    const newChip = {
      filter: FilterParameterValue.TimeSpan,
      searchTerm:
        timeSpans.find((timeSpan) => timeSpan.value === selectedTimeSpan)
          ?.label ?? '',
      value: selectedTimeSpan,
    };
    setChips([newChip]);
  }

  function chipFilterCallback(filterTerms: ChipType[]): void {
    if (filterTerms.length === 0) {
      setHourTimeSpan(undefined);
    }
  }

  return (
    <>
      <InputLabel
        variant="standard"
        htmlFor="hourTimeSpan"
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('eventLog.filter.label')}
      </InputLabel>
      <StihlSelect
        id="hourTimeSpan"
        value={hourTimeSpan ?? 0}
        onChange={handleTimeSpanChange}
        data-testid="timeSpanFilter"
        sx={{ inlineSize: '250px' }}
      >
        <MenuItem disabled value={0}>
          {t('eventLog.filter.emptyPlaceholder')}
        </MenuItem>
        {timeSpans.map((timeSpan) => (
          <MenuItem key={timeSpan.value} value={timeSpan.value}>
            {timeSpan.label}
          </MenuItem>
        ))}
      </StihlSelect>
      <StihlChipContainer
        chips={chips}
        setChips={setChips}
        filterCallback={chipFilterCallback}
        isInvalidInput={isInvalidChipInput}
        setInvalidInput={setInvalidChipInput}
        isClearAllFiltersButtonShown={false}
      />
    </>
  );
};

export default TimeSpanFilter;
