import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import { Component } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

const ErrorText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 100vh;
  font-family: 'STIHL Contraface Display Title', sans-serif;
`;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface ErrorBoundaryProps extends WithTranslation {
  children?: ReactNode;
}
class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: { error: string };
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error: Error): void {
    this.setState({ error: `${error.name}: ${error.message}, ${error.stack}` });
  }

  render(): ReactNode {
    const { error } = this.state;
    const { t, children } = this.props;
    if (error) {
      console.log('Error:', error);
      return <ErrorText>{t('anyError')}</ErrorText>;
    }
    return children;
  }
}

export default withTranslation()(ErrorBoundary);
