import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconLegend from '../../../../base/stihl-material-ui/icons/stihl-icon-legend';

const StyledSpan = styled.span`
  margin-inline-end: 0.5rem;
`;

const ConnectionState = ({
  isConnected,
}: {
  isConnected: boolean | null;
}): JSX.Element => {
  const { t } = useTranslation();

  function isDisconnectedOrUnknownColor(): string {
    return isConnected === false ? 'red' : 'grey.500';
  }

  function isDisconnectedOrUnknownString(): string {
    return isConnected === false
      ? t('deviceConnectionState.disconnected')
      : t('deviceConnectionState.neverOnlineBefore');
  }
  return (
    <Typography data-testid="connectionHint">
      <StyledSpan>
        <StihlIconLegend
          color={isConnected ? 'green' : isDisconnectedOrUnknownColor()}
        />
      </StyledSpan>
      {isConnected
        ? t('deviceConnectionState.connected')
        : isDisconnectedOrUnknownString()}
    </Typography>
  );
};

export default ConnectionState;
