/* eslint-disable sonarjs/cognitive-complexity */
import i18next from 'i18next';
import type { Dtc } from '../../model/dtc.model';

// eslint-disable-next-line max-lines-per-function
export function dtcMapper(): Dtc[] {
  const dtcArray = Object.entries(
    i18next.getResourceBundle(i18next.language, 'dtc') as Record<
      string,
      string
    >,
  );
  // eslint-disable-next-line unicorn/no-array-reduce
  return dtcArray.reduce<Dtc[]>((accumulator, current) => {
    const codeMatches = /_[\dA-Z]{2,6}$/u.exec(current[0]);
    if (codeMatches) {
      const existingIndex = accumulator.findIndex(
        (element) => element.code === codeMatches[0].replace('_', ''),
      );
      const headline = current[0].toLocaleLowerCase().includes('headline')
        ? current[1]
        : '';
      const description = current[0].toLocaleLowerCase().includes('notes')
        ? current[1]
        : '';
      const component = current[0].toLocaleLowerCase().includes('component')
        ? current[1]
        : undefined;
      if (existingIndex >= 0) {
        if (headline) {
          accumulator[existingIndex].headline = headline;
        }
        if (description) {
          accumulator[existingIndex].description = description;
        }
        if (component) {
          accumulator[existingIndex].component = component;
        }
      } else {
        accumulator.push({
          code: codeMatches[0].replace('_', ''),
          description,
          headline,
          component,
        });
      }
    }
    return accumulator;
  }, []);
}
/* eslint-enable sonarjs/cognitive-complexity */
