import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { useStore } from '../../../../app-authentication/service/authentication-store-provider';
import type { DeviceType, StihlDeviceTag } from '../../../../device/model';
import { DeviceModel } from '../../../../device/model';
import type { DeviceCountries } from '../../../model/statistics.model';
import { getStatistics } from '../../../service/device-management-api-client/device-management-api-client';
import { getDefaultProcessingChain } from '../../../service/processing-chain';
import CountriesFilter from '../../filters/countries-filter';
import DeviceModelFilter from '../../filters/device-model-filter';
import DeviceTagFilter from '../../filters/device-tag-filter';
import DeviceTypeFilter from '../../filters/device-type-filter';
import SoftwareVersionFilter from '../../filters/sw-version-filter';

const StyledStihlIconInfo = styled(StihlIconInfo)`
  margin: -0.1rem 1rem;
`;

type MassAssignmentFiltersProps = {
  swVersions: string[];
  handleStepChange: (panel: string) => void;
  deviceModel: DeviceModel;
  deviceModelCallback: (filterValue: DeviceModel) => void;
  deviceTypeCallback: (filterValues: DeviceType[]) => void;
  deviceTagCallback: (filterValue: StihlDeviceTag | undefined) => void;
  softwareVersionCallback: (filterValue: string) => void;
  countryFilterCallback: (filterValue: string) => void;
  disabled: boolean;
  shouldReset: boolean;
};

const MassAssignmentFilters = ({
  swVersions,
  handleStepChange,
  deviceModel,
  deviceModelCallback,
  deviceTypeCallback,
  deviceTagCallback,
  softwareVersionCallback,
  countryFilterCallback,
  disabled,
  shouldReset,
}: MassAssignmentFiltersProps): JSX.Element => {
  const { t } = useTranslation();
  const [store] = useStore();
  const [availableCountries, setAvailableCountries] = useState<
    DeviceCountries[]
  >([]);

  useEffect(() => {
    getStatistics(getDefaultProcessingChain(), deviceModel)
      .then((response) => setAvailableCountries(response.countryStatistics))
      .catch((_error) => {
        console.warn('No available countries found');
      });
    return () => {
      setAvailableCountries([]);
    };
  }, [deviceModel, setAvailableCountries]);

  return (
    <>
      <Typography>
        <b>{t('updateManagement.massAssignment')}</b>
        <StihlTooltip title={t('updateManagement.massAssignmentTooltip')}>
          <StyledStihlIconInfo />
        </StihlTooltip>
      </Typography>
      <Grid
        container
        spacing={2}
        mt={1}
        style={{ display: 'flex' }}
        data-testid="massAssignmentFilters"
      >
        <Grid item xl={2} xs={6}>
          <DeviceModelFilter
            deviceModel={deviceModel}
            setDeviceModel={deviceModelCallback}
            disabled={store.deviceModels.includes(DeviceModel.connectedBox)}
            data-testid="deviceModelFilter"
          />
        </Grid>
        <Grid item xl={2} xs={6}>
          <DeviceTypeFilter
            deviceModel={deviceModel}
            deviceTypeCallback={deviceTypeCallback}
            shouldReset={shouldReset}
          />
        </Grid>
        <Grid item xl={2} xs={6}>
          <DeviceTagFilter
            deviceTagCallback={deviceTagCallback}
            shouldReset={shouldReset}
            data-testid="deviceTagFilter"
          />
        </Grid>
        <Grid item xl={2} xs={6}>
          <SoftwareVersionFilter
            availableSwVersions={swVersions}
            softwareVersionCallback={softwareVersionCallback}
            shouldReset={shouldReset}
          />
        </Grid>
        {!store.deviceModels.includes(DeviceModel.connectedBox) && (
          <Grid item xl={2} xs={6}>
            <CountriesFilter
              availableCountries={availableCountries}
              countryFilterCallback={countryFilterCallback}
              shouldReset={shouldReset}
            />
          </Grid>
        )}
      </Grid>
      <StihlButtonSecondary
        style={{ marginTop: '1.75rem' }}
        onClick={() => handleStepChange('step2')}
        data-testid="continueButton"
        disabled={disabled}
      >
        {t('updateManagement.continue')}
      </StihlButtonSecondary>
    </>
  );
};

export default MassAssignmentFilters;
