export type Country = {
  countryCode: string;
  countryName: string;
  isAllowed: boolean;
};

export const countriesArray: Country[] = [
  { countryCode: 'AF', countryName: 'Afghanistan', isAllowed: false },
  { countryCode: 'AL', countryName: 'Albania', isAllowed: true },
  { countryCode: 'DZ', countryName: 'Algeria', isAllowed: true },
  { countryCode: 'AS', countryName: 'American Samoa', isAllowed: false },
  { countryCode: 'AD', countryName: 'Andorra', isAllowed: false },
  { countryCode: 'AO', countryName: 'Angola', isAllowed: true },
  { countryCode: 'AI', countryName: 'Anguilla', isAllowed: false },
  { countryCode: 'AQ', countryName: 'Antarctica', isAllowed: false },
  { countryCode: 'AG', countryName: 'Antigua and Barbuda', isAllowed: true },
  { countryCode: 'AR', countryName: 'Argentina', isAllowed: true },
  { countryCode: 'AM', countryName: 'Armenia', isAllowed: true },
  { countryCode: 'AW', countryName: 'Aruba', isAllowed: true },
  { countryCode: 'AU', countryName: 'Australia', isAllowed: true },
  { countryCode: 'AT', countryName: 'Austria', isAllowed: true },
  { countryCode: 'AZ', countryName: 'Azerbaijan', isAllowed: false },
  { countryCode: 'BS', countryName: 'Bahamas', isAllowed: false },
  { countryCode: 'BH', countryName: 'Bahrain', isAllowed: false },
  { countryCode: 'BD', countryName: 'Bangladesh', isAllowed: false },
  { countryCode: 'BB', countryName: 'Barbados', isAllowed: false },
  { countryCode: 'BY', countryName: 'Belarus', isAllowed: true },
  { countryCode: 'BE', countryName: 'Belgium', isAllowed: true },
  { countryCode: 'BZ', countryName: 'Belize', isAllowed: false },
  { countryCode: 'BJ', countryName: 'Benin', isAllowed: false },
  { countryCode: 'BM', countryName: 'Bermuda', isAllowed: false },
  { countryCode: 'BT', countryName: 'Bhutan', isAllowed: false },
  { countryCode: 'BO', countryName: 'Bolivia', isAllowed: true },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    isAllowed: true,
  },
  { countryCode: 'BW', countryName: 'Botswana', isAllowed: false },
  { countryCode: 'BV', countryName: 'Bouvet Island', isAllowed: false },
  { countryCode: 'BR', countryName: 'Brasil', isAllowed: true },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    isAllowed: false,
  },
  { countryCode: 'BN', countryName: 'Brunei Darussalam', isAllowed: false },
  { countryCode: 'BG', countryName: 'Bulgaria', isAllowed: true },
  { countryCode: 'BF', countryName: 'Burkina Faso', isAllowed: false },
  { countryCode: 'BI', countryName: 'Burundi', isAllowed: false },
  { countryCode: 'KH', countryName: 'Cambodia', isAllowed: false },
  { countryCode: 'CM', countryName: 'Cameroon', isAllowed: true },
  { countryCode: 'CA', countryName: 'Canada', isAllowed: true },
  { countryCode: 'CV', countryName: 'Cape Verde', isAllowed: false },
  { countryCode: 'KY', countryName: 'Cayman Islands', isAllowed: false },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    isAllowed: false,
  },
  { countryCode: 'TD', countryName: 'Chad', isAllowed: false },
  { countryCode: 'CL', countryName: 'Chile', isAllowed: true },
  { countryCode: 'CN', countryName: 'China', isAllowed: true },
  { countryCode: 'CX', countryName: 'Christmas Island', isAllowed: false },
  {
    countryCode: 'CC',
    countryName: 'Cocos (Keeling) Islands',
    isAllowed: false,
  },
  { countryCode: 'CO', countryName: 'Colombia', isAllowed: true },
  { countryCode: 'KM', countryName: 'Comoros', isAllowed: true },
  { countryCode: 'CG', countryName: 'Congo', isAllowed: false },
  {
    countryCode: 'CD',
    countryName: 'Congo, Democratic Republic of',
    isAllowed: true,
  },
  { countryCode: 'CK', countryName: 'Cook Islands', isAllowed: false },
  { countryCode: 'CR', countryName: 'Costa Rica', isAllowed: true },
  { countryCode: 'CI', countryName: "Cote d'Ivoire", isAllowed: true },
  { countryCode: 'HR', countryName: 'Croatia', isAllowed: true },
  { countryCode: 'CU', countryName: 'Cuba', isAllowed: false },
  { countryCode: 'CW', countryName: 'Curaçao', isAllowed: true },
  { countryCode: 'CY', countryName: 'Cyprus', isAllowed: true },
  { countryCode: 'CZ', countryName: 'Czech Republic', isAllowed: true },
  { countryCode: 'DK', countryName: 'Denmark', isAllowed: true },
  { countryCode: 'DJ', countryName: 'Djibouti', isAllowed: false },
  { countryCode: 'DM', countryName: 'Dominica', isAllowed: true },
  { countryCode: 'DO', countryName: 'Dominican Republic', isAllowed: true },
  { countryCode: 'EC', countryName: 'Ecuador', isAllowed: true },
  { countryCode: 'EG', countryName: 'Egypt', isAllowed: true },
  { countryCode: 'SV', countryName: 'El Salvador', isAllowed: true },
  { countryCode: 'GQ', countryName: 'Equatorial Guinea', isAllowed: true },
  { countryCode: 'ER', countryName: 'Eritrea', isAllowed: false },
  { countryCode: 'EE', countryName: 'Estonia', isAllowed: true },
  { countryCode: 'ET', countryName: 'Ethiopia', isAllowed: false },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands (Malvinas)',
    isAllowed: false,
  },
  { countryCode: 'FO', countryName: 'Faroe Islands', isAllowed: false },
  { countryCode: 'FJ', countryName: 'Fiji', isAllowed: false },
  { countryCode: 'FI', countryName: 'Finland', isAllowed: true },
  { countryCode: 'FR', countryName: 'France', isAllowed: true },
  { countryCode: 'GF', countryName: 'French Guiana', isAllowed: false },
  { countryCode: 'PF', countryName: 'French Polynesia', isAllowed: true },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    isAllowed: false,
  },
  { countryCode: 'GA', countryName: 'Gabon', isAllowed: true },
  { countryCode: 'GM', countryName: 'Gambia', isAllowed: false },
  { countryCode: 'GE', countryName: 'Georgia', isAllowed: true },
  { countryCode: 'DE', countryName: 'Germany', isAllowed: true },
  { countryCode: 'GH', countryName: 'Ghana', isAllowed: true },
  { countryCode: 'GI', countryName: 'Gibraltar', isAllowed: false },
  { countryCode: 'GB', countryName: 'Great Britain', isAllowed: true },
  { countryCode: 'GR', countryName: 'Greece', isAllowed: true },
  { countryCode: 'GL', countryName: 'Greenland', isAllowed: false },
  { countryCode: 'GD', countryName: 'Grenada', isAllowed: true },
  { countryCode: 'GP', countryName: 'Guadeloupe', isAllowed: false },
  { countryCode: 'GU', countryName: 'Guam', isAllowed: false },
  { countryCode: 'GT', countryName: 'Guatemala', isAllowed: true },
  { countryCode: 'GN', countryName: 'Guinea', isAllowed: false },
  { countryCode: 'GW', countryName: 'Guinea-Bissau', isAllowed: false },
  { countryCode: 'GY', countryName: 'Guyana', isAllowed: true },
  { countryCode: 'HT', countryName: 'Haiti', isAllowed: false },
  {
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    isAllowed: false,
  },
  {
    countryCode: 'VA',
    countryName: 'Holy See (Vatican City State)',
    isAllowed: false,
  },
  { countryCode: 'HN', countryName: 'Honduras', isAllowed: true },
  { countryCode: 'HK', countryName: 'Hong Kong', isAllowed: true },
  { countryCode: 'HU', countryName: 'Hungary', isAllowed: true },
  { countryCode: 'IS', countryName: 'Iceland', isAllowed: false },
  { countryCode: 'IN', countryName: 'India', isAllowed: true },
  { countryCode: 'ID', countryName: 'Indonesia', isAllowed: true },
  {
    countryCode: 'IR',
    countryName: 'Iran',
    isAllowed: true,
  },
  { countryCode: 'IQ', countryName: 'Iraq', isAllowed: true },
  { countryCode: 'IE', countryName: 'Ireland', isAllowed: true },
  { countryCode: 'IL', countryName: 'Israel', isAllowed: true },
  { countryCode: 'IT', countryName: 'Italy', isAllowed: true },
  { countryCode: 'JM', countryName: 'Jamaica', isAllowed: true },
  { countryCode: 'JP', countryName: 'Japan', isAllowed: true },
  { countryCode: 'JO', countryName: 'Jordan', isAllowed: true },
  { countryCode: 'KZ', countryName: 'Kazakhstan', isAllowed: true },
  { countryCode: 'KE', countryName: 'Kenya', isAllowed: true },
  { countryCode: 'KI', countryName: 'Kiribati', isAllowed: false },
  { countryCode: 'KP', countryName: 'North Korea', isAllowed: false },
  { countryCode: 'KW', countryName: 'Kuwait', isAllowed: false },
  { countryCode: 'KG', countryName: 'Kyrgyzstan', isAllowed: true },
  {
    countryCode: 'LA',
    countryName: "Lao People's Democratic Republic",
    isAllowed: false,
  },
  { countryCode: 'LV', countryName: 'Latvia', isAllowed: false },
  { countryCode: 'LB', countryName: 'Lebanon', isAllowed: true },
  { countryCode: 'LS', countryName: 'Lesotho', isAllowed: false },
  { countryCode: 'LR', countryName: 'Liberia', isAllowed: false },
  { countryCode: 'LY', countryName: 'Libya', isAllowed: false },
  { countryCode: 'LI', countryName: 'Liechtenstein', isAllowed: false },
  { countryCode: 'LT', countryName: 'Lithuania', isAllowed: true },
  { countryCode: 'LU', countryName: 'Luxembourg', isAllowed: true },
  { countryCode: 'MO', countryName: 'Macao', isAllowed: false },
  { countryCode: 'MG', countryName: 'Madagascar', isAllowed: true },
  { countryCode: 'MW', countryName: 'Malawi', isAllowed: false },
  { countryCode: 'MY', countryName: 'Malaysia', isAllowed: true },
  { countryCode: 'MV', countryName: 'Maldives', isAllowed: false },
  { countryCode: 'ML', countryName: 'Mali', isAllowed: true },
  { countryCode: 'MT', countryName: 'Malta', isAllowed: false },
  { countryCode: 'MH', countryName: 'Marshall Islands', isAllowed: false },
  { countryCode: 'MQ', countryName: 'Martinique', isAllowed: false },
  { countryCode: 'MR', countryName: 'Mauritania', isAllowed: false },
  { countryCode: 'MU', countryName: 'Mauritius', isAllowed: true },
  { countryCode: 'YT', countryName: 'Mayotte', isAllowed: false },
  { countryCode: 'MX', countryName: 'Mexico', isAllowed: true },
  {
    countryCode: 'FM',
    countryName: 'Micronesia}, Federated States of',
    isAllowed: false,
  },
  { countryCode: 'MD', countryName: 'Moldova', isAllowed: true },
  { countryCode: 'MC', countryName: 'Monaco', isAllowed: false },
  { countryCode: 'MN', countryName: 'Mongolia', isAllowed: true },
  { countryCode: 'MS', countryName: 'Montserrat', isAllowed: false },
  { countryCode: 'MA', countryName: 'Morocco', isAllowed: true },
  { countryCode: 'MZ', countryName: 'Mozambique', isAllowed: false },
  { countryCode: 'MM', countryName: 'Myanmar', isAllowed: true },
  { countryCode: 'NA', countryName: 'Namibia', isAllowed: false },
  { countryCode: 'NR', countryName: 'Nauru', isAllowed: false },
  { countryCode: 'NP', countryName: 'Nepal', isAllowed: false },
  { countryCode: 'NL', countryName: 'Netherlands', isAllowed: true },
  { countryCode: 'NC', countryName: 'New Caledonia', isAllowed: false },
  { countryCode: 'NZ', countryName: 'New Zealand', isAllowed: true },
  { countryCode: 'NI', countryName: 'Nicaragua', isAllowed: true },
  { countryCode: 'NE', countryName: 'Niger', isAllowed: false },
  { countryCode: 'NG', countryName: 'Nigeria', isAllowed: true },
  { countryCode: 'NU', countryName: 'Niue', isAllowed: false },
  { countryCode: 'NF', countryName: 'Norfolk Island', isAllowed: false },
  {
    countryCode: 'MK',
    countryName: 'North Macedonia',
    isAllowed: true,
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    isAllowed: false,
  },
  { countryCode: 'NO', countryName: 'Norway', isAllowed: true },
  { countryCode: 'OM', countryName: 'Oman', isAllowed: false },
  { countryCode: 'PK', countryName: 'Pakistan', isAllowed: false },
  { countryCode: 'PW', countryName: 'Palau', isAllowed: false },
  { countryCode: 'PS', countryName: 'State of Palestine', isAllowed: false },
  { countryCode: 'PA', countryName: 'Panama', isAllowed: true },
  { countryCode: 'PG', countryName: 'Papua New Guinea', isAllowed: false },
  { countryCode: 'PY', countryName: 'Paraguay', isAllowed: true },
  { countryCode: 'PE', countryName: 'Peru', isAllowed: true },
  { countryCode: 'PH', countryName: 'Philippines', isAllowed: true },
  { countryCode: 'PN', countryName: 'Pitcairn', isAllowed: false },
  { countryCode: 'PL', countryName: 'Poland', isAllowed: true },
  { countryCode: 'PT', countryName: 'Portugal', isAllowed: true },
  { countryCode: 'PR', countryName: 'Puerto Rico', isAllowed: false },
  { countryCode: 'QA', countryName: 'Qatar', isAllowed: false },
  { countryCode: 'RE', countryName: 'Reunion', isAllowed: false },
  { countryCode: 'RO', countryName: 'Romania', isAllowed: true },
  { countryCode: 'RU', countryName: 'Russia', isAllowed: true },
  { countryCode: 'RW', countryName: 'Rwanda', isAllowed: false },
  { countryCode: 'SH', countryName: 'Saint Helena', isAllowed: false },
  { countryCode: 'KN', countryName: 'Saint Kitts and Nevis', isAllowed: true },
  { countryCode: 'LC', countryName: 'Saint Lucia', isAllowed: true },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    isAllowed: false,
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    isAllowed: true,
  },
  { countryCode: 'WS', countryName: 'Samoa', isAllowed: false },
  { countryCode: 'SM', countryName: 'San Marino', isAllowed: false },
  { countryCode: 'ST', countryName: 'Sao Tome and Principe', isAllowed: false },
  { countryCode: 'SA', countryName: 'Saudi Arabia', isAllowed: true },
  { countryCode: 'SN', countryName: 'Senegal', isAllowed: false },
  { countryCode: 'RS', countryName: 'Serbia', isAllowed: true },
  { countryCode: 'SC', countryName: 'Seychelles', isAllowed: false },
  { countryCode: 'SL', countryName: 'Sierra Leone', isAllowed: false },
  { countryCode: 'SG', countryName: 'Singapore', isAllowed: false },
  { countryCode: 'SK', countryName: 'Slovakia', isAllowed: true },
  { countryCode: 'SI', countryName: 'Slovenia', isAllowed: true },
  { countryCode: 'SB', countryName: 'Solomon Islands', isAllowed: false },
  { countryCode: 'SO', countryName: 'Somalia', isAllowed: false },
  { countryCode: 'ZA', countryName: 'South Africa', isAllowed: true },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    isAllowed: false,
  },
  { countryCode: 'KR', countryName: 'South Korea', isAllowed: true },
  { countryCode: 'ES', countryName: 'Spain', isAllowed: true },
  { countryCode: 'LK', countryName: 'Sri Lanka', isAllowed: false },
  { countryCode: 'SD', countryName: 'Sudan', isAllowed: true },
  { countryCode: 'SR', countryName: 'Suriname', isAllowed: true },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    isAllowed: false,
  },
  { countryCode: 'SZ', countryName: 'Eswatini', isAllowed: false },
  { countryCode: 'SE', countryName: 'Sweden', isAllowed: true },
  { countryCode: 'CH', countryName: 'Switzerland', isAllowed: true },
  { countryCode: 'SY', countryName: 'Syria', isAllowed: true },
  { countryCode: 'TW', countryName: 'Taiwan', isAllowed: true },
  { countryCode: 'TJ', countryName: 'Tajikistan', isAllowed: false },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania}, United Republic of',
    isAllowed: false,
  },
  { countryCode: 'TH', countryName: 'Thailand', isAllowed: true },
  { countryCode: 'TL', countryName: 'Timor-Leste', isAllowed: false },
  { countryCode: 'TG', countryName: 'Togo', isAllowed: false },
  { countryCode: 'TK', countryName: 'Tokelau', isAllowed: false },
  { countryCode: 'TO', countryName: 'Tonga', isAllowed: false },
  { countryCode: 'TT', countryName: 'Trinidad and Tobago', isAllowed: true },
  { countryCode: 'TN', countryName: 'Tunisia', isAllowed: true },
  { countryCode: 'TR', countryName: 'Turkey', isAllowed: true },
  { countryCode: 'TM', countryName: 'Turkmenistan', isAllowed: true },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    isAllowed: false,
  },
  { countryCode: 'TV', countryName: 'Tuvalu', isAllowed: false },
  { countryCode: 'UG', countryName: 'Uganda', isAllowed: true },
  { countryCode: 'UA', countryName: 'Ukraine', isAllowed: true },
  { countryCode: 'UK', countryName: 'United Kingdom', isAllowed: true },
  { countryCode: 'AE', countryName: 'United Arab Emirates', isAllowed: true },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    isAllowed: true,
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    isAllowed: false,
  },
  { countryCode: 'UY', countryName: 'Uruguay', isAllowed: true },
  { countryCode: 'UZ', countryName: 'Uzbekistan', isAllowed: true },
  { countryCode: 'VU', countryName: 'Vanuatu', isAllowed: false },
  { countryCode: 'VE', countryName: 'Venezuela', isAllowed: true },
  { countryCode: 'VN', countryName: 'Vietnam', isAllowed: true },
  {
    countryCode: 'VG',
    countryName: 'Virgin Islands}, British',
    isAllowed: false,
  },
  { countryCode: 'VI', countryName: 'Virgin Islands}, U.S.', isAllowed: false },
  { countryCode: 'WF', countryName: 'Wallis and Futuna', isAllowed: false },
  { countryCode: 'EH', countryName: 'Western Sahara', isAllowed: false },
  { countryCode: 'YE', countryName: 'Yemen', isAllowed: false },
  { countryCode: 'ZM', countryName: 'Zambia', isAllowed: false },
  { countryCode: 'ZW', countryName: 'Zimbabwe', isAllowed: false },
  { countryCode: 'AX', countryName: 'Åland Islands', isAllowed: false },
  {
    countryCode: 'BQ',
    countryName: 'Bonaire}, Sint Eustatius and Saba',
    isAllowed: false,
  },
  { countryCode: 'GG', countryName: 'Guernsey', isAllowed: false },
  { countryCode: 'IM', countryName: 'Isle of Man', isAllowed: false },
  { countryCode: 'JE', countryName: 'Jersey', isAllowed: false },
  { countryCode: 'ME', countryName: 'Montenegro', isAllowed: false },
  { countryCode: 'BL', countryName: 'Saint Barthélemy', isAllowed: false },
  {
    countryCode: 'MF',
    countryName: 'Saint Martin (French part)',
    isAllowed: false,
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten (Dutch part)',
    isAllowed: false,
  },
  { countryCode: 'SS', countryName: 'South Sudan', isAllowed: false },
  { countryCode: 'XK', countryName: 'Kosovo', isAllowed: false },
];
