import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import { startMultiAssignmentUpdate } from '../../../service/device-management-api-client/device-management-api-client';
import { useDeviceManagementStore } from '../../../service/device-management-store/device-management-store-provider';

const StyledInfoWrapper = styled.div`
  display: flex;
`;

const StyledInfoContainer = styled.div`
  padding: 4rem;
  text-align: center;
`;

const MultiAssignmentSummary = ({
  selectedSwVersion,
  handleStepChange,
}: {
  selectedSwVersion: string;
  handleStepChange: (panel: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [store] = useDeviceManagementStore();
  const [_, setAlert] = useAlertStore();

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const affectedDevicesNumber = store.multiAssignmentDevices.size;

  function handleMultiAssignmentUpdate(): void {
    const deviceModel = [...store.multiAssignmentDevices][0].deviceModel;
    const deviceIds = [...store.multiAssignmentDevices].map(
      (device) => device.deviceId,
    );
    setIsLoadingUpdate(true);
    startMultiAssignmentUpdate(deviceModel, deviceIds, selectedSwVersion)
      .then((response) => {
        setAlert({
          isOpen: true,
          message: t('updateManagement.successfullyUpdated', {
            updatedDevices: `${response}/${affectedDevicesNumber}`,
          }),
          severity: 'success',
        });
        setIsLoadingUpdate(false);
        store.clear();
        return handleStepChange('step1');
      })
      .catch((error: Error) => {
        setAlert({
          isOpen: true,
          message: error.message,
          severity: 'error',
        });
        return setIsLoadingUpdate(false);
      });
  }

  return (
    <>
      <StyledInfoWrapper>
        <StyledInfoContainer>
          <Typography variant="h4" pb={1}>
            {affectedDevicesNumber}
          </Typography>
          <b>{t('updateManagement.selectedDevices')}</b>
        </StyledInfoContainer>
        <StyledInfoContainer>
          <Typography variant="h4" pb={1}>
            {selectedSwVersion}
          </Typography>
          <b>{t('updateManagement.softwareVersion')}</b>
        </StyledInfoContainer>
      </StyledInfoWrapper>

      <StihlButtonSecondaryLoading
        loading={isLoadingUpdate}
        onClick={handleMultiAssignmentUpdate}
        disabled={affectedDevicesNumber === 0}
        data-testid="updateButton"
      >
        {t('updateManagement.startProcess')}
      </StihlButtonSecondaryLoading>
    </>
  );
};

export default MultiAssignmentSummary;
