/* istanbul ignore file – will be tested implicitly */
/**
 * @overview Style guide with design tokens defined in Zeplin project
 * [STIHL IA01 | Web]{@link https://app.zeplin.io/project/5f76d825c0b5da6c818591ff}.
 *
 * Other sources [Brand Portal]{@link https://brandportal.stihl.com/en}
 * and [stihl.de]{@link https://www.stihl.de} are used as well when
 * information is missing. This is explicitly documented.
 */

export const stihlColorTransparent = {
  stihlOrange: (opacity = 0.5): string => `rgba(243, 122, 31, ${opacity})`,
  black: (opacity = 0.5): string => `rgba(0, 0, 0, ${opacity})`,
};

export const stihlColor = {
  stihlOrange: '#f37a1f',
  stihlOrangeLight: '#fee2cd',
  stihlOrangeMid: '#f7a669',
  stihlOrangeDark: '#e9640c',
  stihlOrangeDeep: '#d9480f',
  white: '#ffffff',
  black: '#252525',
  background: '#f6f6f6',
  greyLight: '#ededed',
  greyMid: '#dadada',
  greyMidDark: '#252525',
  fontGrey: '#808080',
  greyBase: '#b1b1b1',
  greyDark: '#505050',
  green: '#749f4a',
  greenLight: '#e5f0db',
  greenDark: '#5b863c',
  red: '#d43b3b',
  redLight: '#eebcbd',
  redDark: '#b72427',
  blue: '#249abe',
  blueLight: '#d4ecf7',
  blueDark: '#217aa6',
  yellow: '#fdc543',
  yellowLight: '#fdecc3',
  yellowMid: '#fed277',
  yellowDark: '#f9a521',
  yellowDeep: '#ee8f00',
  forest: '#4a9e5f',
  forestLight: '#dbf0e1',
  forestMid: '#77c089',
  forestDark: '#3c8653',
  forestDeep: '#2d7644',
  priority1: '#a5181d',
  priority2: '#d43b3b',
  priority3: '#eebcbd',
};

/**
 * Expected root font size for the `<html>` element which is set in the browser.
 *
 * 16px is a standard value used as the default by browser vendors. The user
 * can change it in the browser settings to get larger app-fonts and scale the UI
 * (e.g. to get larger app-fonts on a TV, which has a large screen size but is viewed from a distance).
 */
export const htmlFontSize = 16;

function pxToRem(px: number): number {
  return px / htmlFontSize;
}

/**
 * Use `rem` unit for better accessibility: user can change the root font size for `<html>`
 * in browser settings.
 *
 * @param px
 *
 * @see htmlFontSize
 */
export function pxToRemUnit(px: number): string {
  return `${pxToRem(px)}rem`;
}

/**
 * Grid spaces in multiple of `8`.
 */
const spacingGridUnit = 8;

function isStihlGridUnitLength(length: number): boolean {
  const factor = length / spacingGridUnit;
  return factor === Math.abs(factor);
}

export function asStihlGridUnit(length: number): number {
  if (!isStihlGridUnitLength(length)) {
    throw new RangeError(
      `Length is not a multiple of grid unit ${spacingGridUnit}: ${length}`,
    );
  }
  return length;
}

export function toStihlGridUnitCeil(length: number): number {
  return Math.ceil(length / spacingGridUnit) * spacingGridUnit;
}

export const stihlSpacingFactor = {
  xs: 0.5,
  s: 1,
  m: 4,
};

export function stihlSpacingPx(factor: number): number {
  return spacingGridUnit * factor;
}

export function stihlSpacingPxUnit(factor: number): string {
  return `${stihlSpacingPx(factor)}px`;
}

/**
 * Use `rem` unit for spacing that should change when the user changes
 * the root font size for `<html>` (see {@link htmlFontSize} in browser settings.
 * This improves responsiveness and accessibility as the user is able to "scale" the ui depending
 * on the `<html>` font size.
 *
 * @param factor e.g. 2
 * @return e.g. `1rem`
 */
export function stihlSpacingRemUnit(factor: number): string {
  return pxToRemUnit(stihlSpacingPx(factor));
}

/**
 * Spacing in `rem` but using `em` as the unit.
 *
 * Use `em` (instead of `rem`) to scale a component with the local font-size with using
 * the `rem` value as the scale factor. When the local font-size in the CSS cascade is
 * `1em` the component uses the corresponding `rem` value as the factor. Higher `em`
 * scales the component according to the rem factor.
 *
 * Watch out when using `em`s as they also scale with the cascade: every ancestor element
 * in a chain of ancestors that use `em` affect the scaling!
 *
 * @param factor e.g. 2
 * @return e.g. `1em`
 */
export function stihlSpacingRemAsEmUnit(factor: number): string {
  return `${pxToRem(stihlSpacingPx(factor))}em`;
}

/**
 * Fallback app-fonts and font names (without `-` separator) taken
 * from [stihl.de]{@link https://www.stihl.de}.
 */
const stihlFontFamiliesWithFallback = {
  'STIHL Contraface Display Title': [
    'STIHL Contraface Display Title',
    'Arial Black',
    'sans-serif',
  ].join(','),
  'STIHL Contraface Display Medium': [
    'STIHL Contraface Display Medium',
    'Arial',
    'sans-serif',
  ].join(','),
  'STIHL Contraface Text': [
    'STIHL Contraface Text',
    'Arial',
    'sans-serif',
  ].join(','),
  'STIHL Contraface Text Bold': [
    'STIHL Contraface Text Bold',
    'Arial',
    'sans-serif',
  ].join(','),
};

/**
 * `fontWeights` taken from [stihl.de]{@link https://www.stihl.de}.
 */
export const stihlTextStyles = {
  h1: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Display Title'],
    fontSize: pxToRemUnit(36),
    lineHeight: 1.2,
    fontWeight: 400,
  },
  h2: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Display Title'],
    fontSize: pxToRemUnit(26),
    lineHeight: 1.2,
    fontWeight: 400,
  },
  h3: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(20),
    lineHeight: 1.2,
    fontWeight: 700,
  },
  h4: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Display Title'],
    fontSize: pxToRemUnit(20),
    lineHeight: 1.2,
    fontWeight: 400,
  },
  h5: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(16),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  h6: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(16),
    lineHeight: 1.5,
    fontWeight: 400,
  },
  subtitle: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(18),
    lineHeight: 1.333,
    fontWeight: 600,
  },
  copyBig: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(18),
    lineHeight: 1.333,
    fontWeight: 400,
  },
  copyMedium: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(16),
    lineHeight: 1.5,
    fontWeight: 400,
  },
  copyBody2: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(16),
    lineHeight: 1.43,
    fontWeight: 400,
  },
  copyCaption: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(14),
    lineHeight: 1.43,
    fontWeight: 400,
    color: `${stihlColor.fontGrey}`,
  },
  copySmall: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(12),
    lineHeight: 1.333,
    textTransform: undefined,
    fontWeight: 400,
    color: `${stihlColor.greyMidDark}`,
  },
  copyButton: {
    fontFamily: stihlFontFamiliesWithFallback['STIHL Contraface Text'],
    fontSize: pxToRemUnit(16),
    lineHeight: 1.5,
    fontWeight: 700,
    textTransform: undefined,
  },
};
