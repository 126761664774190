import type { StihlTheme, StihlThemeProps } from './stihl-theme';

const muiColorsPaletteMainNames = [
  'primary',
  'secondary',
  'error',
  'warning',
  'info',
  'success',
] as const;
/**
 * Material-UI theme colors conforming to {@link PaletteColor}.
 */
type MuiColorPaletteMain = (typeof muiColorsPaletteMainNames)[number];
function isMuiColorPaletteMain(value: string): value is MuiColorPaletteMain {
  return (muiColorsPaletteMainNames as readonly string[]).includes(value);
}
function getMuiColorPaletteMain(
  theme: StihlTheme,
  color: string,
): string | undefined {
  if (isMuiColorPaletteMain(color)) {
    return theme.palette[color].main;
  }
}

const muiColorsTextNames = [
  'text',
  'text.primary',
  'text.secondary',
  'text.disabled',
  'text.hint',
] as const;
/**
 * Material-UI theme colors conforming to {@link TypeText}.
 */
function getMuiColorText(theme: StihlTheme, color: string): string | undefined {
  if (color === 'text' || color === 'text.primary') {
    return theme.palette.text.primary;
  }
  if (color === 'text.secondary') {
    return theme.palette.text.secondary;
  }
  if (color === 'text.disabled') {
    return theme.palette.text.disabled;
  }
  if (color === 'text.hint') {
    return theme.palette.text.disabled;
  }
}

const muiColorsPaletteContrastTextNames = [
  'primary.contrastText',
  'secondary.contrastText',
  'error.contrastText',
  'warning.contrastText',
  'info.contrastText',
  'success.contrastText',
] as const;
/**
 * Material-UI theme colors conforming to {@link PaletteColor}.
 */
function getMuiColorPaletteContrastText(
  theme: StihlTheme,
  color: string,
): string | undefined {
  if (color === 'primary.contrastText') {
    return theme.palette.primary.contrastText;
  }
  if (color === 'secondary.contrastText') {
    return theme.palette.secondary.contrastText;
  }
  if (color === 'error.contrastText') {
    return theme.palette.error.contrastText;
  }
  if (color === 'warning.contrastText') {
    return theme.palette.warning.contrastText;
  }
  if (color === 'info.contrastText') {
    return theme.palette.info.contrastText;
  }
  if (color === 'success.contrastText') {
    return theme.palette.success.contrastText;
  }
}

const stihlMuiColorsPaletteTransparentNames = [
  'primary.transparent',
  'secondary.transparent',
] as const;

/**
 * Stihl Material-UI theme colors added to the {@link StihlPalette} in the Stihl theme
 */
function getStihlMuiColorPaletteTransparent(
  theme: StihlTheme,
  color: string,
): string | undefined {
  if (color === 'primary.transparent') {
    return theme.palette.primary.transparent;
  }
  if (color === 'secondary.transparent') {
    return theme.palette.secondary.transparent;
  }
}

const stihlMuiColorsPriorityNames = [
  'priority.priority1',
  'priority.priority2',
  'priority.priority3',
] as const;

function getStihlMuiColorPriority(
  theme: StihlTheme,
  color: string,
): string | undefined {
  if (color === 'priority.priority1') {
    return theme.palette.priority.priority1;
  }
  if (color === 'priority.priority2') {
    return theme.palette.priority.priority2;
  }
  if (color === 'priority.priority3') {
    return theme.palette.priority.priority3;
  }
}

const muiColorsCommonNames = ['white', 'black'] as const;
/**
 * Material-UI theme colors conforming to {@link CommonColors}.
 */
type MuiColorCommon = (typeof muiColorsCommonNames)[number];
function isMuiColorCommon(value: string): value is MuiColorCommon {
  return (muiColorsCommonNames as readonly string[]).includes(value);
}
function getMuiColorCommon(
  theme: StihlTheme,
  color: string,
): string | undefined {
  if (isMuiColorCommon(color)) {
    return theme.palette.common[color];
  }
}

const muiStihlColorsNames = [
  'stihlOrange',
  'stihlOrangeLight',
  'stihlOrangeMid',
  'stihlOrangeDark',
  'stihlOrangeDeep',
  'greyBase',
  'yellow',
  'yellowLight',
  'yellowMid',
  'yellowDark',
  'yellowDeep',
] as const;

export type MuiStihlColors = (typeof muiStihlColorsNames)[number];

function getMuiStihlColors(
  theme: StihlTheme,
  color: string,
): string | undefined {
  if (color === 'stihlOrange') {
    return theme.palette.stihlColors.stihlOrange;
  }
  if (color === 'stihlOrangeLight') {
    return theme.palette.stihlColors.stihlOrangeLight;
  }
  if (color === 'stihlOrangeMid') {
    return theme.palette.stihlColors.stihlOrangeMid;
  }
  if (color === 'stihlOrangeDark') {
    return theme.palette.stihlColors.stihlOrangeDark;
  }
  if (color === 'stihlOrangeDeep') {
    return theme.palette.stihlColors.stihlOrangeDeep;
  }
  if (color === 'greyBase') {
    return theme.palette.stihlColors.greyBase;
  }
  if (color === 'yellow') {
    return theme.palette.stihlColors.yellow;
  }
  if (color === 'yellowLight') {
    return theme.palette.stihlColors.yellowLight;
  }
  if (color === 'yellowMid') {
    return theme.palette.stihlColors.yellowMid;
  }
  if (color === 'yellowDark') {
    return theme.palette.stihlColors.yellowDark;
  }
  if (color === 'yellowDeep') {
    return theme.palette.stihlColors.yellowDeep;
  }
}

const muiColorsGreyNames = [
  'grey.50',
  'grey.100',
  'grey.200',
  'grey.300',
  'grey.400',
  'grey.500',
  'grey.600',
  'grey.700',
  'grey.800',
  'grey.900',
] as const;
/**
 * Material-UI theme colors conforming to {@link GreyColors}.
 */
function getMuiColorGrey(theme: StihlTheme, color: string): string | undefined {
  if (color === 'grey.50') {
    return theme.palette.grey[50];
  }
  if (color === 'grey.100') {
    return theme.palette.grey[100];
  }
  if (color === 'grey.200') {
    return theme.palette.grey[200];
  }
  if (color === 'grey.300') {
    return theme.palette.grey[300];
  }
  if (color === 'grey.400') {
    return theme.palette.grey[400];
  }
  if (color === 'grey.400') {
    return theme.palette.grey[400];
  }
  if (color === 'grey.500') {
    return theme.palette.grey[500];
  }
  if (color === 'grey.600') {
    return theme.palette.grey[600];
  }
  if (color === 'grey.700') {
    return theme.palette.grey[700];
  }
  if (color === 'grey.800') {
    return theme.palette.grey[800];
  }
  if (color === 'grey.900') {
    return theme.palette.grey[900];
  }
}

const muiColorsActionNames = [
  /**
   * Same as the explicit `activeActive` because MUI components like
   * [Icon]{@link https://material-ui.com/api/icon/} use it like that.
   */
  'action',
  'action.active',
  /**
   * Same as the explicit `activeDisabled` because MUI components like
   * [Icon]{@link https://material-ui.com/api/icon/} use it like that.
   */
  'disabled',
  'action.disabled',

  'selected',
  'action.selected',

  'focus',
  'action.focus',
] as const;
/**
 * Material-UI theme colors conforming to {@link TypeAction}.
 */
function getMuiColorAction(
  theme: StihlTheme,
  color: string,
): string | undefined {
  // see https://github.com/mui-org/material-ui/blob/3fdb9c3eb6b03a35d277b473a1e428acd4bf218e/packages/material-ui/src/Icon/Icon.js#L30
  if (color === 'action' || color === 'action.active') {
    return theme.palette.action.active;
  }
  if (color === 'disabled' || color === 'action.disabled') {
    return theme.palette.action.disabled;
  }
  if (color === 'selected' || color === 'action.selected') {
    return theme.palette.action.selected;
  }
  if (color === 'focus' || color === 'action.focus') {
    return theme.palette.action.focus;
  }
}

export const stihlThemeColorNames = [
  ...muiColorsPaletteMainNames,
  ...muiColorsPaletteContrastTextNames,
  ...muiColorsTextNames,
  ...muiColorsCommonNames,
  ...muiColorsActionNames,
  ...muiColorsGreyNames,
  ...stihlMuiColorsPaletteTransparentNames,
  ...stihlMuiColorsPriorityNames,
  ...muiStihlColorsNames,
] as const;

export type StihlThemeColor = (typeof stihlThemeColorNames)[number];

/**
 * Get a theme color from the mui-theme. It gets the main theme colors (primary.main, secondary.main) with the keys
 * specified by Material UI and adds some additional keys, which are mapped to the theme (e.g. `action.active`, `white`, `black`).
 *
 * Beware that Material UI's built in components `color` property only support some of the theme colors
 * and error when an unsupported `color` is passed.
 * Therefore pass only colors supported by the MUI component to that component props and handle
 * other `StihlThemeColor` not understood by the component yourself. E.g. by setting the MUI component's `color` to
 * `inherit` and applying non-supported colors yourself to `color`, `background-color` etc.
 *
 * @param theme stihl theme, which is where the colors are defined
 * @param color color-key to identify the theme color
 * @returns the html color corresponding to the theme color `inherit` as default if color is not found.
 *
 * @example
 * type ExampleColor = Extract<
 *   StihlThemeColor,
 *     | 'primary'
 *     | 'secondary'
 *     | 'success'
 *     | 'error'
 *     | 'action'
 *     | 'disabled'
 *     | 'white'
 *     | 'black'
 *   >;
 *
 * type ExampleProps = {
 *  theme: StihlTheme;
 *  color?: ExampleColor;
 * };
 *
 * export const Example = styled.div<ExampleProps>`
 *  color: ${(props) => getStihlThemeColor(props.theme, props.color)};
 * `;
 */
export function getStihlThemeColor(theme: StihlTheme, color: string): string {
  if (color === 'inherit' || color === 'transparent') {
    return color;
  }

  return getStihlThemeColorOnly(theme, color as StihlThemeColor) ?? color;
}

export function getStihlThemeColorOnly(
  theme: StihlTheme,
  color: StihlThemeColor,
): string | undefined {
  return (
    getMuiColorPaletteMain(theme, color) ??
    getMuiColorCommon(theme, color) ??
    getMuiColorGrey(theme, color) ??
    getMuiColorAction(theme, color) ??
    getMuiColorText(theme, color) ??
    getMuiColorPaletteContrastText(theme, color) ??
    getStihlMuiColorPaletteTransparent(theme, color) ??
    getStihlMuiColorPriority(theme, color) ??
    getMuiStihlColors(theme, color)
  );
}

/**
 * To use in `styled` components or `css` property.
 */
export function themeColor(
  color: StihlThemeColor,
): (props: StihlThemeProps) => string {
  return (props: StihlThemeProps) => getStihlThemeColor(props.theme, color);
}

type ThemeColorProps<TColor extends StihlThemeColor = StihlThemeColor> =
  StihlThemeProps<{ color?: TColor }>;

/**
 * To use in `styled` components or `css` property.
 */
export function themeColorFromProps<
  TColor extends StihlThemeColor = StihlThemeColor,
>(props: ThemeColorProps<TColor>): string {
  if (props.color == null) return 'inherit';
  return getStihlThemeColor(props.theme, props.color);
}
