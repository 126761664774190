import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import CardContentItem from './card-content-item';

const DidToSemanticVersionText = ({
  name,
  value,
}: {
  name: string;
  value: string;
}): JSX.Element => {
  // DID = Diagnostic ID
  // DID calculation:  let did=$major*$((2 ** 11))+$minor*$((2 ** 6))+$bugfix

  const dIdAsNumber = Number(value);

  const moduloMajor = dIdAsNumber % 2048;
  const major = (dIdAsNumber - moduloMajor) / 2048;
  const moduloMinor = moduloMajor % 64;
  const minor = (moduloMajor - moduloMinor) / 64;
  const bugfix = moduloMinor;

  const semanticVersion = `${major}.${minor}.${bugfix}`;

  return (
    <CardContentItem name={name}>
      <Typography data-testid="initialSoftwareValue">
        {semanticVersion} ({value})
      </Typography>
    </CardContentItem>
  );
};

export default DidToSemanticVersionText;
