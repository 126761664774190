import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimaryLoading from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import { useAlertStore } from '../../../../../app-alert/service/alert-provider';
import Pagination from '../../../../../app-shell/ui/pagination/pagination';
import type { Card, DeviceModel } from '../../../../model/device.model';
import type { EventLogEntry } from '../../../../model/event-log.model';
import { useEventLog } from '../../../../service/device-service/device-service';
import { CardBase, ContentWrapper } from '../card-component';
import renderSwitch from '../card-utils';
import TimeSpanFilter from './filter/time-span-filter';
import EventLogTableBody from './table/event-log-table-body';
import { EventLogTableHeader } from './table/event-log-table-header';

export type EventLogCardProps = {
  deviceId: string | undefined;
  deviceModel: DeviceModel | undefined;
  cardData: Card;
};

export const EventLogCard = ({
  deviceId,
  deviceModel,
  cardData,
}: EventLogCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const [page, setPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  const [hourTimeSpan, setHourTimeSpan] = useState<number | undefined>();

  const eventLogData = useEventLog(deviceId ?? '', deviceModel ?? '');

  const filteredEventLogData: EventLogEntry[] = useMemo(() => {
    if (hourTimeSpan) {
      const filteringDate = new Date();
      filteringDate.setHours(filteringDate.getHours() - hourTimeSpan);

      return (
        eventLogData.data?.eventLog.filter(
          (eventLogEntry) =>
            new Date(eventLogEntry.lastUpdatedTimestamp) > filteringDate,
        ) ?? []
      );
    }

    return eventLogData.data?.eventLog ?? [];
  }, [eventLogData.data?.eventLog, hourTimeSpan]);

  useEffect(() => {
    if (eventLogData.isError) {
      setAlert({
        isOpen: true,
        severity: 'error',
        message: t('eventLog.getEventLogDataError'),
      });
    }
  }, [eventLogData.isError, setAlert, t]);

  const fetchEventLog = (): void => {
    void eventLogData.refetch();
  };

  const eventLogLength = eventLogData.data?.eventLog.length;
  return (
    <>
      <CardBase title={cardData.title} data-testid="eventLogCard">
        <CardContent sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
          {cardData.cardFields.map((data) => (
            <ContentWrapper key={data.title}>
              {renderSwitch(data)}
            </ContentWrapper>
          ))}
        </CardContent>
        {!eventLogLength && (
          <StihlButtonPrimaryLoading
            data-testid="getEventLogButton"
            onClick={fetchEventLog}
            loading={eventLogData.isFetching}
          >
            {t('eventLog.retrieveData')}
          </StihlButtonPrimaryLoading>
        )}
        {eventLogLength === 0 && (
          <Typography sx={{ marginTop: '1rem' }}>
            {t('eventLog.noEntries')}
          </Typography>
        )}
        {!!eventLogLength && eventLogLength > 0 && (
          <>
            <TimeSpanFilter
              hourTimeSpan={hourTimeSpan}
              setHourTimeSpan={setHourTimeSpan}
            />
            <TableContainer>
              <Table data-testid="eventLogTable">
                <EventLogTableHeader rowCount={filteredEventLogData.length} />
                <EventLogTableBody
                  eventLogEntries={[...filteredEventLogData].slice(
                    firstToDisplay,
                    lastToDisplay,
                  )}
                />
              </Table>
            </TableContainer>
          </>
        )}
      </CardBase>
      {!!eventLogLength && eventLogLength > 0 && (
        <Pagination
          numberOfResults={filteredEventLogData.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('eventLog.entries')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      )}
    </>
  );
};
