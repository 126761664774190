import styled from '@emotion/styled';
import type { CheckboxProps } from '@mui/material/Checkbox';
import Checkbox from '@mui/material/Checkbox';
import type { FC } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

const CheckboxStyled = styled(Checkbox)`
  z-index: 0;
  position: relative;

  /**
   * @Checkbox we need a ::before pseudoelement so that the tick in the checkbox becomes a real white background (otherwise it's transparent
   * and alternatively we could use a custom svg for the checkbox but it seemed as the easier solution this way
   */
  &::before {
    position: absolute;
    z-index: -1;
    background-color: ${stihlColor.white};
    content: ' ';
    inline-size: 15px;
    block-size: 15px;
    inset-block-start: 13px;
    inset-inline-start: 13px;
  }
`;

const StihlCheckbox: FC<CheckboxProps> = (props) => {
  return <CheckboxStyled color="primary" {...props} />;
};

export default StihlCheckbox;
