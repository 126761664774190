import type { AxiosError, AxiosResponse } from 'axios';
import instance from '../../../app-authentication/service/authentication-interceptor';
import type { TabsResponse } from '../../../app-shell/model/app.models';
import type {
  CustomerAccount,
  CustomerListEntry,
  CustomerManagementCustomer,
  CustomerSearchObject,
} from '../../model/customer-account.model';
import type {
  CustomerCreationResponse,
  CustomerDeviceData,
  CustomerProfileUid,
  DisconnectCustomerData,
} from '../../model/customer-profile.model';

export default async function createCustomer(
  formData: CustomerAccount,
): Promise<CustomerCreationResponse> {
  return instance
    .post<CustomerCreationResponse>(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/account`,
      formData,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(
        `${error.message}. ${
          error.response && (error.response.data as Error).message
            ? (error.response.data as Error).message
            : ''
        }`,
      );
    });
}

export async function resendVerificationEmail(
  customerId: string,
): Promise<CustomerProfileUid> {
  return instance
    .post<CustomerProfileUid>(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/account/resend-verification`,
      customerId,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getCustomerManagementTabs(
  signal?: AbortSignal,
): Promise<TabsResponse[]> {
  return instance
    .get<TabsResponse[]>(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/management/tabs`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getCustomers(
  signal?: AbortSignal,
): Promise<CustomerManagementCustomer[]> {
  return instance
    .get<CustomerManagementCustomer[]>(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/management`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function searchCustomers(
  customerSearchObject: CustomerSearchObject,
  signal: AbortSignal | undefined,
): Promise<CustomerListEntry[]> {
  return instance
    .get<CustomerListEntry[]>(
      `${
        process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL
      }/customer/account/search?term=${customerSearchObject.searchTerm.trim()}`,
      { signal },
    )
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function connectCustomerToDevice(
  formData: CustomerDeviceData,
): Promise<AxiosResponse> {
  return instance
    .post(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/account/connect`,
      formData,
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function removeCustomer(
  selectedId: string,
): Promise<AxiosResponse> {
  return instance
    .post(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/account/release-preferred-dealer`,
      selectedId,
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function removeDeputy(
  deputyId: string,
  subscriptionId: string,
): Promise<AxiosResponse> {
  return instance
    .post(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/management/remove-deputy`,
      { customerId: deputyId, subscriptionId },
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function disconnectCustomerFromDevice(
  formData: DisconnectCustomerData,
): Promise<AxiosResponse> {
  return instance
    .post(
      `${process.env.REACT_APP_ISP_API_CUSTOMER_BASE_URL}/customer/management/clear-device-connection`,
      formData,
    )
    .catch((error: AxiosError) => {
      throw new Error(
        `${error.message}. ${
          error.response ? (error.response.data as Error).message : ''
        }`,
      );
    });
}
