import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import type { JSX } from 'react';
import { useState } from 'react';
import { useStore } from '../../../app-authentication/service/authentication-store-provider';
import QueryComponent from '../../../app-shell/ui/query-component';
import type { DeviceModel } from '../../../device/model';
import { useSoftwareVersionsMetadata } from '../../service/device-management-service/device-management-service';
import DeviceModelFilter from '../filters/device-model-filter';
import { DeviceManagementSoftwareVersionsTable } from './device-management-software-versions-table';

const SelectWrapper = styled.div`
  inline-size: 20%;
`;

export const DeviceManagementSoftwareVersionsCard = (): JSX.Element => {
  const [store] = useStore();
  const [deviceModel, setDeviceModel] = useState<DeviceModel>(
    store.deviceModels[0],
  );

  const softwareVersionsMetadataUseQueryResult = useSoftwareVersionsMetadata(
    deviceModel,
    true,
  );

  return (
    <Card data-testid="softwareVersionsCard">
      <CardContent>
        <SelectWrapper>
          <DeviceModelFilter
            deviceModel={deviceModel}
            setDeviceModel={setDeviceModel}
            data-testid="deviceModelFilter"
          />
        </SelectWrapper>
        <br />
        <QueryComponent data={softwareVersionsMetadataUseQueryResult}>
          {softwareVersionsMetadataUseQueryResult.data && (
            <DeviceManagementSoftwareVersionsTable
              deviceModel={deviceModel}
              softwareVersionsMetadata={
                softwareVersionsMetadataUseQueryResult.data
              }
            />
          )}
        </QueryComponent>
      </CardContent>
    </Card>
  );
};
