import type { TooltipProps } from '@mui/material/Tooltip';
import type { FC, ReactNode } from 'react';
import StihlTooltip from './stihl-tooltip';

type StihlTooltipCenteredProps = TooltipProps & {
  children: ReactNode;
  title: string;
};

const StihlTooltipCentered: FC<StihlTooltipCenteredProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <StihlTooltip
      placement="bottom"
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            textAlign: 'center',
            maxWidth: '14rem',
            boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            filter: 'drop-shadow(0px 1px 3px rgb(0, 0, 0, 0.3))',
          },
        },
      }}
      {...props}
    >
      {children}
    </StihlTooltip>
  );
};
export default StihlTooltipCentered;
