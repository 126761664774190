import MenuItem from '@mui/material/MenuItem';
import type { Dispatch, SetStateAction, ChangeEvent, JSX } from 'react';
import StihlSelect from '../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { selectableCountries } from '../service/documents-utils';

export const DocumentCountryFilter = ({
  documentCountry,
  setDocumentCountry,
  hideCountries,
}: {
  documentCountry: string;
  setDocumentCountry: Dispatch<SetStateAction<string>>;
  hideCountries?: [string];
}): JSX.Element => {
  function handleDeviceModelChange(event: ChangeEvent<HTMLInputElement>): void {
    setDocumentCountry(event.target.value);
  }

  return (
    <StihlSelect
      data-testid="documentCountryFilter"
      value={documentCountry}
      onChange={handleDeviceModelChange}
      fullWidth
    >
      {selectableCountries
        .filter(
          (selectableCountry) => !hideCountries?.includes(selectableCountry),
        )
        .map((selectableCountry) => (
          <MenuItem key={selectableCountry} value={selectableCountry}>
            {selectableCountry}
          </MenuItem>
        ))}
    </StihlSelect>
  );
};
