import styled from '@emotion/styled';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TooltipProps } from 'recharts';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import StihlButtonPrimaryLoading from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import { useAlertStore } from '../../../../../app-alert/service/alert-provider';
import type {
  Connection,
  ConnectionGraphData,
} from '../../../../model/connection.model';
import { ConnectionDataType } from '../../../../model/connection.model';
import type { Card, DeviceModel } from '../../../../model/device.model';
import { useConnectionHistory } from '../../../../service/device-service/device-service';
import { CardBase, ContentWrapper } from '../card-component';
import renderSwitch from '../card-utils';
import { ConnectionHistoryDatepicker } from './connection-history-datepicker';
import { ConnectionHistoryLineSelect } from './connection-history-line-select';
import {
  ConnectionHistoryTooltip,
  cellularConnectedColor,
  deviceConnectedColor,
  wifiConnectedColor,
} from './connection-history-tooltip';

const StyledCardContent = styled(CardContent)`
  padding-inline: 0;
  padding-block-start: 0;
`;

type ConnectionHistoryCardProps = {
  deviceId: string | undefined;
  deviceModel: DeviceModel;
  cardData: Card;
};

function filterConnectionHistoryByDate(
  connectionHistory: Connection[],
  dateStart: number,
  dateEnd: number,
): Connection[] {
  const oneDay = 60 * 60 * 24 * 1000;
  const dateEndPlusOneDay = dateEnd + oneDay;

  return connectionHistory.filter((connection) => {
    const connectionTimestamp = new Date(
      connection.lastUpdatedTimestamp,
    ).valueOf();
    return (
      connectionTimestamp >= dateStart &&
      connectionTimestamp < dateEndPlusOneDay
    );
  });
}

function useConnectionGraphData(
  connectionHistory: Connection[] | undefined,
  dateStart: Date | null,
  dateEnd: Date | null,
): ConnectionGraphData[] | undefined {
  return useMemo(() => {
    if (connectionHistory === undefined) {
      return undefined;
    }

    return filterConnectionHistoryByDate(
      connectionHistory,
      dateStart?.valueOf() ?? Number.NEGATIVE_INFINITY,
      dateEnd?.valueOf() ?? Number.POSITIVE_INFINITY,
    ).map((connection) => {
      return {
        [ConnectionDataType.CellularConnected]: connection.cellularIsConnected
          ? 1
          : 0,
        [ConnectionDataType.DeviceConnected]:
          connection.deviceIsCurrentlyConnected ? 1 : 0,
        [ConnectionDataType.WifiConnected]: connection.wifiIsConnected ? 1 : 0,
        timestamp: new Date(connection.lastUpdatedTimestamp).valueOf(),
        providerId: connection.cellularNetworkInformation_providerId ?? '',
      };
    });
  }, [connectionHistory, dateEnd, dateStart]);
}

const dateFormatter = (date: number): string => {
  return new Date(date).toLocaleString();
};

// eslint-disable-next-line max-lines-per-function
export const ConnectionHistoryCard = ({
  deviceId,
  deviceModel,
  cardData,
}: ConnectionHistoryCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const [opacity, setOpacity] = useState({
    [ConnectionDataType.WifiConnected]: 1,
    [ConnectionDataType.DeviceConnected]: 1,
    [ConnectionDataType.CellularConnected]: 1,
  });
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [lineShown, setLineShown] = useState({
    [ConnectionDataType.WifiConnected]: true,
    [ConnectionDataType.DeviceConnected]: true,
    [ConnectionDataType.CellularConnected]: true,
  });

  const connectionHistory = useConnectionHistory(deviceId ?? '', deviceModel);
  const connectionData = useConnectionGraphData(
    connectionHistory.data,
    dateStart,
    dateEnd,
  );

  useEffect(() => {
    if (connectionHistory.isError) {
      setAlert({
        isOpen: true,
        severity: 'error',
        message: t('connectionHistory.getHistoryDataError'),
      });
    }
  }, [connectionHistory.isError, setAlert, t]);

  function fetchHistory(): void {
    void connectionHistory.refetch();
  }

  const customTooltip = useCallback(
    (props: TooltipProps<ValueType, NameType>) => {
      if (!connectionData) {
        return null;
      }

      return (
        <ConnectionHistoryTooltip
          dataPointTimestamp={Number(props.label)}
          connectionData={connectionData}
        />
      );
    },
    [connectionData],
  );

  const handleToggleLineShown = (
    connectionDataType: ConnectionDataType,
  ): void => {
    setLineShown((previousState) => {
      const newState = { ...previousState };
      newState[connectionDataType] = !previousState[connectionDataType];
      return newState;
    });
  };

  return (
    <CardBase title={cardData.title} data-testid="connectionHistoryCard">
      <StyledCardContent>
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>{renderSwitch(data)}</ContentWrapper>
        ))}
      </StyledCardContent>
      {(!connectionData || connectionData.length === 0) &&
        dateStart == null &&
        dateEnd == null && (
          <StihlButtonPrimaryLoading
            data-testid="getHistoryDataButton"
            onClick={fetchHistory}
            loading={connectionHistory.isFetching}
          >
            {t('connectionHistory.getHistoryData')}
          </StihlButtonPrimaryLoading>
        )}
      {((connectionData != null && connectionData.length > 0) ||
        dateStart != null ||
        dateEnd != null) && (
        <ConnectionHistoryDatepicker
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
        />
      )}
      {connectionData && connectionData.length === 0 && (
        <Typography sx={{ marginTop: '1rem' }}>
          {t('connectionHistory.notHistory')}
        </Typography>
      )}
      {connectionData && connectionData.length > 0 && (
        <>
          <ConnectionHistoryLineSelect
            isWifiConnectedShown={lineShown[ConnectionDataType.WifiConnected]}
            isDeviceConnectedShown={
              lineShown[ConnectionDataType.DeviceConnected]
            }
            isCellularConnectedShown={
              lineShown[ConnectionDataType.CellularConnected]
            }
            onLineSelect={handleToggleLineShown}
          />

          <ResponsiveContainer width="99%" height={320}>
            <LineChart
              data={connectionData}
              margin={{
                top: 32,
                right: 10,
                left: 60,
                bottom: 4,
              }}
            >
              <XAxis
                dataKey="timestamp"
                domain={[
                  connectionData[0].timestamp,
                  connectionData[connectionData.length - 1].timestamp,
                ]}
                scale="time"
                type="number"
                tickFormatter={dateFormatter}
              />
              <YAxis
                yAxisId="left"
                label={{
                  value: t('connectionHistory.connectionState'),
                  position: 'insideLeft',
                  dy: -132,
                }}
                type="number"
                domain={[0, 1]}
                ticks={[0, 1]}
                tickFormatter={(value: number) =>
                  value
                    ? t('connectionHistory.connected')
                    : t('connectionHistory.notConnected')
                }
                orientation="left"
                padding={{ top: 40, bottom: 40 }}
              />
              {lineShown[ConnectionDataType.WifiConnected] && (
                <Line
                  name={t('connectionHistory.wifiConnected')}
                  yAxisId="left"
                  type="stepAfter"
                  dataKey="wifiConnected"
                  stroke={wifiConnectedColor}
                  strokeOpacity={opacity[ConnectionDataType.WifiConnected]}
                />
              )}
              {lineShown[ConnectionDataType.DeviceConnected] && (
                <Line
                  name={t('connectionHistory.deviceConnected')}
                  yAxisId="left"
                  type="stepAfter"
                  dataKey="deviceConnected"
                  stroke={deviceConnectedColor}
                  strokeOpacity={opacity[ConnectionDataType.DeviceConnected]}
                />
              )}
              {lineShown[ConnectionDataType.CellularConnected] && (
                <Line
                  name={t('connectionHistory.mobileConnection')}
                  yAxisId="left"
                  type="stepAfter"
                  dataKey="cellularConnected"
                  stroke={cellularConnectedColor}
                  strokeOpacity={opacity[ConnectionDataType.CellularConnected]}
                />
              )}
              <Legend
                onMouseEnter={(data) => {
                  const { dataKey } = data as { dataKey: ConnectionDataType };
                  setOpacity(() => {
                    const newState = {
                      [ConnectionDataType.CellularConnected]: 0.3,
                      [ConnectionDataType.DeviceConnected]: 0.3,
                      [ConnectionDataType.WifiConnected]: 0.3,
                    };
                    newState[dataKey] = 1;
                    return newState;
                  });
                }}
                onMouseLeave={() => {
                  setOpacity({
                    [ConnectionDataType.CellularConnected]: 1,
                    [ConnectionDataType.DeviceConnected]: 1,
                    [ConnectionDataType.WifiConnected]: 1,
                  });
                }}
              />
              <Tooltip content={customTooltip} />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </CardBase>
  );
};
