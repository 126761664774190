import styled from '@emotion/styled';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { JSX } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledButton = styled(Button)`
  white-space: nowrap;
  box-shadow: none;

  &:hover {
    color: ${stihlColor.white};
    background-color: ${stihlColor.greyDark};
    box-shadow: none;
  }

  &:disabled {
    color: ${stihlColor.white};
    background-color: ${stihlColor.greyBase};
  }
`;

const StihlButtonSecondary = (props: ButtonProps): JSX.Element => {
  return <StyledButton variant="contained" color="secondary" {...props} />;
};

export default StihlButtonSecondary;
