import styled from '@emotion/styled';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import LoadingButton from '@mui/lab/LoadingButton';
import type { JSX } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledLoadingButton = styled(LoadingButton)`
  color: ${stihlColor.black};

  &:hover {
    color: ${stihlColor.black};
    background-color: ${stihlColor.stihlOrangeDark};
  }

  &:disabled {
    color: ${stihlColor.black};
    background-color: ${stihlColor.greyMid};
  }
`;

const StihlButtonPrimaryLoading = (props: LoadingButtonProps): JSX.Element => {
  return <StyledLoadingButton variant="contained" color="primary" {...props} />;
};

export default StihlButtonPrimaryLoading;
