import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimary from '../../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import StihlIconRain from '../../../../../../base/stihl-material-ui/icons/stihl-icon-rain';
import StihlIconSun from '../../../../../../base/stihl-material-ui/icons/stihl-icon-sun';
import StihlIconTakeOff from '../../../../../../base/stihl-material-ui/icons/stihl-icon-takeoff';
import type {
  CardField,
  RangeFieldValue,
  RangeOption,
} from '../../../../model';
import { CardFieldType } from '../../../../model';
import ExitModal from '../../../enduser-app-integration/exit-modal';
import type { CardComponentProps } from '../card-component';
import {
  CardHeader,
  ContentWrapper,
  StyledCardContent,
} from '../card-component';
import renderSwitch from '../card-utils';

const rainSensorSensitivityValues: RangeOption[] = [
  {
    value: 0,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.anyWeather',
  },
  {
    value: 0.25,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.veryWet',
  },
  {
    value: 0.5,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.wet',
  },
  {
    value: 0.75,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.aLittleWet',
  },
  { value: 1, label: 'deviceDetails.settings.rainSensorSensitivityValues.dry' },
];

function getMappedData(field: CardField): CardField {
  switch (field.title) {
    case 'deviceDetails.settings.cuttingHeight': {
      const value = `${Math.round((field.value as number) * 1000)} mm`;
      const type = CardFieldType.Text;
      return { ...field, value, type };
    }
    case 'deviceDetails.settings.defaultManualMowingDuration': {
      const value = `${new Date((field.value as number) * 1000)
        .toISOString()
        .slice(11, 16)} h`;
      const type = CardFieldType.Text;
      return { ...field, value, type };
    }
    case 'deviceDetails.settings.rainSensorSensitivity': {
      const value: RangeFieldValue = {
        currentValue: field.value as number,
        rangeOptions: rainSensorSensitivityValues,
        minIcon: <StihlIconRain />,
        maxIcon: <StihlIconSun />,
      };
      const type = CardFieldType.Range;
      return { ...field, value, type };
    }
    default: {
      return field;
    }
  }
}

const SettingsCard = ({ meta, cardData }: CardComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  function handleModalOpen(): void {
    setModalOpen(true);
  }

  function handleClose(): void {
    setModalOpen(false);
  }

  return cardData.cardFields.length > 0 ? (
    <MuiCard data-testid="settingsCard">
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
        {Boolean(meta?.hasMyiMowAppAccess) && (
          <StihlButtonPrimary
            data-testid="imowAppAnchorButton"
            onClick={handleModalOpen}
          >
            <StihlIconTakeOff />
            {t('jumpToApp.iMowApp')}
          </StihlButtonPrimary>
        )}

        <ExitModal
          open={isModalOpen}
          onClose={handleClose}
          deviceId={meta?.deviceId}
          deviceModel={meta?.deviceModel}
        />
      </CardHeader>
      <StyledCardContent>
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>
            {renderSwitch(getMappedData(data))}
          </ContentWrapper>
        ))}
      </StyledCardContent>
    </MuiCard>
  ) : (
    <> </>
  );
};
export default SettingsCard;
