import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import type { UseQueryResult } from '@tanstack/react-query';
import type { Dispatch, SetStateAction, JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormattingParamsDefault } from '../../../../base/date-formatting/date-formatting';
import StihlButtonPrimaryLoading from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import StihlCheckbox from '../../../../base/stihl-material-ui/components/stihl-checkbox/stihl-checkbox';
import StihlTableHeader from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import type { Order } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import { getComparator } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlTooltipSmall from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-small';
import StihlIconArrowDown from '../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconBin from '../../../../base/stihl-material-ui/icons/stihl-icon-bin';
import StihlIconRefresh from '../../../../base/stihl-material-ui/icons/stihl-icon-refresh';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import Pagination from '../../../app-shell/ui/pagination/pagination';
import type {
  CustomerManagementCustomer,
  CustomerManagementEquipment,
  CustomerManagementColumns,
  CustomerManagementHeadCell,
} from '../../model/customer-account.model';
import CustomerDetailsTable from './customer-details-table';
// eslint-disable-next-line import/max-dependencies
import { RemoveCustomerModal } from './remove-customer-modal';

const StyledArrowIcon = styled(StihlIconArrowDown)`
  transform: rotate(180deg);
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-block-end: 1px solid ${stihlColor.greyMid};
`;

const StyledTableCell = styled(TableCell)<{ open: boolean }>`
  border-block-end: ${(props) => (props.open ? '' : '0px')};
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &:hover {
    rect {
      fill: ${stihlColor.stihlOrangeDark};
    }
  }
`;

const StyledLoadingButton = styled(StihlButtonPrimaryLoading)`
  min-inline-size: 40px;
  block-size: 40px;
  padding: 0;
  border-radius: 50%;
`;

const StyledFlexAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

// eslint-disable-next-line max-lines-per-function
const Row = ({
  row,
  customerDeviceDetails,
  isItemSelected,
  selected,
  setSelected,
  selectedCallback,
}: {
  row: CustomerManagementCustomer;
  customerDeviceDetails: CustomerManagementEquipment[];
  isItemSelected: boolean;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  selectedCallback: (serialNumber: string[], wasSelected: boolean) => void;
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const [isHoveredAction, setIsHoveredAction] = useState(false);
  const { t } = useTranslation();

  function handleClick(): void {
    const customerId = row.id;
    const selectedIndex = selected.indexOf(customerId);
    let newSelected: string[] = [];

    /* eslint-disable unicorn/prefer-spread */
    switch (selectedIndex) {
      case -1: {
        newSelected = newSelected.concat(selected, customerId);
        selectedCallback([customerId], true);
        break;
      }
      case 0: {
        newSelected = newSelected.concat(selected.slice(1));
        selectedCallback([customerId], false);
        break;
      }
      case selected.length - 1: {
        newSelected = newSelected.concat(selected.slice(0, -1));
        selectedCallback([customerId], false);
        break;
      }
      default: {
        if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
          selectedCallback([customerId], false);
        }
      }
    }
    /* eslint-enable unicorn/prefer-spread */
    setSelected(newSelected);
  }

  function handleOnOpen(event: React.ChangeEvent<unknown>): void {
    event.preventDefault();
    event.stopPropagation();
    setOpen(!open);
  }

  function onHoverAction(): void {
    setIsHoveredAction(true);
  }
  function onStopHoverAction(): void {
    setIsHoveredAction(false);
  }

  return (
    <>
      <TableRow
        hover={!isHoveredAction}
        onClick={handleClick}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        data-testid="customerRow"
        sx={{
          '&.Mui-selected': {
            backgroundColor: `${stihlColor.greyLight}`,
          },
          '&.MuiTableRow-root.Mui-selected:hover': {
            backgroundColor: isHoveredAction
              ? stihlColor.greyLight
              : stihlColor.greyMid,
          },
        }}
      >
        <TableCell padding="checkbox" sx={{ paddingLeft: '1.75rem' }}>
          <StihlCheckbox onClick={handleClick} checked={isItemSelected} />
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.lastName}
        </TableCell>
        <TableCell align="left">{row.firstName}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{t(row.contactBusinessTypeCategory)}</TableCell>
        <TableCell align="left">
          {row.isAccountValid
            ? t('customerManagement.verified')
            : row.isAccountValid === false
              ? t('customerManagement.unverified')
              : t('customerManagement.unknown')}
        </TableCell>
        <TableCell>
          {customerDeviceDetails.length > 0 && (
            <StihlTooltipSmall
              title={
                open
                  ? t('customerManagement.hideDevices')
                  : t('customerManagement.showDevices')
              }
            >
              <IconButton
                data-testid="openCustomerRowButton"
                onClick={handleOnOpen}
                onMouseOver={onHoverAction}
                onMouseOut={onStopHoverAction}
              >
                {open ? <StyledArrowIcon /> : <StihlIconArrowDown />}
              </IconButton>
            </StihlTooltipSmall>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          open={open}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {customerDeviceDetails.length > 0 && (
              <CustomerDetailsTable
                customerId={row.id}
                customerDeviceDetails={customerDeviceDetails}
                customerName={`${row.firstName} ${row.lastName}`}
              />
            )}
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export type EnhancedTableToolbarProps = {
  selectedRowsNumber: number;
  selected: string[];
  customerManagementCustomersQueryResult: UseQueryResult<
    CustomerManagementCustomer[],
    Error
  >;
};

const EnhancedTableToolbar = ({
  selectedRowsNumber,
  selected,
  customerManagementCustomersQueryResult,
}: EnhancedTableToolbarProps): JSX.Element => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  function handleModalOpen(): void {
    setOpenModal(true);
  }

  const lastUpdatedTimeStamp =
    customerManagementCustomersQueryResult.dataUpdatedAt;
  const lastUpdated = new Date(lastUpdatedTimeStamp);

  function handleRefetch(): void {
    void customerManagementCustomersQueryResult.refetch();
  }

  return (
    <StyledToolbar>
      {selectedRowsNumber > 0 ? (
        <Typography data-testid="selectedCount">
          {selectedRowsNumber} {t('selected')}
        </Typography>
      ) : (
        <Typography>
          <b>{t('customerManagement.customers')}</b>
        </Typography>
      )}
      {selectedRowsNumber === 0 &&
        (customerManagementCustomersQueryResult.isRefetching ? (
          <StyledLoadingButton loading />
        ) : (
          <StyledFlexAligner>
            <Typography variant="caption" data-testid="customersLastUpdated">
              {t('customerManagement.customersLastUpdated', {
                lastUpdated,
                formatParams: {
                  lastUpdated: dateTimeFormattingParamsDefault,
                },
              })}
            </Typography>

            <StihlTooltip
              title={t('customerManagement.refreshTooltip')}
              placement="left"
            >
              <StyledIconButton>
                <StihlIconRefresh onClick={handleRefetch} />
              </StyledIconButton>
            </StihlTooltip>
          </StyledFlexAligner>
        ))}
      {selectedRowsNumber > 0 && (
        <StihlTooltipSmall title={t('customerManagement.removeCustomer')}>
          <IconButton
            data-testid="removeCustomerButton"
            onClick={handleModalOpen}
          >
            <StihlIconBin />
          </IconButton>
        </StihlTooltipSmall>
      )}
      <RemoveCustomerModal
        customersCounter={selectedRowsNumber}
        customersToBeRemoved={selected}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </StyledToolbar>
  );
};

type CustomerManagementTableHeaderProps = {
  order: 'asc' | 'desc';
  orderBy: keyof CustomerManagementColumns;
  setOrder: Dispatch<SetStateAction<Order>>;
  setOrderBy: Dispatch<SetStateAction<keyof CustomerManagementColumns>>;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numberSelected: number;
  rowCount: number;
};

const CustomerManagementTableHeader = ({
  order,
  orderBy,
  setOrder,
  setOrderBy,
  onSelectAllClick,
  numberSelected,
  rowCount,
}: CustomerManagementTableHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  const headCells: CustomerManagementHeadCell[] = [
    {
      id: 'lastName',
      label: t('customerManagement.lastName'),
      isSortable: true,
    },
    {
      id: 'firstName',
      label: t('customerManagement.firstName'),
      isSortable: true,
    },

    {
      id: 'email',
      label: t('customerManagement.email'),
      isSortable: true,
    },
    {
      id: 'contactBusinessTypeCategory',
      label: t('customerManagement.type'),
      isSortable: true,
    },
    {
      id: 'isAccountValid',
      label: t('Status'),
      isSortable: true,
    },
  ];

  return (
    <StihlTableHeader<CustomerManagementColumns>
      order={order}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
      onSelectAllClick={onSelectAllClick}
      numberSelected={numberSelected}
      rowCount={rowCount}
      headCells={headCells}
      shouldHaveCheckbox
      shouldHaveLastColumnEmpty
    />
  );
};

export type CustomerManagementTableProps = {
  customerManagementCustomers: CustomerManagementCustomer[];
  customerManagementCustomersQueryResult: UseQueryResult<
    CustomerManagementCustomer[],
    Error
  >;
};

const CustomerManagementTable = ({
  customerManagementCustomers,
  customerManagementCustomersQueryResult,
}: CustomerManagementTableProps): JSX.Element => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof CustomerManagementColumns>('lastName');
  const [selectedRowsNumber, setSelectedRowsNumber] = useState<number>(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Set<string>>(
    new Set(),
  );
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  function handleSelectClick(ids: string[], wasSelected: boolean): void {
    for (const id of ids) {
      if (wasSelected) {
        selectedCustomers.add(id);
      } else {
        selectedCustomers.delete(id);
      }
    }
    setSelectedRowsNumber(selectedCustomers.size);
  }

  function handleSelectAllClick(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const newSelectedCustomers = customerManagementCustomers.map((n) => n.id);
    if (event.target.checked) {
      setSelected(newSelectedCustomers);
      handleSelectClick(newSelectedCustomers, true);
      return;
    }
    setSelected([]);
    setSelectedCustomers(new Set());
    handleSelectClick(newSelectedCustomers, false);
  }

  const isSelected = (id: string): boolean => selected.includes(id);

  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  return (
    <>
      <Card style={{ marginTop: '10px' }}>
        {customerManagementCustomersQueryResult.data && (
          <EnhancedTableToolbar
            selectedRowsNumber={selectedRowsNumber}
            selected={selected}
            customerManagementCustomersQueryResult={
              customerManagementCustomersQueryResult
            }
          />
        )}
        <TableContainer style={{ overflowY: 'hidden' }}>
          <Table data-testid="customerManagementTable">
            <CustomerManagementTableHeader
              order={order}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setOrder={setOrder}
              onSelectAllClick={handleSelectAllClick}
              rowCount={customerManagementCustomers.length}
              numberSelected={selected.length}
            />
            <TableBody>
              {[...customerManagementCustomers]
                .sort(getComparator(order, orderBy))
                .slice(firstToDisplay, lastToDisplay)
                .map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      customerDeviceDetails={row.equipments}
                      setSelected={setSelected}
                      selected={selected}
                      isItemSelected={isItemSelected}
                      selectedCallback={handleSelectClick}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {customerManagementCustomers.length > 0 && (
        <Pagination
          numberOfResults={customerManagementCustomers.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('customerManagement.customers')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      )}
    </>
  );
};

export default CustomerManagementTable;
