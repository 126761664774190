import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconXFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { CardComponentProps } from '../card-component';
import {
  CardHeader,
  ContentWrapper,
  StyledCardContent,
} from '../card-component';
import renderSwitch from '../card-utils';

export const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const FleetmanagementCard = ({
  meta,
  cardData,
}: CardComponentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MuiCard data-testid="fleetmanagementCard">
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
      </CardHeader>
      <StyledCardContent>
        {meta?.hasFleetError && (
          <IconAligner>
            <StihlIconXFilled color={stihlColor.red} />
            <Typography>{t('deviceDetails.noFleetData')}</Typography>
          </IconAligner>
        )}
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>{renderSwitch(data)}</ContentWrapper>
        ))}
      </StyledCardContent>
    </MuiCard>
  );
};
export default FleetmanagementCard;
