import type { DeviceModel } from '../../device/model';

export type ActivationStateRequestBody = {
  deviceId: string;
  deviceModel: DeviceModel;
  activationState: ActivationState;
};

export enum ActivationState {
  Enabled = 'enabled',
  Disabled = 'disabled',
}
