import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlTextField from '../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type SoftwareVersionFilterProps = {
  availableSwVersions: string[];
  softwareVersionCallback: (filterValue: string) => void;
  shouldReset: boolean;
};

const SoftwareVersionFilter = ({
  softwareVersionCallback,
  availableSwVersions,
  shouldReset,
}: SoftwareVersionFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const [softwareVersion, setSoftwareVersion] = useState<string>('');

  useEffect(() => {
    if (shouldReset) {
      setSoftwareVersion('');
    }
  }, [shouldReset]);

  function handleSoftwareVersionChange(
    event: ChangeEvent<HTMLInputElement>,
  ): void {
    setSoftwareVersion(event.target.value);
    softwareVersionCallback(event.target.value);
  }

  return (
    <StyledWrapper>
      <InputLabel
        variant="standard"
        htmlFor="softwareVersion"
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('filterLabel.currentSoftwareVersion')}
      </InputLabel>
      <StihlSelect
        id="softwareVersion"
        value={
          availableSwVersions.includes(softwareVersion) ? softwareVersion : ''
        }
        onChange={handleSoftwareVersionChange}
        fullWidth
        data-testid="swVersionSelector"
      >
        <MenuItem disabled value="">
          {t('filterLabel.currentSoftwareVersionSelectPlaceholder')}
        </MenuItem>
        {availableSwVersions.map((stihlSoftwareVersion) => (
          <MenuItem key={stihlSoftwareVersion} value={stihlSoftwareVersion}>
            {stihlSoftwareVersion}
          </MenuItem>
        ))}
      </StihlSelect>
      <StihlTextField
        onChange={handleSoftwareVersionChange}
        value={softwareVersion}
        // eslint-disable-next-line no-secrets/no-secrets
        placeholder={t('filterLabel.currentSoftwareVersionInputPlaceholder')}
      />
    </StyledWrapper>
  );
};

export default SoftwareVersionFilter;
