import semver from 'semver';

export type Order = 'asc' | 'desc';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  // value can be undefined
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!valueA) {
    return -1;
  }
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!valueB) {
    return 1;
  }
  if (orderBy === 'softwareVersion') {
    try {
      return -semver.compare(String(valueA), String(valueB));
    } catch {
      return -String(valueA).localeCompare(String(valueB), undefined, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        numeric: true,
      });
    }
  }

  if (orderBy === 'creationDate') {
    try {
      return new Date(String(valueA)).getTime() <
        new Date(String(valueB)).getTime()
        ? 1
        : -1;
    } catch {
      return -String(valueA).localeCompare(String(valueB), undefined, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        numeric: true,
      });
    }
  }

  return -String(valueA).localeCompare(String(valueB), undefined, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    numeric: true,
  });
}

// Error Value is set to isSortable = false, but needs to be mentioned here for completeness
export function getComparator<TSortCriteria extends string | number>(
  order: Order,
  orderBy: TSortCriteria,
): (
  a: {
    [key in TSortCriteria]: string | boolean | null | undefined;
  },
  b: {
    [key in TSortCriteria]: string | boolean | null | undefined;
  },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
