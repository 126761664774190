import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import type { TextFieldProps } from '@mui/material/TextField';
import type { FC } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

type StihltextFieldAdditionalProps = { isHalfWidth?: boolean };

export type StihlTextFieldProps = TextFieldProps &
  StihltextFieldAdditionalProps;

function getDisplayValue(props: StihlTextFieldProps): string | undefined {
  if (props.isHalfWidth) {
    return 'block';
  }
  if (props.hidden) {
    return 'none';
  }
  return props.style?.display;
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isHalfWidth',
})<StihltextFieldAdditionalProps>`
  &.MuiFormControl-marginDense {
    margin-block-start: 0.25rem;
  }

  > .MuiInputBase-root {
    inline-size: 100%;
    block-size: ${(props) => (props.size === 'small' ? '2rem' : '3rem')};
    line-height: 1rem;
    background-color: ${stihlColor.white};
  }

  /* stylelint-disable */
  > .MuiFormHelperText-root.Mui-error {
    font-size: 0.875rem;
  }
  inline-size: ${(props) =>
    props.isHalfWidth ? 'calc(50% - 0.5rem)' : props.style?.width};
  display: ${(props) => getDisplayValue(props)};

  input:read-only {
    color: ${stihlColor.greyBase};
  }
`;

const StihlTextField: FC<StihlTextFieldProps> = ({ InputProps, ...props }) => {
  return (
    <StyledTextField
      variant="outlined"
      margin="dense"
      type="text"
      color="secondary"
      {...props}
      InputProps={{
        sx: {
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            boxSizing: 'border-box',
          },
          '& .Mui-disabled': {
            backgroundColor: `${stihlColor.greyLight}`,
          },
        },
        ...InputProps,
      }}
    />
  );
};

export default StihlTextField;
