import { countriesArray } from '../../app-shell/service/countries';
import { languagesArray } from '../../app-shell/service/languages';

// change termsofuse currentVersion here
export const currentTermsVersion = '202212';

// change privacynotes currentVersion here
export const currentPrivacyNotesVersion = '202212';

const availableCountries = new Set([
  'Albania',
  'Australia',
  'Austria',
  'Belgium',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Canada',
  'Canada',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'New Zealand',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Switzerland',
  'Switzerland',
  'United Kingdom',
  'United States of America',
]);

export function generateFileName(
  documentType: string,
  // country with language definition, typically looks like "Norway (NB)"
  documentCountry: string,
): string {
  const file = `${documentType}${currentTermsVersion}${documentCountry
    .split(' (')[1]
    .slice(0, 2)
    .toLocaleLowerCase()}${documentCountry.split(' (')[0].replaceAll(' ', '')}`;

  return file;
}

const filteredCountries = countriesArray.filter((country) =>
  availableCountries.has(country.countryName),
);

export const selectableCountries = filteredCountries
  .flatMap((country) =>
    languagesArray
      .filter((language) => language.isAllowed.includes(country.countryCode))
      .map(
        (allowedLanguage) =>
          `${country.countryName} (${allowedLanguage.languageCode
            .split('-')[0]
            .toUpperCase()})`,
      ),
  )
  .sort();
