/* eslint-disable complexity */
import type { JSX } from 'react';
import StihlIconBattery from '../../../../../base/stihl-material-ui/icons/stihl-icon-battery';
import StihlIconBlades from '../../../../../base/stihl-material-ui/icons/stihl-icon-blades';
import StihlIconBluetooth from '../../../../../base/stihl-material-ui/icons/stihl-icon-bluetooth';
import StihlIconDistance from '../../../../../base/stihl-material-ui/icons/stihl-icon-distance';
import StihlIconHeart from '../../../../../base/stihl-material-ui/icons/stihl-icon-heart';
import StihlIconKey from '../../../../../base/stihl-material-ui/icons/stihl-icon-key';
import StihlIconMower from '../../../../../base/stihl-material-ui/icons/stihl-icon-mower';
import StihlIconMowing from '../../../../../base/stihl-material-ui/icons/stihl-icon-mowing';
import StihlIconPlug from '../../../../../base/stihl-material-ui/icons/stihl-icon-plug';
import { CardFieldType, CardFieldIcon } from '../../../model';
import type {
  CardField,
  ErrorValue,
  JobValue,
  ServiceValue,
  StatusValue,
  DeviceResult,
  WarrantyValue,
  DateFieldValue,
  WifiAvailabilityData,
  RangeFieldValue,
} from '../../../model';
import AccessPinCodeText from './card-texts/access-pin-code-text';
import CardText from './card-texts/card-text';
import CellularConnectionText from './card-texts/cellular-connection-text';
import DateText from './card-texts/date-text';
import DemoModeText from './card-texts/demo-mode-text';
import DidToSemanticVersionText from './card-texts/did-to-semantic-version-text';
import ErrorText, { NoErrorText } from './card-texts/error-text';
import EthernetConnectionText from './card-texts/ethernet-connection-text';
import JobText from './card-texts/job-text';
import ListText from './card-texts/list-text';
import OperationsDataText from './card-texts/operations-data-text';
import PartOfOrganizationText from './card-texts/part-of-organization-text';
import PercentageText from './card-texts/percentage-text';
import RangeText from './card-texts/range-text';
import ServiceText from './card-texts/service-text';
import StatusText from './card-texts/status-text';
import SubtitleText from './card-texts/subtitle-text';
import TeamMowerText from './card-texts/team-mower-text';
import TextlineText from './card-texts/textline-text';
import ToggleText from './card-texts/toggle-text';
import ValidityText from './card-texts/validity-text';
import WarrantyDataText from './card-texts/warranty-data-text';
import WifiConnectionText from './card-texts/wifi-connection-text';
import GpsPositionUpdated from './gps-card/gps-position-updated';
// eslint-disable-next-line import/max-dependencies
import MultiDeviceSearchResult from './multi-device-search-result';

function getIcon(icon?: CardFieldIcon): JSX.Element | undefined {
  switch (icon) {
    case CardFieldIcon.Heart: {
      return <StihlIconHeart />;
    }
    case CardFieldIcon.StateOfCharge: {
      return <StihlIconBattery />;
    }
    case CardFieldIcon.Plug: {
      return <StihlIconPlug />;
    }
    case CardFieldIcon.Blades: {
      return <StihlIconBlades />;
    }
    case CardFieldIcon.Imow: {
      return <StihlIconMower />;
    }
    case CardFieldIcon.MowingState: {
      return <StihlIconMowing />;
    }
    case CardFieldIcon.Distance: {
      return <StihlIconDistance />;
    }
    case CardFieldIcon.Key: {
      return <StihlIconKey />;
    }
    case CardFieldIcon.Bluetooth: {
      return <StihlIconBluetooth />;
    }
    default: {
      return undefined;
    }
  }
}

// eslint-disable-next-line max-lines-per-function
export default function renderSwitch(
  fieldValue: CardField,
): JSX.Element | null {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (fieldValue.type) {
    case CardFieldType.Text:
    case CardFieldType.DeviceCurrentArea:
    case CardFieldType.Boolean: {
      return (
        <CardText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as string}
          icon={getIcon(fieldValue.icon)}
        />
      );
    }
    case CardFieldType.Percentage: {
      return (
        <PercentageText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as number}
          icon={getIcon(fieldValue.icon)}
        />
      );
    }
    case CardFieldType.Date: {
      return (
        <DateText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as DateFieldValue}
        />
      );
    }

    case CardFieldType.Number: {
      return (
        <CardText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as number}
          icon={getIcon(fieldValue.icon)}
        />
      );
    }

    case CardFieldType.DeviceError: {
      return (fieldValue.value as ErrorValue[]).length === 0 ? (
        <NoErrorText
          key={fieldValue.title}
          name={fieldValue.title}
          value="deviceDetails.noErrorsActive"
        />
      ) : (
        <ErrorText
          name={fieldValue.title}
          value={fieldValue.value as ErrorValue[]}
        />
      );
    }

    case CardFieldType.DeviceServiceRequired: {
      return (fieldValue.value as ServiceValue[]).length === 0 ? (
        <CardText
          key={fieldValue.title}
          name={fieldValue.title}
          value="deviceDetails.noServicesRequired"
          icon={getIcon(fieldValue.icon)}
        />
      ) : (
        <ServiceText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as ServiceValue[]}
        />
      );
    }

    case CardFieldType.DeviceJobState: {
      return (
        <JobText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as JobValue}
        />
      );
    }

    case CardFieldType.DeviceOverallState: {
      return (
        <StatusText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as StatusValue}
        />
      );
    }

    case CardFieldType.DeviceGpsPositionValid: {
      return (
        <ValidityText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as boolean}
        />
      );
    }

    case CardFieldType.DeviceGpsPositionUpdated: {
      return (
        <GpsPositionUpdated
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as number}
        />
      );
    }

    case CardFieldType.DeviceWifiConnection: {
      return (
        <WifiConnectionText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as boolean}
        />
      );
    }

    case CardFieldType.DeviceCellularConnection: {
      return (
        <CellularConnectionText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as boolean}
        />
      );
    }

    case CardFieldType.DeviceEthernetConnection: {
      return (
        <EthernetConnectionText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as boolean}
        />
      );
    }

    case CardFieldType.DeviceDemoMode: {
      return (fieldValue.value as boolean) ? (
        <DemoModeText key={fieldValue.title} name={fieldValue.title} />
      ) : null;
    }

    case CardFieldType.Subtitle: {
      return fieldValue.value === '' ? (
        <SubtitleText key={fieldValue.title} name={fieldValue.title} />
      ) : (
        <SubtitleText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as boolean}
        />
      );
    }
    case CardFieldType.SearchResult: {
      return (
        <MultiDeviceSearchResult
          key={fieldValue.title}
          value={fieldValue.value as { results: DeviceResult[] }}
        />
      );
    }
    case CardFieldType.DeviceStatusError: {
      return (
        <TextlineText
          key={fieldValue.title}
          value={fieldValue.value as string}
        />
      );
    }
    case CardFieldType.Toggle: {
      return (
        <ToggleText
          key={fieldValue.title}
          title={fieldValue.title}
          value={fieldValue.value as boolean}
          icon={getIcon(fieldValue.icon)}
        />
      );
    }
    case CardFieldType.TeamMower: {
      return (
        <TeamMowerText
          key={fieldValue.title}
          title={fieldValue.title}
          value={fieldValue.value as boolean}
        />
      );
    }
    case CardFieldType.AccessPinCode: {
      return (
        <AccessPinCodeText
          key={fieldValue.title}
          title={fieldValue.title}
          value={fieldValue.value as boolean}
          icon={getIcon(fieldValue.icon)}
        />
      );
    }
    case CardFieldType.FleetManaged:
    case CardFieldType.PartOfOrganization: {
      return (
        <PartOfOrganizationText
          key={fieldValue.title}
          title={fieldValue.title}
          value={fieldValue.value as boolean}
          icon={getIcon(fieldValue.icon)}
        />
      );
    }
    case CardFieldType.DeviceTotalWorkingSeconds:
    case CardFieldType.DeviceTotalWorkingTimeOfCurrentCuttingKnifes:
    case CardFieldType.DeviceTotalDrivenDistance:
    case CardFieldType.DeviceTotalStartedMowingJobs:
    case CardFieldType.DeviceTotalWorkingTimeOfCuttingMotor: {
      return (
        <OperationsDataText
          key={fieldValue.title}
          name={fieldValue.title}
          icon={getIcon(fieldValue.icon)}
          value={fieldValue.value as number}
        />
      );
    }
    case CardFieldType.DeviceWarrantyData: {
      return (
        <WarrantyDataText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as WarrantyValue}
        />
      );
    }
    case CardFieldType.DeviceWifiAvailability: {
      return (
        <ListText
          key={fieldValue.title}
          name={fieldValue.title}
          value={(fieldValue.value as WifiAvailabilityData[]).map(
            (wifi) => wifi.ssid,
          )}
        />
      );
    }
    case CardFieldType.Range: {
      return (
        <RangeText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as RangeFieldValue}
        />
      );
    }
    case CardFieldType.DeviceEmbeddedSoftwareHmi:
    case CardFieldType.DeviceEmbeddedSoftwarePowerPcb:
    case CardFieldType.DeviceEmbeddedSoftwareDocking:
    case CardFieldType.DeviceEmbeddedSoftwareSignalPcb:
    case CardFieldType.SoftwarePackageVersionInitial: {
      return (
        <DidToSemanticVersionText
          key={fieldValue.title}
          name={fieldValue.title}
          value={fieldValue.value as string}
        />
      );
    }
    default: {
      return <> </>;
    }
  }
}

/* eslint-enable complexity */
