import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTextField from '../../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { getHelperText } from '../utils/form-helper.utils';

const VerificationCodeWrapper = styled.div`
  padding-inline-start: 3rem;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${stihlColor.stihlOrange};
  }
`;

export type DeviceFields = {
  serialNumber: string;
  verificationCode: string;
  isVerificationCodeEnabled: boolean;
  isConnectingDevice?: boolean;
};

const CustomerAccountRegistrationStepTwo = ({
  isExpanded,
  setIsStepTwoValidated,
}: {
  isExpanded: boolean;
  setIsStepTwoValidated: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<DeviceFields>();

  function handleToggleVerificationCodeEnabled(): void {
    void setFieldValue('verificationCode', '');
    void setFieldValue(
      'isVerificationCodeEnabled',
      !values.isVerificationCodeEnabled,
    );
  }

  useEffect(() => {
    if (Boolean(errors.serialNumber) || Boolean(errors.verificationCode)) {
      setIsStepTwoValidated(false);
    } else {
      setIsStepTwoValidated(true);
    }
  }, [errors, setIsStepTwoValidated]);

  useEffect(() => {
    void setFieldValue('isConnectingDevice', isExpanded);
  }, [isExpanded, setFieldValue]);

  return (
    <>
      <InputLabel htmlFor="serialNumber" variant="standard">
        {`${t('customerRegistration.serialNumber')}`}
      </InputLabel>
      <StihlTextField
        inputProps={{ 'data-testid': 'serialNumber' }}
        id="serialNumber"
        fullWidth
        error={touched.serialNumber && Boolean(errors.serialNumber)}
        helperText={getHelperText(
          Boolean(touched.serialNumber),
          errors.serialNumber,
        )}
        value={values.serialNumber}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <InputLabel htmlFor="isVerificationCodeEnabled" variant="standard">
        <StyledCheckbox
          id="isVerificationCodeEnabled"
          onChange={handleToggleVerificationCodeEnabled}
          checked={values.isVerificationCodeEnabled}
          inputProps={{
            // @ts-expect-error Mui TypeScript issue https://github.com/mui-org/material-ui/issues/20160
            'data-testid': 'verificationCodeCheckbox',
          }}
        />
        {t('customerRegistration.deviceIsAMower')}
      </InputLabel>

      {values.isVerificationCodeEnabled && (
        <VerificationCodeWrapper>
          <Typography gutterBottom>
            {t('customerRegistration.verificationCode')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('customerRegistration.verificationCodeMowerMessage')}
          </Typography>
          <StihlTextField
            label=""
            inputProps={{
              'data-testid': 'verificationCode',
              maxLength: 2,
            }}
            id="verificationCode"
            error={touched.verificationCode && Boolean(errors.verificationCode)}
            helperText={getHelperText(
              Boolean(touched.verificationCode),
              errors.verificationCode,
            )}
            value={values.verificationCode}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="00"
          />
        </VerificationCodeWrapper>
      )}
    </>
  );
};

export default CustomerAccountRegistrationStepTwo;
