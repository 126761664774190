import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import type { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../base/date-formatting/date-formatting';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlTableHeader from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import { StihlTableRow } from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-row';
import { getComparator } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconBin from '../../../../base/stihl-material-ui/icons/stihl-icon-bin';
import StihlIconWarning from '../../../../base/stihl-material-ui/icons/stihl-icon-warning';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { DeviceModel } from '../../../device/model';
import type {
  MassAssignmentsColumns,
  MassAssignmentsHeadCell,
  SwMassAssignment,
} from '../../model/update-management.model';
// eslint-disable-next-line import/max-dependencies
import { useInvalidateMassAssignment } from '../../service/device-management-service/device-management-service';

const StyledTableCell = styled(TableCell)<
  TableCellProps & { isInvalid?: boolean }
>`
  color: ${(props) => (props.isInvalid ? stihlColor.greyBase : '')};
`;

const ConfirmationHint = styled(Typography)`
  display: flex;
  gap: 0.5rem;
  margin-block-start: 1rem;
`;

// eslint-disable-next-line max-lines-per-function
export const MassAssignmentsTable = ({
  massAssignments,
  deviceModel,
  paginationFirstToDisplay,
  paginationLastToDisplay,
}: {
  massAssignments: SwMassAssignment[];
  deviceModel: DeviceModel;
  paginationFirstToDisplay: number;
  paginationLastToDisplay: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateString } = useDateFormatting();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof MassAssignmentsColumns>('creationDate');
  const [openModal, setOpenModal] = useState(false);
  const [massAssignmentInModal, setMassAssignmentInModal] = useState<
    SwMassAssignment | undefined
  >();
  const [_, setAlert] = useAlertStore();

  const invalidateMassAssignment = useInvalidateMassAssignment(deviceModel);

  const dateFormattedMassAssignments: SwMassAssignment[] = useMemo(() => {
    return massAssignments.map((massAssignment) => {
      return {
        ...massAssignment,
        creationDate: toLocaleDateString(new Date(massAssignment.creationDate)),
      };
    });
  }, [massAssignments, toLocaleDateString]);

  const headCells: MassAssignmentsHeadCell[] = [
    {
      id: 'creationDate',
      label: t('massAssignments.creationDate'),
      isSortable: true,
    },
    {
      id: 'deviceModel',
      label: t('massAssignments.deviceModel'),
      isSortable: false,
    },
    {
      id: 'iotDeviceFlag',
      label: t('massAssignments.iotDeviceFlag'),
      isSortable: true,
    },
    {
      id: 'iotDeviceCountry',
      label: t('massAssignments.iotDeviceCountry'),
      isSortable: true,
    },
    {
      id: 'installedSwVersion',
      label: t('massAssignments.installedSwVersion'),
      isSortable: true,
    },
    {
      id: 'targetSwVersion',
      label: t('massAssignments.targetSwVersion'),
      isSortable: true,
    },
  ];

  function handleInvalidate(): void {
    invalidateMassAssignment.mutate(massAssignmentInModal?.assignmentId ?? 0, {
      onSuccess: () => {
        setAlert({
          isOpen: true,
          message: 'massAssignments.invalidateSuccess',
          severity: 'success',
        });
      },
      onError: () => {
        setAlert({
          isOpen: true,
          message: 'massAssignments.invalidateError',
          severity: 'error',
        });
      },
      onSettled: () => {
        handleClose();
      },
    });
  }

  function handleClose(): void {
    setOpenModal(false);
    setMassAssignmentInModal(undefined);
  }

  function handleModalOpen(massAssignment: SwMassAssignment): void {
    setOpenModal(true);
    setMassAssignmentInModal(massAssignment);
  }

  return (
    <>
      <TableContainer>
        <Table>
          <StihlTableHeader<MassAssignmentsColumns>
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            headCells={headCells}
            shouldHaveCheckbox={false}
            shouldHaveLastColumnEmpty
          />
          <TableBody>
            {[...dateFormattedMassAssignments]
              .sort(getComparator(order, orderBy))
              .slice(paginationFirstToDisplay, paginationLastToDisplay)
              .map((row) => {
                return (
                  <StihlTableRow
                    key={row.assignmentId}
                    id={`${row.assignmentId}`}
                    shouldHaveCheckbox={false}
                  >
                    <StyledTableCell isInvalid={!row.isValid} align="left">
                      {t(row.creationDate)}
                    </StyledTableCell>
                    <StyledTableCell isInvalid={!row.isValid} align="left">
                      {row.deviceModel}
                    </StyledTableCell>
                    <StyledTableCell isInvalid={!row.isValid} align="left">
                      {row.iotDeviceFlag}
                    </StyledTableCell>
                    <StyledTableCell isInvalid={!row.isValid} align="left">
                      {row.iotDeviceCountry}
                    </StyledTableCell>
                    <StyledTableCell isInvalid={!row.isValid} align="left">
                      {row.installedSwVersion}
                    </StyledTableCell>
                    <StyledTableCell isInvalid={!row.isValid} align="left">
                      {row.targetSwVersion}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.isValid && (
                        <IconButton
                          onClick={() => handleModalOpen(row)}
                          data-testid="deleteMassAssignment"
                        >
                          <StihlTooltip
                            title={t('massAssignments.deleteTooltip')}
                            placement="left"
                          >
                            <StihlIconBin />
                          </StihlTooltip>
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </StihlTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <StihlModal
        open={openModal}
        onClose={handleClose}
        title={t('massAssignments.confirmationTitle', {
          date: massAssignmentInModal?.creationDate,
        })}
        actionButtonText={t('massAssignments.confirmationButton')}
        handleActionClick={handleInvalidate}
        isLoading={invalidateMassAssignment.isPending}
      >
        <>
          <Typography>{t('massAssignments.confirmationText')}</Typography>
          <ConfirmationHint variant="h5">
            <StihlIconWarning />
            {t('massAssignments.confirmationHint')}
          </ConfirmationHint>
        </>
      </StihlModal>
    </>
  );
};

export default MassAssignmentsTable;
