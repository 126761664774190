import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import type { FC, ReactNode } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

export type StihlTooltipProps = TooltipProps & {
  title: ReactNode;
  children: ReactNode;
};

const StihlTooltip: FC<StihlTooltipProps> = ({ children, title, ...props }) => {
  return (
    <Tooltip
      placement="right"
      arrow
      title={title}
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            ...props.componentsProps?.tooltip?.sx,
            padding: '1rem',
            fontSize: '1rem',
            color: `${stihlColor.black}`,
            backgroundColor: `${stihlColor.greyLight}`,
            boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            filter: 'drop-shadow(0px 1px 3px rgb(0, 0, 0, 0.3))',
          },
        },
        arrow: {
          sx: {
            color: `${stihlColor.greyLight}`,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
export default StihlTooltip;
