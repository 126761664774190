import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';

const TextlineText = ({ value }: { value: string }): JSX.Element => {
  const { t } = useTranslation();
  return <Typography style={{ paddingBottom: '1rem' }}>{t(value)}</Typography>;
};

export default TextlineText;
