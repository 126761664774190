/* eslint-disable max-lines-per-function */
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { StihlHelperText } from '../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import StihlTextField from '../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconBusiness from '../../../../base/stihl-material-ui/icons/stihl-icon-business';
import StihlIconClose from '../../../../base/stihl-material-ui/icons/stihl-icon-close';
import StihlIconKey from '../../../../base/stihl-material-ui/icons/stihl-icon-key';
import StihlIconUser from '../../../../base/stihl-material-ui/icons/stihl-icon-user';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type {
  CustomerListEntry,
  CustomerSearchObject,
} from '../../model/customer-account.model';
import { searchCustomers } from '../../service/customer-api-client/customer-api-client';

const StyledFlexBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`;

export type SearchCustomerBarProps = {
  setSelectedCustomerId: Dispatch<SetStateAction<string>>;
  customerSearchObject: CustomerSearchObject;
  setCustomerSearchObject: Dispatch<SetStateAction<CustomerSearchObject>>;
};

const SearchCustomerBar = ({
  setSelectedCustomerId,
  customerSearchObject,
  setCustomerSearchObject,
}: SearchCustomerBarProps): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [active, setActive] = useState(false);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [customers, setCustomers] = useState<CustomerListEntry[]>([]);
  const isLoading = open && customers.length === 0 && active;
  const [controller, setController] = useState<AbortController>();

  useEffect(() => {
    if (!open) {
      setCustomers([]);
      setActive(false);
      setHasSearchError(false);
      setHasSearched(false);
    }
    return () => {
      setCustomers([]);
      setActive(false);
      setHasSearchError(false);
      setHasSearched(false);
    };
  }, [open, setCustomers]);

  useEffect(() => {
    if (customerSearchObject.searchTerm === '') {
      setHasSearchError(false);
    }
  }, [customerSearchObject.searchTerm, setHasSearchError]);

  useEffect(() => {
    if (customerSearchObject.isAborted) {
      controller?.abort();
      return;
    }
    return () => {
      controller?.abort();
    };
  }, [controller, customerSearchObject.isAborted]);

  function handleSearch(): void {
    const abortController = new AbortController();
    setController(abortController);
    setHasSearched(true);
    setActive(true);
    setHasSearchError(false);
    searchCustomers(customerSearchObject, abortController.signal)
      .then((response) => {
        if (response.length === 0) {
          setActive(false);
        }
        return setCustomers(response);
      })
      .catch(() => {
        setHasSearchError(true);
        setActive(false);
      });
  }

  function handleSearchEdit(): void {
    setCustomerSearchObject({
      searchTerm: '',
      isAborted: true,
    });
    setCustomers([]);
  }

  const customNoOptionsNode = (): JSX.Element => {
    if (hasSearchError) {
      return <StihlHelperText text="searchErrors.customerSearch" />;
    }
    if (!hasSearched) {
      return <>{t('customerRegistrationModal.searchPrompt')}</>;
    }

    return <>{t('customerRegistrationModal.noOptionsText')}</>;
  };

  const getCustomerTypeIcon = (
    contactBusinessTypeCategory: string,
  ): JSX.Element => {
    switch (contactBusinessTypeCategory) {
      case 'customerManagement.contactType.private': {
        return <StihlIconKey color="greyBase" data-testid="keyIcon" />;
      }
      case 'customerManagement.contactType.business': {
        return <StihlIconBusiness data-testid="businessIcon" />;
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case 'customerManagement.contactType.other':
      default: {
        return (
          <StihlIconUser color={stihlColor.greyBase} data-testid="userIcon" />
        );
      }
    }
  };

  return (
    <Autocomplete
      data-testid="searchByNameOrEmail"
      fullWidth
      ListboxProps={{ style: { maxHeight: '16rem' } }}
      open={open}
      forcePopupIcon={false}
      clearIcon={false}
      inputValue={customerSearchObject.searchTerm}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={(_event, reason) => {
        if (reason !== 'blur') {
          setOpen(false);
        }
      }}
      onChange={(_, value) => {
        setSelectedCustomerId(value?.id ?? '');
        const firstName = value?.firstName ?? '';
        const lastName = value?.lastName ?? '';
        const email = value?.email ?? '';
        setCustomerSearchObject({
          searchTerm: `${firstName} ${lastName} ${email}`,
          isAborted: false,
        });
      }}
      filterOptions={(option) => option}
      noOptionsText={customNoOptionsNode()}
      loadingText={t('customerRegistrationModal.loadingText')}
      getOptionLabel={(customer) =>
        `${customer.firstName ?? ''} ${customer.lastName ?? ''}  ${
          customer.email ?? ''
        }`
      }
      options={customers}
      renderOption={(props, customer) => {
        return (
          <li {...props} key={customer.id} data-testid="optionsItem">
            <StyledFlexBox>
              {getCustomerTypeIcon(customer.contactBusinessTypeCategory)}
              {customer.firstName ?? ''} {customer.lastName ?? ''}
              <span
                style={{
                  color: `${stihlColor.greyBase}`,
                  marginLeft: '.25rem',
                }}
              >
                {customer.email ?? ''}
              </span>
            </StyledFlexBox>
          </li>
        );
      }}
      loading={isLoading}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSearch();
        }
      }}
      renderInput={(parameters) => (
        <StihlTextField
          sx={{
            '.MuiOutlinedInput-input.MuiInputBase-input': {
              boxSizing: 'border-box',
            },
            button: {
              order: 3, // order 3 means the search icon will appear after the clear icon which has an order of 2
            },

            '.MuiOutlinedInput-root.MuiInputBase-root': {
              padding: '0 0.5rem',
            },
          }}
          placeholder={t('customerDetails.customerSearchPlaceholder')}
          error={hasSearchError}
          {...parameters}
          onChange={(event) => {
            setCustomerSearchObject({
              searchTerm: event.target.value,
              isAborted: false,
            });
          }}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {customerSearchObject.searchTerm !== '' && (
                  <IconButton
                    style={{ padding: '6px' }}
                    size="large"
                    onClick={handleSearchEdit}
                    data-testid="resetButton"
                  >
                    <StihlIconClose color="stihlColor.greyBase" />
                  </IconButton>
                )}

                <IconButton
                  style={{ padding: '6px' }}
                  type="submit"
                  size="large"
                  onClick={handleSearch}
                  disabled={
                    customerSearchObject.searchTerm.length >= 30 ||
                    customerSearchObject.searchTerm.length === 0
                  }
                  data-testid="customerSearchPrompt"
                >
                  <SearchIcon />
                </IconButton>
                {parameters.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchCustomerBar;
/* eslint-enable max-lines-per-function */
