import styled from '@emotion/styled';
import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconInfo from '../../icons/stihl-icon-info';
import StihlIconValidFilled from '../../icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../icons/stihl-icon-x-filled';
import { stihlColor } from '../../theme/stihl-style-guide';

type Alert = {
  isOpen: boolean;
  severity: 'success' | 'warning' | 'error' | 'info';
  message: string;
};

type StihlSnackbarAlertProps = {
  alert: Alert;
  setAlert: (alert: Alert) => void;
};

const alertColor = (severity?: AlertColor): string => {
  switch (severity) {
    case 'error': {
      return stihlColor.redLight;
    }
    case 'warning': {
      return stihlColor.yellowLight;
    }
    case 'info': {
      return stihlColor.greyLight;
    }
    default: {
      return stihlColor.greenLight;
    }
  }
};

const StyledSnackbar = styled(Snackbar)`
  inset-block-start: 115px;
  inset-inline-end: 40px;
`;

export const StyledAlert = styled(Alert)`
  font-weight: 700;
  background-color: ${(props) => alertColor(props.severity)};
  pointer-events: visible;

  > .MuiAlert-action {
    padding: 1px 0 0 26px;
  }

  > .MuiAlert-icon {
    margin-inline-end: 1rem;
    padding: 9px 0;
  }
`;

const getIcon = (severity: AlertColor): ReactNode => {
  switch (severity) {
    case 'error':
    case 'warning': {
      return <StihlIconXFilled color={severity} />;
    }
    case 'info': {
      return <StihlIconInfo color={severity} />;
    }
    default: {
      return <StihlIconValidFilled color={severity} />;
    }
  }
};

export const StihlSnackbarAlert: FC<StihlSnackbarAlertProps> = ({
  alert,
  setAlert,
}) => {
  const { t } = useTranslation();

  function handleCloseAlert(_: unknown, reason?: string): void {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      isOpen: false,
      message: '',
      severity: 'success',
    });
  }

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={alert.isOpen}
      autoHideDuration={
        alert.severity === 'warning' || alert.severity === 'error' ? null : 4000
      }
      transitionDuration={0}
      onClose={handleCloseAlert}
    >
      <StyledAlert
        icon={getIcon(alert.severity)}
        severity={alert.severity}
        data-testid={`${alert.severity}Alert`}
        onClose={handleCloseAlert}
      >
        {t(alert.message)}
      </StyledAlert>
    </StyledSnackbar>
  );
};

export default StihlSnackbarAlert;
