/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { JSX, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChipType } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import {
  FilterParameterValue,
  StihlChipContainer,
} from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { StihlHelperText } from '../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconFilter from '../../../../base/stihl-material-ui/icons/stihl-icon-filter';
import StihlIconInfo from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledForm = styled.form`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  inline-size: 250px;
  margin: 2.55rem 0 0 1.5rem;
`;
const StyledIconButton = styled(IconButton)`
  margin: 2rem 0.5rem 1.75rem;
`;

export type CustomerTableFilterProps = {
  filterCallback: (filterTerms: ChipType[]) => void;
  chips: ChipType[];
  setChips: Dispatch<SetStateAction<ChipType[]>>;
};

// eslint-disable-next-line max-lines-per-function
const CustomerTableFilter = ({
  filterCallback,
  chips,
  setChips,
}: CustomerTableFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState(FilterParameterValue.SerialNumber);
  const [searchTerm, setSearchTerm] = useState('');

  const [isInvalidInput, setInvalidInput] = useState(false);

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setFilter(event.target.value as FilterParameterValue);
    setInvalidInput(false);
  }

  function checkDuplicate(): boolean {
    const chipFilter = chips.find((chip) => chip.filter === filter);
    return Boolean(chipFilter);
  }

  function addChip(): void {
    const isDuplicate = checkDuplicate();
    const newChip: ChipType = { filter, searchTerm };
    if (!searchTerm || isDuplicate) {
      setInvalidInput(true);
      return;
    }
    setInvalidInput(false);
    setChips([...chips, newChip]);
    filterCallback([...chips, newChip]);
    setFilter(FilterParameterValue.SerialNumber);
    setSearchTerm('');
  }

  function handleSearchTermChange(
    event: React.ChangeEvent<{ name: string | undefined; value: unknown }>,
  ): void {
    setSearchTerm(event.target.value as string);
  }

  function handleEnter(event: React.KeyboardEvent): void {
    const pressedKey: string = event.key;
    if (pressedKey === 'Enter' && validateSearchField()) {
      addChip();
    }
  }
  function getPlaceholderText(value: FilterParameterValue | undefined): string {
    switch (value) {
      case FilterParameterValue.Email: {
        return t('filterParameters.email');
      }
      case FilterParameterValue.Name: {
        return t('filterParameters.name');
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case FilterParameterValue.SerialNumber:
      default: {
        return t('filterParameters.serialNumber');
      }
    }
  }

  function validateSearchField(): boolean {
    if (filter === FilterParameterValue.SerialNumber) {
      const onlyNumbers = new RegExp(/^\d{1,10}$/u, 'u');
      return onlyNumbers.test(searchTerm);
    }
    return searchTerm.length <= 30;
  }

  function getErrorText(): string {
    if (filter === FilterParameterValue.SerialNumber) {
      return 'customerTableFilter.errorSerialNr';
    }
    return 'customerTableFilter.errorName';
  }

  return (
    <Card style={{ padding: '0.75rem' }} data-testid="customerFilter">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography mt="0.75rem">{t('customerTableFilter.title')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <StyledForm>
            <div>
              <InputLabel
                variant="standard"
                htmlFor="filter"
                sx={{ color: `${stihlColor.black}` }}
              >
                {t('customerTableFilter.filterCriteria')}
              </InputLabel>
              <StihlSelect
                id="filter"
                name="filter"
                value={filter}
                sx={{ inlineSize: '250px' }}
                onChange={handleChange}
                data-testid="dropdown"
              >
                <MenuItem value={FilterParameterValue.SerialNumber}>
                  {t('customerTableFilter.serialItem')}
                </MenuItem>
                <MenuItem
                  value={FilterParameterValue.Email}
                  data-testid="email"
                >
                  {t('customerTableFilter.emailItem')}
                </MenuItem>
                <MenuItem value={FilterParameterValue.Name} data-testid="name">
                  {t('customerTableFilter.nameItem')}
                </MenuItem>
              </StihlSelect>
            </div>
            <StyledTextField
              placeholder={getPlaceholderText(filter)}
              variant="standard"
              color="secondary"
              value={searchTerm}
              onChange={handleSearchTermChange}
              onKeyDown={handleEnter}
              inputProps={{ 'data-testid': 'input' }}
              error={searchTerm.length > 0 && !validateSearchField()}
              helperText={
                searchTerm.length > 0 && !validateSearchField() ? (
                  <StihlHelperText
                    text={getErrorText()}
                    data-testid="helperText"
                  />
                ) : (
                  ''
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StihlTooltip
                      title={t('customerManagement.wildcard')}
                      placement="top"
                      data-testid="tooltip"
                    >
                      <StihlIconInfo data-testid="info" />
                    </StihlTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <StyledIconButton
              data-testid="customerFilterButton"
              onClick={addChip}
              disabled={!validateSearchField() || searchTerm.length === 0}
            >
              <StihlIconFilter
                color={
                  !validateSearchField() || searchTerm.length === 0
                    ? 'text.disabled'
                    : 'text.primary'
                }
              />
            </StyledIconButton>
          </StyledForm>
        </Grid>
        <Grid item xs={12}>
          <StihlChipContainer
            chips={chips}
            setChips={setChips}
            filterCallback={filterCallback}
            isInvalidInput={isInvalidInput}
            setInvalidInput={setInvalidInput}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerTableFilter;
/* eslint-enable react/jsx-max-depth */
