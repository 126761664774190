import styled from '@emotion/styled';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { JSX } from 'react';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledButton = styled(Button)`
  white-space: nowrap;
  background-color: ${stihlColor.white};
  border-color: ${stihlColor.black};

  &:hover {
    background-color: ${stihlColor.greyLight};
  }
`;

const StihlButtonOutlined = (props: ButtonProps): JSX.Element => {
  return <StyledButton variant="outlined" color="secondary" {...props} />;
};

export default StihlButtonOutlined;
