/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/naming-convention */
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import type { Dispatch, SetStateAction, SyntheticEvent, JSX } from 'react';
import { useEffect, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconInfo from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { useDisconnectCustomerFromDevice } from '../../service/customer-service/customer-service';

const StyledFormLabel = styled(FormLabel)`
  &.Mui-focused {
    color: ${stihlColor.fontGrey};
  }
`;

export type CustomerDisconnectDeviceModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  serialNumber: string;
  deviceModel: string;
};

enum SelectedOption {
  DisconnectDevice = 'disconnectDeviceOption',
  ScrapDevice = 'scrapDeviceOption',
}

const CustomerDisconnectDeviceModal = ({
  open,
  setOpen,
  serialNumber,
  deviceModel,
}: CustomerDisconnectDeviceModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const isMounted = useRef(true);
  const [value, setValue] = useState<SelectedOption>(
    SelectedOption.DisconnectDevice,
  );
  const [scrapDevice, setScrapDevice] = useState(false);
  const [resetDevice, setResetDevice] = useState(false);

  const disconnectCustomerFromDevice = useDisconnectCustomerFromDevice();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedOption = event.target.value as SelectedOption;
    setValue(selectedOption);
    if (selectedOption === SelectedOption.DisconnectDevice) {
      setScrapDevice(false);
    } else {
      setResetDevice(false);
      setScrapDevice(true);
    }
  };

  const handleResetChange = (event: SyntheticEvent<Element, Event>): void => {
    setResetDevice((event.target as HTMLInputElement).checked);
  };

  function handleClose(): void {
    setResetDevice(false);
    setScrapDevice(false);
    setOpen(false);
  }

  const handleDeviceDisconnect = useCallback(() => {
    disconnectCustomerFromDevice.mutate(
      {
        serialNumber,
        deviceModel,
        scrapDevice,
        resetDevice,
      },
      {
        onSuccess: () => {
          if (!isMounted.current) {
            return;
          }
          setOpen(false);
          setResetDevice(false);
          setScrapDevice(false);
          if (resetDevice) {
            setAlert({
              isOpen: true,
              message: t(
                'customerDisconnectDeviceModal.resetAndDisconnectSuccessMessage',
              ),
              severity: 'success',
            });
          } else {
            setAlert({
              isOpen: true,
              message: t('customerDisconnectDeviceModal.successMessage'),
              severity: 'success',
            });
          }
        },
        onError: (error) => {
          setOpen(false);
          setResetDevice(false);
          setScrapDevice(false);

          if (error.message.includes('409')) {
            setAlert({
              isOpen: true,
              message: t('customerDisconnectDeviceModal.fleetErrorMessage'),
              severity: 'error',
            });
          } else if (error.message.includes('reset')) {
            setAlert({
              isOpen: true,
              message: t('customerDisconnectDeviceModal.resetErrorMessage'),
              severity: 'error',
            });
          } else if (resetDevice) {
            setAlert({
              isOpen: true,
              message: t(
                'customerDisconnectDeviceModal.resetSuccessDisconnectFailedMessage',
              ),
              severity: 'error',
            });
          } else {
            setAlert({
              isOpen: true,
              message: t('customerDisconnectDeviceModal.errorMessage'),
              severity: 'error',
            });
          }
        },
      },
    );
  }, [
    disconnectCustomerFromDevice,
    serialNumber,
    deviceModel,
    scrapDevice,
    resetDevice,
    setOpen,
    setAlert,
    t,
  ]);

  return (
    <StihlModal
      title={`${t('customerDisconnectDeviceModal.title')} ${serialNumber}`}
      open={open}
      onClose={handleClose}
      isLoading={disconnectCustomerFromDevice.isPending}
      actionButtonText={t('customerDisconnectDeviceModal.disconnect')}
      handleActionClick={handleDeviceDisconnect}
      data-testid="customerDisconnectDeviceModal"
    >
      <>
        <Typography>
          <StihlIconInfo />
          <Typography
            component="span"
            variant="h5"
            sx={{ marginLeft: '0.5rem' }}
          >
            {t('customerDisconnectDeviceModal.warning')}
          </Typography>
        </Typography>
        <Typography sx={{ marginTop: '1rem' }} />
        <FormControl component="fieldset">
          <StyledFormLabel>
            {t('customerDisconnectDeviceModal.subtitle')}
          </StyledFormLabel>
          <RadioGroup
            aria-label="disconnectReasonDeviceOwnership"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={SelectedOption.DisconnectDevice}
              control={<Radio />}
              label={t('customerDisconnectDeviceModal.deviceOwnerLabel')}
            />
            {value === SelectedOption.DisconnectDevice && (
              <FormGroup>
                <FormControlLabel
                  sx={{
                    marginLeft: '1rem',
                  }}
                  control={<Checkbox />}
                  label={t('customerDisconnectDeviceModal.resetDeviceLabel')}
                  checked={resetDevice}
                  onChange={handleResetChange}
                />
              </FormGroup>
            )}
          </RadioGroup>
        </FormControl>
      </>
    </StihlModal>
  );
};

export default CustomerDisconnectDeviceModal;
/* eslint-enable @typescript-eslint/naming-convention */
/* eslint-enable max-lines-per-function */
