import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { Form, Formik, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import type { ChangeEvent, HTMLAttributes, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, object, string } from 'yup';
import StihlButtonOutlined from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlButtonSecondaryLoading from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlMultiSelect from '../../../../base/stihl-material-ui/components/stihl-multi-select/stihl-multi-select';
import StihlTextField from '../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconWarning from '../../../../base/stihl-material-ui/icons/stihl-icon-warning';
import { getHelperText } from '../../../customer-management/ui/customer-registration/utils/form-helper.utils';
import type { ReleaseManagementMetaData } from '../../model/release-management/release-management.model';
import { getSoftwareVersions } from '../../service/device-management-api-client/device-management-api-client';
import { typeModelMatcher } from '../overview-items/utils/device-management.const';

const MetadataRadioGroup = ({
  value,
  label,
  fieldName,
  setFieldValue,
  ...rest
}: {
  value?: boolean;
  label: string;
  fieldName: string;
  setFieldValue: (name: string, value: boolean) => void;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(fieldName, event.target.checked);
    },
    [setFieldValue, fieldName],
  );

  return (
    <>
      <Typography>{label}</Typography>
      <RadioGroup row value={value} {...rest}>
        <FormControlLabel
          control={<Radio onChange={handleChange} />}
          label={t('releaseManagement.editMetadataForm.yes')}
          value
          data-testid="yes"
        />
        <FormControlLabel
          control={
            <Radio
              onChange={(event) =>
                setFieldValue(fieldName, !event.target.checked)
              }
            />
          }
          label={t('releaseManagement.editMetadataForm.no')}
          value={false}
        />
      </RadioGroup>
    </>
  );
};

const FieldGroupWrapper = styled.div`
  margin-inline-start: 1rem;
`;

const validationSchema = object().shape({
  buildNumber: string(),
  isIncremental: boolean().required('formik.mustEnterIsIncrementalVersion'),
  isBetaVersion: boolean(),
  isSecurityRelevant: boolean(),
  isSecurityCritical: boolean(),
  requiresReboot: boolean(),
  supportedDeviceTypes: array(string()).min(1),
  fileHash: object({ type: string(), value: string() }).test(
    'is-optional',
    // eslint-disable-next-line no-secrets/no-secrets
    'formik.bothFileHashInputsRequired',
    ({ type, value }) => {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return !((type && !value) || (!type && value));
    },
  ),
});

export type EditMetadataFormProps = {
  metadata: ReleaseManagementMetaData;
  handleSubmitForm: (metadata: ReleaseManagementMetaData) => void;
  handleCancel: () => void;
};
// eslint-disable-next-line max-lines-per-function
const EditMetaDataForm = ({
  handleSubmitForm,
  handleCancel,
  metadata,
}: EditMetadataFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [availableSwVersions, setAvailableSwVersions] = useState<string[]>([]);

  const initialValues: ReleaseManagementMetaData = {
    supportedDeviceTypes: [],
    ...metadata,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    validateField,
  } = formik;

  // needed to validate required supported device types
  useEffect(() => {
    void validateField('supportedDeviceTypes');
  }, [validateField]);

  useEffect(() => {
    getSoftwareVersions(metadata.deviceModel)
      .then((response) => {
        return setAvailableSwVersions(
          response.filter((swVersion) => swVersion !== metadata.version),
        );
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setAvailableSwVersions([]);
      });
    return () => {
      setAvailableSwVersions([]);
    };
  }, [setAvailableSwVersions, metadata.deviceModel, metadata.version]);

  function handleSubmit(): void {
    handleSubmitForm(values);
  }

  function handleSetFieldValue(name: string, value: boolean): void {
    void setFieldValue(name, value);
  }

  return (
    <>
      <Typography variant="h3" data-testid="editMetadataForm">
        {t('releaseManagement.editMetadataForm.addMetadataForRelease', {
          version: metadata.version,
        })}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form style={{ maxWidth: '530px' }}>
          <InputLabel htmlFor="version" variant="standard">
            {t('releaseManagement.editMetadataForm.version')}
          </InputLabel>
          <StihlTextField
            inputProps={{ 'data-testid': 'version' }}
            id="version"
            fullWidth
            value={values.version}
            InputProps={{
              readOnly: true,
            }}
          />
          <InputLabel htmlFor="buildNumber" variant="standard">
            {`${t('releaseManagement.editMetadataForm.buildNumber')} (${t(
              'releaseManagement.editMetadataForm.optional',
            )})`}
          </InputLabel>
          <StihlTextField
            inputProps={{ 'data-testid': 'buildNumber' }}
            id="buildNumber"
            fullWidth
            value={values.buildNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <MetadataRadioGroup
            label={t('releaseManagement.editMetadataForm.isIncremental')}
            value={values.isIncremental}
            setFieldValue={handleSetFieldValue}
            fieldName="isIncremental"
            data-testid="isIncrementalRadio"
          />
          {getHelperText(Boolean(touched.isIncremental), errors.isIncremental)}
          <MetadataRadioGroup
            label={`${t('releaseManagement.editMetadataForm.isBeta')} (${t(
              'releaseManagement.editMetadataForm.optional',
            )})`}
            value={values.isBetaVersion}
            setFieldValue={handleSetFieldValue}
            fieldName="isBetaVersion"
            data-testid="isBetaRadio"
          />
          <MetadataRadioGroup
            label={`${t(
              'releaseManagement.editMetadataForm.isSecurityRelevant',
            )} (${t('releaseManagement.editMetadataForm.optional')})`}
            value={values.isSecurityRelevant}
            setFieldValue={handleSetFieldValue}
            fieldName="isSecurityRelevant"
            data-testid="isSecurityRelevantRadio"
          />
          <MetadataRadioGroup
            label={`${t(
              'releaseManagement.editMetadataForm.isSecurityCritical',
            )} (${t('releaseManagement.editMetadataForm.optional')})`}
            value={values.isSecurityCritical}
            setFieldValue={handleSetFieldValue}
            fieldName="isSecurityCritical"
            data-testid="isSecurityCriticalRadio"
          />
          <MetadataRadioGroup
            label={`${t(
              'releaseManagement.editMetadataForm.requiresReboot',
            )} (${t('releaseManagement.editMetadataForm.optional')})`}
            value={values.requiresReboot}
            setFieldValue={handleSetFieldValue}
            fieldName="requiresReboot"
            data-testid="requiresRebootRadio"
          />
          <InputLabel htmlFor="supportedDeviceModels" variant="standard">
            {t('releaseManagement.editMetadataForm.supportedDeviceModels')}
          </InputLabel>
          <StihlTextField
            inputProps={{ 'data-testid': 'supportedDeviceModels' }}
            id="supportedDeviceModels"
            fullWidth
            value={values.deviceModel}
            InputProps={{
              readOnly: true,
            }}
          />
          <StihlMultiSelect
            label={t('releaseManagement.editMetadataForm.supportedDeviceTypes')}
            items={typeModelMatcher[metadata.deviceModel]}
            selectedCallback={(selected) => {
              void setFieldValue('supportedDeviceTypes', selected);
            }}
            preSelected={initialValues.supportedDeviceTypes}
            data-testid="deviceTypes"
          />
          <InputLabel
            htmlFor="supportedDeviceMaterialNumbers"
            variant="standard"
          >
            {`${t(
              'releaseManagement.editMetadataForm.supportedDeviceMaterialNumbers',
            )} (${t('releaseManagement.editMetadataForm.optional')})`}
          </InputLabel>
          <StihlTextField
            inputProps={{ 'data-testid': 'supportedDeviceMaterialNumbers' }}
            id="supportedDeviceMaterialNumbers"
            fullWidth
            value={values.supportedDeviceMaterialNumbers}
            onChange={(event) => {
              void setFieldValue(
                'supportedDeviceMaterialNumbers',
                event.target.value.replaceAll(' ', '').split(','),
              );
            }}
            onBlur={handleBlur}
          />
          <InputLabel
            htmlFor="supportedUpgradeSourceVersions"
            variant="standard"
          >
            {t(
              'releaseManagement.editMetadataForm.supportedUpgradeSourceVersions',
            )}
          </InputLabel>
          <StihlMultiSelect
            id="supportedUpgradeSourceVersions"
            data-testid="versionSelect"
            items={availableSwVersions.map((swVersion) => ({
              label: swVersion,
              value: swVersion,
            }))}
            selectedCallback={(selected) => {
              void setFieldValue('supportedUpgradeSourceVersions', selected);
            }}
            preSelected={initialValues.supportedUpgradeSourceVersions}
            style={{ inlineSize: '100%' }}
          />

          <StihlTextField
            fullWidth
            id="supportedUpgradeSourceVersionsUserInput"
            data-testid="versionUserInput"
            onChange={(event) => {
              void setFieldValue(
                'supportedUpgradeSourceVersionsUserInput',
                event.target.value.replaceAll(' ', '').split(','),
              );
            }}
            value={values.supportedUpgradeSourceVersionsUserInput}
            placeholder={t(
              'releaseManagement.editMetadataForm.additionalSwVersionsHint',
            )}
            onBlur={handleBlur}
          />

          <InputLabel htmlFor="fileName" variant="standard">
            {t('releaseManagement.editMetadataForm.fileName')}
          </InputLabel>
          <StihlTextField
            inputProps={{ 'data-testid': 'fileName' }}
            id="fileName"
            fullWidth
            value={values.fileName}
            InputProps={{
              readOnly: true,
            }}
          />
          <Typography>
            {`${t('releaseManagement.editMetadataForm.fileHash')} (${t(
              'releaseManagement.editMetadataForm.optional',
            )})`}
          </Typography>
          <FieldGroupWrapper>
            <InputLabel htmlFor="fileHash.value" variant="standard">
              {t('releaseManagement.editMetadataForm.fileHashValue')}
            </InputLabel>
            <StihlTextField
              inputProps={{ 'data-testid': 'fileHashValue' }}
              id="fileHash.value"
              fullWidth
              value={values.fileHash?.value}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.fileHash && values.fileHash?.type)}
              helperText={getHelperText(
                Boolean(!values.fileHash?.value && values.fileHash?.type),
                errors.fileHash,
              )}
            />
            <InputLabel htmlFor="fileHash.type" variant="standard">
              {t('releaseManagement.editMetadataForm.fileHashType')}
            </InputLabel>
            <StihlTextField
              inputProps={{ 'data-testid': 'fileHashType' }}
              id="fileHash.type"
              fullWidth
              value={values.fileHash?.type}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.fileHash && values.fileHash?.value)}
              helperText={getHelperText(
                Boolean(values.fileHash?.value && !values.fileHash.type),
                errors.fileHash,
              )}
            />
          </FieldGroupWrapper>
          <br />
          <Typography variant="h5">
            <StihlIconWarning />{' '}
            {t('releaseManagement.editMetadataForm.submitWarning')}
          </Typography>
          <br />
          <StihlButtonOutlined
            style={{
              marginRight: '1rem',
            }}
            onClick={handleCancel}
            data-testid="cancelButton"
          >
            {t('releaseManagement.editMetadataForm.buttonCancel')}
          </StihlButtonOutlined>
          <StihlButtonSecondaryLoading
            onClick={handleSubmit}
            disabled={!isValid}
            data-testid="submitButton"
          >
            {t('releaseManagement.editMetadataForm.buttonCheckMetadata')}
          </StihlButtonSecondaryLoading>
        </Form>
      </Formik>
    </>
  );
};

export default EditMetaDataForm;
