import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import StihlIconTextFlexbox from '../../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlTooltip from '../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconLegend from '../../../../../../base/stihl-material-ui/icons/stihl-icon-legend';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlThemeColor } from '../../../../../../base/stihl-material-ui/theme/stihl-theme-colors';
import type { ErrorValue } from '../../../../model';
import { DeviceTabsContext } from '../../../tabs/device-tabs.context';
import CardContentItem from './card-content-item';
import GreyText from './grey-text';

const StyledLink = styled.button`
  color: ${stihlColor.blueDark};
  text-decoration: underline;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export function renderPriority(priority: number | undefined): JSX.Element {
  let priorityColor: StihlThemeColor = 'grey.500';

  switch (priority) {
    case 1: {
      priorityColor = 'priority.priority1';
      break;
    }
    case 2: {
      priorityColor = 'priority.priority2';
      break;
    }
    case 3: {
      priorityColor = 'priority.priority3';
      break;
    }
    default: {
      break;
    }
  }
  return <StihlIconLegend color={priorityColor} />;
}

type ErrorTextProps = {
  name: string;
  value: ErrorValue[];
};

const TooltipIcon = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StihlTooltip
      title={t('deviceDetails.errorsAndServicesTooltip')}
      placement="bottom"
      data-testid="activeErrorsTooltip"
    >
      <StihlIconInfo />
    </StihlTooltip>
  );
};

const ErrorText = ({ name, value }: ErrorTextProps): JSX.Element => {
  const { openTab } = useContext(DeviceTabsContext);
  const { toLocaleDateTimeString } = useDateFormatting();

  function handleClick(): void {
    openTab('tabs.dtcCodes');
  }
  return (
    <CardContentItem name={name} icon={<TooltipIcon />}>
      {value.map((data: ErrorValue, index) => (
        // Code might not be unique, so the index has to be used instead
        // eslint-disable-next-line react/no-array-index-key
        <StihlIconTextFlexbox key={index}>
          <div>{renderPriority(data.priority)}</div>
          <div style={{ marginLeft: '0.5rem' }}>
            <Typography>
              <StyledLink onClick={handleClick}>{data.code}</StyledLink> -{' '}
              {data.requiredAction}
              <GreyText>
                {' '}
                {toLocaleDateTimeString(new Date(data.activeSince))}
              </GreyText>
            </Typography>
          </div>
        </StihlIconTextFlexbox>
      ))}
    </CardContentItem>
  );
};

export default ErrorText;

type NoErrorTextProps = {
  name: string;
  value: string;
};
export const NoErrorText = ({ name, value }: NoErrorTextProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <CardContentItem name={name}>
      <StihlIconTextFlexbox>
        <StihlIconLegend color={stihlColor.green} />
        <Typography sx={{ marginLeft: '0.5rem' }}>{t(value)}</Typography>
      </StihlIconTextFlexbox>
    </CardContentItem>
  );
};
