// disable: oidc 3rd party lib does not follow naming conventions
/* eslint-disable @typescript-eslint/naming-convention */
export const authenticationClientConfig = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY,

  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  response_type: 'code',
  scope: process.env.REACT_APP_AUTH_SCOPES,
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  acr_values: '2fa',
  metadata: {
    issuer: process.env.REACT_APP_AUTH_ISSUER,
    userinfo_endpoint: process.env.REACT_APP_AUTH_END_USER,
    authorization_endpoint: process.env.REACT_APP_AUTH_AUTHORIZATION,
    token_endpoint: process.env.REACT_APP_AUTH_TOKEN,
    end_session_endpoint: process.env.REACT_APP_AUTH_END_SESSION,
  },
};
/* eslint-enable @typescript-eslint/naming-convention */
