import type { ManagedDevice } from '../../model/overview.models';
import type { DeviceStatus } from '../../model/statistics.model';

export type DeviceManagementStore = {
  multiAssignmentDevices: Set<ManagedDevice | DeviceStatus>;
  addDevices: (rows: (DeviceStatus | ManagedDevice)[]) => void;
  removeDevices: (rows: (DeviceStatus | ManagedDevice)[]) => void;
  clear: () => void;
};

export const createDeviceManagementStore = (): DeviceManagementStore => {
  const store = {
    multiAssignmentDevices: new Set<ManagedDevice | DeviceStatus>(),

    addDevices: (rows: (DeviceStatus | ManagedDevice)[]) => {
      if (store.multiAssignmentDevices.size === 0) {
        for (const row of rows) {
          store.multiAssignmentDevices.add(row);
        }
      } else {
        for (const row of rows) {
          const isIncluded = [...store.multiAssignmentDevices].find(
            (device) => device.deviceId === row.deviceId,
          );
          if (!isIncluded) {
            store.multiAssignmentDevices.add(row);
          }
        }
      }
    },

    removeDevices: (rows: (DeviceStatus | ManagedDevice)[]) => {
      for (const row of rows) {
        store.multiAssignmentDevices.delete(row);
      }
    },
    clear: () => store.multiAssignmentDevices.clear(),
  };

  return store;
};

export default createDeviceManagementStore;
