import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconGlobe from '../../../../base/stihl-material-ui/icons/stihl-icon-globe';
import { generateFileName } from '../../../legal-documents/service/documents-utils';
import { DocumentCountryFilter } from '../../../legal-documents/ui/document-country-filter';

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export type FooterModalProps = {
  open: boolean;
  setOpen: () => void;
};

const DataProtectionModal = ({
  open,
  setOpen,
}: FooterModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [dataProtectionCountry, setDataProtectionCountry] =
    useState<string>('Germany (DE)');

  function handleClose(): void {
    setOpen();
  }

  function handleOpenDocument(): void {
    window.open(
      `./privacynotices/${generateFileName(
        'privacynotice',
        dataProtectionCountry,
      )}.pdf`,
    );
    setOpen();
  }

  return (
    <StihlModal
      open={open}
      onClose={handleClose}
      title={t('footer.dataProtection')}
      data-testid="dataProtectionModal"
      actionButtonText={t('openDocument')}
      handleActionClick={handleOpenDocument}
    >
      <div>
        <Typography>{t('footer.selectLanguage')}</Typography>
        <br />
        <FlexContainer>
          <StihlIconGlobe color="black" />
          <DocumentCountryFilter
            documentCountry={dataProtectionCountry}
            setDocumentCountry={setDataProtectionCountry}
            hideCountries={['United States of America (EN)']}
          />
        </FlexContainer>
        <br />
      </div>
    </StihlModal>
  );
};

export default DataProtectionModal;
