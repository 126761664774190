import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import StihlIconInfo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconValidFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { CertificateStatus } from '../../../../model';

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-block-start: 0.5rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = ({ text }: { text: string }): JSX.Element => {
  return (
    <InfoContainer>
      <StihlIconInfo />
      <Typography component="span" variant="h5" sx={{ marginLeft: '0.5rem' }}>
        {text}
      </Typography>
    </InfoContainer>
  );
};

const Status = ({
  icon,
  statusText,
  infoText,
}: {
  icon: ReactNode;
  statusText: string;
  infoText?: string;
}): JSX.Element => {
  return (
    <>
      <StatusContainer>
        {icon}
        <Typography>{statusText}</Typography>
      </StatusContainer>
      {!!infoText && <Info text={infoText} />}
    </>
  );
};

export const CommunicationCertificateStatus = ({
  certificateStatus,
}: {
  certificateStatus: CertificateStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateString } = useDateFormatting();

  const { lifecycleValidFrom, lifecycleValidTo, birthValidFrom, birthValidTo } =
    useMemo(() => {
      return {
        lifecycleValidFrom: certificateStatus.lifecycleValidFrom
          ? toLocaleDateString(new Date(certificateStatus.lifecycleValidFrom))
          : '',
        lifecycleValidTo: certificateStatus.lifecycleValidTo
          ? toLocaleDateString(new Date(certificateStatus.lifecycleValidTo))
          : '',
        birthValidFrom: certificateStatus.birthValidFrom
          ? toLocaleDateString(new Date(certificateStatus.birthValidFrom))
          : '',
        birthValidTo: certificateStatus.birthValidTo
          ? toLocaleDateString(new Date(certificateStatus.birthValidTo))
          : '',
      };
    }, [
      certificateStatus.birthValidFrom,
      certificateStatus.birthValidTo,
      certificateStatus.lifecycleValidFrom,
      certificateStatus.lifecycleValidTo,
      toLocaleDateString,
    ]);

  if (
    certificateStatus.lifecycleValidTo &&
    certificateStatus.lifecycleValidFrom
  ) {
    if (certificateStatus.lifecycleValidTo > Date.now()) {
      return (
        <Status
          icon={<StihlIconValidFilled color="success" />}
          statusText={t(
            'certificateStatus.communicationCertificateSection.lifecycleValid',
            {
              validFrom: lifecycleValidFrom,
              validTo: lifecycleValidTo,
            },
          )}
          infoText={t(
            'certificateStatus.communicationCertificateSection.lifecycleValidInfo',
          )}
        />
      );
    }

    return (
      <Status
        icon={<StihlIconXFilled color="error" />}
        statusText={t(
          'certificateStatus.communicationCertificateSection.lifecycleInvalid',
          {
            validTo: lifecycleValidTo,
          },
        )}
        infoText={t(
          'certificateStatus.communicationCertificateSection.lifecycleInvalidInfo',
        )}
      />
    );
  }

  if (certificateStatus.birthValidTo && certificateStatus.birthValidFrom) {
    if (certificateStatus.birthValidTo > Date.now()) {
      return (
        <Status
          icon={<StihlIconValidFilled color="success" />}
          statusText={t(
            'certificateStatus.communicationCertificateSection.birthValid',
            {
              validFrom: birthValidFrom,
              validTo: birthValidTo,
            },
          )}
          infoText={t(
            'certificateStatus.communicationCertificateSection.noLifecycleInfo',
          )}
        />
      );
    }

    return (
      <Status
        icon={<StihlIconXFilled color="error" />}
        statusText={t(
          'certificateStatus.communicationCertificateSection.birthInvalid',
        )}
        infoText={t(
          'certificateStatus.communicationCertificateSection.noValidBirthInfo',
        )}
      />
    );
  }

  return (
    <Status
      icon={<StihlIconXFilled color="error" />}
      statusText={t(
        'certificateStatus.communicationCertificateSection.noBirth',
      )}
      infoText={t(
        'certificateStatus.communicationCertificateSection.noValidBirthInfo',
      )}
    />
  );
};

export const DiagnosisCertificateStatus = ({
  certificateStatus,
}: {
  certificateStatus: CertificateStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateString } = useDateFormatting();

  const { diagnosisValidFrom, diagnosisValidTo } = useMemo(() => {
    return {
      diagnosisValidFrom: certificateStatus.diagnosisValidFrom
        ? toLocaleDateString(new Date(certificateStatus.diagnosisValidFrom))
        : '',
      diagnosisValidTo: certificateStatus.diagnosisValidTo
        ? toLocaleDateString(new Date(certificateStatus.diagnosisValidTo))
        : '',
    };
  }, [
    certificateStatus.diagnosisValidFrom,
    certificateStatus.diagnosisValidTo,
    toLocaleDateString,
  ]);

  if (
    certificateStatus.diagnosisValidTo &&
    certificateStatus.diagnosisValidFrom
  ) {
    if (certificateStatus.diagnosisValidTo > Date.now()) {
      return (
        <Status
          icon={<StihlIconValidFilled color="success" />}
          statusText={t('certificateStatus.diagnosisCertificateSection.valid', {
            validFrom: diagnosisValidFrom,
            validTo: diagnosisValidTo,
          })}
        />
      );
    }

    return (
      <Status
        icon={<StihlIconXFilled color="error" />}
        statusText={t(
          'certificateStatus.diagnosisCertificateSection.wasValid',
          {
            validFrom: diagnosisValidFrom,
            validTo: diagnosisValidTo,
          },
        )}
      />
    );
  }

  return (
    <StatusContainer>
      <StihlIconXFilled color={stihlColor.greyBase} />
      <Typography>
        {t('certificateStatus.diagnosisCertificateSection.notExisting')}
      </Typography>
    </StatusContainer>
  );
};
