/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable import/max-dependencies */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlTooltipCentered from '../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-centered';
import StihlIconArrow from '../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import StihlIconLink from '../../../base/stihl-material-ui/icons/stihl-icon-link';
import StihlIconMedal from '../../../base/stihl-material-ui/icons/stihl-icon-medal';
import StihlIconMowerLink from '../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import StihlIconSettings from '../../../base/stihl-material-ui/icons/stihl-icon-settings';
import StihlIconUserLink from '../../../base/stihl-material-ui/icons/stihl-icon-user-link';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import CustomerDisconnectDeviceModal from '../../customer-management/ui/customer-disconnect-device-modal/customer-disconnect-device-modal';
import { AccessLicenses } from '../../device/model';
import { SearchParameterValues } from '../../device/model/search.model';
import type { DeviceCustomer, Equipment, EmailSearchResult } from '../model';

/* eslint-enable import/max-dependencies */

const CardHeader = styled.div`
  position: relative;
  padding: 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  padding: 0.5rem;
`;

const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

const StyledHeadingTableCell = styled(TableCell)`
  color: ${stihlColor.greyBase};
  font-size: 0.875rem;
  text-decoration: underline;
  border-block-end: none;
`;

const StyledBodyTableCell = styled(TableCell)`
  border-block-end: none;
`;

const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
`;

const StyledIconButton = styled(IconButton)`
  align-items: baseline;
  align-self: top;
`;

const StyledStihlIconArrow = styled(StihlIconArrow)`
  block-size: 12px;
  padding: 0 0 4px 2px;
`;

export type SearchResultListProps = {
  searchResult: EmailSearchResult;
};

const EmailSearchResultList = ({
  searchResult,
}: SearchResultListProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const [isCustomerDisconnectModalOpen, setCustomerDisconnectModalOpen] =
    useState(false);
  const [isHoveredAction, setIsHoveredAction] = useState(false);
  const [activeEquipment, setActiveEquipment] = useState<Equipment | undefined>(
    undefined,
  );
  const [store] = useStore();

  function onHoverAction(): void {
    setIsHoveredAction(true);
  }
  function onStopHoverAction(): void {
    setIsHoveredAction(false);
  }

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    equipment: Equipment,
  ): void => {
    setAnchorElement(event.currentTarget);
    setActiveEquipment(equipment);
  };

  function handleSelectMenu(event: React.MouseEvent): void {
    event.stopPropagation();
    setAnchorElement(null);
  }

  function handleOpenDevice(equipment: Equipment): void {
    // In this usecase we can only use the serial number to search a new device
    // because the CDH does not know about the deviceId
    history.push('/device', {
      search: equipment.serialNumber,
      selector: SearchParameterValues.SerialNumber,
      deviceModel: equipment.deviceModel,
    });
  }

  function handleUserClick(customer: DeviceCustomer): void {
    history.push({
      pathname: '/customer-management',
      state: { filterEmail: customer.customerEmail },
    });
  }

  function handleTooltipTitle(
    hasMatchingPreferredDealer: boolean,
    isAccountValid: boolean,
  ): string {
    if (
      (!hasMatchingPreferredDealer &&
        (store.license === AccessLicenses.DealerLicense ||
          store.license === AccessLicenses.DealerUsLicense)) ||
      store.license === AccessLicenses.VuLicense ||
      store.license === AccessLicenses.VuUsLicense
    ) {
      return t('customerDetails.notPreferredDealer');
    }
    if (!isAccountValid) {
      return t('customerDetails.invalidAccount');
    }
    return '';
  }

  return (
    <Card>
      <CardHeader>
        <HeaderContainer>
          <Typography variant="h3">{t(searchResult.title)}</Typography>
        </HeaderContainer>
      </CardHeader>
      <StyledCardContent>
        {searchResult.customers.map((customer) => (
          <Grid container key={customer.customerEmail + customer.customerName}>
            <Grid item>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.customerName')}
                      </StyledHeadingTableCell>

                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.customerEmail')}
                      </StyledHeadingTableCell>
                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.location')}
                      </StyledHeadingTableCell>
                      <StyledHeadingTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StihlTooltipCentered
                      title={handleTooltipTitle(
                        customer.isUserPreferredDealer,
                        customer.isAccountValid,
                      )}
                    >
                      <TableRow data-testid="customerRow">
                        <StyledBodyTableCell scope="row">
                          <b>{customer.customerName}</b>
                        </StyledBodyTableCell>

                        <StyledBodyTableCell>
                          {customer.customerEmail}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell>
                          {customer.customerLocation}
                        </StyledBodyTableCell>

                        <StyledBodyTableCell>
                          {Boolean(customer.isUserPreferredDealer) &&
                          Boolean(customer.isAccountValid) ? (
                            <IconButton
                              onClick={() => handleUserClick(customer)}
                              data-testid="handleClickButton"
                            >
                              <StihlIconUserLink color="primary" />
                            </IconButton>
                          ) : (
                            <IconButton disabled>
                              <StihlIconUserLink color="grey.500" />
                            </IconButton>
                          )}
                        </StyledBodyTableCell>
                      </TableRow>
                    </StihlTooltipCentered>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.role')}
                      </StyledHeadingTableCell>
                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.deviceModel')}
                      </StyledHeadingTableCell>
                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.type')}
                      </StyledHeadingTableCell>
                      <StyledHeadingTableCell>
                        {t('searchResultTable.headers.serialNumber')}
                      </StyledHeadingTableCell>
                      <StyledHeadingTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customer.equipments.map((equipment) => (
                      <TableRow
                        key={equipment.serialNumber}
                        style={{
                          cursor: 'pointer',
                        }}
                        hover={!isHoveredAction}
                        data-testid="tableRow"
                        onClick={() => {
                          handleOpenDevice(equipment);
                        }}
                      >
                        <StyledBodyTableCell>
                          <IconAligner>
                            {equipment.role === 'customerDetails.deputy' ? (
                              <StihlIconLink />
                            ) : (
                              <StihlIconMedal color="grey.500" />
                            )}
                            {t(equipment.role)}
                          </IconAligner>
                        </StyledBodyTableCell>
                        <StyledBodyTableCell>
                          {equipment.deviceModel}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell>
                          {equipment.modelName}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell>
                          {equipment.serialNumber}
                        </StyledBodyTableCell>
                        <StyledBodyTableCell>
                          <IconWrapper>
                            <StihlIconMowerLink color="stihlOrange" />
                          </IconWrapper>
                        </StyledBodyTableCell>

                        {store.license !==
                          AccessLicenses.FirstLevelSupportLicense &&
                          store.license !== AccessLicenses.DealerLicense &&
                          store.license !== AccessLicenses.DealerUsLicense && (
                            <StyledBodyTableCell>
                              <StyledIconButton
                                data-testid="settingsButton"
                                onMouseOver={onHoverAction}
                                onMouseOut={onStopHoverAction}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleOpenMenu(event, equipment);
                                }}
                              >
                                <StihlIconSettings color="black" />{' '}
                                <StyledStihlIconArrow />
                              </StyledIconButton>
                              <Menu
                                anchorEl={anchorElement}
                                open={
                                  open &&
                                  equipment.serialNumber ===
                                    activeEquipment?.serialNumber
                                }
                                onClick={(event) => {
                                  handleSelectMenu(event);
                                }}
                              >
                                <MenuItem
                                  data-testid="disconnectDevice"
                                  onClick={() =>
                                    setCustomerDisconnectModalOpen(true)
                                  }
                                >
                                  {t('searchResultTable.disconnectDevice')}
                                </MenuItem>
                              </Menu>
                            </StyledBodyTableCell>
                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ))}
      </StyledCardContent>
      {activeEquipment != null && (
        <CustomerDisconnectDeviceModal
          open={isCustomerDisconnectModalOpen}
          setOpen={setCustomerDisconnectModalOpen}
          serialNumber={activeEquipment.serialNumber}
          deviceModel={activeEquipment.deviceModel}
        />
      )}
    </Card>
  );
};

export default EmailSearchResultList;
/* eslint-enable react/jsx-max-depth */
/* eslint-enable max-lines-per-function */
