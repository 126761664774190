import CardMedia from '@mui/material/CardMedia';
import type { FC } from 'react';

type StihlCardMediaProps = {
  image: string;
  title: string;
  backgroundImage?: string;
  height?: string;
  width?: string;
};

const StihlCardMedia: FC<StihlCardMediaProps> = ({
  image,
  title,
  backgroundImage,
  height = '155px',
  width = '100%',
}) => {
  return (
    <CardMedia
      component="img"
      image={image}
      title={title}
      style={{
        height,
        width,
        objectFit: 'none',
        backgroundImage: backgroundImage ? `${backgroundImage}` : undefined,
      }}
    />
  );
};

export default StihlCardMedia;
