import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { DiagnosisSessionData } from '../../model';
import type { SearchObject } from '../../model/search.model';
import { SearchParameterValues } from '../../model/search.model';
import { useDeviceSearch } from '../../service/device-service/device-search-service';
import DeviceTabsDeviceDetails from '../tabs/device-tabs-device-details';
import DeviceTabsSearch from '../tabs/device-tabs-search';
import { useDeviceDetailsAlert } from './device-details-alert';

export const DeviceDetails: FC = () => {
  const { state, search } = useLocation<SearchObject | null>();

  const [searchValue, setSearch] = useState<SearchObject | undefined>();

  useEffect(() => {
    if (state) {
      setSearch(state);
    }

    const query = new URLSearchParams(search);

    const serialNumber = query.get('serialNumber');
    const deviceModel = query.get('deviceModel');

    if (serialNumber != null && deviceModel != null) {
      setSearch({
        selector: SearchParameterValues.SerialNumber,
        deviceModel,
        search: serialNumber,
      });
    }
  }, [state, search]);

  const deviceData = useDeviceSearch(searchValue);

  useDeviceDetailsAlert(deviceData);

  const [diagnosisDataArray, setDiagnosisDataArray] = useState<
    DiagnosisSessionData[] | undefined
  >(undefined);

  return deviceData.type === 'device' && deviceData.data ? (
    <DeviceTabsDeviceDetails
      deviceData={deviceData}
      diagnosisDataArray={diagnosisDataArray}
      setDiagnosisDataArray={setDiagnosisDataArray}
    />
  ) : (
    <DeviceTabsSearch deviceData={deviceData} />
  );
};

export default DeviceDetails;
