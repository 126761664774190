import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { JSX, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconClose from '../../../../base/stihl-material-ui/icons/stihl-icon-close';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { SoftwareVersionMetadata } from '../../model/software-versions.model';

const StyledCard = styled(Card)`
  min-inline-size: 350px;
  max-inline-size: 400px;

  @media (max-width: 1024px) {
    max-inline-size: 350px;
  }
`;

const StyledCardHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 60px;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

const CloseButtonPositioner = styled.div`
  position: absolute;
  inset-block-start: 0.5rem;
  inset-inline-end: 1rem;
`;

const StyledFieldTitle = styled(Typography)`
  color: ${stihlColor.fontGrey};
`;

const StyledTypography = styled(Typography)`
  margin-block-end: 0.5rem;
  word-wrap: break-word;
`;

const OptionalMetadataField = ({
  children,
  metadata,
}: {
  children: ReactNode;
  metadata: number | string | string[] | boolean | null;
}): JSX.Element | null => {
  if (metadata === null) {
    return null;
  }
  return <div>{children}</div>;
};

// eslint-disable-next-line max-lines-per-function
export const DeviceManagementSoftwareVersionsDetails = ({
  softwareVersionMetadata,
  handleCloseDetails,
}: {
  softwareVersionMetadata: SoftwareVersionMetadata;
  handleCloseDetails: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledCard data-testid="softwareVersionsDetailsCard">
      <StyledCardHeader>
        <Typography variant="h3">
          {t('softwareVersions.details.title', {
            version: softwareVersionMetadata.swVersion,
          })}
        </Typography>
        <CloseButtonPositioner>
          <IconButton
            data-testid="detailsCloseButton"
            onClick={handleCloseDetails}
          >
            <StihlIconClose />
          </IconButton>
        </CloseButtonPositioner>
      </StyledCardHeader>
      <CardContent data-testid="softwareVersionsDetailsContent">
        <OptionalMetadataField metadata={softwareVersionMetadata.importDate}>
          <StyledFieldTitle>
            {t('softwareVersions.details.importDate')}
          </StyledFieldTitle>
          <StyledTypography>
            {!!softwareVersionMetadata.importDate &&
              new Date(softwareVersionMetadata.importDate).toLocaleDateString()}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField metadata={softwareVersionMetadata.buildNumber}>
          <StyledFieldTitle>
            {t('softwareVersions.details.buildNumber')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.buildNumber}
          </StyledTypography>
        </OptionalMetadataField>

        <StyledFieldTitle>
          {t('softwareVersions.details.deviceModel')}
        </StyledFieldTitle>
        <StyledTypography>
          {softwareVersionMetadata.deviceModel}
        </StyledTypography>

        <OptionalMetadataField metadata={softwareVersionMetadata.deviceType}>
          <StyledFieldTitle>
            {t('softwareVersions.details.supportedDeviceTypes')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.deviceType?.[0] === '*'
              ? t('softwareVersions.details.all')
              : softwareVersionMetadata.deviceType?.join(', ')}
          </StyledTypography>
        </OptionalMetadataField>

        <StyledFieldTitle>
          {t('softwareVersions.details.isIncremental')}
        </StyledFieldTitle>
        <StyledTypography>
          {softwareVersionMetadata.isIncremental
            ? t('softwareVersions.details.yes')
            : t('softwareVersions.details.no')}
        </StyledTypography>

        <OptionalMetadataField metadata={softwareVersionMetadata.isBetaVersion}>
          <StyledFieldTitle>
            {t('softwareVersions.details.isBeta')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.isBetaVersion
              ? t('softwareVersions.details.yes')
              : t('softwareVersions.details.no')}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField
          metadata={softwareVersionMetadata.isSecurityRelevant}
        >
          <StyledFieldTitle>
            {t('softwareVersions.details.isSecurityRelevant')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.isSecurityRelevant
              ? t('softwareVersions.details.yes')
              : t('softwareVersions.details.no')}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField
          metadata={softwareVersionMetadata.isSecurityCritical}
        >
          <StyledFieldTitle>
            {t('softwareVersions.details.isSecurityCritical')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.isSecurityCritical
              ? t('softwareVersions.details.yes')
              : t('softwareVersions.details.no')}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField
          metadata={softwareVersionMetadata.requiresReboot}
        >
          <StyledFieldTitle>
            {t('softwareVersions.details.requiresReboot')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.requiresReboot
              ? t('softwareVersions.details.yes')
              : t('softwareVersions.details.no')}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField
          metadata={softwareVersionMetadata.upgradePathSwVersion}
        >
          <StyledFieldTitle>
            {t('softwareVersions.details.supportedUpgradeVersions')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.upgradePathSwVersion?.join(', ')}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField
          metadata={softwareVersionMetadata.downGradePathSwVersion}
        >
          <StyledFieldTitle>
            {t('softwareVersions.details.supportedDowngradeVersions')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.downGradePathSwVersion?.join(', ')}
          </StyledTypography>
        </OptionalMetadataField>

        <StyledFieldTitle>
          {t('softwareVersions.details.fileName')}
        </StyledFieldTitle>
        <StyledTypography>{softwareVersionMetadata.fileName}</StyledTypography>

        <OptionalMetadataField metadata={softwareVersionMetadata.fileHashValue}>
          <StyledFieldTitle>
            {t('softwareVersions.details.fileHashValue')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.fileHashValue}
          </StyledTypography>
        </OptionalMetadataField>

        <OptionalMetadataField metadata={softwareVersionMetadata.fileHashType}>
          <StyledFieldTitle>
            {t('softwareVersions.details.fileHashType')}
          </StyledFieldTitle>
          <StyledTypography>
            {softwareVersionMetadata.fileHashType}
          </StyledTypography>
        </OptionalMetadataField>
      </CardContent>
    </StyledCard>
  );
};
