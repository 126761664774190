import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsType } from '../../../app-shell/model/app.models';
import QueryComponent from '../../../app-shell/ui/query-component';
import TabsBase, { TabPanel } from '../../../app-shell/ui/tabs-base';
import { useCustomerManagementTabs } from '../../service/customer-service/customer-service';
import CustomerAccountRegistrationForm from '../customer-registration/customer-account-registration';
import CustomerManagementOverviewTab from './customer-management-overview-tab';

export const CustomerManagementTabsComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const customerManagementResult = useCustomerManagementTabs();
  const { data: tabs } = customerManagementResult;

  const tabLabels =
    tabs?.map((tab) => ({ label: t(tab.title), hasBadge: false })) ?? [];

  function handleTabsChange(
    _: React.ChangeEvent<unknown>,
    newValue: number,
  ): void {
    setActiveTab(newValue);
  }

  function renderTabPanel(type: TabsType): JSX.Element {
    switch (type) {
      case TabsType.OverviewCustomer: {
        return <CustomerManagementOverviewTab />;
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case TabsType.RegisterCustomer:
      default: {
        return <CustomerAccountRegistrationForm />;
      }
    }
  }

  return (
    <QueryComponent data={customerManagementResult}>
      {tabs && (
        <>
          <TabsBase
            value={activeTab}
            handleChange={handleTabsChange}
            tabs={tabLabels}
          />
          {tabs.map((tab, index) => (
            <TabPanel key={tab.title} index={index} value={activeTab}>
              {renderTabPanel(tab.type)}
            </TabPanel>
          ))}
        </>
      )}
    </QueryComponent>
  );
};
