import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SearchObject } from '../../device/model/search.model';
import { SearchParameterValues } from '../../device/model/search.model';
import type { OrganizationSearchResult } from '../model/organization-search.model';
import { getDeviceIdsFromOrganization } from './fleet-api-client';

export function useOrganizationSearch(
  search: SearchObject | undefined,
): UseQueryResult<OrganizationSearchResult, Error> {
  return useQuery({
    // eslint rule does not know that optional chaining does not change a value
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['organizationSearch', search?.selector, search?.search],
    queryFn: async ({ signal }) =>
      search?.selector === SearchParameterValues.OrganizationId
        ? getDeviceIdsFromOrganization(search.search, undefined, signal)
        : getDeviceIdsFromOrganization(undefined, search?.search, signal),
    enabled: (
      [
        SearchParameterValues.OrganizationId,
        SearchParameterValues.OrganizationEmail,
      ] as (string | undefined)[]
    ).includes(search?.selector),
    refetchOnWindowFocus: false,
  });
}
