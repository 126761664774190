import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, type Dispatch, type JSX, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { StihlHelperText } from '../../../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import { SthilLocalizationProvider } from '../../../../../../base/stihl-material-ui/components/stihl-localization-provider/stihl-localization-provider';
import StihlIconBin from '../../../../../../base/stihl-material-ui/icons/stihl-icon-bin';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledContainer = styled.form`
  display: flex;
  align-items: flex-start;
  padding-block-start: 1.5rem;
  color: black;
`;

const RangeTypography = styled(Typography)`
  align-self: center;
  padding-inline: 0.5rem;
`;

const StyledClearChip = styled(Chip)`
  align-self: center;
  margin-inline: 1rem 0.75rem;
  padding-inline-start: 0.33rem;
  background-color: ${stihlColor.greyLight};
`;

export type ConnectionHistoryDatepickerProps = {
  dateStart: Date | null;
  setDateStart: Dispatch<SetStateAction<Date | null>>;
  dateEnd: Date | null;
  setDateEnd: Dispatch<SetStateAction<Date | null>>;
};

export const ConnectionHistoryDatepicker = ({
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
}: ConnectionHistoryDatepickerProps): JSX.Element => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);

  function clearFilter(): void {
    setHasError(false);
    setDateStart(null);
    setDateEnd(null);
  }

  return (
    <>
      <StyledContainer>
        <SthilLocalizationProvider>
          <RangeTypography>{t('connectionHistory.startDate')}</RangeTypography>
          <DatePicker
            value={dateStart}
            onChange={(value) => {
              setHasError(false);
              setDateStart(value);
            }}
            onError={(error) => {
              setHasError(error != null); // Sometimes falsely triggered with error = null
            }}
          />
          <RangeTypography>{t('connectionHistory.endDate')}</RangeTypography>
          <DatePicker
            value={dateEnd}
            onChange={(value) => {
              setHasError(false);
              setDateEnd(value);
            }}
            onError={(error) => {
              setHasError(error != null); // Sometimes falsely triggered with error = null
            }}
          />
        </SthilLocalizationProvider>
        {(dateStart != null || dateEnd != null) && (
          <StyledClearChip
            label={t('connectionHistory.clearDateFilter')}
            icon={<StihlIconBin />}
            onClick={clearFilter}
            data-testid="clearFilterChip"
          />
        )}
      </StyledContainer>
      {hasError && <StihlHelperText text="deviceManagementFilter.errorDate" />}
    </>
  );
};
