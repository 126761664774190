import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import type { ModalProps } from '@mui/material/Modal';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconClose from '../../icons/stihl-icon-close';
import { stihlColor } from '../../theme/stihl-style-guide';
import StihlButtonOutlined from '../stihl-button/stihl-button-outlined';
import StihlButtonSecondaryLoading from '../stihl-button/stihl-button-secondary-loading';

const StyledModal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: start;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  inline-size: 50vw;
  min-inline-size: 370px;
  max-inline-size: 750px;
  min-block-size: 200px;
  max-block-size: 70%;
  padding: 2.5rem 3.5rem;
  overflow: hidden;
  background-color: ${stihlColor.white};
  transform: translate(-50%, -50%);
`;

const ModalBody = styled.div`
  flex-grow: 1;
  margin: 1rem 0;
  overflow-y: auto;
`;

const CloseButtonPositioner = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  background-color: ${stihlColor.black};
`;

const ActionButtonsPositioner = styled.div`
  inset-block-end: 1rem;
  display: flex;
  justify-content: space-between;
  justify-self: end;
`;

export type StihlModalProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  actionButtonText?: string;
  cancelButtonText?: string;
  handleActionClick?: () => void;
  handleOnClose?: () => void;
  isLoading?: boolean;
  noButtons?: boolean;
  isDisabled?: boolean;
  noCloseButton?: boolean;
};

const StihlModal: FC<ModalProps & StihlModalProps> = ({
  title,
  open,
  onClose,
  actionButtonText,
  cancelButtonText,
  handleActionClick,
  handleOnClose,
  isLoading = false,
  noButtons = false,
  isDisabled = false,
  noCloseButton = false,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} {...props}>
      {/* onClick needed to prevent clicks in the background */}
      {/* eslint-disable-next-line styled-components-a11y/no-static-element-interactions, styled-components-a11y/click-events-have-key-events */}
      <StyledModal onClick={(event) => event.stopPropagation()}>
        {noCloseButton ? null : (
          <CloseButtonPositioner>
            <IconButton
              onClick={handleOnClose ?? onClose}
              data-testid="modalClose"
              size="large"
            >
              <StihlIconClose color="white" />
            </IconButton>
          </CloseButtonPositioner>
        )}
        <Typography variant="h3">{title}</Typography>
        <ModalBody>{props.children}</ModalBody>
        {!noButtons && (
          <ActionButtonsPositioner>
            <StihlButtonOutlined
              onClick={handleOnClose ?? onClose}
              data-testid="modalCancel"
            >
              {cancelButtonText ?? t('cancel')}
            </StihlButtonOutlined>

            <StihlButtonSecondaryLoading
              loading={isLoading}
              disabled={isDisabled}
              onClick={handleActionClick}
              data-testid="modalAction"
            >
              {actionButtonText}
            </StihlButtonSecondaryLoading>
          </ActionButtonsPositioner>
        )}
      </StyledModal>
    </Modal>
  );
};

export default StihlModal;
