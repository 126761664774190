import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import StihlIconLegend from '../../../../base/stihl-material-ui/icons/stihl-icon-legend';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlThemeColor } from '../../../../base/stihl-material-ui/theme/stihl-theme-colors';
import type { SwVersions } from '../../model/statistics.model';

const StyledBarChartContainer = styled.div`
  inline-size: 100%;
  block-size: 320px;
`;
const StyledLegendContainer = styled.div`
  inline-size: 100%;
  margin: 0 auto 2rem;
  padding-inline-start: 3rem;
`;

export type StatisticsVersionRolloutProps = {
  softwareVersions: SwVersions[];
};
const StatisticsVersionRollout = ({
  softwareVersions,
}: StatisticsVersionRolloutProps): JSX.Element => {
  const { t } = useTranslation();
  const data = softwareVersions.map((swVersion) => ({
    name: swVersion.targetSwVersion,
    installed: swVersion.numInstalled,
    assigned: swVersion.numAssigned,
    downloaded: swVersion.numDownloadedOnly,
  }));

  const iconLabels: string[] = [
    t('deviceStatistics.swVersionRollout.assigned'),
    t('deviceStatistics.swVersionRollout.installed'),
    t('deviceStatistics.swVersionRollout.downloaded'),
  ];
  const iconColors: StihlThemeColor[] = ['black', 'yellowDark', 'grey.500'];

  return (
    <>
      <StyledBarChartContainer>
        <Typography variant="h3" data-testid="softwareVersionRollout">
          {t('deviceStatistics.swVersionRollout.swVersionRollout')}
        </Typography>
        {/* see https://github.com/recharts/recharts/issues/172 */}
        <ResponsiveContainer width="99%" height={320}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 50,
              left: 0,
              bottom: 25,
            }}
          >
            <Tooltip cursor={{ fill: `${stihlColor.greyLight}` }} />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar
              dataKey="assigned"
              name={t('deviceStatistics.swVersionRollout.assigned')}
              fill={stihlColor.black}
            />
            <Bar
              dataKey="installed"
              name={t('deviceStatistics.swVersionRollout.installed')}
              stackId="a"
              fill={stihlColor.yellowDark}
            />
            <Bar
              dataKey="downloaded"
              name={t('deviceStatistics.swVersionRollout.downloaded')}
              stackId="a"
              fill={stihlColor.greyBase}
            />
          </BarChart>
        </ResponsiveContainer>
      </StyledBarChartContainer>
      <StyledLegendContainer>
        {iconLabels.map((versionRolloutStatus, index) => (
          <Typography
            component="span"
            sx={{ margin: '.2rem' }}
            key={versionRolloutStatus}
          >
            <StihlIconLegend color={iconColors[index % iconColors.length]} />{' '}
            {versionRolloutStatus}
          </Typography>
        ))}
      </StyledLegendContainer>
    </>
  );
};
export default StatisticsVersionRollout;
