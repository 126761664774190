import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import type { DateFieldValue } from '../../../../model';
import CardContentItem from './card-content-item';

const DateText = ({
  name,
  value,
}: {
  name: string;
  value: DateFieldValue;
}): JSX.Element => {
  const { toLocaleDateString, toLocaleDateTimeString } = useDateFormatting();

  const date = new Date(value.date);
  const dateString = value.isDateOnly
    ? toLocaleDateString(date)
    : toLocaleDateTimeString(date);

  return (
    <CardContentItem name={name}>
      <Typography>{dateString}</Typography>
    </CardContentItem>
  );
};

export default DateText;
