import type { UseQueryResult } from '@tanstack/react-query';
import type { EmailSearchResult } from '../../../email-search/model';
import { useEmailSearch } from '../../../email-search/service/email-search-service';
import type { OrganizationSearchResult } from '../../../organization-search/model/organization-search.model';
import { useOrganizationSearch } from '../../../organization-search/service/fleet-api-service';
import type {
  DeviceSearchResult,
  SearchObject,
} from '../../model/search.model';
import { useDeviceDetailsSearch } from './device-service';

export type SearchResult =
  | ({ type: 'device' } & UseQueryResult<DeviceSearchResult>)
  | ({ type: 'email' } & UseQueryResult<EmailSearchResult>)
  | ({ type: 'organization' } & UseQueryResult<OrganizationSearchResult>);

export function useDeviceSearch(
  search: SearchObject | undefined,
): SearchResult {
  const deviceSearchResult = useDeviceDetailsSearch(search);
  const emailSearchResult = useEmailSearch(search);
  const organizationSearchResult = useOrganizationSearch(search);

  if (
    !emailSearchResult.isPending ||
    emailSearchResult.fetchStatus !== 'idle'
  ) {
    return Object.assign(emailSearchResult, { type: 'email' as const });
  }

  if (
    !organizationSearchResult.isPending ||
    organizationSearchResult.fetchStatus !== 'idle'
  ) {
    return Object.assign(organizationSearchResult, {
      type: 'organization' as const,
    });
  }

  return Object.assign(deviceSearchResult, { type: 'device' as const });
}
