import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlDeviceTag } from '../../../device/model/device.model';
import { deviceTags } from '../overview-items/utils/device-management.const';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

const DeviceTagFilter = ({
  deviceTagCallback,
  shouldReset,
}: {
  deviceTagCallback: (filterValue: StihlDeviceTag | undefined) => void;
  shouldReset: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [deviceTag, setDeviceTag] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (shouldReset) {
      setDeviceTag(undefined);
    }
  }, [shouldReset]);

  function handleDeviceTagChange(event: ChangeEvent<HTMLInputElement>): void {
    setDeviceTag(event.target.value);
    deviceTagCallback(event.target.value as StihlDeviceTag);
  }

  return (
    <StyledWrapper>
      <InputLabel
        variant="standard"
        htmlFor="deviceTag"
        sx={{ color: stihlColor.black }}
      >
        {t('filterLabel.deviceTag')}
      </InputLabel>
      <StihlSelect
        id="deviceTag"
        value={deviceTag}
        onChange={handleDeviceTagChange}
        fullWidth
        data-testid="deviceTagFilter"
      >
        <MenuItem value={undefined}>
          {t('deviceTagFilter.emptyPlaceholder')}
        </MenuItem>

        {deviceTags.map((stihlDeviceTag) => (
          <MenuItem key={stihlDeviceTag.value} value={stihlDeviceTag.value}>
            {stihlDeviceTag.label}
          </MenuItem>
        ))}
      </StihlSelect>
    </StyledWrapper>
  );
};

export default DeviceTagFilter;
