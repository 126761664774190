/* eslint-disable unicorn/no-thenable */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-lines-per-function */
import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { string, object, boolean } from 'yup';
import StihlButtonSecondaryLoading from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlCircleValid from '../../../../base/stihl-material-ui/icons/stihl-icon-circle-valid';
import StihlNumberOne from '../../../../base/stihl-material-ui/icons/stihl-number-one';
import StihlNumberTwo from '../../../../base/stihl-material-ui/icons/stihl-number-two';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { CustomerCreationResponse } from '../../model/customer-profile.model';
import createCustomer from '../../service/customer-api-client/customer-api-client';
import { CustomerAccountRegistrationConfirmationModal } from './customer-account-registration-confirmation-modal';
import type { CreateCustomerValues } from './steps/customer-account-registration-step-one';
import CustomerAccountRegistrationStepOne from './steps/customer-account-registration-step-one';
import type { DeviceFields } from './steps/customer-account-registration-step-two';
import CustomerAccountRegistrationStepTwo from './steps/customer-account-registration-step-two';
// eslint-disable-next-line import/max-dependencies
import { getAlertMessage } from './utils/form-helper.utils';

const FormWrapper = styled.div`
  padding: 1rem;

  > form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const StyledAccordionDetails = styled(MuiAccordionDetails)`
  max-inline-size: 650px;
`;

const StyledHorizontalLine = styled.hr`
  inline-size: 100%;
  margin: 0;
  border-block-start: 1px solid ${stihlColor.greyMid};
`;

export const IconTextWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;

  /* This is the recommended way to style MUI components */
  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiAccordionSummary-expandIconWrapper {
    align-items: start;
    margin: 0;
  }

  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }

  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    padding-inline-start: 1rem;
    padding-block-start: 0.25rem;
  }
`;

export const initialValues: CreateCustomerValues & DeviceFields = {
  lastName: '',
  firstName: '',
  email: '',
  language: 'de-DE',
  country: 'DE',
  serialNumber: '',
  verificationCode: '',
  isVerificationCodeEnabled: false,
  isConnectingDevice: false,
};

const validationSchema = object({
  isVerificationCodeEnabled: boolean(),
  lastName: string()
    .matches(/^[\s\p{L}'-]+$/u, 'formik.validCharacters')
    .required('formik.validLastName')
    .max(30, 'formik.max30Chars'),
  firstName: string()
    .matches(/^[\s\p{L}'-]+$/u, 'formik.validCharacters')
    .required('formik.validFirstName')
    .max(30, 'formik.max30Chars'),
  email: string()
    // eslint-disable-next-line regexp/no-super-linear-backtracking
    .matches(/^\S+@\S+\.\S+$/u, 'formik.containsValidEmail')
    .required('formik.enterValidEmail')
    .max(60, 'formik.max60Chars'),
  country: string().required(),
  language: string().required(),
  serialNumber: string()
    .matches(/^\d*$/u, 'formik.onlyNumbers')
    .min(9, 'formik.min9Digits')
    .max(10, 'formik.max10Digits')
    .when('isVerificationCodeEnabled', {
      is: (isVerificationCodeEnabled: boolean) => isVerificationCodeEnabled,
      then: (schema) => schema.required('formik.enterSerialNumber'),
    })
    .when('isConnectingDevice', {
      is: (isConnectingDevice: boolean) => isConnectingDevice,
      then: (schema) => schema.required('formik.enterSerialNumber'),
    }),
  verificationCode: string()
    .matches(/^\d*$/u, 'formik.onlyDigits')
    .length(2, 'formik.exact2Digits')
    .when('isVerificationCodeEnabled', {
      is: (isVerificationCodeEnabled: boolean) => isVerificationCodeEnabled,
      then: (schema) => schema.required('formik.enter2DigitsCode'),
    }),
  isConnectingDevice: boolean(),
});

export const CustomerAccountRegistrationForm = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isStepOneValidated, setIsStepOneValidated] = useState(false);
  const [isStepTwoValidated, setIsStepTwoValidated] = useState(false);
  const [expanded, setExpanded] = useState<string>('step1');
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [storedSubmitData, setStoredSubmitData] = useState<
    | {
        valuesToSubmit: CreateCustomerValues & DeviceFields;
        actions: FormikHelpers<CreateCustomerValues & DeviceFields>;
      }
    | undefined
  >(undefined);
  const [_, setAlert] = useAlertStore();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  initialValues.serialNumber = query.get('serialNumber') ?? '';

  async function submit(
    valuesToSubmit: CreateCustomerValues & DeviceFields,
    actions: FormikHelpers<CreateCustomerValues & DeviceFields>,
  ): Promise<void> {
    actions.setSubmitting(false);
    const {
      isVerificationCodeEnabled: _IsVerificationCodeEnabled,
      ...submitValues
    } = valuesToSubmit;
    setIsLoading(true);
    await createCustomer({
      ...submitValues,
      language: `${valuesToSubmit.language}`,
    })
      .then((customerCreationResponse) => {
        setAlertDependingOnResponse(customerCreationResponse);
        actions.resetForm();

        history.push('/customer-management');
        setIsLoading(false);
        setConfirmationModalOpen(false);
        setStoredSubmitData(undefined);
        return true;
      })
      .catch((error: AxiosError) => {
        setAlert({
          isOpen: true,
          message: t('customerRegistration.errorMessage'),
          severity: 'error',
        });

        if (
          error.message.includes('400') &&
          error.message.includes('Verification code')
        ) {
          actions.setFieldError(
            'verificationCode',
            t('customerRegistration.serialNumberCodeErrorMessage'),
          );
        }
        setIsLoading(false);
      });
  }

  function setAlertDependingOnResponse(
    customerCreationResponse: CustomerCreationResponse,
  ): void {
    const { message, severity, reason } = getAlertMessage(
      customerCreationResponse,
    );
    let fullMessage = t(message);
    if (reason) {
      fullMessage += ` ${t(reason)}`;
    }
    setAlert({
      isOpen: true,
      message: fullMessage,
      severity,
    });
  }

  function handleStepChange(panel: string): void {
    setExpanded(panel);
  }

  async function handleSubmit(
    valuesToSubmit: CreateCustomerValues & DeviceFields,
    actions: FormikHelpers<CreateCustomerValues & DeviceFields>,
  ): Promise<void> {
    if (valuesToSubmit.isConnectingDevice) {
      setConfirmationModalOpen(true);
      setStoredSubmitData({ valuesToSubmit, actions });
    } else {
      await handleHelperForSubmit(valuesToSubmit, actions);
    }
  }

  async function handleHelperForSubmit(
    valuesToSubmit: CreateCustomerValues & DeviceFields,
    actions: FormikHelpers<CreateCustomerValues & DeviceFields>,
  ): Promise<void> {
    const submitValues: CreateCustomerValues & DeviceFields = {
      lastName: valuesToSubmit.lastName,
      firstName: valuesToSubmit.firstName,
      email: valuesToSubmit.email,
      language: valuesToSubmit.language,
      country: valuesToSubmit.country,
      serialNumber: '',
      verificationCode: '',
      isVerificationCodeEnabled: false,
    };
    if (valuesToSubmit.isConnectingDevice) {
      submitValues.serialNumber = valuesToSubmit.serialNumber;
      submitValues.verificationCode = valuesToSubmit.verificationCode;
      submitValues.isVerificationCodeEnabled =
        valuesToSubmit.isVerificationCodeEnabled;
    }
    await submit(submitValues, actions);
  }

  function handleConfirmationModalSubmit(): void {
    if (!storedSubmitData) {
      return;
    }

    const { valuesToSubmit, actions } = storedSubmitData;

    void handleHelperForSubmit(valuesToSubmit, actions);
  }

  function handleOnClose(): void {
    setConfirmationModalOpen(false);
    setStoredSubmitData(undefined);
  }

  return (
    <>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form data-testid="customerRegistrationForm">
            <MuiAccordion
              expanded={expanded === 'step1'}
              onChange={() => handleStepChange('step1')}
              data-testid="panelStepOne"
              style={{
                margin: '0',
                background: 'transparent',
                boxShadow: 'none',
              }}
            >
              <StyledMuiAccordionSummary
                id="step1-header"
                expandIcon={
                  isStepOneValidated ? <StihlCircleValid /> : <StihlNumberOne />
                }
              >
                <Typography variant="h3" data-testid="createNewCustomerAccount">
                  {t('customerRegistration.titleStepOne')}
                </Typography>
                <Typography
                  style={{ textDecoration: 'underline', fontSize: '0.9rem' }}
                  hidden={expanded === 'step1'}
                >
                  {t('customerRegistration.edit')}
                </Typography>
              </StyledMuiAccordionSummary>
              <StyledAccordionDetails>
                <CustomerAccountRegistrationStepOne
                  isStepOneValidated={isStepOneValidated}
                  setIsStepOneValidated={setIsStepOneValidated}
                  handleStepChange={handleStepChange}
                  isLoading={isLoading}
                />
              </StyledAccordionDetails>
            </MuiAccordion>
            <StyledHorizontalLine />
            <MuiAccordion
              expanded={expanded === 'step2'}
              data-testid="panelStepTwo"
              // these styles are needed for Mui overwrite specificity reasons
              sx={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '&::before': {
                  blockSize: '0',
                },
              }}
              style={{
                margin: '0',
                background: 'transparent',
                boxShadow: 'none',
              }}
            >
              <StyledMuiAccordionSummary
                id="step2-header"
                expandIcon={
                  isStepTwoValidated ? <StihlCircleValid /> : <StihlNumberTwo />
                }
              >
                <Typography variant="h3">
                  {t('customerRegistration.titleStepTwo')}
                </Typography>
              </StyledMuiAccordionSummary>
              <StyledAccordionDetails>
                <CustomerAccountRegistrationStepTwo
                  isExpanded={expanded === 'step2'}
                  setIsStepTwoValidated={setIsStepTwoValidated}
                />
                <br />
                <Typography paragraph>
                  {t('customerRegistration.reminder')}
                </Typography>

                <StihlButtonSecondaryLoading
                  type="submit"
                  loading={isLoading}
                  data-testid="submitButtonWithDevice"
                >
                  {t('customerRegistration.finishRegistration')}
                </StihlButtonSecondaryLoading>
              </StyledAccordionDetails>
            </MuiAccordion>
          </Form>
        </Formik>
      </FormWrapper>
      <CustomerAccountRegistrationConfirmationModal
        open={isConfirmationModalOpen}
        onClose={handleOnClose}
        handleSubmit={handleConfirmationModalSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default CustomerAccountRegistrationForm;
/* eslint-enable max-lines-per-function */
/* eslint-enable react/jsx-max-depth */
/* eslint-enable unicorn/no-thenable */
