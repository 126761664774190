import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { Locale } from 'date-fns';
import {
  enUS,
  de,
  fr,
  it,
  cs,
  bg,
  da,
  el,
  fi,
  es,
  et,
  hr,
  hu,
  lt,
  lv,
  nb,
  nl,
  pl,
  pt,
  ro,
  sk,
  sl,
  sr,
  sv,
} from 'date-fns/locale';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const i18nLanguageToAdapterLocaleMapping: Record<string, Locale> = {
  'en-US': enUS,
  'de-DE': de,
  'fr-FR': fr,
  'it-IT': it,
  'cs-CZ': cs,
  'bg-BG': bg,
  'da-DK': da,
  'el-GR': el,
  'fi-FI': fi,
  'es-ES': es,
  'et-EE': et,
  'hr-HR': hr,
  'hu-HU': hu,
  'lt-LT': lt,
  'lv-LV': lv,
  'nb-NO': nb,
  'nl-NL': nl,
  'pl-PL': pl,
  'pt-PT': pt,
  'ro-RO': ro,
  'sk-SK': sk,
  'sl-SI': sl,
  'sr-RS': sr,
  'sv-SE': sv,
};

export const SthilLocalizationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { i18n } = useTranslation();

  const adapterLocale =
    // result actually CAN be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    i18nLanguageToAdapterLocaleMapping[i18n.language] ?? enUS;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
    >
      {children}
    </LocalizationProvider>
  );
};
