import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import CardContentItem from './card-content-item';

const PercentageText = ({
  name,
  icon,
  value,
}: {
  name: string;
  value: number;
  icon?: JSX.Element;
}): JSX.Element => {
  return (
    <CardContentItem name={name} icon={icon}>
      <Typography>{Math.round(value * 100)} %</Typography>
    </CardContentItem>
  );
};

export default PercentageText;
