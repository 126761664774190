export type Language = {
  languageCode: string;
  languageName: string;
  isAllowed: string[];
};

export const languagesArray: Language[] = [
  { languageCode: 'aa', languageName: 'Afar', isAllowed: [] },
  { languageCode: 'ab', languageName: 'Abkhazian', isAllowed: [] },
  { languageCode: 'ae', languageName: 'Avestan', isAllowed: [] },
  { languageCode: 'af', languageName: 'Afrikaans', isAllowed: [] },
  { languageCode: 'ak', languageName: 'Akan', isAllowed: [] },
  { languageCode: 'am', languageName: 'Amharic', isAllowed: [] },
  { languageCode: 'an', languageName: 'Aragonese', isAllowed: [] },
  {
    languageCode: 'ar-AE',
    languageName: 'Arabic',
    isAllowed: [
      'AE',
      'DZ',
      'EG',
      'IQ',
      'JO',
      'LB',
      'MA',
      'SA',
      'SD',
      'SY',
      'TN',
    ],
  },
  { languageCode: 'as', languageName: 'Assamese', isAllowed: [] },
  { languageCode: 'av', languageName: 'Avaric', isAllowed: [] },
  { languageCode: 'ay', languageName: 'Aymara', isAllowed: [] },
  { languageCode: 'az', languageName: 'Azerbaijani', isAllowed: [] },
  { languageCode: 'ba', languageName: 'Bashkir', isAllowed: [] },
  { languageCode: 'be-BY', languageName: 'Belarusian', isAllowed: ['BY'] },
  { languageCode: 'bg-BG', languageName: 'Bulgarian', isAllowed: ['BG'] },
  { languageCode: 'bh', languageName: 'Bihari languages', isAllowed: [] },
  { languageCode: 'bi', languageName: 'Bislama', isAllowed: [] },
  { languageCode: 'bm', languageName: 'Bambara', isAllowed: [] },
  { languageCode: 'bn', languageName: 'Bengali', isAllowed: [] },
  { languageCode: 'bo', languageName: 'Tibetan', isAllowed: [] },
  { languageCode: 'br', languageName: 'Breton', isAllowed: [] },
  { languageCode: 'bs-BA', languageName: 'Bosnian', isAllowed: ['BA'] },
  { languageCode: 'ca', languageName: 'Catalan', isAllowed: [] },
  { languageCode: 'ce', languageName: 'Chechen', isAllowed: [] },
  { languageCode: 'ch', languageName: 'Chamorro', isAllowed: [] },
  { languageCode: 'co', languageName: 'Corsican', isAllowed: [] },
  { languageCode: 'cr', languageName: 'Cree', isAllowed: [] },
  { languageCode: 'cs-CZ', languageName: 'Czech', isAllowed: ['CZ'] },
  { languageCode: 'cu', languageName: 'Church Slavic', isAllowed: [] },
  { languageCode: 'cv', languageName: 'Chuvash', isAllowed: [] },
  { languageCode: 'cy', languageName: 'Welsh', isAllowed: [] },
  { languageCode: 'da-DK', languageName: 'Danish', isAllowed: ['DK'] },
  {
    languageCode: 'de-DE',
    languageName: 'German',
    isAllowed: ['DE', 'AT', 'CH'],
  },
  { languageCode: 'dv', languageName: 'Maldivian', isAllowed: [] },
  { languageCode: 'dz', languageName: 'Dzongkha', isAllowed: [] },
  { languageCode: 'ee', languageName: 'Ewe', isAllowed: [] },
  { languageCode: 'el-CY', languageName: 'Greek', isAllowed: ['CY'] },
  { languageCode: 'el-GR', languageName: 'Greek', isAllowed: ['GR'] },
  {
    languageCode: 'en-GB',
    languageName: 'English',
    isAllowed: [
      'GB',
      'UK',
      'AU',
      'NZ',
      'ZA',
      'AG',
      'KM',
      'DM',
      'GH',
      'GD',
      'GY',
      'IE',
      'KE',
      'MU',
      'NG',
      'KN',
      'LC',
      'VC',
      'TT',
      'UG',
    ],
  },
  {
    languageCode: 'en-US',
    languageName: 'English',
    isAllowed: ['US', 'CA', 'JM', 'PH'],
  },
  { languageCode: 'eo', languageName: 'Esperanto', isAllowed: [] },
  { languageCode: 'es-AR', languageName: 'Spanish', isAllowed: ['AR'] },
  { languageCode: 'es-MX', languageName: 'Spanish', isAllowed: ['MX'] },
  {
    languageCode: 'es-ES',
    languageName: 'Spanish',
    isAllowed: [
      'ES',
      'BO',
      'CL',
      'CO',
      'CR',
      'DO',
      'EC',
      'SV',
      'GQ',
      'GT',
      'HN',
      'NI',
      'PA',
      'PY',
      'PE',
      'UY',
      'VE',
    ],
  },
  { languageCode: 'et-EE', languageName: 'Estonian', isAllowed: ['EE'] },
  { languageCode: 'eu', languageName: 'Basque', isAllowed: [] },
  { languageCode: 'fa-IR', languageName: 'Persian', isAllowed: ['IR'] },
  { languageCode: 'ff', languageName: 'Fulah', isAllowed: [] },
  { languageCode: 'fi-FI', languageName: 'Finnish', isAllowed: ['FI'] },
  { languageCode: 'fj', languageName: 'Fijian', isAllowed: [] },
  { languageCode: 'fo', languageName: 'Faroese', isAllowed: [] },
  {
    languageCode: 'fr-BE',
    languageName: 'French',
    isAllowed: ['BE', 'LU'],
  },
  { languageCode: 'fr-CH', languageName: 'French', isAllowed: ['CH'] },
  {
    languageCode: 'fr-FR',
    languageName: 'French',
    isAllowed: ['CA', 'FR', 'CM', 'CI', 'CD', 'PF', 'GA', 'MG', 'ML'],
  },
  { languageCode: 'fy', languageName: 'Western Frisian', isAllowed: [] },
  { languageCode: 'ga', languageName: 'Irish', isAllowed: [] },
  { languageCode: 'gd', languageName: 'Gaelic', isAllowed: [] },
  { languageCode: 'gl', languageName: 'Galician', isAllowed: [] },
  { languageCode: 'gn', languageName: 'Guaraní', isAllowed: [] },
  { languageCode: 'gu', languageName: 'Gujarati', isAllowed: [] },
  { languageCode: 'gv', languageName: 'Manx', isAllowed: [] },
  { languageCode: 'ha', languageName: 'Hausa', isAllowed: [] },
  { languageCode: 'he-IL', languageName: 'Hebrew', isAllowed: ['IL'] },
  { languageCode: 'hi-IN', languageName: 'Hindi', isAllowed: ['IN'] },
  { languageCode: 'ho', languageName: 'Hiri Motu', isAllowed: [] },
  { languageCode: 'hr-HR', languageName: 'Croatian', isAllowed: ['HR'] },
  { languageCode: 'ht', languageName: 'Haitian Creole', isAllowed: [] },
  { languageCode: 'hu-HU', languageName: 'Hungarian', isAllowed: ['HU'] },
  { languageCode: 'hy-AM', languageName: 'Armenian', isAllowed: ['AM'] },
  { languageCode: 'hz', languageName: 'Herero', isAllowed: [] },
  { languageCode: 'ia', languageName: 'Interlingua', isAllowed: [] },
  { languageCode: 'id-ID', languageName: 'Indonesian', isAllowed: ['ID'] },
  { languageCode: 'ie', languageName: 'Interlingue', isAllowed: [] },
  { languageCode: 'ig', languageName: 'Igbo', isAllowed: [] },
  { languageCode: 'ii', languageName: 'Nuosu', isAllowed: [] },
  { languageCode: 'ik', languageName: 'Inupiaq', isAllowed: [] },
  { languageCode: 'io', languageName: 'Ido', isAllowed: [] },
  { languageCode: 'is', languageName: 'Icelandic', isAllowed: [] },
  { languageCode: 'it-CH', languageName: 'Italian', isAllowed: ['CH'] },
  { languageCode: 'it-IT', languageName: 'Italian', isAllowed: ['IT'] },
  { languageCode: 'iu', languageName: 'Inuktitut', isAllowed: [] },
  { languageCode: 'ja-JP', languageName: 'Japanese', isAllowed: ['JP'] },
  { languageCode: 'jv', languageName: 'Javanese', isAllowed: [] },
  { languageCode: 'ka-GE', languageName: 'Georgian', isAllowed: ['GE'] },
  { languageCode: 'kg', languageName: 'Kongo', isAllowed: [] },
  { languageCode: 'ki', languageName: 'Kikuyu', isAllowed: [] },
  { languageCode: 'kj', languageName: 'Kwanyama', isAllowed: [] },
  { languageCode: 'kk-KZ', languageName: 'Kazakh', isAllowed: ['KZ'] },
  { languageCode: 'kl', languageName: 'Greenlandic', isAllowed: [] },
  { languageCode: 'km', languageName: 'Central Khmer', isAllowed: [] },
  { languageCode: 'kn', languageName: 'Kannada', isAllowed: [] },
  { languageCode: 'ko-KR', languageName: 'Korean', isAllowed: ['KR'] },
  { languageCode: 'kr', languageName: 'Kanuri', isAllowed: [] },
  { languageCode: 'ks', languageName: 'Kashmiri', isAllowed: [] },
  { languageCode: 'ku', languageName: 'Kurdish', isAllowed: [] },
  { languageCode: 'kv', languageName: 'Komi', isAllowed: [] },
  { languageCode: 'kw', languageName: 'Cornish', isAllowed: [] },
  { languageCode: 'ky-KG', languageName: 'Kyrgyz', isAllowed: ['KG'] },
  { languageCode: 'la', languageName: 'Latin', isAllowed: [] },
  { languageCode: 'lb', languageName: 'Luxembourgish', isAllowed: [] },
  { languageCode: 'lg', languageName: 'Ganda', isAllowed: [] },
  { languageCode: 'li', languageName: 'Limburgish', isAllowed: [] },
  { languageCode: 'ln', languageName: 'Lingala', isAllowed: [] },
  { languageCode: 'lo', languageName: 'Lao', isAllowed: [] },
  { languageCode: 'lt-LT', languageName: 'Lithuanian', isAllowed: ['LT'] },
  { languageCode: 'lu', languageName: 'Luba-Katanga', isAllowed: [] },
  { languageCode: 'lv-LV', languageName: 'Latvian', isAllowed: [] },
  { languageCode: 'mg', languageName: 'Malagasy', isAllowed: [] },
  { languageCode: 'mh', languageName: 'Marshallese', isAllowed: [] },
  { languageCode: 'mi', languageName: 'Maori', isAllowed: [] },
  { languageCode: 'mk-MK', languageName: 'Macedonian', isAllowed: ['MK'] },
  { languageCode: 'ml', languageName: 'Malayalam', isAllowed: [] },
  { languageCode: 'mn-MN', languageName: 'Mongolian', isAllowed: ['MN'] },
  { languageCode: 'mr', languageName: 'Marathi', isAllowed: [] },
  { languageCode: 'ms-MY', languageName: 'Malay', isAllowed: ['MY'] },
  { languageCode: 'mt', languageName: 'Maltese', isAllowed: [] },
  { languageCode: 'my-MM', languageName: 'Burmese', isAllowed: ['MM'] },
  { languageCode: 'na', languageName: 'Nauru', isAllowed: [] },
  { languageCode: 'nb', languageName: 'Bokmål', isAllowed: [] },
  { languageCode: 'nd', languageName: 'North Ndebele', isAllowed: [] },
  { languageCode: 'ne', languageName: 'Nepali', isAllowed: [] },
  { languageCode: 'ng', languageName: 'Ndonga', isAllowed: [] },
  { languageCode: 'nl-BE', languageName: 'Dutch', isAllowed: ['BE'] },
  {
    languageCode: 'nl-NL',
    languageName: 'Dutch',
    isAllowed: ['NL', 'AW', 'CW', 'SR'],
  },
  { languageCode: 'nn', languageName: 'Norwegian Nynorsk', isAllowed: [] },
  { languageCode: 'nb-NO', languageName: 'Norwegian', isAllowed: ['NO'] },
  { languageCode: 'nr', languageName: 'South Ndebele', isAllowed: [] },
  { languageCode: 'nv', languageName: 'Navajo', isAllowed: [] },
  { languageCode: 'ny', languageName: 'Nyanja', isAllowed: [] },
  { languageCode: 'oc', languageName: 'Occitan', isAllowed: [] },
  { languageCode: 'oj', languageName: 'Ojibwa', isAllowed: [] },
  { languageCode: 'om', languageName: 'Oromo', isAllowed: [] },
  { languageCode: 'or', languageName: 'Oriya', isAllowed: [] },
  { languageCode: 'os', languageName: 'Ossetian', isAllowed: [] },
  { languageCode: 'pa', languageName: 'Punjabi', isAllowed: [] },
  { languageCode: 'pi', languageName: 'Pali', isAllowed: [] },
  { languageCode: 'pl-PL', languageName: 'Polish', isAllowed: ['PL'] },
  { languageCode: 'ps', languageName: 'Pashto', isAllowed: [] },
  {
    languageCode: 'pt-BR',
    languageName: 'Brasilian Portuguese',
    isAllowed: ['BR'],
  },
  {
    languageCode: 'pt-PT',
    languageName: 'Portuguese',
    isAllowed: ['PT', 'AO'],
  },
  { languageCode: 'qu', languageName: 'Quechua', isAllowed: [] },
  { languageCode: 'rm', languageName: 'Romansh', isAllowed: [] },
  { languageCode: 'rn', languageName: 'Rundi', isAllowed: [] },
  { languageCode: 'ro-RO', languageName: 'Romanian', isAllowed: ['RO', 'MD'] },
  { languageCode: 'ru-RU', languageName: 'Russian', isAllowed: ['RU'] },
  { languageCode: 'rw', languageName: 'Kinyarwanda', isAllowed: [] },
  { languageCode: 'sa', languageName: 'Sanskrit', isAllowed: [] },
  { languageCode: 'sc', languageName: 'Sardinian', isAllowed: [] },
  { languageCode: 'sd', languageName: 'Sindhi', isAllowed: [] },
  { languageCode: 'se', languageName: 'Northern Sami', isAllowed: [] },
  { languageCode: 'sg', languageName: 'Sango', isAllowed: [] },
  { languageCode: 'si', languageName: 'Sinhalese', isAllowed: [] },
  { languageCode: 'sk-SK', languageName: 'Slovak', isAllowed: ['SK'] },
  { languageCode: 'sl-SI', languageName: 'Slovenian', isAllowed: ['SI'] },
  { languageCode: 'sm', languageName: 'Samoan', isAllowed: [] },
  { languageCode: 'sn', languageName: 'Shona', isAllowed: [] },
  { languageCode: 'so', languageName: 'Somali', isAllowed: [] },
  { languageCode: 'sq-AL', languageName: 'Albanian', isAllowed: ['AL'] },
  { languageCode: 'sr-RS', languageName: 'Serbian', isAllowed: ['RS'] },
  { languageCode: 'ss', languageName: 'Swati', isAllowed: [] },
  { languageCode: 'st', languageName: 'Southern Sotho', isAllowed: [] },
  { languageCode: 'su', languageName: 'Sundanese', isAllowed: [] },
  { languageCode: 'sv-SE', languageName: 'Swedish', isAllowed: ['SE'] },
  { languageCode: 'sw', languageName: 'Swahili', isAllowed: [] },
  { languageCode: 'ta', languageName: 'Tamil', isAllowed: [] },
  { languageCode: 'te', languageName: 'Telugu', isAllowed: [] },
  { languageCode: 'tg', languageName: 'Tajik', isAllowed: [] },
  { languageCode: 'th-TH', languageName: 'Thai', isAllowed: ['TH'] },
  { languageCode: 'ti', languageName: 'Tigrinya', isAllowed: [] },
  { languageCode: 'tk-TM', languageName: 'Turkmen', isAllowed: ['TM'] },
  { languageCode: 'tl', languageName: 'Tagalog', isAllowed: [] },
  { languageCode: 'tn', languageName: 'Tswana', isAllowed: [] },
  { languageCode: 'to', languageName: 'Tonga', isAllowed: [] },
  { languageCode: 'tr-TR', languageName: 'Turkish', isAllowed: ['TR'] },
  { languageCode: 'ts', languageName: 'Tsonga', isAllowed: [] },
  { languageCode: 'tt', languageName: 'Tatar', isAllowed: [] },
  { languageCode: 'tw', languageName: 'Twi', isAllowed: [] },
  { languageCode: 'ty', languageName: 'Tahitian', isAllowed: [] },
  { languageCode: 'ug', languageName: 'Uyghur', isAllowed: [] },
  { languageCode: 'uk-UA', languageName: 'Ukrainian', isAllowed: ['UA'] },
  { languageCode: 'ur', languageName: 'Urdu', isAllowed: [] },
  { languageCode: 'uz-UZ', languageName: 'Uzbek', isAllowed: ['UZ'] },
  { languageCode: 've', languageName: 'Venda', isAllowed: [] },
  { languageCode: 'vi-VN', languageName: 'Vietnamese', isAllowed: ['VN'] },
  { languageCode: 'vo', languageName: 'Volapük', isAllowed: [] },
  { languageCode: 'wa', languageName: 'Walloon', isAllowed: [] },
  { languageCode: 'wo', languageName: 'Wolof', isAllowed: [] },
  { languageCode: 'xh', languageName: 'Xhosa', isAllowed: [] },
  { languageCode: 'yi', languageName: 'Yiddish', isAllowed: [] },
  { languageCode: 'yo', languageName: 'Yoruba', isAllowed: [] },
  { languageCode: 'za', languageName: 'Zhuang', isAllowed: [] },
  { languageCode: 'zh-CN', languageName: 'Chinese', isAllowed: ['CN'] },
  { languageCode: 'zh-HK', languageName: 'Chinese', isAllowed: ['HK'] },
  { languageCode: 'zh-TW', languageName: 'Chinese', isAllowed: ['TW'] },
];
