import {
  FilterParameterValue,
  type ChipType,
} from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type { CustomerManagementCustomer } from '../../model/customer-account.model';

export function matchesNameFilter(
  filterValue: string,
  firstName: string,
  lastName: string,
): boolean {
  const lowerCasedFirstName = firstName.toLowerCase();
  const lowerCasedLastName = lastName.toLowerCase();
  const lowerCasedFilterValue = filterValue.toLowerCase();
  return (
    lowerCasedFirstName.startsWith(lowerCasedFilterValue) ||
    lowerCasedLastName.startsWith(lowerCasedFilterValue) ||
    `${lowerCasedFirstName} ${lowerCasedLastName}`.startsWith(
      lowerCasedFilterValue,
    ) ||
    `${lowerCasedLastName} ${lowerCasedFirstName}`.startsWith(
      lowerCasedFilterValue,
    )
  );
}

export function filterDataByChips(
  customerData: CustomerManagementCustomer[],
  chips: ChipType[],
): CustomerManagementCustomer[] {
  return customerData.filter((customer) => {
    if (chips.length === 0) {
      return true;
    }

    for (const chip of chips) {
      if (
        chip.filter === FilterParameterValue.Name &&
        !matchesNameFilter(
          chip.searchTerm,
          customer.firstName,
          customer.lastName,
        )
      ) {
        return false;
      }
      if (
        chip.filter === FilterParameterValue.SerialNumber &&
        !customer.equipments.some((equipment) =>
          equipment.serialNumber.startsWith(chip.searchTerm),
        )
      ) {
        return false;
      }
      if (
        chip.filter === FilterParameterValue.Email &&
        !customer.email.startsWith(chip.searchTerm)
      ) {
        return false;
      }
    }
    return true;
  });
}
