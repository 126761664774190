import type { JSX } from 'react';
import type { Meta, Customer, Card } from '../../../device/model';
import { CardBase } from '../../../device/ui/card/ui/card-component';
import CustomerDetailsCardContent from './customer-details-card-content';
import {
  CustomerDetailsEmptyCardContent,
  CustomerDetailsErrorCardContent,
} from './customer-details-no-card-content';

export type CustomerDetailsCardProps = {
  meta: Meta;
  cardData: Card;
};

function returnCardContent(customer: Customer, meta: Meta): JSX.Element {
  if (!customer.hasCustomersAssigned && !customer.hasError) {
    return (
      <CustomerDetailsEmptyCardContent
        deviceSerialNumber={meta.deviceSerialNumber}
      />
    );
  }
  if (customer.hasError) {
    return <CustomerDetailsErrorCardContent />;
  }
  return (
    <CustomerDetailsCardContent
      customer={customer}
      isUserDealer={meta.hasMatchingPreferredDealer}
      hasCustomerAccess={meta.hasCustomerAccess}
    />
  );
}

const CustomerDetailsCard = ({
  meta,
  cardData,
}: CustomerDetailsCardProps): JSX.Element => {
  const customer = cardData.cardFields[0].value as Customer;

  return (
    <CardBase title="customerDetails.customerDetails">
      {returnCardContent(customer, meta)}
    </CardBase>
  );
};
export default CustomerDetailsCard;
