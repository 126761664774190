import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { JobStateValue, JobValue } from '../../../../model';
import CardContentItem from './card-content-item';

type JobTextProps = {
  name: string;
  value: JobValue;
};
const JobText = ({ name, value }: JobTextProps): JSX.Element => {
  const stateValue: JobStateValue | undefined = value.value;
  const { t } = useTranslation();
  return (
    <CardContentItem name={name}>
      <Grid container>
        {Boolean(stateValue) && (
          <>
            <Typography>{t('mower.mowingZoneId')}</Typography>
            <Typography>{t('mower.startEndTime')}</Typography>
          </>
        )}
        <Fragment key={value.state}>
          <Typography>{value.state}</Typography>
          {Boolean(stateValue) && (
            <>
              <Typography>
                {(t('statusTexts.inZone'), stateValue?.mowingZoneId)}, cyclic{' '}
                {stateValue?.isNonCyclic ? t('off') : t('on')}
              </Typography>
              <Typography>
                {stateValue?.startTime} - {stateValue?.endTime}
              </Typography>
            </>
          )}
        </Fragment>
      </Grid>
    </CardContentItem>
  );
};

export default JobText;
