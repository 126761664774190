import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import StihlIconLegend from '../../../../base/stihl-material-ui/icons/stihl-icon-legend';
import type { StihlThemeColor } from '../../../../base/stihl-material-ui/theme/stihl-theme-colors';

const StyledPieChartContainer = styled.div`
  flex-basis: 70%;
  inline-size: 100%;
  block-size: 320px;
`;
const StyledLegendContainer = styled.div`
  display: flex;
  flex-basis: 30%;
  flex-flow: column wrap;
  justify-content: center;
  inline-size: 100%;
  block-size: 230px;
  margin: auto;
  text-align: start;

  > span {
    margin: 0;
  }
`;

const StatisticsPieChart = ({
  title,
  chartData,
  colors,
  iconColors,
}: {
  title: string;
  chartData: { name: string; value: number }[];
  colors: string[];
  iconColors: StihlThemeColor[];
}): JSX.Element => {
  return (
    <>
      <StyledPieChartContainer>
        <Typography variant="h3">{title}</Typography>
        {/* see https://github.com/recharts/recharts/issues/172 */}
        <ResponsiveContainer width="99%" height={320}>
          <PieChart style={{ marginTop: '1rem' }}>
            <Pie
              data={chartData}
              dataKey="value"
              cy={120}
              innerRadius={60}
              outerRadius={120}
              paddingAngle={0}
            >
              {chartData.map((_entry, index) => (
                <Cell
                  key={`cell-${chartData}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </StyledPieChartContainer>
      <StyledLegendContainer>
        {chartData.map((connectionType, index) => (
          <Typography
            component="span"
            sx={{ margin: '1rem' }}
            key={connectionType.name}
          >
            <StihlIconLegend color={iconColors[index % colors.length]} />{' '}
            {connectionType.name}
          </Typography>
        ))}
      </StyledLegendContainer>
    </>
  );
};

export default StatisticsPieChart;
