/* eslint-disable jest/require-hook */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Global, css } from '@emotion/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-unassigned-import
import './i18n';
import App from './app/app';
import { stihlColor } from './base/stihl-material-ui/theme/stihl-style-guide';
import { AlertProvider } from './modules/app-alert/service/alert-provider';
import { authenticationClientConfig } from './modules/app-authentication/service/authentication-config';
import initAuthManager from './modules/app-authentication/service/authentication-session-service';
import { StoreProvider } from './modules/app-authentication/service/authentication-store-provider';
import { msalConfig } from './modules/app-authentication/service/azure/authentication-config';
import { DeviceManagementStoreProvider } from './modules/device-management/service/device-management-store/device-management-store-provider';

const queryCache = new QueryCache({});

const queryClient = new QueryClient({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  defaultOptions: { queries: { retry: false } },
  queryCache,
});

initAuthManager(authenticationClientConfig);

const msalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.querySelector('#root');

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <StoreProvider>
          <DeviceManagementStoreProvider>
            <AlertProvider>
              <QueryClientProvider client={queryClient}>
                {/* eslint-disable-next-line react/jsx-max-depth */}
                <Global
                  styles={css`
                    body {
                      background-color: ${stihlColor.background};
                    }
                  `}
                />
                <Suspense fallback="Loading...">
                  {/* eslint-disable-next-line react/jsx-max-depth */}
                  <App queryCache={queryCache} />
                </Suspense>
              </QueryClientProvider>
            </AlertProvider>
          </DeviceManagementStoreProvider>
        </StoreProvider>
      </MsalProvider>
    </StrictMode>,
  );
}
/* eslint-enable jest/require-hook */
