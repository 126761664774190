import CssBaseline from '@mui/material/CssBaseline';
import {
  useTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import type { FC, ReactNode } from 'react';
import { isStihlMaterialUiTheme, stihlMaterialUiTheme } from './stihl-theme';
import type { StihlTheme } from './stihl-theme';

export type StihlMaterialThemeProviderProps = {
  children: ReactNode | ReactNode[];
};

export const StihlMaterialThemeProvider: FC<
  StihlMaterialThemeProviderProps
> = ({ children }) => {
  const muiTheme = stihlMaterialUiTheme;
  return (
    // styled components, jss or alike are handled as the most important styles
    // see https://material-ui.com/styles/advanced/#injectfirst
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export function useStihlTheme(): StihlTheme {
  const theme = useTheme<StihlTheme>();
  if (!isStihlMaterialUiTheme(theme)) {
    throw new TypeError(`Missing context provider: StihlMaterialThemeProvider`);
  }
  return theme;
}
