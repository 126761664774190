import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SessionStore } from '../../../app-authentication/service/authentication-session-store';
import type { TermsAndConditionsBody } from '../../model/terms-and-conditions.model';
import { getTermsAndConditionsAcceptance } from '../terms-and-conditions-api-client/terms-and-conditions-api-client';

export function useTermsAndConditions(
  session: SessionStore,
): UseQueryResult<TermsAndConditionsBody, Error> {
  return useQuery<TermsAndConditionsBody, Error>({
    // this will only be loaded once (since user does not change during app runtime)
    // so it's ok if the user info is not contained within the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['termsAndConditionsAcceptance'],
    queryFn: async () => getTermsAndConditionsAcceptance(session),
    refetchOnWindowFocus: false,
  });
}
