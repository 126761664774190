import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import type { JSX, ChangeEvent, ReactNode } from 'react';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useDeviceManagementStore } from '../../device-management/service/device-management-store/device-management-store-provider';
import { TABBAR_HEIGHT_IN_PX } from './sidebar';

// ISP not built for mobile - Warning ignored: "Unexpected browser feature "css-sticky" is only partially supported by UC Browser for Android 13.4"
const StyledAppBar = styled(AppBar)`
  position: sticky;
  inline-size: auto;
  background-color: ${stihlColor.greyMid};
  box-shadow: none;
  inset-block-start: 0;
`;

const StyledToolbar = styled(Toolbar)`
  color: ${stihlColor.black};
  background-color: ${stihlColor.greyMid};
`;

const StyledTabPanelBody = styled.div`
  block-size: 100%;
  padding: 1rem;
`;

const TabPanelWrapper = styled.div`
  flex: 1;

  @media screen and (min-width: 2000px) {
    padding: 1rem;
  }
`;

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index } = props;

  return (
    <TabPanelWrapper role="tabpanel" hidden={value !== index}>
      {value === index && <StyledTabPanelBody>{children}</StyledTabPanelBody>}
    </TabPanelWrapper>
  );
};

export type TabData = {
  label: string;
  hasBadge: boolean;
};

type TabBarBaseProps = {
  value?: number;
  handleChange?: (_: ChangeEvent<unknown>, newValue: number) => void;
  tabs: TabData[];
  children?: ReactNode;
};

export const TabsBase = ({
  value,
  handleChange,
  tabs,
  children,
}: TabBarBaseProps): JSX.Element => {
  const [store] = useDeviceManagementStore();

  const badgeNumber = store.multiAssignmentDevices.size;
  const displayBadgeNumber: string | number =
    badgeNumber >= 1000 ? '⬤' : badgeNumber;

  return (
    <StyledAppBar data-testid="tabs-bar">
      <StyledToolbar
        style={{ minHeight: `${TABBAR_HEIGHT_IN_PX}px`, padding: '0' }}
      >
        <Tabs
          TabIndicatorProps={{
            style: {
              blockSize: '5px',
              backgroundColor: `${stihlColor.stihlOrange}`,
            },
          }}
          value={value}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              label={
                tab.hasBadge ? (
                  <Badge
                    badgeContent={displayBadgeNumber}
                    max={1000}
                    color="primary"
                    style={{ paddingRight: '0.85rem' }}
                    data-testid="badge"
                  >
                    {tab.label}
                  </Badge>
                ) : (
                  tab.label
                )
              }
              style={{
                color: `${stihlColor.black}`,
                minWidth: '140px',
                fontFamily: 'STIHL Contraface Text',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
              data-testid={tab.label}
            />
          ))}
        </Tabs>
        {children}
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default TabsBase;
