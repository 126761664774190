import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import SearchDeviceBar from './search-device-bar';

const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  block-size: 3.25rem;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

export type DeviceSearchCardProps = {
  isLoading: boolean;
};

const DeviceSearchCard = ({
  isLoading,
}: DeviceSearchCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <Typography variant="h3" data-testid="searchTitle">
          {t('deviceSearch.title')}
        </Typography>
      </CardHeader>
      <StyledCardContent>
        <Typography> {t('deviceSearch.hint')}</Typography>
        <SearchDeviceBar isLoading={isLoading} />
      </StyledCardContent>
    </Card>
  );
};
export default DeviceSearchCard;
