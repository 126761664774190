import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectionDataType } from '../../../../model/connection.model';
import {
  cellularConnectedColor,
  deviceConnectedColor,
  wifiConnectedColor,
} from './connection-history-tooltip';

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledInputLabel = styled(InputLabel)<{ labelColor: string }>`
  color: ${(props) => props.labelColor};
`;

const StyledCheckbox = styled(Checkbox)<{ checkboxColor: string }>`
  &.Mui-checked {
    color: ${(props) => props.checkboxColor};
  }
`;

export type ConnectionHistoryLineSelectProps = {
  isWifiConnectedShown: boolean;
  isDeviceConnectedShown: boolean;
  isCellularConnectedShown: boolean;
  onLineSelect: (connectionDataType: ConnectionDataType) => void;
};

export const ConnectionHistoryLineSelect = ({
  isWifiConnectedShown,
  isDeviceConnectedShown,
  isCellularConnectedShown,
  onLineSelect,
}: ConnectionHistoryLineSelectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledInputLabel
        labelColor={wifiConnectedColor}
        htmlFor="isWifiConnectedShown"
        variant="standard"
      >
        <StyledCheckbox
          id="isWifiConnectedShown"
          checkboxColor={wifiConnectedColor}
          onChange={() => onLineSelect(ConnectionDataType.WifiConnected)}
          checked={isWifiConnectedShown}
        />
        {t('connectionHistory.wifiConnected')}
      </StyledInputLabel>

      <StyledInputLabel
        labelColor={deviceConnectedColor}
        htmlFor="isDeviceConnectedShown"
        variant="standard"
      >
        <StyledCheckbox
          id="isDeviceConnectedShown"
          checkboxColor={deviceConnectedColor}
          onChange={() => onLineSelect(ConnectionDataType.DeviceConnected)}
          checked={isDeviceConnectedShown}
        />
        {t('connectionHistory.deviceConnected')}
      </StyledInputLabel>

      <StyledInputLabel
        labelColor={cellularConnectedColor}
        htmlFor="isCellularConnectedShown"
        variant="standard"
      >
        <StyledCheckbox
          id="isCellularConnectedShown"
          checkboxColor={cellularConnectedColor}
          onChange={() => onLineSelect(ConnectionDataType.CellularConnected)}
          checked={isCellularConnectedShown}
        />
        {t('connectionHistory.mobileConnection')}
      </StyledInputLabel>
    </Container>
  );
};
